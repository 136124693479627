import { useApolloClient } from '@apollo/client';
import { Flex, Spinner, Text } from '@chakra-ui/react';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Table } from 'libs/shared/modules/table/src';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { ErrorMessage } from '@lon/shared/components';
import { QuerySsStandardsArgs } from '@lon/shared/gql/types';
import { useDebounce } from '@lon/shared/hooks';
import { LevelEnumType, useGetStandardsItemsQuery } from '@lon/shared/requests';
import { parseJSON } from '@lon/shared/utils';
import {
  GET_STANDARD_REPORT,
  ReponseObject,
  StandardRecord,
  StandardReportResponse,
  getColumns,
  getEnumKeyFromNumber,
  isValidEnumKey,
  mapStandardToStudent,
  prepareData,
  sortStandardsAlphaNumerically,
} from './duck';
import { Caption } from './components';

interface ReportProps {
  assignmentIds?: string[];
  teacherId?: string;
  schoolId?: string;
  districtId?: string;
}

const standardsPerPage = 10;
const itemsPerPage = 30;

const Report: React.FC<ReportProps> = ({
  assignmentIds = [],
  teacherId,
  schoolId,
  districtId,
}) => {
  const { t } = useTranslation();
  const client = useApolloClient();
  const [pageLoading, setPageLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [standardRecord, setStandardRecord] = useState<StandardRecord[]>([]);
  const firstElementRef = useRef<{ VarCharValue: string }[]>();
  const defaultLevelRef = useRef<string>('Level5');
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;
  const pageSize = searchParams.get('pageSize') || itemsPerPage;

  const standardsPage = Number(searchParams.get('standardsPage')) || 1;
  const classFilter = searchParams.get('class');
  const levelKey = searchParams.get('level') || undefined;

  const { loading: standardsItemsLoading } = useGetStandardsItemsQuery({
    variables: {
      filter: {
        teacherIds: teacherId ? [teacherId as string] : undefined,
        assignmentId: assignmentIds[0],
      },
    },
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      const params = Object.fromEntries(searchParams.entries());

      if (!data?.ssStandardsItems || !data?.ssStandardsItems?.length) {
        defaultLevelRef.current = 'Level5';
        setSearchParams({ ...params, level: 'Level5' });
      } else {
        const levels = data.ssStandardsItems
          .map((item) => item?.standard?.split('.'))
          .sort((a, b) => (b?.length || 0) - (a?.length || 0));
        const lowestLevel = levels[0]?.length;
        const level = getEnumKeyFromNumber(lowestLevel || 5);
        defaultLevelRef.current = level;
        setSearchParams({
          ...params,
          level,
        });
      }
    },
    onError() {
      const params = Object.fromEntries(searchParams.entries());
      defaultLevelRef.current = 'Level5';
      setSearchParams({ ...params, level: 'Level5' });
    },
  });

  const level =
    levelKey && isValidEnumKey(levelKey) ? LevelEnumType[levelKey] : undefined;

  useEffect(() => {
    if (standardsItemsLoading) {
      return;
    }

    setLoading(true);
    const fetchReports = async () => {
      const getQuery = (assignmentId?: string) =>
        client.query<StandardReportResponse, QuerySsStandardsArgs>({
          query: GET_STANDARD_REPORT,
          variables: {
            schoolId,
            districtId,
            assignmentId,
            level,
            itemsPerPage: 999,
            teacherId,
            ...(classFilter !== 'undefined' && { classId: classFilter }),
          },
        });

      try {
        const requests = assignmentIds.length
          ? assignmentIds?.map((assignmentId) => getQuery(assignmentId))
          : [getQuery()];

        const response = await Promise.all(requests);
        const result = response.reduce<StandardRecord[]>((acc, val) => {
          const json = val?.data.ssStandards?.resultSetJson || '{}';
          const parsedJson: ReponseObject = parseJSON(json);

          if (!parsedJson) {
            return acc;
          }

          if (!firstElementRef.current) {
            firstElementRef.current = parsedJson?.Rows?.shift()?.Data;
          } else {
            parsedJson?.Rows?.shift();
          }
          const preparedData = prepareData(parsedJson, firstElementRef.current);
          return acc.concat(preparedData);
        }, []);
        setStandardRecord(result);
      } catch (error) {
        console.log(error);
      } finally {
        setPageLoading(false);
        setLoading(false);
      }
    };

    fetchReports();
  }, [level, classFilter, standardsItemsLoading, assignmentIds?.length]);

  const { columnData, columns, total } = useMemo(() => {
    const standardToStudentMap = mapStandardToStudent(standardRecord);

    const allStandards =
      standardToStudentMap?.map((item) => {
        const { studentId, studentUsername, studentName, ...rest } = item;

        const data = Object.keys(rest).reduce<
          { standard: string; description?: string }[]
        >((acc, val) => {
          return [
            ...acc,
            {
              standard: val,
              description: (rest[val] as StandardRecord).description,
            },
          ];
        }, []);
        return data;
      }) || [];

    const uniqueStandards = allStandards
      .flat()
      .reduce<{ standard: string; description?: string }[]>((acc, current) => {
        if (!acc.find((item) => item.standard === current.standard)) {
          acc.push(current);
        }
        return acc;
      }, []);

    const sortedStandards = sortStandardsAlphaNumerically(uniqueStandards);

    const paginatedStandards = sortedStandards.slice(
      (standardsPage - 1) * standardsPerPage,
      standardsPage * standardsPerPage
    );

    const columns = getColumns(paginatedStandards);
    return {
      columnData: standardToStudentMap,
      columns,
      total: sortedStandards.length,
    };
  }, [standardRecord, standardsPage]);

  const usersHaveReports = columnData?.some(
    (data) => Object.keys(data).length > 3
  );

  const isLoading = pageLoading || loading || standardsItemsLoading;

  const debouncedPaginationChange = useDebounce(
    (page: number, pageSize: number) => {
      const params = Object.fromEntries(searchParams.entries());
      setSearchParams({
        ...params,
        page: String(page + 1),
        pageSize: String(pageSize || itemsPerPage),
      });
    },
    { timeoutMs: 100 }
  );

  return (
    <Flex direction="column" grow={1}>
      {isLoading ? (
        <Flex
          grow={1}
          direction="column"
          borderRadius="6px"
          backgroundColor="white"
        >
          <Flex justify="space-between" align="center" h="full">
            <Spinner mx="auto" color="primary.800" />
          </Flex>
        </Flex>
      ) : (
        <>
          <Table
            renderGroupActions={() => (
              <Caption
                total={total}
                itemsPerPage={standardsPerPage}
                defaultLevel={defaultLevelRef.current}
              />
            )}
            useDefaultSortingIcon={false}
            showGroupActionsIfEmpty
            // case when there are users but they do not have reports
            data={usersHaveReports ? columnData : []}
            columns={columns}
            enableClientSideSorting
            enableClientSidePagination
            enableVerticalBorders
            syncExternalPagination={{
              pageIndex: Number(page) === 0 ? 0 : Number(page) - 1,
              pageSize: Number(pageSize),
            }}
            paginationProps={{
              initialPageSize: Number(pageSize) as 10 | 20 | 50,
            }}
            onPaginationChange={(page, pageSize) => {
              debouncedPaginationChange(page, pageSize);
            }}
            containerProps={{
              boxShadow: 'none',
              borderBottom: 'none',
              borderRadius: '0.375rem 0.375rem 0 0',
            }}
            emptyMessage={
              <ErrorMessage
                hasBack={false}
                hasHome={false}
                title={t('reportsDashboard.noDataTitle')}
                body={
                  <Text variant="n1">
                    {t('reportsDashboard.noDataMessage')}
                  </Text>
                }
              />
            }
          />
        </>
      )}
    </Flex>
  );
};

export default Report;
