import chmln from '@chamaeleonidae/chmln';
import { isEmpty } from 'lodash-es';
import { useEffect, useRef } from 'react';
import { useAuth } from '@lon/shared/hooks';
import { UserTypeEnum } from '@lon/shared/requests';
import { useGetActiveContentGroupsWithSchoolsQuery } from '@lon/shared/requests';
import { chamToken } from './constants';

export const useChameleon = () => {
  const chameleonRef = useRef(false);
  const { data, loading: contentGroupsLoading } =
    useGetActiveContentGroupsWithSchoolsQuery();
  const [{ user }] = useAuth();

  const contentGroups =
    data?.activeContentGroupsWithSchools[0]?.contentGroups || [];

  useEffect(() => {
    if (isEmpty(user) || contentGroupsLoading) {
      return;
    }

    let role = '';

    if (
      user?.type === UserTypeEnum.Parent ||
      user?.type === UserTypeEnum.Student
    ) {
      const type = user.type;
      role = type.charAt(0).toUpperCase() + type.slice(1);
    } else {
      user?.userRoles
        ?.filter((role) => !!role.code)
        ?.forEach((r) => {
          role = role += r.code;
        });
    }

    if (!chameleonRef.current && !user.impersonated && !!role) {
      chameleonRef.current = true;

      chmln.init(chamToken, { fastUrl: 'https://fast.chameleon.io/' });

      chmln.identify(user?.userId || '', {
        email: user?.email || '',
        name: `${user?.firstName} ${user?.lastName}`,
        role: role,
        product: contentGroups.join(','),
        version: 'STEMscopes 4.0',
        district: user?.districtId || '',
        state: user?.state || '',
      });
    }
  }, [contentGroups]);
};
