import useAuth from '../use-auth';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@lon/shared/configs';
import { Preferences } from '@lon/shared/types';
import {
  getSignature,
  hashValue,
  parseJSON,
  patchLocalStorage,
} from '@lon/shared/utils';

const useCurrentSchoolId = (): [string, (schoolId: string) => void] => {
  const [{ user }] = useAuth();
  const applicationSettings = useSelector(
    (state: RootState) => state.applicationSettings
  );
  const preferences = parseJSON<Preferences>(applicationSettings?.preferences);
  const [currentSchoolId, setCurrentSchoolId] = useState<string>('');
  useEffect(() => {
    if (user?.userId && user?.schoolIds && !currentSchoolId) {
      const lastSchoolIdsBySignature =
        JSON.parse(localStorage.getItem('lastSchoolIdsBySignature') || '{}') ||
        {};
      const signature = getSignature();
      const school =
        lastSchoolIdsBySignature[signature] || preferences?.defaultSchool;

      if (school && user.schoolIds.includes(school)) {
        setCurrentSchoolId(school);
      } else {
        setCurrentSchoolId(
          preferences?.defaultSchool &&
            user.schoolIds.includes(preferences?.defaultSchool)
            ? preferences?.defaultSchool
            : user.schoolIds[0]
        );
      }
    }
  }, [user, preferences]);

  const updateCurrentSchoolId = useCallback(
    (schoolId: string) => {
      setCurrentSchoolId(schoolId);

      hashValue(user.userId).then((value) => {
        patchLocalStorage('lastSchoolIdsBySignature', {
          [value]: schoolId,
        });
      });
    },
    [user?.userId]
  );

  return [currentSchoolId, updateCurrentSchoolId] as [
    string,
    (schoolId: string) => void
  ];
};

export default useCurrentSchoolId;
