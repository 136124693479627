import { ManageSubgroupsContext } from '../../duck';
import { Flex, IconButton, Link, Tag, Text, Tooltip } from '@chakra-ui/react';
import { RiArrowLeftSLine } from '@react-icons/all-files/ri/RiArrowLeftSLine';
import { RiArrowRightSLine } from '@react-icons/all-files/ri/RiArrowRightSLine';
import { sanitize } from 'dompurify';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink, generatePath, useParams } from 'react-router-dom';
import { ErrorMessage, useToast } from '@lon/shared/components';
import { IdPart } from '@lon/shared/enums';
import { Table } from '@lon/shared/modules/table';
import {
  useAddStudentsToStudentSubgroupMutation,
  useGetClassAndSubgroupQuery,
  useRemoveStudentsFromStudentSubgroupMutation,
} from '@lon/shared/requests';
import { clearCache } from '@lon/shared/utils';
import { routes } from '@lon/shared/configs';
import { ClassContext } from '@lon/suit/contexts';
import { selectors, subgroupStudentsUtils } from './duck';

const SubgroupStudents = () => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const { isMainTeacher } = useContext(ClassContext);
  const [subgroupStudentsSelect, setSubgroupStudentsSelect] = useState<
    string[]
  >([]);
  const [classStudentsSelect, setClassStudentsSelect] = useState<string[]>([]);
  const { classId, subgroupId } = useParams();
  const [watchResetSubgroups, setWatchResetSubgroups] = useState(false);
  const [watchResetClasses, setWatchResetClasses] = useState(false);
  const {
    resetClassPagination,
    setResetClassPagination,
    resetSubgroupPagination,
    setResetSubgroupPagination,
  } = useContext(ManageSubgroupsContext);
  const { data, loading } = useGetClassAndSubgroupQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      classId: `${IdPart.Classes}${classId}`,
      subgroupId: `${IdPart.Subgroups}${subgroupId}`,
    },
    skip: !subgroupId || !classId,
  });

  useEffect(() => {
    setSubgroupStudentsSelect([]);
    setClassStudentsSelect([]);
    setWatchResetSubgroups(true);
    setWatchResetClasses(true);
  }, [subgroupId]);

  const [addToSubgroup, { loading: addLoading }] =
    useAddStudentsToStudentSubgroupMutation({
      refetchQueries: ['getClassAndSubgroup'],
      update: () => {
        clearCache(`Class:${IdPart.Classes}${classId}`);
      },
    });
  const [removeFromSubgroup, { loading: removeLoading }] =
    useRemoveStudentsFromStudentSubgroupMutation({
      refetchQueries: ['getClassAndSubgroup'],
      update: () => {
        clearCache(`Class:${IdPart.Classes}${classId}`);
      },
    });

  const classStudents = useMemo(
    () => selectors.getClassStudents(data),
    [
      data?.studentSubgroup?._id,
      data?.studentSubgroup?.students?.collection?.length,
    ]
  );

  const subgroupStudents = useMemo(
    () => selectors.getSubgroupStudents(data),
    [
      data?.studentSubgroup?._id,
      data?.studentSubgroup?.students?.collection?.length,
    ]
  );

  const columns = useMemo(subgroupStudentsUtils.getColumns, []);

  const assignToSubgroup = async () => {
    try {
      await addToSubgroup({
        variables: {
          input: {
            subgroupId: subgroupId as string,
            studentIds: classStudentsSelect,
          },
        },
        onCompleted() {
          toast({
            position: 'bottom',
            duration: 5000,
            variant: 'success-light',
            title: t('subgroups.addedToSubgroupTitle', {
              count: classStudentsSelect.length,
            }),
            description: sanitize(
              t('subgroups.addedToSubgroupDescription', {
                count: classStudentsSelect.length,
                subgroupName: data?.studentSubgroup?.name,
                interpolation: {
                  escapeValue: false,
                },
              })
            ),
            isClosable: true,
          });
        },
      });
    } catch (error) {
      console.error(error);
    }

    setWatchResetClasses(true);
    setClassStudentsSelect([]);
  };

  const unAssignFromSubgroup = async () => {
    await removeFromSubgroup({
      variables: {
        input: {
          subgroupId: subgroupId as string,
          studentIds: subgroupStudentsSelect,
        },
      },
    });

    setWatchResetSubgroups(true);
    setSubgroupStudentsSelect([]);
  };

  return (
    <Flex direction="row" gap={6} mt={4} flexGrow={1} height="0">
      <Flex w="calc(50% - 40px)" direction="column" data-testid="class-table">
        <Flex
          border="1px solid"
          borderBottom="none"
          borderTopRadius="md"
          borderColor="secondary.200"
          bgColor="white"
          py={4}
          px={8}
          alignItems="center"
          gap="4"
        >
          <>
            <Tooltip
              variant="dark"
              label={sanitize(
                t('subgroups.studentsIn', {
                  name: `${data?.class?.name || ''}`,
                  interpolation: {
                    escapeValue: false,
                  },
                })
              )}
            >
              <Text
                isTruncated
                variant="h5"
                color="primary.800"
                maxWidth="100%"
              >
                {sanitize(
                  t('subgroups.studentsIn', {
                    name: `${data?.class?.name || ''}`,
                    interpolation: {
                      escapeValue: false,
                    },
                  })
                )}
              </Text>
            </Tooltip>
            <Tag variant="primary" bgColor="primary.30" minW="unset">
              <Text variant="s2" color="primary.700">
                {classStudents.length}
              </Text>
            </Tag>
          </>
        </Flex>
        <Table
          tableSize="sm"
          // TODO remove ignore
          // @ts-ignore
          data={classStudents}
          getRowId={(row, index) => row.id || index.toString()}
          columns={columns}
          loading={loading}
          enableRowSelection
          enableClientSideSorting
          enableClientSidePagination
          showGroupActionsIfEmpty
          isRowSelectionDisabled={!isMainTeacher}
          onRowSelectStateChange={(data) => {
            if (data.length !== classStudentsSelect.length) {
              setClassStudentsSelect(data);
            }
          }}
          resetRows={{
            status: watchResetClasses,
            onReset: () => setWatchResetClasses(false),
          }}
          resetPagination={{
            status: resetClassPagination,
            onReset: () => {
              setResetClassPagination(false);
            },
          }}
          skeletonRowCount={10}
          skeletonRowHeight={9}
          paginationProps={{
            paginationContainerProps: {
              position: 'sticky',
              bottom: 0,
              px: 2,
              py: 1.5,
            },
          }}
          headerProps={{
            px: '1rem',
          }}
          bodyProps={{
            padding: '0.75rem 1rem',
          }}
          navToConfig={{
            hasPagination: false,
          }}
          hasNextTable={!!subgroupStudents.length}
          emptyMessage={
            classStudents.length === 0 &&
            data?.studentSubgroup?.students?.collection?.length !== 0 ? (
              <ErrorMessage
                hasBack={false}
                hasHome={false}
                testId="class-empty-message"
                title={
                  <Text
                    variant="h4"
                    fontWeight="700"
                    mb="4"
                    wordBreak="break-word"
                  >
                    {sanitize(
                      t('subgroups.studentsAlreadyMoved', {
                        name: data?.studentSubgroup?.name,
                        className: data?.class?.name,
                        interpolation: {
                          escapeValue: false,
                        },
                      })
                    )}
                  </Text>
                }
              />
            ) : (
              <ErrorMessage
                hasBack={false}
                hasHome={false}
                testId="class-empty-message"
                title={
                  <Text
                    variant="h4"
                    fontWeight="700"
                    mb="4"
                    wordBreak="break-word"
                  >
                    <Trans
                      i18nKey={sanitize(
                        t(
                          `subgroups.${
                            isMainTeacher
                              ? 'emptyClassTitle'
                              : 'emptyClassTitleAdditionalView'
                          }`,
                          {
                            className: data?.class?.name,
                            interpolation: {
                              escapeValue: false,
                            },
                          }
                        )
                      )}
                    />
                  </Text>
                }
                body={
                  isMainTeacher ? (
                    <Text variant="n3">
                      <Trans i18nKey="subgroups.emptyClassBody">
                        You can add students from the School list at the
                        <Link
                          as={RouterLink}
                          to={generatePath(
                            routes.classes.show.roster.manageStudents,
                            {
                              classId,
                            }
                          )}
                          textDecor="underline"
                        >
                          Manage Students
                        </Link>
                        tab.
                      </Trans>
                    </Text>
                  ) : (
                    <></>
                  )
                }
              />
            )
          }
        />
      </Flex>
      <Flex direction="column" justify="center" gap={2}>
        <IconButton
          mt={1}
          size="sm"
          variant="solid"
          fontSize="lg"
          aria-label={t('subgroups.removeLabel')}
          icon={<RiArrowLeftSLine />}
          onClick={unAssignFromSubgroup}
          isDisabled={!subgroupStudentsSelect.length || !isMainTeacher}
          isLoading={removeLoading}
        />
        <IconButton
          mt={1}
          size="sm"
          variant="solid"
          fontSize="lg"
          aria-label={t('subgroups.addLabel')}
          icon={<RiArrowRightSLine />}
          isDisabled={!classStudentsSelect.length || !isMainTeacher}
          onClick={assignToSubgroup}
          isLoading={addLoading}
        />
      </Flex>
      <Flex
        w="calc(50% - 40px)"
        direction="column"
        data-testid="subgroups-table"
      >
        <Flex
          border="1px solid"
          borderBottom="none"
          borderTopRadius="md"
          borderColor="secondary.200"
          bgColor="white"
          py={4}
          px={8}
          alignItems="center"
          gap="4"
        >
          <>
            <Tooltip
              variant="dark"
              label={sanitize(
                t('subgroups.studentsIn', {
                  name: data?.studentSubgroup?.name,
                  interpolation: {
                    escapeValue: false,
                  },
                })
              )}
            >
              <Text
                isTruncated
                variant="h5"
                color="primary.800"
                maxWidth="100%"
              >
                {sanitize(
                  t('subgroups.studentsIn', {
                    name: data?.studentSubgroup?.name,
                    interpolation: {
                      escapeValue: false,
                    },
                  })
                )}
              </Text>
            </Tooltip>
            <Tag variant="primary" bgColor="primary.30" minW="unset">
              <Text variant="s2" color="primary.700">
                {data?.studentSubgroup?.students?.collection?.length}
              </Text>
            </Tag>
          </>
        </Flex>
        <Table
          tableSize="sm"
          // TODO remove ignore
          // @ts-ignore
          data={subgroupStudents}
          getRowId={(row, index) => row.id || index.toString()}
          columns={columns}
          loading={loading}
          enableClientSideSorting
          enableRowSelection
          enableClientSidePagination
          showGroupActionsIfEmpty
          isRowSelectionDisabled={!isMainTeacher}
          tableOrder={2}
          hasPreviousTable={!!classStudents.length}
          onRowSelectStateChange={(data) => {
            if (data.length !== subgroupStudentsSelect.length) {
              setSubgroupStudentsSelect(data);
            }
          }}
          navToConfig={{
            hasPagination: false,
          }}
          resetRows={{
            status: watchResetSubgroups,
            onReset: () => setWatchResetSubgroups(false),
          }}
          resetPagination={{
            status: resetSubgroupPagination,
            onReset: () => {
              setResetSubgroupPagination(false);
            },
          }}
          skeletonRowCount={10}
          skeletonRowHeight={9}
          paginationProps={{
            paginationContainerProps: {
              position: 'sticky',
              bottom: 0,
              px: 2,
              py: 1.5,
            },
          }}
          headerProps={{
            px: '1rem',
          }}
          bodyProps={{
            padding: '0.75rem 1rem',
          }}
          emptyMessage={
            <ErrorMessage
              hasBack={false}
              hasHome={false}
              testId="subgroup-empty-message"
              title={
                <Text
                  variant="h4"
                  fontWeight="700"
                  mb="4"
                  wordBreak="break-word"
                >
                  <Trans
                    i18nKey={sanitize(
                      t(
                        `subgroups.${
                          isMainTeacher
                            ? 'emptySubgroupTitle'
                            : 'emptySubgroupTitleAdditionalView'
                        }`,
                        {
                          name: data?.studentSubgroup?.name,
                          interpolation: {
                            escapeValue: false,
                          },
                        }
                      )
                    )}
                  />
                </Text>
              }
              body={
                isMainTeacher ? (
                  <Text variant="n3">
                    <Trans
                      i18nKey={t('subgroups.emptySubgroupBody') as string}
                    />
                  </Text>
                ) : (
                  <></>
                )
              }
            />
          }
        />
      </Flex>
    </Flex>
  );
};

export default SubgroupStudents;
