export enum SettingsType {
  INITIAL = 'INITIAL',
  NEW = 'NEW',
  EDIT = 'EDIT',
}

export enum TemplateTitleValidationStatusType {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  REMAIN = 'REMAIN',
  ORIGIN = 'ORIGIN', // for edit template original name
  ERROR = 'ERROR', // when API throw error
}
