import { isEmpty } from 'lodash-es';
import { createBrowserRouter } from 'react-router-dom';
import { ProfileTypeEnum } from '@lon/shared/enums';
import { RoleCodeEnum } from '@lon/shared/gql/types';
import { useAuth } from '@lon/shared/hooks';
import { UserTypeEnum } from '@lon/shared/requests';
import { ImpersonatedUserLS } from '@lon/shared/types';
import { routes } from '@lon/shared/configs';
import CampusLeaderRouter from './CampusLeaderRouter';
import LtiRouter from './LtiRouter';
import ParentRouter from './ParentRouter';
import StudentRouter from './StudentRouter';
import TeacherRouter from './TeacherRouter';

const isLtiUser = window.location.pathname.includes('/lti');

const useCurrentRouter = () => {
  const impersonateData = JSON.parse(
    localStorage.getItem('impersonate') || '{}'
  ) as ImpersonatedUserLS;
  const impersonate = !isEmpty(impersonateData);
  const [{ user, profileType }] = useAuth();
  const userType = user.type;

  switch (true) {
    case isLtiUser:
      return createBrowserRouter(LtiRouter, {
        basename: routes.root(),
      });
    case (!impersonate &&
      profileType &&
      [
        ProfileTypeEnum.CAMPUS_LEADER,
        ProfileTypeEnum.CAMPUS_CURRICULUM_LEADER,
        ProfileTypeEnum.DISTRICT_CURRICULUM_LEADER,
      ].includes(profileType)) ||
      [
        RoleCodeEnum.CampusLeader,
        RoleCodeEnum.CampusCurriculumLeader,
        RoleCodeEnum.DistrictCurriculumLeader,
      ].includes(impersonateData.type):
      return createBrowserRouter(CampusLeaderRouter, {
        basename: routes.root(),
      });
    case (!impersonate && userType === UserTypeEnum.Staff) ||
      impersonateData.type === RoleCodeEnum.Teacher:
      return createBrowserRouter(TeacherRouter, {
        basename: routes.root(),
      });
    case userType === UserTypeEnum.Parent:
      return createBrowserRouter(ParentRouter, {
        basename: routes.root(),
      });
    case (!impersonate && userType === UserTypeEnum.Student) ||
      impersonateData.type === RoleCodeEnum.Student:
      return createBrowserRouter(StudentRouter, {
        basename: routes.root(),
      });
    default:
      return createBrowserRouter(TeacherRouter, {
        basename: routes.root(),
      });
  }
};

export default useCurrentRouter;
