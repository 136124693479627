import { Flex, Link, Text } from '@chakra-ui/react';
import React from 'react';
import { isProdCluster } from '@lon/shared/constants';
import { getEnvVariable } from '@lon/shared/utils';

export const stemDomain = getEnvVariable(
  'NX_STEMSCOPES_URL',
  'https://dev2019.stemscopes.com'
);

export const prodStemDomain = 'https://www.acceleratelearning.com';

const Footer = ({ mx = 6, mb = 6 }: { mx?: number; mb?: number }) => {
  return (
    <Flex
      borderRadius="6px"
      py={4}
      px={4}
      mx={mx}
      mb={mb}
      alignItems="center"
      justifyContent="center"
      color="primary.800"
      boxShadow="0 5px 20px 12px rgba(43,54,70,.07)"
    >
      <Text mr={2}>&copy; {new Date().getFullYear()}</Text>
      <Link
        variant="primary"
        href={isProdCluster() ? prodStemDomain : `${stemDomain}/home.php`}
      >
        Accelerate Learning Inc.
      </Link>
      <Link
        variant="primary"
        ml={8}
        href={isProdCluster() ? prodStemDomain : `${stemDomain}/home.php`}
      >
        Terms and Conditions
      </Link>
    </Flex>
  );
};

export default Footer;
