import { types as ElementListTypes } from '../../duck';
import { ElementCard } from '../element-card';
import { useGetIconQuery } from '@lon/shared/requests';

const SuitElementCardWrapper: React.FC<
  ElementListTypes.WrapperElementCardProps
> = ({ image, ...props }) => {
  const { data: iconResp, loading } = useGetIconQuery({
    skip: !image,
    variables: {
      filter: {
        type: image,
      },
    },
  });

  const iconPath = iconResp?.icons?.collection?.find(
    (icon) => icon?.filePath
  )?.filePath;

  return <ElementCard {...props} iconPath={iconPath} iconLoading={loading} />;
};

export default SuitElementCardWrapper;
