import { gql } from '@apollo/client';
import { Assignment, DistrictClassAssignmentsType } from '@lon/shared/requests';
import { GqlResponse } from '@lon/shared/types';

export type AssignmentResponse = GqlResponse<Assignment, 'assignment'>;
export const GET_ASSIGNMENT = gql`
  query getAssignment($id: String, $schoolId: String) {
    assignment(id: $id, schoolId: $schoolId) {
      assignmentId
      originLearnosityId
      studentAssignments {
        id
        studentId
        status
        answers
        sessionId
      }
    }
  }
`;

export type DistrictAssignmentsResponse = GqlResponse<
  DistrictClassAssignmentsType,
  'districtAssignments'
>;
export const GET_DISTRICT_ASSIGNMENT = gql`
  query getDistrictAssignments($filter: DistrictAssignmentsFilter) {
    districtAssignments(filter: $filter) {
      collection {
        assignmentId
        originLearnosityId
        studentAssignments {
          id
          studentId
          answers
          status
        }
      }
    }
  }
`;
