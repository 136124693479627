import { types as ElementListTypes } from '../../duck';
import { ElementCard } from '../element-card';

const ContentPlayerElementCardWrapper: React.FC<
  ElementListTypes.WrapperElementCardProps
> = ({ image, ...props }) => {
  // for content-player create manual path as content-player does not have GraphQL
  const iconName = image
    ? `${image?.split(' ')?.[0]?.toLocaleLowerCase()}.png`
    : '';
  const iconPath = image ? `/icons/${iconName}` : '';

  return <ElementCard {...props} iconPath={iconPath} />;
};

export default ContentPlayerElementCardWrapper;
