import { AsyncOutlet } from '../../../../../async-outlet';
import { BackButton } from '../../../../../back-button';
import { PageContent } from '../../../../../page-content';
import LessonScopeHeader from '../lesson-scope-header';
import Menubar from '../menubar';
import Sidebar from '../sidebar';
import { Flex, Tag, Text } from '@chakra-ui/react';
import { sanitize } from 'dompurify';
import get from 'lodash-es/get';
import React, { Suspense, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { generatePath, useMatch, useParams } from 'react-router-dom';
import { routes } from '@lon/shared/configs';
import { FullScreenContext, ScopeContext } from '@lon/shared/contexts';
import { Element, ScopeType, TransformedSection } from '@lon/shared/types';
import { Standard } from '@lon/shared/types';
import { checkContentPlayer } from '@lon/shared/utils';

const ScopeView: React.FC<{
  loading: boolean;
  elementsBySection: Record<string, any>;
  refetch: () => {};
  attempts: number;
  setAttempts: React.Dispatch<React.SetStateAction<number>>;
  projectType: string;
  called: boolean;
  metadata: any;
  scope: any;
  scopeStandards?: Standard[];
  sections?: TransformedSection[];
}> = ({
  loading,
  elementsBySection,
  refetch,
  attempts,
  setAttempts,
  projectType,
  called,
  metadata,
  scope,
  scopeStandards,
  sections,
}) => {
  const { t } = useTranslation();
  const isLandingPage = !!useMatch(routes.scopes.show);
  const params = useParams();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const isContentPlayer = checkContentPlayer();

  const { elementId, sectionId, projectId } = params as {
    scopeId: string;
    elementId: string;
    sectionId: string;
    projectId: string;
  };

  const currentElement = get(
    elementsBySection as any,
    `[${sectionId}][${elementId}]`
  ) as Element;

  const isDailyNumeracy = scope?.projectType === ScopeType.DailyNumeracy;
  const isHowTo = scope?.projectType === ScopeType.HowTo;
  const isNumeracyLanding = isDailyNumeracy && isLandingPage;
  const withoutSidebar = isNumeracyLanding || loading;

  const backButtonPath = !isContentPlayer
    ? routes.scopes.root
    : generatePath(routes.scopes.projectScopes, {
        projectId,
      });

  const scopeHeader = useMemo(() => {
    switch (scope?.projectType) {
      case ScopeType.LessonScope:
        return scope?.sections?.length ? (
          <Flex
            minW="0"
            width="full"
            bgColor="var(--grey-6)"
            direction="column"
          >
            <LessonScopeHeader sections={scope?.sections} />
          </Flex>
        ) : null;
      case ScopeType.Scope:
      case ScopeType.HowTo:
        return scope?.sections?.length ? (
          <Flex
            minW="0"
            width="full"
            bgColor="var(--grey-6)"
            direction="column"
          >
            <Menubar sections={scope?.sections} sectionsLoading={loading} />
          </Flex>
        ) : null;
      default:
        return null;
    }
  }, [scope, loading]);

  const scopeName =
    (isHowTo
      ? `${scope?.metadata?.projectType}: ${scope?.metadata?.displayName}`
      : scope?.displayName) || '';

  const howToElementName = !currentElement
    ? 'Home'
    : currentElement?.metadata?.elementName;

  const pageTitle = sanitize(
    t('scopes.name', {
      scopeName,
      separator: currentElement?.metadata?.elementName || isHowTo ? '|' : '',
      elementName: isHowTo
        ? howToElementName
        : currentElement?.metadata?.elementName,
      interpolation: {
        escapeValue: false,
      },
    })
  );

  return (
    <FullScreenContext.Provider value={{ isFullScreen, setIsFullScreen }}>
      <ScopeContext.Provider
        value={{
          loading,
          elementsBySection,
          refetch,
          attempts,
          setAttempts,
          projectType: scope?.projectType,
          called,
          metadata: scope?.metadata,
          sections,
          bannerImg: scope?.banner,
          pageTitle,
        }}
      >
        {!isFullScreen && !withoutSidebar && (
          <Sidebar
            isLoading={loading || !called}
            scope={scope as any}
            standards={scopeStandards}
          />
        )}
        <PageContent
          withoutSidebar={withoutSidebar}
          portalContentHeight={!isFullScreen && elementId ? 62 : 0}
          pageTitle={
            isHowTo ? (
              <>
                <Tag
                  variant="subtle"
                  size="md"
                  borderRadius="12px"
                  borderWidth="1px"
                  borderColor="calendar.blue.800"
                >
                  <Text variant="tag" color="calendar.blue.800">
                    {scope?.metadata?.curriculumArea}
                  </Text>
                </Tag>
                <Text
                  variant="h4"
                  color="white"
                  as="h1"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  whiteSpace="nowrap"
                >
                  {pageTitle}
                </Text>
              </>
            ) : (
              pageTitle
            )
          }
          isFullScreen={isFullScreen}
          {...(isFullScreen
            ? {
                wrapperProps: { background: 'unset' },
                bodyProps: {
                  p: 0,
                  pr: 0,
                },
              }
            : {})}
          headerElements={
            <BackButton
              to={backButtonPath}
              label={t('backButton.scope.label')}
              tooltipLabel={t('backButton.scope.tooltipLabel')}
            />
          }
          toolbar={!isFullScreen && scopeHeader}
        >
          <Helmet>
            <title>{pageTitle}</title>
          </Helmet>
          <Suspense fallback={null}>
            <AsyncOutlet />
          </Suspense>
        </PageContent>
      </ScopeContext.Provider>
    </FullScreenContext.Provider>
  );
};

export default ScopeView;
