import { Box, Flex, Text } from '@chakra-ui/react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../../../../../../../../icon';
import { useGetTheme } from '@lon/shared/hooks';
import { getThemeStyleValue } from '@lon/shared/utils';
import { ScopeContext } from '@lon/shared/contexts';

const Banner: React.FC = () => {
  const { t } = useTranslation();
  const currentTheme = useGetTheme();
  const { bannerImg } = useContext(ScopeContext);
  const isBannerProvided = !!bannerImg;
  const imageUrl = isBannerProvided
    ? bannerImg
    : 'suit/assets/numeracy-banner.png';

  return (
    <Flex
      justifyContent="space-between"
      background={getThemeStyleValue(
        `linear-gradient(271deg, rgba(19, 95, 55, 0.00) 21.56%, rgba(7, 97, 104, 0.80) 90.39%), linear-gradient(90deg, rgba(30, 65, 124, 0.00) 46.08%, rgba(30, 65, 124, 0.50) 100%), url(${imageUrl}) lightgray 49% 50% / 109.861% 485.104% no-repeat`,
        'secondary.1000'
      )(currentTheme)}
      backgroundSize="cover"
      border={getThemeStyleValue('none', '1px solid #FFFFFF')(currentTheme)}
      color="white"
      minH="244px"
      display="flex"
      borderRadius={{ base: 'none', b768: '6px' }}
      overflow="hidden"
      boxShadow="0px 5px 20px -2px #2B364612"
    >
      <Flex
        px={{ base: 6, b1280: '3.125rem' }}
        py="1.875rem"
        maxW="600px"
        justifyContent="center"
        flexDir="column"
        gap={4}
        zIndex={1}
      >
        <Text variant="h1" textTransform="uppercase">
          {t('factFluency.bannerTitle')}
        </Text>
        <Text variant="n1" fontStyle="italic">
          {t('factFluency.bannerDescription')}
        </Text>
      </Flex>
      <Box
        display={{ base: 'none', b850: 'block' }}
        pos="absolute"
        right={{ base: '1%', b1024: '4.7%' }}
        top="0px"
      >
        <Icon name="numeracyRobo" />
      </Box>
    </Flex>
  );
};

export default Banner;
