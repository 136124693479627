import { Pagination } from '../../../pagination';
import { Flex, VisuallyHidden } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePrevious } from '@lon/shared/hooks';
import { useThemeStyleValue } from '@lon/shared/hooks';
import { GetScopesQuery } from '@lon/shared/types';
import { checkContentPlayer } from '@lon/shared/utils';
import { RefetchContext, listConstants, listTypes } from './duck';
import { List } from './components';

const ScopesList: React.FC<listTypes.ScopesListProps> = ({
  type,
  gridGap,
  backgroundColor = 'blue.75',
  itemPath,
  data,
  loading,
  refetch,
  studentsLoading,
}) => {
  const { t } = useTranslation();

  const [attempts, setAttempts] = useState<number>(0);
  const prevAttempts = usePrevious(attempts);

  const refetchValues = useMemo(
    () => ({
      refetch,
      attempts: prevAttempts,
      setAttempts,
    }),
    [refetch, prevAttempts, setAttempts]
  );

  const scopes = (data as GetScopesQuery)?.scopes?.collection;

  return (
    <RefetchContext.Provider value={refetchValues}>
      <VisuallyHidden role="status" aria-live="polite">
        {loading || studentsLoading ? t('scopes.loadMessage') : ''}
      </VisuallyHidden>
      <VisuallyHidden role="status" aria-live="polite">
        {!loading || studentsLoading
          ? t('scopes.countOfResults', {
              count:
                (data as GetScopesQuery)?.scopes?.pageInfo?.totalCount || 0,
            })
          : ''}
      </VisuallyHidden>
      <Flex
        direction="column"
        grow="1"
        justify="space-between"
        backgroundColor={useThemeStyleValue(backgroundColor, 'secondary.950')}
        pb={{ md: 6, lg: 6, b1281: 0.5 }}
      >
        <List
          data={scopes}
          loading={loading || studentsLoading}
          skeletonsCount={listConstants.DEFAULT_ITEMS_COUNT}
          type={type}
          gridGap={gridGap}
          itemPath={itemPath}
        />
        {!checkContentPlayer() && (
          <Pagination
            total={(data as GetScopesQuery)?.scopes.pageInfo?.totalCount || 0}
            paginationWrapperProps={{ marginTop: 'auto' }}
            initialPageSize={listConstants.DEFAULT_ITEMS_COUNT}
            paginationConfig={listConstants.paginationConfig}
            onChangeComplete={() => {
              const content = document.querySelector('.page-content');
              content?.scrollTo(0, 0);
            }}
          />
        )}
      </Flex>
    </RefetchContext.Provider>
  );
};

export default ScopesList;
