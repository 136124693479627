import {
  Box,
  Button,
  Icon as ChakraIcon,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import parseISO from 'date-fns/parseISO';
import { sanitize } from 'dompurify';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { closeOutlined } from '@lon/shared/assets';
import {
  FormTextarea,
  Icon,
  ReactDatePicker,
  RequiredNotification,
} from '@lon/shared/components';
import { dateFormats, sxLightScrollBar } from '@lon/shared/constants';
import { useResetAssignmentMutation } from '@lon/shared/requests';
import { types, utils } from './duck';
import {
  ErrorMessage,
  InfoMessage,
  IntroMessage,
  WarningMessage,
} from './components';
import styles from './ResetAssignmentModal.module.css';

const ResetAssignmentModal: React.FC<types.ResetAssignmentModalProps> = ({
  isOpen,
  isGroupActions = false,
  onClose,
  studentAssignments,
  onCompleted,
}) => {
  const toast = useToast();
  const { t } = useTranslation();
  const studentName = `${studentAssignments.canBeReseted[0]?.firstName} ${studentAssignments.canBeReseted[0]?.lastName}`;
  const assignmentsWithPassedDueDate = studentAssignments.canBeReseted.filter(
    (assignment) => {
      const endDate = assignment.time?.endDate;
      return (
        endDate &&
        parseISO(endDate).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)
      );
    }
  );
  const showDatePicker = assignmentsWithPassedDueDate.some(
    (assignment) => !assignment.lateTurnIn
  );
  const { validationSchema, defaultValues } =
    utils.getValidation(showDatePicker);
  const [resetAssignment, { loading }] = useResetAssignmentMutation();
  const formProviderProps = useForm<types.Form>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
    mode: 'onSubmit',
  });

  const onCloseModal = () => {
    formProviderProps.reset();
    onClose();
  };

  const handleSubmit = async (values: types.Form) => {
    const payloads = studentAssignments.canBeReseted.reduce<
      {
        assignmentId: string;
        studentIds: string[];
        notes: string;
        endDate: string | null;
      }[]
    >((acc, val) => {
      const existingPayload = acc.find(
        (item) => item.assignmentId === val.assignmentId
      );

      if (existingPayload) {
        existingPayload.studentIds.push(val.studentId as string);
      } else {
        acc.push({
          assignmentId: val.assignmentId,
          studentIds: [val.studentId as string],
          notes: values.notes || '',
          endDate: values.endDate ? utils.formatDate(values.endDate) : null,
        });
      }

      return acc;
    }, []);

    try {
      const result = await Promise.all(
        payloads.map((payload) => {
          return resetAssignment({
            variables: { sessionsParams: payload },
          });
        })
      );
      const areAllSuccessful = result.every(
        (res) => res?.data?.resetAssignment?.success
      );
      const areSomeSuccessful = result.some(
        (res) => res?.data?.resetAssignment?.success
      );

      const successToast = (eligibleStudentsAmount: number) => {
        toast({
          title: t('resetAssignmentModal.resetSuccessToastTitle'),
          description: (
            <Text whiteSpace="pre-wrap">
              {isGroupActions
                ? t(
                    'resetAssignmentModal.resetSuccessToastDescriptionMultiple',
                    {
                      amount: eligibleStudentsAmount,
                    }
                  )
                : sanitize(
                    t('resetAssignmentModal.resetSuccessToastDescription', {
                      name: studentName,
                      interpolation: {
                        escapeValue: false,
                      },
                    })
                  )}
            </Text>
          ),
          variant: 'success-light',
          status: 'success',
          isClosable: true,
          duration: 3000,
        });
      };

      if (areAllSuccessful) {
        const eligibleStudentsAmount = payloads.reduce((acc, val) => {
          return acc + (val?.studentIds?.length || 0);
        }, 0);
        successToast(eligibleStudentsAmount);
        onCompleted?.();
        onCloseModal();
        return;
      } else if (areSomeSuccessful) {
        result.forEach((item, index) => {
          if (item.data?.resetAssignment?.success) {
            successToast(payloads[index]?.studentIds?.length);
          }
        });
        onCompleted?.();
        onCloseModal();
        throw new Error();
      } else {
        throw new Error();
      }
    } catch (e) {
      toast({
        title: t('systemMessages.038'),
        status: 'error',
        variant: 'error-light',
        isClosable: true,
        duration: null,
      });
    }
  };

  return (
    <>
      <Modal
        variant="rounded"
        isCentered
        isOpen={isOpen}
        onClose={onCloseModal}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent data-focus-visible-disabled>
          <FormProvider {...formProviderProps}>
            <Flex
              as="form"
              noValidate
              direction="column"
              overflow="hidden"
              id="datepicker-portal"
              onSubmit={formProviderProps.handleSubmit(handleSubmit)}
            >
              <ModalHeader flexDirection="column" p="0" borderBottom="none">
                <Flex
                  w="full"
                  align="center"
                  justify="space-between"
                  gap="6"
                  py="4"
                  px="8"
                  borderBottom="1px solid #e1e7f0"
                >
                  <Text as="h5" variant="formHeader">
                    {t('resetAssignmentModal.header')}
                  </Text>
                  <IconButton
                    aria-label={t('resetAssignmentModal.closeModal')}
                    size="md"
                    variant="inverted"
                    onClick={onCloseModal}
                    icon={<ChakraIcon as={closeOutlined} w="24px" h="24px" />}
                  />
                </Flex>
                <Box
                  w="full"
                  py="2"
                  px="8"
                  boxShadow="0px 2px 7px 0px rgba(43, 54, 70, 0.10)"
                >
                  <Text variant="n1">
                    {sanitize(
                      t('resetAssignmentModal.subHeader', {
                        target: isGroupActions
                          ? t('resetAssignmentModal.multipleStudents')
                          : studentName,
                        interpolation: {
                          escapeValue: false,
                        },
                      })
                    )}
                  </Text>
                </Box>
              </ModalHeader>
              <ModalBody css={sxLightScrollBar}>
                <Flex
                  direction="column"
                  gap="4"
                  sx={{
                    label: {
                      mb: 2,
                    },
                  }}
                >
                  {!studentAssignments?.canBeReseted.length ? (
                    <>
                      <ErrorMessage />
                      <InfoMessage />
                    </>
                  ) : (
                    <>
                      <IntroMessage
                        isGroupActions={isGroupActions}
                        studentAssignments={studentAssignments}
                      />
                      {studentAssignments?.canNotBeReseted.length ? (
                        <WarningMessage
                          students={studentAssignments?.canNotBeReseted}
                        />
                      ) : null}
                      {showDatePicker && (
                        <>
                          <RequiredNotification />
                          <ReactDatePicker
                            isRequired
                            showCurrentDatePicker
                            name="endDate"
                            placeholder={t('resetAssignmentModal.dateFormat')}
                            calendarClassName={styles['react-datepicker']}
                            popperPlacement="bottom-end"
                            dateFormat={dateFormats.monthDayYear}
                            minDate={new Date()}
                            portalId="datepicker-portal"
                            label={
                              <Text
                                as="span"
                                title={t('resetAssignmentModal.endDateLabel')}
                                isTruncated
                              >
                                {t('resetAssignmentModal.endDateLabel')}
                              </Text>
                            }
                            inputLayoutProps={{
                              formLabelProps: {
                                display: 'flex',
                                overflow: 'hidden',
                              },
                            }}
                          />
                        </>
                      )}
                      <FormTextarea
                        name="notes"
                        height="148px"
                        placeholder={t(
                          `resetAssignmentModal.${
                            isGroupActions
                              ? 'studentNoteGroupPlaceholder'
                              : 'studentNotePlaceholder'
                          }`
                        )}
                        data-user-input="true"
                        label={
                          <>
                            <Text
                              as="span"
                              display="block"
                              variant="n1"
                              textAlign="left"
                              color="primary.800"
                              {...(isGroupActions && { mb: '2' })}
                            >
                              {t('resetAssignmentModal.studentNoteLabel')}
                            </Text>
                            {isGroupActions && (
                              <Text as="span" variant="s3" color="primary.300">
                                {t(
                                  'resetAssignmentModal.studentNoteRuleExplanation'
                                )}
                              </Text>
                            )}
                          </>
                        }
                      />
                    </>
                  )}
                </Flex>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={onCloseModal}>
                  <Text as="span">
                    {t('resetAssignmentModal.cancelButton')}
                  </Text>
                </Button>
                <Button
                  isLoading={loading}
                  isDisabled={!studentAssignments?.canBeReseted.length}
                  variant="solid"
                  type="submit"
                  leftIcon={<Icon name="check-outlined" size="md" />}
                >
                  <Text as="span">{t('resetAssignmentModal.resetButton')}</Text>
                </Button>
              </ModalFooter>
            </Flex>
          </FormProvider>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ResetAssignmentModal;
