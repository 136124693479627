import { PermissionButton } from '../../../permissions';
import { types } from '../duck';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModuleEnum, OperationEnum } from '@lon/shared/types';
import { checkContentPlayer } from '@lon/shared/utils';

const PrintPermissionWrapper: React.FC<types.PrintPermissionWrapperProps> = ({
  children,
}) => {
  const { t } = useTranslation();
  const isContentPlayer = checkContentPlayer();
  if (!isContentPlayer) {
    return (
      <PermissionButton
        module={ModuleEnum.learningMaterials}
        operation={OperationEnum.print}
        tooltip={t('toolbar.print')}
        feature="Print"
      >
        {children}
      </PermissionButton>
    );
  }
  return children;
};

export default PrintPermissionWrapper;
