import { Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Message } from '@lon/shared/components';

const ErrorMessage: React.FC<{
  messageCode: string;
}> = ({ messageCode = 'ra.01' }) => {
  const { t } = useTranslation();

  return (
    <Message
      variant="formError"
      alertDescriptionProps={{
        display: 'block',
        padding: 0,
      }}
      message={
        <Text variant="s3">
          {t(`reopenAssignmentModal.messages.${messageCode}`)}
        </Text>
      }
    />
  );
};

export default ErrorMessage;
