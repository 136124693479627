import { collapsibleSidebarPaddingRight } from '../../../../../collapsible-sidebar';
import { SearchPanel } from '../../../../../search-panel';
import { Box, Divider } from '@chakra-ui/react';
import React from 'react';
import { useThemeStyleValue } from '@lon/shared/hooks';
import { checkContentPlayer } from '@lon/shared/utils';
import { FilterTabsFrostWrapper, FilterTabsSuitWrapper } from './components';

const Filters: React.FC = () => {
  return (
    <Box zIndex={2} position="relative">
      <Box p="6" pr={collapsibleSidebarPaddingRight} pb="10">
        <SearchPanel name="scopes" />
      </Box>
      <Divider
        display={useThemeStyleValue('none', 'block')}
        color="white"
        mb={6}
      />
      {checkContentPlayer() ? (
        <FilterTabsFrostWrapper />
      ) : (
        <FilterTabsSuitWrapper />
      )}
    </Box>
  );
};

export default Filters;
