import { useLazyQuery } from '@apollo/client';
import { Flex, Spinner, Text } from '@chakra-ui/react';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Table } from 'libs/shared/modules/table/src';
import { isEqual } from 'lodash-es';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { ErrorMessage } from '@lon/shared/components';
import { WorkingLocation } from '@lon/shared/contexts';
import { ApplicationEnum } from '@lon/shared/enums';
import { LevelEnumType, useGetStandardsItemsQuery } from '@lon/shared/requests';
import { parseJSON } from '@lon/shared/utils';
import {
  GET_STANDARD_REPORT,
  Order,
  ReponseObject,
  StandardRecord,
  StandardReportResponse,
  getColumns,
  getEnumKeyFromNumber,
  getOrder,
  isValidEnumKey,
  mapStandardToStudent,
  prepareData,
  sortStandardsAlphaNumerically,
} from './duck';
import { Caption, Pagination } from './components';

interface ReportProps {
  assignmentId?: string;
  teacherId?: string;
  schoolId?: string;
  districtId?: string;
  daId?: string;
}

const standardsPerPage = 10;
const itemsPerPage = 30;

const ReportNew: React.FC<ReportProps> = ({
  assignmentId,
  teacherId,
  schoolId,
  districtId,
  daId,
}) => {
  const { t } = useTranslation();
  const { application } = useContext(WorkingLocation);
  const [pageLoading, setPageLoading] = useState(true);
  const [tokenCache, setTokenCache] = useState<Record<string, any>>({});
  const [currentToken, setCurrentToken] = useState<string>('');
  const firstElementRef = useRef<{ VarCharValue: string }[]>();
  const isTeacher = application === ApplicationEnum.TEACHER_SUIT;
  const defaultLevelRef = useRef<string>('Level5');
  const prevParamsRef = useRef<{
    order: Order;
    level: LevelEnumType | undefined;
    classFilter: string | null;
  }>();

  const [sorting, setSorting] = useState<{ id: string; desc: boolean }[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const standardsPage = Number(searchParams.get('standards-page')) || 1;
  const classFilter = searchParams.get('class');
  const levelKey = searchParams.get('level') || undefined;

  const { loading: standardsItemsLoading } = useGetStandardsItemsQuery({
    variables: {
      filter: {
        teacherIds: teacherId ? [teacherId as string] : undefined,
        assignmentId: assignmentId,
      },
    },
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      const params = Object.fromEntries(searchParams.entries());

      if (!data?.ssStandardsItems || !data?.ssStandardsItems?.length) {
        defaultLevelRef.current = 'Level5';
        setSearchParams({ ...params, level: 'Level5' });
      } else {
        const levels = data.ssStandardsItems
          .map((item) => item?.standard?.split('.'))
          .sort((a, b) => (b?.length || 0) - (a?.length || 0));
        const lowestLevel = levels[0]?.length;
        const level = getEnumKeyFromNumber(lowestLevel || 5);
        defaultLevelRef.current = level;
        setSearchParams({
          ...params,
          level,
        });
      }
    },
    onError() {
      const params = Object.fromEntries(searchParams.entries());
      defaultLevelRef.current = 'Level5';
      setSearchParams({ ...params, level: 'Level5' });
    },
  });

  const level =
    levelKey && isValidEnumKey(levelKey) ? LevelEnumType[levelKey] : undefined;

  const [fetch, { loading }] =
    useLazyQuery<StandardReportResponse>(GET_STANDARD_REPORT);

  const cacheTokenData = (
    token: string,
    result: any,
    nextToken: string,
    prevToken: string
  ) => {
    setTokenCache((prevCache) => ({
      ...prevCache,
      [token || 'initial']: {
        data: result,
        nextToken,
        prevToken,
      },
    }));
    setCurrentToken(token);
  };

  useEffect(() => {
    const order = getOrder(sorting);
    const areParamsEqual = isEqual(
      { order, level, classFilter },
      prevParamsRef.current
    );

    if (areParamsEqual || standardsItemsLoading) {
      return;
    }

    fetch({
      fetchPolicy: 'cache-and-network',
      variables: {
        districtId,
        level,
        order,
        itemsPerPage,
        ...(schoolId ? { schoolId } : {}),
        ...(isTeacher ? { assignmentId } : { districtAssessmentId: daId }),
        ...(isTeacher ? { teacherId } : {}),
        ...(isTeacher &&
          classFilter !== 'undefined' && { classId: classFilter }),
      },
      onCompleted(data) {
        const response: ReponseObject = parseJSON(
          data?.ssStandards?.resultSetJson || '[]'
        );
        firstElementRef.current = response?.Rows?.shift()?.Data;
        const preparedData = prepareData(response, firstElementRef.current);

        setTokenCache({
          initial: {
            data: preparedData,
            nextToken: data?.ssStandards?.nextToken,
            prevToken: '',
          },
        });
        setCurrentToken('initial');
        prevParamsRef.current = { order, level, classFilter };
        searchParams.delete('standards-page');
        setSearchParams(searchParams);
        setPageLoading(false);
      },
      onError() {
        setPageLoading(false);
      },
    });
  }, [sorting, level, classFilter, standardsItemsLoading]);

  const currentPageStudents = tokenCache[currentToken];

  const { columnData, columns, total } = useMemo(() => {
    const standardToStudentMap = mapStandardToStudent(
      currentPageStudents?.data
    );

    const allStandards =
      standardToStudentMap?.map((item) => {
        const { studentId, studentUsername, studentName, ...rest } = item;

        const data = Object.keys(rest).reduce<
          { standard: string; description?: string }[]
        >((acc, val) => {
          return [
            ...acc,
            {
              standard: val,
              description: (rest[val] as StandardRecord).description,
            },
          ];
        }, []);
        return data;
      }) || [];

    const uniqueStandards = allStandards
      .flat()
      .reduce<{ standard: string; description?: string }[]>((acc, current) => {
        if (!acc.find((item) => item.standard === current.standard)) {
          acc.push(current);
        }
        return acc;
      }, []);

    const sortedStandards = sortStandardsAlphaNumerically(uniqueStandards);

    const paginatedStandards = sortedStandards.slice(
      (standardsPage - 1) * standardsPerPage,
      standardsPage * standardsPerPage
    );

    const columns = getColumns(paginatedStandards);
    return {
      columnData: standardToStudentMap,
      columns,
      total: sortedStandards.length,
    };
  }, [currentPageStudents, standardsPage]);

  const handleNextPage = () => {
    const nextToken = currentPageStudents?.nextToken;
    const prevToken = currentToken;

    if (nextToken) {
      if (tokenCache[nextToken]) {
        setCurrentToken(nextToken);
        return;
      }

      const order = getOrder(sorting);

      fetch({
        fetchPolicy: 'cache-and-network',
        variables: {
          districtId,
          level,
          order,
          itemsPerPage,
          nextToken,
          ...(schoolId ? { schoolId } : {}),
          ...(isTeacher ? { assignmentId } : { districtAssessmentId: daId }),
          ...(isTeacher ? { teacherId } : {}),
          ...(isTeacher &&
            classFilter !== 'undefined' && { classId: classFilter }),
        },
        onCompleted(data) {
          const response: ReponseObject = parseJSON(
            data?.ssStandards?.resultSetJson || '{}'
          );

          if (!response?.Rows?.length) {
            cacheTokenData(
              currentToken,
              currentPageStudents?.data,
              '',
              currentPageStudents?.prevToken
            );
            return;
          }

          const preparedData = prepareData(response, firstElementRef.current);
          cacheTokenData(
            nextToken,
            preparedData,
            data?.ssStandards?.nextToken,
            prevToken
          );
        },
      });
    }
  };

  const handlePreviousPage = () => {
    const prevToken = currentPageStudents?.prevToken;
    if (prevToken) {
      if (tokenCache[prevToken]) {
        setCurrentToken(prevToken);
        return;
      }
    }
  };

  const usersHaveReports = columnData?.some(
    (data) => Object.keys(data).length > 3
  );

  const sortedColumnData = useMemo(() => {
    if (sorting.length === 0 || sorting[0].id === 'studentName') {
      return columnData;
    }

    const { id, desc } = sorting[0] || {};

    return columnData.slice().sort((a, b) => {
      const firstGrade = (a[id] as StandardRecord)?.scorePct;
      const secondGrade = (b[id] as StandardRecord)?.scorePct;
      const firstType = typeof firstGrade;
      const secondType = typeof secondGrade;

      if (desc) {
        if (firstType === 'undefined' && secondType === 'undefined') {
          return 0;
        } else if (firstType === 'undefined') {
          return 1;
        } else if (secondType === 'undefined') {
          return -1;
        }
        return +secondGrade - +firstGrade;
      } else {
        if (firstType === 'undefined' && secondType === 'undefined') {
          return 0;
        } else if (firstType === 'undefined') {
          return -1;
        } else if (secondType === 'undefined') {
          return 1;
        }
        return +firstGrade - +secondGrade;
      }
    });
  }, [sorting, columnData]);

  const isLoading = loading || pageLoading;

  return (
    <Flex direction="column" grow={1}>
      {isLoading ? (
        <Flex
          grow={1}
          direction="column"
          borderRadius="6px"
          backgroundColor="white"
        >
          <Flex justify="space-between" align="center" h="full">
            <Spinner mx="auto" color="primary.800" />
          </Flex>
        </Flex>
      ) : (
        <>
          <Table
            renderGroupActions={() => (
              <Caption
                total={total}
                itemsPerPage={standardsPerPage}
                defaultLevel={defaultLevelRef.current}
              />
            )}
            useDefaultSortingIcon={false}
            showGroupActionsIfEmpty
            enableVerticalBorders
            // case when there are users but they do not have reports
            data={usersHaveReports ? sortedColumnData : []}
            columns={columns}
            serverSideSorting={{ sorting, setSorting }}
            containerProps={{
              boxShadow: 'none',
              borderBottom: 'none',
              borderRadius: '0.375rem 0.375rem 0 0',
            }}
            emptyMessage={
              <ErrorMessage
                hasBack={false}
                hasHome={false}
                title={t('reportsDashboard.noDataTitle')}
                body={
                  <Text variant="n1">
                    {t('reportsDashboard.noDataMessage')}
                  </Text>
                }
              />
            }
          />
          <Pagination
            handlePrevPage={handlePreviousPage}
            handleNextPage={handleNextPage}
            isDisabledPrev={!currentPageStudents?.prevToken || loading}
            isDisableNext={!currentPageStudents?.nextToken || loading}
          />
        </>
      )}
    </Flex>
  );
};

export default ReportNew;
