import { FormControl, FormLabel, Switch } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { modifyTextBatch, observeTextChanges, processTextNodes } from './duck';

const LanguageSwitcher = () => {
  const [isChecked, setIsChecked] = useState(() => {
    const savedState = localStorage.getItem('poc-spanish');
    return savedState === 'true';
  });

  const hasTranslated = useRef(false);

  useEffect(() => {
    let observer: MutationObserver | null = null;

    if (isChecked) {
      processTextNodes(modifyTextBatch);
      observer = observeTextChanges(modifyTextBatch, isChecked);
      hasTranslated.current = true;
    } else if (hasTranslated.current) {
      processTextNodes(modifyTextBatch, true); // Revert translations
    }

    return () => observer?.disconnect();
  }, [isChecked]);

  return (
    <FormControl
      display="flex"
      alignItems="center"
      css={{ '& .chakra-switch': { marginBottom: '0' } }}
    >
      <FormLabel htmlFor="lang-switcher" mb="0" color="primary.800">
        Translate
      </FormLabel>
      <Switch
        isChecked={isChecked}
        id="lang-switcher"
        onChange={() =>
          setIsChecked((prevChecked) => {
            localStorage.setItem('poc-spanish', `${!prevChecked}`);
            return !prevChecked;
          })
        }
      />
    </FormControl>
  );
};

export default LanguageSwitcher;
