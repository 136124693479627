import { Icon } from '../../../../../../../../../icon';
import { ButtonSkeleton } from '../button-skeleton';
import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Flex,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetTheme } from '@lon/shared/hooks';
import { getThemeStyleValue } from '@lon/shared/utils';
import { FilterAccordionrItemProps } from './duck';
import { FilterButton } from './components';

const FilterAccordionItem: React.FC<FilterAccordionrItemProps> = ({
  data = [],
  label,
  name,
  loading,
}) => {
  const { t } = useTranslation();
  const theme = useGetTheme();

  if (loading) {
    return <ButtonSkeleton />;
  }

  if (!data?.length) {
    return null;
  }

  return (
    <AccordionItem
      bg="white"
      borderRadius="4px"
      borderWidth={0}
      as="li"
      listStyleType="none"
    >
      {({ isExpanded }) => (
        <>
          <AccordionButton
            _disabled={{
              bgColor: 'unset',
              cursor: 'unset',
            }}
            color={getThemeStyleValue('primary.800', 'secondary.1000')(theme)}
            borderRadius="4px"
            borderBottomRadius={isExpanded ? 0 : '4px'}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pos="relative"
            lineHeight="1.625rem"
            border="1px solid transparent"
            _focusVisible={{
              boxShadow: getThemeStyleValue(
                '0 0 0 4px var(--chakra-colors-blue-600)',
                'focus.teal'
              )(theme),
              border: getThemeStyleValue(
                'none',
                '1px solid var(--chakra-colors-secondary-1000)'
              )(theme),
            }}
            _hover={{
              backgroundColor: getThemeStyleValue(
                'var(--chakra-colors-blackAlpha-50)',
                'secondary.400'
              )(theme),
            }}
          >
            <Text as="span" isTruncated>
              {t(label)}
            </Text>
            <Flex
              transform={isExpanded ? 'rotate(90deg)' : 'none'}
              justifyContent="center"
              alignItems="center"
            >
              <Icon name="arrow-right" />
            </Flex>
          </AccordionButton>
          <AccordionPanel
            bg={getThemeStyleValue('white', 'secondary.1000')(theme)}
            pb="4"
            border={getThemeStyleValue('none', '1px solid white')(theme)}
            borderTop={getThemeStyleValue(
              '1px solid var(--chakra-colors-primary-30)',
              '1px solid white'
            )(theme)}
            borderBottomRadius="4px"
          >
            <UnorderedList listStyleType="none" marginLeft="0px">
              {data.map((filter) => (
                <ListItem key={`${name}_${filter}`} pb={4} _last={{ pb: 0 }}>
                  <FilterButton filterGroup={name} filterName={filter} />
                </ListItem>
              ))}
            </UnorderedList>
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};

export default FilterAccordionItem;
