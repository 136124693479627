import { FormFields } from '../form-fields';
import { useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { AiOutlinePlus } from '@react-icons/all-files/ai/AiOutlinePlus';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  ConfirmModal,
  Icon,
  RequiredNotification,
} from '@lon/shared/components';
import { useToast } from '@lon/shared/components';
import { sxLightScrollBar } from '@lon/shared/constants';
import { IdPart } from '@lon/shared/enums';
import { MutationUpdateClassArgs } from '@lon/shared/gql/types';
import {
  StudentSubgroup,
  useGetClassSubgroupsQuery,
} from '@lon/shared/requests';
import { clearCache } from '@lon/shared/utils';
import { queries, types, utils } from './duck';
import { checkUniqueness } from './duck/utils';

const FormDrawer: FC<types.FormDrawerProps> = ({ isOpen, onClose }) => {
  const { toast } = useToast();
  const { classId } = useParams() as { classId: string };

  const { data: classData } = useGetClassSubgroupsQuery({
    variables: {
      classId: `${IdPart.Classes}${classId}`,
      activeTeachers: true,
    },
    skip: !classId,
  });
  const { t } = useTranslation();
  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();
  const [customDirty, setCustomDirty] = useState(false);
  const [updateClass, { loading: updateLoading }] = useMutation<
    queries.UpdateClassResponse,
    MutationUpdateClassArgs
  >(queries.UPDATE_CLASS);

  const teachersById: types.TeachersById = useMemo(() => {
    return (
      classData?.class?.classTeachers?.collection?.reduce((acc, teacher) => {
        return {
          ...acc,
          ...(teacher ? { [teacher.teacher._id]: teacher } : {}),
        };
      }, {}) || {}
    );
  }, [classData]);

  useEffect(() => {
    if (isOpen) {
      formProviderProps.reset(defaultValues);
      setCustomDirty(false);
    }
  }, [isOpen]);

  useEffect(() => {
    formProviderProps.reset(defaultValues);
    setCustomDirty(false);
  }, [classData]);

  const { validationSchema, defaultValues } = utils.getValidation(
    teachersById,
    classData?.class?.subgroups?.collection as StudentSubgroup[]
  );

  const formProviderProps = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'onChange',
  });
  const { fields, append } = useFieldArray({
    control: formProviderProps.control,
    name: 'subgroups',
  });

  const addSubgroup = () => {
    formProviderProps.trigger().then((isValid) => {
      if (isValid) {
        append({
          name: '',
          teachers: [],
        });
      }
    });
  };

  const closeForm = () => {
    if (!updateLoading) {
      if (formProviderProps.formState.isDirty || customDirty) {
        onConfirmOpen();
      } else {
        onClose();
      }
    }

    return;
  };

  const processSubmit = async (
    isSubgroupsEmpty: boolean,
    callBack?: () => void
  ) => {
    try {
      const formValues = formProviderProps.getValues();

      const updateResponse = await updateClass({
        variables: {
          input: {
            id: `${IdPart.Classes}${classId}`,
            subgroups: isSubgroupsEmpty
              ? []
              : formValues?.subgroups?.map((subgroup: Record<string, any>) => ({
                  name: subgroup.name.trim(),
                  ...(subgroup?.id ? { id: subgroup?.id } : {}),
                  teacherIds: subgroup.teachers.map(
                    (teacher: any) => teacher.value
                  ),
                })),
          },
        },
        refetchQueries: ['getClassSubgroups'],
        awaitRefetchQueries: true,
      });

      clearCache(`Class:${IdPart.Classes}${classId}`);

      const updatedSubgroups =
        updateResponse?.data?.updateClass?.class?.subgroups;

      if (typeof callBack === 'function') {
        callBack();
      }

      utils.showSubgroupsToast({
        toast,
        className: classData?.class?.name,
        notOptimizedUpdatedSubgroups:
          updatedSubgroups?.collection as StudentSubgroup[],
        notOptimizedInitialSubgroups: classData?.class?.subgroups
          ?.collection as StudentSubgroup[],
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (callBack?: () => void) => {
    const formValues = formProviderProps.getValues();
    const isErrors = checkUniqueness(formValues, formProviderProps);
    const isSubgroupsEmpty =
      formValues?.subgroups?.length === 1 &&
      !formValues?.subgroups?.[0]?.name &&
      !formValues?.subgroups[0].teachers.length;

    if (isSubgroupsEmpty) {
      processSubmit(isSubgroupsEmpty, callBack);
    } else if (!isErrors) {
      formProviderProps.trigger().then(async (isValid) => {
        if (isValid) {
          await processSubmit(isSubgroupsEmpty, callBack);
        }

        onConfirmClose();
      });
    }
  };

  return (
    <>
      <Drawer
        closeOnEsc={false}
        closeOnOverlayClick={false}
        variant="formDrawer"
        placement="right"
        isOpen={isOpen}
        onClose={closeForm}
        onEsc={closeForm}
        onOverlayClick={closeForm}
      >
        <DrawerOverlay />
        <FormProvider {...formProviderProps}>
          <Box
            as="form"
            className="emotion-css-cache userInputForm"
            noValidate
            onSubmit={formProviderProps.handleSubmit(() => handleSubmit())}
          >
            <DrawerContent boxShadow="none">
              <DrawerCloseButton />
              <DrawerHeader>{t('subgroups.formHeader')}</DrawerHeader>
              <HStack
                justify="center"
                p={1.5}
                backgroundColor="secondary.50"
                borderBottomWidth={1}
                borderColor="secondary.200"
              >
                <Button
                  leftIcon={<AiOutlinePlus />}
                  onClick={addSubgroup}
                  variant="ghost"
                  fontWeight={400}
                >
                  <Text as="span" isTruncated>
                    {t('subgroups.addMore')}
                  </Text>
                </Button>
              </HStack>
              <DrawerBody css={sxLightScrollBar}>
                <Box px={8} pt={4}>
                  <RequiredNotification />
                </Box>
                <FormFields
                  fields={fields as any}
                  setCustomDirty={setCustomDirty}
                  defaultValues={defaultValues}
                  errors={formProviderProps.formState.errors}
                />
              </DrawerBody>
              <DrawerFooter>
                <HStack justify="space-between" width="100%">
                  <Button
                    variant="outline"
                    mr={3}
                    onClick={closeForm}
                    isDisabled={updateLoading}
                  >
                    <Text as="span" isTruncated>
                      {t('subgroups.cancel')}
                    </Text>
                  </Button>
                  <Button
                    type="submit"
                    isLoading={updateLoading}
                    variant="solid"
                    isDisabled={
                      !(formProviderProps.formState.isDirty || customDirty)
                    }
                  >
                    <Text as="span" isTruncated>
                      {t('subgroups.save')}
                    </Text>
                  </Button>
                </HStack>
              </DrawerFooter>
            </DrawerContent>
            <ConfirmModal
              primaryHeading={t('subgroups.confirmHeader')}
              subHeading={t('subgroups.confirmText')}
              hasDontSaveBtn
              isModalOpen={isConfirmOpen}
              onModalClose={() => {}}
              handleSave={() => {
                handleSubmit(() => {
                  onConfirmClose();
                  onClose();
                });
              }}
              handleDontSave={() => {
                onConfirmClose();
                onClose();
                formProviderProps.reset(defaultValues);
              }}
              handleCancel={onConfirmClose}
              isLoading={updateLoading}
              dontSaveBtnProps={{
                variant: 'empty',
                leftIcon: <Icon name="delete-outlined" size="lg" />,
              }}
              submitBtnProps={{
                leftIcon: <Icon name="check-simple-outlined" size="lg" />,
              }}
            />
          </Box>
        </FormProvider>
      </Drawer>
    </>
  );
};

export default FormDrawer;
