import { BreadcrumbsContainer } from '../breadcrumbs-container';
import Cookies from 'js-cookie';
import { useGetIconQuery } from '@lon/shared/requests';
import { useCurrentElement } from '@lon/shared/hooks';
import { TransformedElement } from '@lon/shared/types';

const SuitBreadcrumbsWrapper = () => {
  const currentElement = useCurrentElement() as TransformedElement;

  const { data: activityTimeIconData } = useGetIconQuery({
    skip: !currentElement?.metadata?.activityTime,
    variables: {
      filter: {
        type: currentElement?.metadata?.activityTime,
      },
    },
  });

  const getIconPath = () => {
    const selectedLanguage = Cookies.get('langPref')?.split('-')[0] || 'en';
    const allLanguageIcon =
      activityTimeIconData?.icons?.collection[0]?.languageFilePath;
    const iconToDisplay = allLanguageIcon?.find(
      (icon) => icon?.languageCode === selectedLanguage
    )?.filePath;
    if (iconToDisplay) {
      return iconToDisplay;
    }
    return allLanguageIcon?.find((icon) => icon?.languageCode === 'en')
      ?.filePath;
  };

  return <BreadcrumbsContainer activityTimeIconPath={getIconPath()} />;
};

export default SuitBreadcrumbsWrapper;
