import { StyledNavLink } from '../../Header.styles';
import { headerLinks, headerTypes } from '../../duck';
import {
  HAMBURGER_BUTTON_ID,
  LOGO_WRAPPER_WIDTH,
  MENU_TOGGLE_BUTTON_AND_LOGO_GAP,
} from '../../duck/constants';
import {
  Button,
  Flex,
  List,
  ListItem,
  Text,
  Tooltip,
  useOutsideClick,
} from '@chakra-ui/react';
import clsx from 'clsx';
import {
  FocusEvent,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router-dom';
import {
  CHILD_SITE_HEADER_HEIGHT,
  IMPERSONATE_BORDER_BOTTOM_WIDTH_REM,
  IMPERSONATE_BORDER_WIDTH_REM,
  SITE_HEADER_HEIGHT,
  sxLightScrollBar,
  sxRoundedContentScrollbar,
} from '@lon/shared/constants';
import { StudentGradeContext, useFullScreen } from '@lon/shared/contexts';
import { FullScreenContext } from '@lon/shared/contexts';
import {
  useGetTheme,
  useQueryParams,
  useThemeStyleValue,
} from '@lon/shared/hooks';
import { useAuth } from '@lon/shared/hooks';
import { getThemeStyleValue } from '@lon/shared/utils';
import { useNavigationLinks, useResizeObserver } from '@lon/suit/hooks';
import { types } from './duck';
import styles from './MainMenu.module.css';

const MainMenu: React.FC<types.MainMenuProps> = ({
  isOpen,
  onClose,
  isHamburgerMenu,
  setIsHamburgerMenu,
  toggleMenuButtonRef,
  logoWrapperRef,
}) => {
  const [auth] = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const currentTheme = useGetTheme();
  const { isStudentBelowK3 } = useContext(StudentGradeContext);
  const [params, setParams] = useQueryParams();
  const horizontalMenuWrapperRef = useRef<HTMLDivElement>(null);
  const navRef = useRef<HTMLDivElement>(null);
  const [lastHorizontalMenuWidth, setLastHorizontalMenuWidth] = useState(0);
  const isHorizontalMenuWrapperWidthChanged = useResizeObserver(
    horizontalMenuWrapperRef
  );
  const { setIsFullScreen } = useFullScreen();
  const { setIsFullScreen: setTeacherIsFullScreen } =
    useContext(FullScreenContext);
  const rem = parseFloat(getComputedStyle(document.documentElement).fontSize);
  const [menuWidthRecheckTrigger, setMenuWidthRecheckTrigger] = useState(false);
  const siteHeaderHeight = isStudentBelowK3
    ? CHILD_SITE_HEADER_HEIGHT
    : SITE_HEADER_HEIGHT;
  const isImpersonated = auth.user?.impersonated;
  const hamburgerMenuOffsetTop = `calc(${siteHeaderHeight}px + ${
    isImpersonated ? IMPERSONATE_BORDER_WIDTH_REM : 0
  }rem)`;
  const hamburgerMenuOffsetLeft = `${
    isImpersonated ? IMPERSONATE_BORDER_WIDTH_REM : 0
  }rem`;
  const hamburgerMenuOffsetRight = `${
    isImpersonated ? IMPERSONATE_BORDER_WIDTH_REM : 0
  }rem`;
  const hamburgerMenuOffsetBottom = `${
    isImpersonated ? IMPERSONATE_BORDER_BOTTOM_WIDTH_REM : 0
  }rem`;

  const routes = useNavigationLinks({
    links: headerLinks,
  });

  useLayoutEffect(() => {
    const horizontalMenuWrapperWidth =
      horizontalMenuWrapperRef?.current?.offsetWidth || 0;
    const isHorizontalMenuNotFit =
      navRef.current &&
      horizontalMenuWrapperWidth <= navRef.current?.offsetWidth;
    const toggleButtonAndGapWidth =
      (toggleMenuButtonRef.current?.offsetWidth || 0) +
      MENU_TOGGLE_BUTTON_AND_LOGO_GAP * (rem * 0.25);
    const isLastHorizontalMenuFit =
      horizontalMenuWrapperWidth + toggleButtonAndGapWidth >
      lastHorizontalMenuWidth;

    if (
      logoWrapperRef.current &&
      logoWrapperRef.current?.offsetWidth !== LOGO_WRAPPER_WIDTH
    ) {
      logoWrapperRef.current.style.width = `${LOGO_WRAPPER_WIDTH}px`;
    }

    if (!isHamburgerMenu && isHorizontalMenuNotFit) {
      setIsHamburgerMenu(true);
      setLastHorizontalMenuWidth(navRef.current?.offsetWidth);
    } else if (isHamburgerMenu && isLastHorizontalMenuFit) {
      setIsHamburgerMenu(false);
    }
  }, [isHorizontalMenuWrapperWidthChanged, menuWidthRecheckTrigger]);

  useLayoutEffect(() => {
    if (isHamburgerMenu) {
      setMenuWidthRecheckTrigger(!menuWidthRecheckTrigger);
      setIsHamburgerMenu(false);
    }
  }, [rem]);

  useEffect(() => {
    if (!isHamburgerMenu || !isOpen) {
      return;
    }

    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', onKeyDown, false);

    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
    };
  }, [isHamburgerMenu, isOpen]);

  useOutsideClick({
    enabled: isHamburgerMenu && isOpen,
    ref: navRef,
    handler: (e) => {
      const target = e.target as HTMLElement;

      if (target.id !== HAMBURGER_BUTTON_ID) {
        onClose();
      }
    },
  });

  const onOutsideBlur = (e: FocusEvent<HTMLDivElement, Element>) => {
    if (
      !e.currentTarget?.contains(e.relatedTarget) &&
      e.relatedTarget?.id !== HAMBURGER_BUTTON_ID
    ) {
      onClose();
    }
  };

  const focusFirstPageElement = () => {
    document
      .getElementById('language-switcher')
      ?.shadowRoot?.getElementById('combobox')
      ?.focus();
  };

  return (
    <Flex
      ref={horizontalMenuWrapperRef}
      justifyContent="flex-end"
      flexGrow="1"
      h="full"
      w="full"
    >
      <Flex
        ref={navRef}
        as="nav"
        aria-label={t('header.mainNavigation')}
        className={clsx({
          [styles.xNavigation]: !isHamburgerMenu,
          [styles.yNavigation]: isHamburgerMenu,
        })}
        backgroundColor={useThemeStyleValue(
          isHamburgerMenu ? 'white' : 'unset',
          'secondary.1000'
        )}
        borderTop={useThemeStyleValue(
          isHamburgerMenu
            ? '1px solid var(--chakra-colors-secondary-200)'
            : 'unset',
          isHamburgerMenu ? '1px solid var(--chakra-colors-white)' : 'unset'
        )}
        {...(isHamburgerMenu && isOpen
          ? {
              clipPath: 'inset(0px -20px 0px 0px)',
              boxShadow: '0px 3px 20px 0px #2b364633',
              minW: {
                base: `calc(100% - (${hamburgerMenuOffsetLeft} + ${hamburgerMenuOffsetRight}))!important`,
                b744: '330px!important',
              },
            }
          : {})}
        {...(isHamburgerMenu
          ? {
              top: hamburgerMenuOffsetTop,
              left: hamburgerMenuOffsetLeft,
              bottom: hamburgerMenuOffsetBottom,
              right: hamburgerMenuOffsetRight,
            }
          : {})}
        onBlur={onOutsideBlur}
      >
        <List
          aria-label={t('header.mainMenu')}
          className={clsx(styles.menu, {
            [styles.xMenu]: !isHamburgerMenu,
            [styles.yMenu]: isHamburgerMenu,
            [styles.open]: isHamburgerMenu && isOpen,
          })}
          sx={isStudentBelowK3 ? sxRoundedContentScrollbar : sxLightScrollBar}
        >
          {routes
            .filter(({ hide }) => !hide)
            .map((link: headerTypes.MenuItem) => {
              const label =
                matchPath(
                  {
                    path: link.activePath ? link.activePath : link.path,
                  },
                  location.pathname
                ) !== null
                  ? link.tooltipActive
                  : link.tooltip;

              return (
                <ListItem
                  key={link.path}
                  className={clsx({
                    [styles.xNavItem]: !isHamburgerMenu,
                    [styles.yNavItem]: isHamburgerMenu,
                    [styles.xChildNavItem]:
                      !isHamburgerMenu && isStudentBelowK3,
                  })}
                >
                  <Tooltip
                    closeOnClick={false}
                    variant="dark"
                    aria-hidden={true}
                    label={label}
                  >
                    <Button
                      as={StyledNavLink}
                      variant="navLink"
                      size="md"
                      to={link.path}
                      aria-label={label}
                      {...(link.onClick
                        ? {
                            onClick: (e) => {
                              if (typeof link.onClick === 'function') {
                                link.onClick({
                                  event: e,
                                  path: link.path,
                                  location,
                                  params,
                                  setParams,
                                });
                              }
                              setTeacherIsFullScreen &&
                                setTeacherIsFullScreen(false);
                              setIsFullScreen(false);
                              onClose();
                              focusFirstPageElement();
                            },
                          }
                        : {
                            onClick: () => {
                              setTeacherIsFullScreen &&
                                setTeacherIsFullScreen(false);
                              setIsFullScreen(false);
                              onClose();
                              focusFirstPageElement();
                            },
                          })}
                      className={clsx(styles.navLink, {
                        [styles.xNavLink]: !isHamburgerMenu,
                        yNavLink: isHamburgerMenu,
                        [styles.xChildNavLinkHeight]:
                          !isHamburgerMenu && isStudentBelowK3,
                      })}
                      backgroundColor={getThemeStyleValue(
                        link.backgroundColor,
                        'white'
                      )(currentTheme)}
                      color={getThemeStyleValue('white', 'black')(currentTheme)}
                      hoverBgColor={getThemeStyleValue(
                        link.hoverBgColor,
                        '#A0AEC2FF'
                      )(currentTheme)}
                      currentTheme={currentTheme}
                      {...(!isHamburgerMenu
                        ? { borderBottom: 'none !important' }
                        : {})}
                    >
                      <Text as="span" variant="n3" isTruncated>
                        {link.text}
                      </Text>
                    </Button>
                  </Tooltip>
                </ListItem>
              );
            })}
        </List>
      </Flex>
    </Flex>
  );
};

export default MainMenu;
