import { Flex, Skeleton } from '@chakra-ui/react';

const BannerSkeleton = () => {
  return (
    <Flex flexDirection="column" gap={4}>
      <Skeleton height={6} width={100} />
      <Skeleton height="60px" width="80%" />
      <Skeleton height="22px" width="70px" />
      <Skeleton height="44px" width="full" />
      <Skeleton height="44px" width="full" />
      <Skeleton height="44px" width="full" />
    </Flex>
  );
};

export default BannerSkeleton;
