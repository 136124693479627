export const setAssessSubmitLoadingScreen = ({
  dialogBody,
  isLoaderSeted,
}: {
  dialogBody: Element | null | undefined;
  isLoaderSeted: React.MutableRefObject<boolean>;
}) => {
  const loadingScreenStyle = `
          .spinnerWrapper {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
          }
          @keyframes rotation {
              from {
                  transform: rotate(0deg);
              }
  
              to {
                  transform: rotate(359deg);
              }
          }
          .spinner {
              --spinner-size: 1.6rem;
              display: inline-block;
              -webkit-animation: rotation 0.45s linear infinite;
              animation: rotation 0.45s linear infinite;
              border-width: 2px;
              border-style: solid;
              border-color: currentColor;
              border-bottom-color: transparent;
              border-left-color: transparent;
              border-radius: 99999px;
              width: var(--spinner-size);
              height: var(--spinner-size);
          }
      `;

  if (dialogBody) {
    const dialogBodyContent = document.createElement('div');
    dialogBodyContent.classList.add('dialogBodyContent');
    dialogBodyContent.innerHTML = dialogBody?.innerHTML;
    dialogBodyContent.style.display = 'none';

    const loadingScreenStyleElement = document.createElement('style');
    loadingScreenStyleElement.type = 'text/css';
    loadingScreenStyleElement.innerHTML = loadingScreenStyle;

    const spinnerWrapper = document.createElement('div');
    spinnerWrapper.classList.add('spinnerWrapper');
    const spinner = document.createElement('div');
    spinner.classList.add('spinner');
    spinnerWrapper.appendChild(spinner);

    dialogBody.innerHTML = '';
    dialogBody.appendChild(dialogBodyContent);
    dialogBody.appendChild(loadingScreenStyleElement);
    dialogBody.appendChild(spinnerWrapper);

    isLoaderSeted.current = true;
  }
};

export const hideAssessSubmitLoadingScreen = ({
  dialogBody,
  isLoaderSeted,
}: {
  dialogBody: Element | null | undefined;
  isLoaderSeted: React.MutableRefObject<boolean>;
}) => {
  if (dialogBody) {
    const dialogBodyContent = dialogBody.querySelector(
      '.dialogBodyContent'
    ) as HTMLSpanElement;
    const spinnerWrapper = dialogBody.querySelector(
      '.spinnerWrapper'
    ) as HTMLDivElement;
    if (spinnerWrapper) {
      spinnerWrapper.style.display = 'none';
    }
    if (dialogBodyContent) {
      dialogBodyContent.style.display = 'inline';
    }

    isLoaderSeted.current = false;
  }
};
