import { Skeleton, SkipNavTo, TableContent } from '../../components';
import { Pagination } from '../../components';
import TableCaption from '../../components/table-content/components/table-caption';
import { tableHooks, tableTypes } from '../../duck';
import {
  Box,
  Table as ChakraTable,
  Divider,
  Flex,
  useUpdateEffect,
} from '@chakra-ui/react';
import {
  ExpandedState,
  SortingState,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ErrorMessage } from 'libs/shared/components/src/errors';
import { isEqual, isEmpty as lodashIsEmpty, uniqueId } from 'lodash-es';
import {
  startTransition,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  sxHighContrastScrollBar,
  sxLightScrollBar,
} from '@lon/shared/constants';
import { PageContentContext } from '@lon/shared/contexts';
import { useThemeStyleValue } from '@lon/shared/hooks';

const PrimaryTable = <Data extends object>({
  data = [],
  columns,
  loading = false,
  onRowSelectStateChange = () => {},
  renderRowActions,
  renderGroupActions,
  enableClientSidePagination,
  enableClientSideSorting,
  serverSidePagination,
  serverSideSorting,
  enableStickyColumns,
  enableRowSelection,
  isRowSelectionDisabled,
  paginationInitialState,
  tableSize = 'md',
  resetRows,
  skeletonRowCount = 30,
  skeletonRowHeight,
  containerProps,
  captionProps,
  headerProps,
  bodyProps,
  footerProps,
  emptyMessage,
  enableVerticalBorders = true,
  enableYScroll = true,
  useDefaultSortingIcon = true,
  showGroupActionsIfEmpty = true,
  activeRowId,
  showFullSkeleton,
  hasNextTable,
  hasPreviousTable,
  tableOrder,
  paginationProps = {},
  expandedState: externalExpandedState,
  setExpandedState: externalSetExpandedState,
  autoResetPageIndexOff = true,
  showNoDataHeader = false,
  getRowId,
  isPageContentScroll = false,
  setTableModel = () => {},
  customPagination,
  unlimitedPageSize,
  initialSelection = {},
  navToConfig = {},
  withoutBottomBorder = false,
  multiPageSelectManager,
  resetPagination,
  onPaginationChange,
  syncExternalPagination,
  onSortingChange,
  initSortingState = [],
}: tableTypes.TableProps<Data>) => {
  const paginationRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLTableElement>(null);
  const { pageContentRef } = useContext(PageContentContext);
  const tableScrollRef = useRef<HTMLDivElement>(null);
  const scrollRef = isPageContentScroll ? pageContentRef : tableScrollRef;
  const [sorting, setSorting] = useState<SortingState>(initSortingState);
  const [rowSelection, setRowSelection] = useState(initialSelection);
  const [internalExpandedState, setInternalExpandedState] =
    useState<ExpandedState>(true);
  const hasServerSidePagination = typeof serverSidePagination !== 'undefined';
  const hasServerSideSorting = typeof serverSideSorting !== 'undefined';
  const isEmpty = !loading && !data.length;
  const hasPagination =
    typeof serverSidePagination !== 'undefined' || enableClientSidePagination;
  const preparedColumns = tableHooks.usePrepareData({
    data,
    columns,
    renderRowActions,
    enableRowSelection,
    isRowSelectionDisabled,
    hasServerSidePagination,
    multiPageSelectManager,
  });

  const scrollHandlerNumber = useRef(0);
  const scrollWrapperTop = useRef<HTMLDivElement>(null);
  const tableScrollWrapper = useRef<HTMLDivElement | null>();
  const scrollWrapperBottom = useRef<HTMLDivElement>(null);
  const theadRef = useRef<HTMLTableSectionElement>(null);
  const [tableOverflowed, setTableOverflowed] = useState(false);
  const scrollbar = useThemeStyleValue(
    sxLightScrollBar,
    sxHighContrastScrollBar
  );
  const themeColor = useThemeStyleValue('blue.500', 'white');
  const themeBorderColor = useThemeStyleValue('secondary.200', 'white');
  const themeBgColor = useThemeStyleValue('white', 'secondary.1000');

  useUpdateEffect(() => {
    onSortingChange?.(sorting);
  }, [sorting]);

  const table = useReactTable({
    columns: preparedColumns,
    data,
    getRowId,
    autoResetPageIndex: !autoResetPageIndexOff,
    onExpandedChange: externalSetExpandedState || setInternalExpandedState,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: hasServerSideSorting
      ? serverSideSorting.setSorting
      : setSorting,
    pageCount: hasServerSidePagination
      ? serverSidePagination.pageCount
      : undefined,
    getSortedRowModel: enableClientSideSorting
      ? getSortedRowModel()
      : undefined,
    onRowSelectionChange: setRowSelection,
    getPaginationRowModel: enableClientSidePagination
      ? getPaginationRowModel()
      : undefined,
    getSubRows: (row: any) => row.subRows,
    getExpandedRowModel: getExpandedRowModel(),
    ...(hasServerSidePagination
      ? {
          onPaginationChange: serverSidePagination.setPagination,
          getRowId: getRowId ? getRowId : (row: any) => row.id,
        }
      : {}),
    manualPagination: hasServerSidePagination,
    manualSorting: hasServerSideSorting,
    initialState: {
      ...paginationInitialState,
    },
    state: {
      sorting,
      rowSelection,
      expanded: externalExpandedState || internalExpandedState,
      ...(hasServerSidePagination
        ? { pagination: serverSidePagination.pagination }
        : {}),
      ...(hasServerSideSorting ? { sorting: serverSideSorting.sorting } : {}),
    },
  });
  const rowModel = table.getRowModel();

  useEffect(() => {
    if (!multiPageSelectManager) {
      return;
    }

    const { isSelectedAll, excludedRows } = multiPageSelectManager;
    const isAllPageRowsSelected = table.getIsAllPageRowsSelected();
    const hasExcludedRowsOnCurrentPage =
      excludedRows.size &&
      rowModel.flatRows.some((item) => excludedRows.has(item.id));

    if (
      isSelectedAll &&
      !isAllPageRowsSelected &&
      !hasExcludedRowsOnCurrentPage
    ) {
      table.toggleAllPageRowsSelected();
    }
  }, [rowModel]);

  useEffect(() => {
    if (
      !lodashIsEmpty(initialSelection) &&
      !isEqual(initialSelection, rowSelection)
    ) {
      setRowSelection(initialSelection);
    }
  }, [initialSelection]);

  const tableId = useMemo(() => uniqueId('table-'), []);

  // determine that table content overflowed by x-axis
  useLayoutEffect(() => {
    let observer: ResizeObserver;
    if (!tableScrollWrapper?.current) {
      return;
    }
    if (tableScrollWrapper) {
      const callback = () => {
        startTransition(() =>
          setTableOverflowed(
            (tableRef.current?.clientWidth || 0) >
              (tableScrollWrapper.current?.clientWidth || 0)
          )
        );
      };

      observer = new ResizeObserver(callback);
      observer.observe(tableRef?.current as Element);
      callback();
    }
    return () => {
      observer && observer.disconnect();
    };
  }, [data]);

  useEffect(() => {
    if (unlimitedPageSize) {
      table.setPageSize(data.length);
    }
  }, [data]);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 100);
    }
  }, [loading]);

  const handleScrollTop = () => {
    scrollHandlerNumber.current += 1;

    if (scrollHandlerNumber.current !== 1) {
      if (scrollHandlerNumber.current === 3) {
        scrollHandlerNumber.current = 0;
      }
      return;
    }

    if (
      tableScrollWrapper.current &&
      scrollWrapperTop.current &&
      scrollWrapperBottom.current &&
      theadRef.current
    ) {
      tableScrollWrapper.current.scrollLeft =
        scrollWrapperTop.current.scrollLeft;
      scrollWrapperBottom.current.scrollLeft =
        scrollWrapperTop.current.scrollLeft;
      theadRef.current.scrollLeft = scrollWrapperTop.current.scrollLeft;
    }
  };

  const handleTableScroll = () => {
    scrollHandlerNumber.current += 1;

    if (scrollHandlerNumber.current !== 1) {
      if (scrollHandlerNumber.current === 3) {
        scrollHandlerNumber.current = 0;
      }
      return;
    }

    if (
      tableScrollWrapper.current &&
      scrollWrapperTop.current &&
      scrollWrapperBottom.current &&
      theadRef.current
    ) {
      scrollWrapperTop.current.scrollLeft =
        tableScrollWrapper.current.scrollLeft;
      scrollWrapperBottom.current.scrollLeft =
        tableScrollWrapper.current.scrollLeft;
      theadRef.current.scrollLeft = tableScrollWrapper.current.scrollLeft;
    }
  };

  const handleScrollBottom = () => {
    scrollHandlerNumber.current += 1;

    if (scrollHandlerNumber.current !== 1) {
      if (scrollHandlerNumber.current === 3) {
        scrollHandlerNumber.current = 0;
      }
      return;
    }

    if (
      tableScrollWrapper.current &&
      scrollWrapperTop.current &&
      scrollWrapperBottom.current &&
      theadRef.current
    ) {
      scrollWrapperTop.current.scrollLeft =
        scrollWrapperBottom.current.scrollLeft;
      tableScrollWrapper.current.scrollLeft =
        scrollWrapperBottom.current.scrollLeft;
      theadRef.current.scrollLeft = scrollWrapperBottom.current.scrollLeft;
    }
  };

  useEffect(() => {
    if (table) {
      setTableModel(table);
    }
  }, [table]);

  return (
    <>
      {(!isEmpty || showGroupActionsIfEmpty) && (
        <Flex
          ref={tableScrollRef}
          flexGrow={1}
          borderRadius="md"
          boxShadow="0px 5px 20px -2px rgba(43, 54, 70, 0.07)"
          color={themeColor}
          bg="white"
          direction="column"
          border="1px solid"
          borderColor={themeBorderColor}
          {...(enableYScroll
            ? {
                overflowY: 'auto',
                overflowX: 'hidden',
              }
            : {})}
          {...containerProps}
          sx={sxLightScrollBar}
          position="relative"
          bgColor={themeBgColor}
        >
          {!isEmpty && (
            <SkipNavTo
              tableId={tableId}
              hasNextTable={hasNextTable}
              hasPreviousTable={hasPreviousTable}
              tableOrder={tableOrder}
              hasPagination={navToConfig.hasPagination}
            />
          )}
          {typeof renderGroupActions === 'function' &&
            (!isEmpty || showGroupActionsIfEmpty) && (
              <TableCaption id={`tableCaption_${tableId}`} {...captionProps}>
                {renderGroupActions(table)}
              </TableCaption>
            )}
          {tableOverflowed && (
            <>
              <Box
                overflowX="auto"
                overflowY="hidden"
                sx={scrollbar}
                onScroll={handleScrollTop}
                ref={scrollWrapperTop}
                w="auto"
                h="10px"
                minHeight="10px"
                p={0}
                my={3}
              >
                <Box
                  w={tableScrollWrapper.current?.scrollWidth}
                  overflowX="auto"
                  h="10px"
                />
              </Box>
              <Divider m={0} />
            </>
          )}
          <Box
            overflow="auto"
            ref={(node) => (tableScrollWrapper.current = node)}
            onScroll={handleTableScroll}
            display="flex"
            flexDirection="column"
            flexGrow={1}
            justifyContent="space-between"
            sx={{
              ...scrollbar,
              '&::-webkit-scrollbar': {
                width: '10px',
                height: '0',
              },
            }}
          >
            <ChakraTable
              size={tableSize}
              h="1px"
              ref={tableRef}
              {...(enableStickyColumns && {
                css: { borderCollapse: 'collapse', borderSpacing: 0 },
              })}
            >
              {loading && showFullSkeleton ? (
                <Skeleton
                  columns={preparedColumns}
                  rowCount={
                    paginationInitialState?.pagination?.pageSize ||
                    skeletonRowCount
                  }
                  skeletonRowHeight={skeletonRowHeight}
                />
              ) : (
                <TableContent
                  loading={loading}
                  skeleton={
                    <Skeleton
                      hasEmptyRow={false}
                      columns={preparedColumns}
                      rowCount={
                        paginationInitialState?.pagination?.pageSize ||
                        skeletonRowCount
                      }
                      skeletonRowHeight={skeletonRowHeight}
                    />
                  }
                  table={table}
                  onRowSelectStateChange={onRowSelectStateChange}
                  renderGroupActions={renderGroupActions}
                  enableClientSidePagination={enableClientSidePagination}
                  enableClientSideSorting={enableClientSideSorting}
                  enableStickyColumns={enableStickyColumns}
                  serverSidePagination={serverSidePagination}
                  serverSideSorting={serverSideSorting}
                  resetRows={resetRows}
                  captionProps={captionProps}
                  headerProps={headerProps}
                  bodyProps={bodyProps}
                  footerProps={footerProps}
                  enableVerticalBorders={enableVerticalBorders}
                  emptyMessage={emptyMessage}
                  hasData={!isEmpty}
                  useDefaultSortingIcon={useDefaultSortingIcon}
                  enableRowSelection={enableRowSelection}
                  showGroupActionsIfEmpty={showGroupActionsIfEmpty}
                  activeRowId={activeRowId}
                  showNoDataHeader={showNoDataHeader}
                  tableId={tableId}
                  innerTheadRef={theadRef}
                  withoutBottomBorder={withoutBottomBorder}
                />
              )}
            </ChakraTable>
            {isEmpty ? (
              emptyMessage ? (
                emptyMessage
              ) : (
                <ErrorMessage
                  hasBack={false}
                  hasHome={false}
                  title="There are no data in the table."
                />
              )
            ) : (
              <></>
            )}
          </Box>
          {tableOverflowed && (
            <>
              <Divider m={0} />
              <Box
                overflowX="auto"
                overflowY="hidden"
                sx={scrollbar}
                onScroll={handleScrollBottom}
                ref={scrollWrapperBottom}
                w="auto"
                h="10px"
                minHeight="10px"
                p={0}
                my={3}
              >
                <Box
                  w={tableScrollWrapper.current?.scrollWidth}
                  overflowX="auto"
                  h="10px"
                />
              </Box>
            </>
          )}
          {customPagination && hasPagination && customPagination}
          {!customPagination && hasPagination && (
            <Pagination
              pageCount={serverSidePagination?.pageCount}
              syncExternalPagination={syncExternalPagination}
              innerRef={paginationRef}
              tableRef={tableRef}
              scrollRef={scrollRef}
              table={table}
              loading={loading}
              resetPagination={resetPagination}
              onPaginationChange={onPaginationChange}
              {...paginationProps}
            />
          )}
        </Flex>
      )}
      {isEmpty && !showGroupActionsIfEmpty ? (
        emptyMessage ? (
          emptyMessage
        ) : (
          <ErrorMessage
            hasBack={false}
            hasHome={false}
            title={'There are no data in the table.'}
          />
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default PrimaryTable;
