import { Box, Flex } from '@chakra-ui/react';
import {
  sxRoundedDarkContentScrollbar,
  sxRoundedHighContrastScrollbar,
} from '@lon/shared/constants';
import { useThemeStyleValue } from '@lon/shared/hooks';
import { types } from './duck';

const LowerStudentWrapper: React.FC<types.LowerStudentWrapperProps> = ({
  leftColumnComponent,
  leftContentWrapperProps,
  leftContentDesktopWidth,
}) => {
  const marginRight = 'calc(var(--chakra-space-1) * 2.55)';
  const padding = 'var(--chakra-space-8)';

  return (
    <Flex
      flexGrow={1}
      direction="column"
      overflowX="hidden"
      h="full"
      mr={marginRight}
      pt={padding}
      pb={padding}
      sx={useThemeStyleValue(
        sxRoundedDarkContentScrollbar,
        sxRoundedHighContrastScrollbar
      )}
      {...leftContentWrapperProps}
    >
      <Box
        pl={padding}
        pr={`calc(${padding} - ${marginRight} - (${leftContentDesktopWidth} - ${marginRight} - 100%))`}
      >
        {leftColumnComponent}
      </Box>
    </Flex>
  );
};

export default LowerStudentWrapper;
