import {
  Badge,
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import React, { ElementRef, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@lon/shared/components';
import { TabContext, WorkingLocation } from '@lon/shared/contexts';
import { ApplicationEnum } from '@lon/shared/enums';
import { useAuth, useGradeLevel, useMediaQuery } from '@lon/shared/hooks';
import { UserTypeEnum } from '@lon/shared/requests';
import { isIpad } from '@lon/shared/utils';
import { preferencesConstants, preferencesTypes } from './duck';
import { Tabs as TabComponents } from './components';
import { UserSettingsTabEnum } from './duck/constants';

export type AccessibilityRef = ElementRef<
  typeof TabComponents.AccessibilitySettings
>;
export type TeamsRef = ElementRef<typeof TabComponents.Teams>;
export type CoursesRef = ElementRef<typeof TabComponents.Courses>;
export type CredentialsRef = ElementRef<typeof TabComponents.Credentials>;

const UserSettings: React.FC<preferencesTypes.PreferencesProps> = ({
  isPreferencesOpen,
  setIsPreferencesOpen,
  initialTabIndex,
}) => {
  const [{ user }] = useAuth();
  const [isMobile] = useMediaQuery('(max-width: 767px)');
  const accessibilityRef = useRef<AccessibilityRef>(null);
  const { isStudentBelowK3 } = useGradeLevel();
  const teamsRef = useRef<TeamsRef>(null);
  const coursesRef = useRef<CoursesRef>(null);
  const credentialRef = useRef<CredentialsRef>(null);
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(initialTabIndex || 0);
  const [isDirtyForm, setIsDirtyForm] = useState(false);
  const { application } = useContext(WorkingLocation);
  const isStudent = user?.type === UserTypeEnum.Student;
  const isRenderCourses =
    application !== ApplicationEnum.ADMIN &&
    !isStudent &&
    application !== ApplicationEnum.PARENT_SUIT;
  const isRenderTeams =
    application !== ApplicationEnum.ADMIN &&
    !isStudent &&
    application !== ApplicationEnum.PARENT_SUIT;

  const closeForm = () => {
    //Close any active readspeaker buttons
    (window as any).rspkr?.ui &&
      (window as any).rspkr.ui?.destroyActivePlayer();

    if (currentTab === 0 && accessibilityRef?.current?.closeSettings) {
      accessibilityRef.current.closeSettings();
    } else if (currentTab === 1 && coursesRef?.current?.changeTab) {
      coursesRef.current.closeSettings();
    } else if (currentTab === 2 && teamsRef?.current?.changeTab) {
      teamsRef.current.closeSettings();
    } else if (
      (currentTab === 3 || currentTab === 1) &&
      credentialRef?.current?.changeTab
    ) {
      credentialRef.current.closeSettings();
    }
    setCurrentTab(initialTabIndex || 0);
  };

  const onTabChange = (index: number) => {
    //Close any active readspeaker buttons
    (window as any).rspkr?.ui &&
      (window as any).rspkr.ui?.destroyActivePlayer();

    if (currentTab === 0 && accessibilityRef?.current?.changeTab) {
      accessibilityRef.current.changeTab(index);
    } else if (currentTab === 1 && coursesRef?.current?.changeTab) {
      coursesRef.current.changeTab(index);
    } else if (currentTab === 2 && teamsRef?.current?.changeTab) {
      teamsRef.current.changeTab(index);
    } else if (
      (currentTab === 1 || currentTab === 3) &&
      credentialRef?.current?.changeTab
    ) {
      credentialRef.current.changeTab(index);
    }
  };

  const getTabs = () => {
    const tabs = preferencesConstants.TABS;

    switch (application) {
      case ApplicationEnum.ADMIN: {
        return tabs.filter(({ value }) =>
          [
            UserSettingsTabEnum.Accessibility,
            UserSettingsTabEnum.Credentials,
          ].includes(value)
        );
      }
      case ApplicationEnum.PARENT_SUIT: {
        return tabs.filter(({ value }) =>
          [
            UserSettingsTabEnum.Accessibility,
            UserSettingsTabEnum.Credentials,
          ].includes(value)
        );
      }
      case ApplicationEnum.PLATFORM_ADMIN: {
        return tabs.filter(({ value }) =>
          [UserSettingsTabEnum.Accessibility].includes(value)
        );
      }
      case ApplicationEnum.STUDENT_SUIT: {
        return tabs.filter(({ value }) =>
          [
            UserSettingsTabEnum.Accessibility,
            UserSettingsTabEnum.Credentials,
          ].includes(value)
        );
      }
      default:
        return tabs;
    }
  };

  return (
    <Drawer
      placement="right"
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isOpen={isPreferencesOpen}
      onClose={closeForm}
      onEsc={closeForm}
      onOverlayClick={closeForm}
      variant="formDrawer"
      isFullHeight
    >
      <DrawerOverlay />
      <DrawerContent
        boxShadow="none"
        maxW={{ base: '100vw', lg: '1000px' }}
        maxH="100vh"
      >
        <DrawerHeader
          h="4.75rem"
          py={0}
          flex="none"
          borderBottomWidth="1px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex alignItems="center" gap={6}>
            <Text
              variant="h1"
              fontSize="1.18rem"
              lineHeight="1.77rem"
              fontWeight={600}
              color="primary.800"
            >
              {t('userSettings.title')}
            </Text>
          </Flex>
          <Flex alignItems="center" gap={4}>
            {isDirtyForm && (
              <Badge variant="yellowBadge">{t('form.edit')}</Badge>
            )}
            <IconButton
              aria-label="close-drawer"
              size="md"
              variant="ghost"
              onClick={closeForm}
            >
              <Icon name="close-outlined" />
            </IconButton>
          </Flex>
        </DrawerHeader>
        <DrawerBody p={0} overflowY="hidden">
          <Tabs
            isLazy
            variant="clear"
            flexGrow={1}
            display="flex"
            flexDirection="column"
            onChange={onTabChange}
            index={currentTab}
          >
            <TabList
              gap={{ sm: 2, md: 10, lg: '4rem', xl: '100px' }}
              h="4.5rem"
              pl={{ base: 5, md: 7, lg: '150px' }}
              pr={{ base: 5, md: 7, lg: '125px' }}
              justifyContent={{
                base: 'space-between',
                md: ApplicationEnum.ADMIN ? 'space-between' : 'initial',
              }}
            >
              {getTabs().map((tab) => (
                <Tab key={tab.value} isDisabled={tab.disabled}>
                  <Flex
                    align="center"
                    fontWeight={isStudentBelowK3 ? 600 : 400}
                    lineHeight={isStudentBelowK3 ? '1.5rem' : '1.625rem'}
                  >
                    <Box mr={{ base: 0, md: 3 }} lineHeight="0.5rem">
                      <Icon
                        name={tab.icon}
                        width="1.125rem"
                        height="1.125rem"
                      />
                    </Box>
                    {!isMobile && t(tab.name)}
                  </Flex>
                </Tab>
              ))}
            </TabList>
            <Flex
              overflowY="auto"
              height={
                isIpad() ? 'calc(100vh - 11.25rem)' : 'calc(100dvh - 9.25rem)'
              }
            >
              <TabContext.Provider
                value={{
                  editingRows: [],
                  setEditingRows: () => {},
                }}
              >
                <TabPanels>
                  <TabComponents.AccessibilitySettings
                    setIsPreferencesOpen={setIsPreferencesOpen}
                    setIsDirtyForm={
                      currentTab === 0 ? setIsDirtyForm : () => {}
                    }
                    setCurrentTab={setCurrentTab}
                    ref={accessibilityRef}
                    initialTabIndex={initialTabIndex}
                  />
                  {isRenderCourses && (
                    <TabComponents.Courses
                      setIsPreferencesOpen={setIsPreferencesOpen}
                      isFormDirty={isDirtyForm}
                      setIsDirtyForm={
                        currentTab === 1 ? setIsDirtyForm : () => {}
                      }
                      setCurrentTab={setCurrentTab}
                      ref={coursesRef}
                      initialTabIndex={initialTabIndex}
                    />
                  )}
                  {isRenderTeams && (
                    <TabComponents.Teams
                      setIsPreferencesOpen={setIsPreferencesOpen}
                      isFormDirty={isDirtyForm}
                      setIsDirtyForm={
                        currentTab === 2 ? setIsDirtyForm : () => {}
                      }
                      setCurrentTab={setCurrentTab}
                      ref={teamsRef}
                      initialTabIndex={initialTabIndex}
                    />
                  )}
                  <TabComponents.Credentials
                    setIsPreferencesOpen={setIsPreferencesOpen}
                    setIsDirtyForm={
                      currentTab === 3 ? setIsDirtyForm : () => {}
                    }
                    setCurrentTab={setCurrentTab}
                    ref={credentialRef}
                    initialTabIndex={initialTabIndex}
                  />
                </TabPanels>
              </TabContext.Provider>
            </Flex>
          </Tabs>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default UserSettings;
