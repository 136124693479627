import { Icon } from '../../icon';
import { MenuItem } from '../../menu';
import actionsStyles from '../ToolbarActions.module.css';
import { Button, IconButton, Text, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { types } from './duck';
import { PrintPermissionWrapper } from './components';

const Print: React.FC<types.PrintProps> = ({
  printMethod,
  isResponsive,
  ...rest
}) => {
  const { t } = useTranslation();
  const [isTablet] = useMediaQuery('(min-width: 768px)');

  return (
    <MenuItem {...rest}>
      <>
        {isTablet && isResponsive ? (
          <PrintPermissionWrapper>
            <Button
              as={Link}
              to="#"
              size="md"
              variant="ghost"
              className={actionsStyles.action}
              aria-label={t('toolbar.print')}
              leftIcon={<Icon name="printer-outlined" size="lg" />}
              onClick={printMethod ? printMethod : () => {}}
            >
              <Text as="span" isTruncated variant="bs">
                {t('toolbar.print')}
              </Text>
            </Button>
          </PrintPermissionWrapper>
        ) : (
          <PrintPermissionWrapper>
            <IconButton
              as={Link}
              to="#"
              icon={<Icon name="printer-outlined" size="lg" />}
              aria-label={t('toolbar.print')}
              variant="ghost"
              size="md"
              className={actionsStyles.action}
              onClick={
                printMethod
                  ? (e) => {
                      e.currentTarget.blur();
                      printMethod();
                    }
                  : () => {}
              }
            />
          </PrintPermissionWrapper>
        )}
      </>
    </MenuItem>
  );
};

export default Print;
