import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icon } from '@lon/shared/components';
import {
  CHILD_SITE_HEADER_HEIGHT,
  SITE_HEADER_HEIGHT,
  isProdCluster,
  isReviewSite,
  sxDarkScrollBar,
} from '@lon/shared/constants';
import {
  FormMeta,
  FullScreenContext,
  StudentGradeContext,
  useFullScreen,
} from '@lon/shared/contexts';
import { useAuth, useGetTheme } from '@lon/shared/hooks';
import { UserPurposeEnum } from '@lon/shared/requests';
import { getThemeStyleValue, logout, logoutPreview } from '@lon/shared/utils';
import { userNavConstants, userNavTypes, userNavUtils } from './duck';
import { ApplicationSwitcher, UserSettings } from './components';

const UserNav: React.FC<userNavTypes.UserNavProps> = ({
  firstName,
  lastName,
  hasLogout = true,
  hasApplicationSwitcher = false,
  hasLegalNotices = false,
  userNavWrapperProps,
}) => {
  const { t } = useTranslation();
  const [{ user }] = useAuth();
  const currentTheme = useGetTheme();
  const { isStudentBelowK3 } = useContext(StudentGradeContext);
  const [isPreferencesOpen, setIsPreferencesOpen] = React.useState(false);
  const [hasApplications, setHasApplications] = React.useState(false);
  const userInitials = userNavUtils.getInitials(firstName, lastName);
  const { needFormPrompt } = React.useContext(FormMeta);
  const activeItemRef = useRef<HTMLButtonElement>(null);
  const [isTooltipVisible, setTooltipVisible] = React.useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const { setIsFullScreen } = useFullScreen();
  const { setIsFullScreen: setTeacherIsFullScreen } =
    useContext(FullScreenContext);

  const siteHeaderHeight = isStudentBelowK3
    ? CHILD_SITE_HEADER_HEIGHT
    : SITE_HEADER_HEIGHT;

  const handleLogoutSubmit = async () => {
    try {
      await axios.post(`${process.env.NX_API_URL}/login/papi/logout`);

      if (user?.purpose === UserPurposeEnum.PreviewAccount) {
        logoutPreview();
      } else {
        logout();
      }
    } catch (error) {
      toast({
        title: t('systemMessages.038'),
        status: 'error',
        isClosable: true,
        position: 'top',
        duration: 3000,
      });
    }
  };

  return (
    <Box data-testid="user-nav" {...userNavWrapperProps}>
      <Menu
        gutter={(siteHeaderHeight - userNavConstants.MENU_BUTTON_SIZE) / 2}
        {...(hasApplications ? { initialFocusRef: activeItemRef } : {})}
        autoSelect={false}
      >
        {({ isOpen }) => (
          <>
            <Tooltip
              variant="dark"
              label={isOpen ? t('userMenu.closeMenu') : t('userMenu.openMenu')}
              aria-hidden={true}
              isOpen={isTooltipVisible}
            >
              <MenuButton
                aria-label={
                  isOpen ? t('userMenu.closeMenu') : t('userMenu.openMenu')
                }
                data-testid="menu-button"
                backgroundColor="primary.800"
                borderRadius="full"
                className="notranslate"
                w={`${userNavConstants.MENU_BUTTON_SIZE}px`}
                h={`${userNavConstants.MENU_BUTTON_SIZE}px`}
                {...getThemeStyleValue(
                  {},
                  {
                    color: 'secondary.1000',
                    backgroundColor: isOpen ? 'secondary.500' : 'white',
                    border: 'none',
                    _hover: {
                      backgroundColor: 'secondary.400',
                    },
                    _focusVisible: {
                      border: '1px solid',
                      borderColor: 'secondary.1000',
                      boxShadow: '0 0 0 4px var(--chakra-colors-teal-500)',
                    },
                  }
                )(currentTheme)}
                onMouseEnter={() => setTooltipVisible(true)}
                onMouseLeave={() => setTooltipVisible(false)}
                onFocus={() => setTooltipVisible(false)}
                onBlur={() => setTooltipVisible(false)}
                onClick={() => {
                  setTeacherIsFullScreen && setTeacherIsFullScreen(false);
                  setIsFullScreen(false);
                }}
              >
                {userInitials}
              </MenuButton>
            </Tooltip>
            <MenuList maxH="600px" overflowY="auto" sx={sxDarkScrollBar}>
              {hasApplicationSwitcher && (
                <ApplicationSwitcher
                  activeItemRef={activeItemRef}
                  setHasApplications={setHasApplications}
                />
              )}
              <>
                <MenuItem
                  onClick={() => setIsPreferencesOpen(!isReviewSite())}
                  color="primary.800"
                >
                  <HStack align="center">
                    <Icon name="settings" width="20px" height="20px" />
                    <Text
                      variant="s2"
                      sx={{ marginInlineStart: '0.75rem !important' }}
                    >
                      {t('userMenu.userSettings')}
                    </Text>
                  </HStack>
                </MenuItem>
                <MenuItem
                  onClick={() => setIsPreferencesOpen(!isReviewSite())}
                  color="primary.800"
                >
                  <HStack align="center">
                    <Icon
                      name="accessibility-outlined"
                      width="20px"
                      height="20px"
                    />
                    <Text
                      variant="s2"
                      sx={{ marginInlineStart: '0.75rem !important' }}
                    >
                      {t('userMenu.accessibility')}
                    </Text>
                  </HStack>
                </MenuItem>
              </>
              {hasLegalNotices && (
                <>
                  <MenuItem
                    color="primary.800"
                    as="a"
                    cursor="pointer"
                    href={`${userNavConstants.prodStemDomain}/tech-and-legal/#terms-and-conditions`}
                    target="_blank"
                  >
                    <HStack align="center" as="span">
                      <Icon
                        name="fileTextFilledBlue"
                        width="20px"
                        height="20px"
                        color="primary.800"
                      />
                      <Text
                        as="span"
                        variant="s2"
                        sx={{ marginInlineStart: '0.75rem !important' }}
                      >
                        {t('header.terms', {
                          ampersand: '&',
                          interpolation: { escapeValue: false },
                        })}
                      </Text>
                    </HStack>
                  </MenuItem>

                  <MenuItem
                    color="primary.800"
                    as="a"
                    cursor="pointer"
                    href={
                      isProdCluster()
                        ? userNavConstants.prodStemDomain
                        : `${userNavConstants.stemDomain}/home.php`
                    }
                    target="_blank"
                  >
                    <HStack align="center" as="span">
                      <Icon
                        name="aliIconFilledBlue"
                        width="20px"
                        height="20px"
                      />
                      <Text
                        as="span"
                        variant="s2"
                        sx={{ marginInlineStart: '0.75rem !important' }}
                      >
                        <Box as="span">{t('header.company')}</Box>
                        <Box as="span"> &copy; {new Date().getFullYear()}</Box>
                      </Text>
                    </HStack>
                  </MenuItem>
                </>
              )}
              {hasLogout && (
                <>
                  <MenuDivider />
                  <HStack justify="center" px={6} py={2}>
                    <Button
                      as={MenuItem}
                      size="sm"
                      w="full"
                      variant="solid"
                      onClick={() => {
                        if (needFormPrompt.current) {
                          navigate(location.pathname, {
                            state: {
                              afterNavigateFn: handleLogoutSubmit,
                            },
                          });
                        } else {
                          handleLogoutSubmit();
                        }
                      }}
                    >
                      <Text variant="bs">{t('userMenu.logout')}</Text>
                    </Button>
                  </HStack>
                </>
              )}
            </MenuList>
          </>
        )}
      </Menu>
      <UserSettings
        isPreferencesOpen={isPreferencesOpen}
        setIsPreferencesOpen={setIsPreferencesOpen}
      />
    </Box>
  );
};

export default UserNav;
