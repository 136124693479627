import { ReopenAssignmentModalContentProps } from '../../duck/types';
import { ErrorMessage } from '../error-massage';
import { InfoMessage } from '../info-message';
import { IntroMessage } from '../intro-message';
import { WarningMessage } from '../warning-message';
import { Box, Text } from '@chakra-ui/react';
import { isBefore } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { FormTextarea, ReactDatePicker } from '@lon/shared/components';
import { dateFormats } from '@lon/shared/constants';
import styles from './ReopenAssignmentModalContent.module.css';

export const ReopenAssignmentModalContent: React.FC<
  ReopenAssignmentModalContentProps
> = ({ endDate, studentAssignments, isGroupActions }) => {
  const allStudentsAreIneligible = Boolean(
    studentAssignments?.canNotBeReopened.length &&
      !studentAssignments.canBeReopened.length
  );
  const someStudentsAreIneligible = Boolean(
    studentAssignments?.canNotBeReopened.length &&
      studentAssignments.canBeReopened.length
  );
  const allSubmittedByStudents =
    !studentAssignments?.canBeReopened.length &&
    studentAssignments?.canNotBeReopened.length &&
    studentAssignments?.canNotBeReopened?.every((item) => item?.completedAt);

  const isExpired = studentAssignments?.canNotBeReopened?.some((assignment) => {
    const endDate = assignment.time.endDate || '';
    const today = new Date();
    const expiryDate = endDate ? new Date(endDate) : today;

    return isBefore(expiryDate, today);
  });

  const { t } = useTranslation();

  if (allSubmittedByStudents && !isExpired) {
    return <ErrorMessage messageCode="ra10" />;
  }

  if (allSubmittedByStudents) {
    return (
      <>
        <ErrorMessage messageCode="ra12" />
        <InfoMessage />
      </>
    );
  }

  if (studentAssignments?.lateTurnIn) {
    return <ErrorMessage messageCode="ra11" />;
  }

  if (allStudentsAreIneligible) {
    return <ErrorMessage messageCode="ra10" />;
  }

  return (
    <>
      <IntroMessage endDate={endDate} />
      {someStudentsAreIneligible ? (
        <WarningMessage students={studentAssignments?.canNotBeReopened} />
      ) : null}
      <Box position="relative">
        <ReactDatePicker
          isRequired
          showCurrentDatePicker
          name="endDate"
          placeholder={t('reopenAssignmentModal.dateFormat')}
          calendarClassName={styles['react-datepicker']}
          popperPlacement="bottom-end"
          dateFormat={dateFormats.monthDayYear}
          minDate={new Date()}
          portalId="datepicker-portal"
          label={
            <Text
              as="span"
              title={t('reopenAssignmentModal.endDateLabel')}
              isTruncated
            >
              {t('reopenAssignmentModal.endDateLabel')}
            </Text>
          }
          inputLayoutProps={{
            formLabelProps: {
              display: 'flex',
              overflow: 'hidden',
            },
          }}
        />
      </Box>
      <FormTextarea
        name="notes"
        height="148px"
        placeholder={t(
          `assignments.${
            isGroupActions
              ? 'studentNoteGroupPlaceholder'
              : 'studentNotePlaceholder'
          }`
        )}
        data-user-input="true"
        label={
          <>
            <Text
              variant="n1"
              textAlign="left"
              color="primary.800"
              {...(isGroupActions && { mb: '2' })}
            >
              {t('reopenAssignmentModal.studentNoteLabel')}
            </Text>
            {isGroupActions && (
              <Text variant="s3" color="primary.300">
                {studentAssignments?.canNotBeReopened.length
                  ? t('reopenAssignmentModal.studentNoteRuleExplanationAllowed')
                  : t('reopenAssignmentModal.studentNoteRuleExplanationAll')}
              </Text>
            )}
          </>
        }
      />
    </>
  );
};
