import { Columns } from '../activities-table/duck';
import {
  Box,
  Button,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { Table } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../../../../icon';

const Caption: React.FC<{
  table: Table<Columns>;
  handleMarkAllAsGradable: () => void;
  handleMarkAllAsResource: () => void;
}> = ({ table, handleMarkAllAsGradable, handleMarkAllAsResource }) => {
  const { t } = useTranslation();

  return (
    <Flex px="8" py="4" justifyContent="space-between">
      <Text as="h2" variant="h5">
        {t('createAssignmentDrawer.plannedActivities')}
      </Text>
      <Menu
        variant="primary"
        autoSelect={false}
        eventListeners={{ scroll: false }}
        placement="bottom-end"
      >
        {({ isOpen }) => (
          <>
            <MenuButton
              as={Button}
              rightIcon={
                <Box
                  as={Icon}
                  name="chevron-down"
                  size="xl"
                  transform={`rotate(${isOpen ? -180 : 0}deg)`}
                />
              }
              size="sm"
            >
              <Text as="span" variant="bs">
                {t('createAssignmentDrawer.quickActions')}
              </Text>
            </MenuButton>
            <MenuList data-focus-visible-disabled>
              <Tooltip
                variant="dark"
                label={t('createAssignmentDrawer.selectAllUnassignedTooltip')}
              >
                <MenuItem
                  py={2}
                  px={6}
                  onClick={() => {
                    const rows = table.getRowModel().rows;

                    const selectedRows = rows.reduce<Record<string, boolean>>(
                      (acc, val) => {
                        if (!val.original.previouslyAssigned) {
                          acc[val.id] = true;
                        }
                        return acc;
                      },
                      {}
                    );

                    table.setRowSelection(selectedRows);
                  }}
                >
                  <Text as="span" variant="s2">
                    {t('createAssignmentDrawer.selectAllUnassigned')}
                  </Text>
                </MenuItem>
              </Tooltip>
              <Divider my={2} />
              <Tooltip
                variant="dark"
                label={t('createAssignmentDrawer.markGradableTooltip')}
              >
                <MenuItem py={2} px={6} onClick={handleMarkAllAsGradable}>
                  <Text as="span" variant="s2">
                    {t('createAssignmentDrawer.markGradable')}
                  </Text>
                </MenuItem>
              </Tooltip>
              <Tooltip
                variant="dark"
                label={t('createAssignmentDrawer.markResourceTooltip')}
              >
                <MenuItem py={2} px={6} onClick={handleMarkAllAsResource}>
                  <Text as="span" variant="s2">
                    {t('createAssignmentDrawer.markResource')}
                  </Text>
                </MenuItem>
              </Tooltip>
            </MenuList>
          </>
        )}
      </Menu>
    </Flex>
  );
};

export default Caption;
