import React from 'react';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { MenuGroupItem } from '../../../../../../../sidebar';
import { routes } from '@lon/shared/configs';
import { NumeracyScopeMenuTypes } from './duck';

const NumeracyScopeMenu: React.FC<
  NumeracyScopeMenuTypes.NumeracyScopeMenuProps
> = ({ sections }) => {
  const [activeTab, setActiveTab] = React.useState<number>();
  const { scopeId, projectId } = useParams() as {
    scopeId: string;
    projectId: string;
  };
  const location = useLocation();
  const navigate = useNavigate();

  const handleTabOpen = (i: number, path?: string) => {
    activeTab === i ? setActiveTab(undefined) : setActiveTab(i);
    path && navigate(path);
  };

  return (
    <>
      {sections?.map((section, index) => {
        const sectionTitle = section?.metadata?.sectionTitle || section?.title;
        const sectionElements =
          section.elements.map((element) => ({
            id: element.identifier,
            title: element.metadata?.elementName || '',
            path: generatePath(routes.scopes.element, {
              scopeId,
              projectId,
              sectionId: section.identifier,
              elementId: element.identifier,
            }),
          })) || [];

        return (
          <MenuGroupItem
            key={index}
            index={index}
            activeTab={activeTab}
            handleTabOpen={handleTabOpen}
            selectedPath={location.pathname}
            mode="light"
            title={sectionTitle}
            icon="read-outlined"
            subItems={sectionElements}
          />
        );
      })}
    </>
  );
};

export default NumeracyScopeMenu;
