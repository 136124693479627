import { Footer } from '../footer';
import { Icon } from '../icon';
import { Box, Flex, IconButton, Text, Tooltip } from '@chakra-ui/react';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { sidebarSlice } from '@lon/shared/configs';
import {
  DESKTOP_CHILD_CONTENT_PADDING,
  DESKTOP_CONTENT_PADDING,
  PAGE_HEADER_HEIGHT,
  SITE_FOOTER_HEIGHT,
  SITE_HEADER_HEIGHT,
  TOOLBAR_HEIGHT,
  WIZARD_HEIGHT,
  isMobileDevice,
  sxHighContrastScrollBar,
  sxLightScrollBar,
} from '@lon/shared/constants';
import { PageContentContext, StudentGradeContext } from '@lon/shared/contexts';
import { DistrictPermission } from '@lon/shared/enums';
import {
  useAuth,
  useMediaQuery,
  usePermission,
  useThemeStyleValue,
} from '@lon/shared/hooks';
import { UserTypeEnum } from '@lon/shared/requests';
import { ModuleEnum, OperationEnum } from '@lon/shared/types';
import { checkContentPlayer } from '@lon/shared/utils';
import { types } from './duck';

const PageContent: React.FC<types.PageContentProps> = ({
  withoutSidebar,
  children,
  headerElements,
  isFullScreen,
  toolbar,
  pageTitle,
  loadingPageTitle,
  portalContentHeight,
  isAdmin = false,
  bodyProps,
  wrapperProps,
  reserveScrollbarWidth = false,
  pageContentProps,
  toolbarHeight = TOOLBAR_HEIGHT,
}) => {
  const [isLargerThan1281] = useMediaQuery('(min-width: 1281px)');
  const [isTablet] = useMediaQuery('(min-width: 768px)');
  const isOpen = useSelector((state: any) => state.sidebar.isOpen);
  const isContentPlayer = checkContentPlayer();
  const pageContentRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [withWizard] = usePermission([
    {
      permission: DistrictPermission.ADMIN,
      module: ModuleEnum.wizard,
      operation: OperationEnum.view,
    },
  ]);
  const [{ user }] = useAuth();
  const [hasScrollBar, setHasScrollBar] = useState(false);

  const isStudent = isContentPlayer
    ? false
    : user?.type === UserTypeEnum.Student;
  const studentContext = React.useContext(StudentGradeContext);
  const isStudentBelowK3 = isStudent && studentContext?.isStudentBelowK3;

  const FINAL_SITE_HEADER_HEIGHT = !isContentPlayer ? SITE_HEADER_HEIGHT : 0;
  const FINAL_SITE_FOOTER_HEIGHT = !isContentPlayer ? SITE_FOOTER_HEIGHT : 0;
  const sidebarBtnBg = useThemeStyleValue('primary.600', '#fff');
  const pageContentBorder = useThemeStyleValue('none', '1px solid #fff');
  const scrollbar = useThemeStyleValue(
    sxLightScrollBar,
    sxHighContrastScrollBar
  );

  useLayoutEffect(() => {
    if (reserveScrollbarWidth) {
      const updateScroll = () => {
        const el = pageContentRef.current;

        el &&
          setHasScrollBar(
            pageContentProps?.overflowY === 'hidden' ||
              pageContentProps?.overflow === 'hidden'
              ? false
              : el.scrollHeight > el.clientHeight
          );
      };

      updateScroll();
      window.addEventListener('resize', updateScroll);
      return () => window.removeEventListener('resize', updateScroll);
    }

    return undefined;
  }, [pageContentProps?.overflowY, pageContentProps?.overflow]);

  const fullHeight = isMobileDevice ? '100dvh' : '100vh';

  return (
    <Flex
      direction="column"
      backgroundColor="blue.75"
      w="full"
      overflow="hidden"
      as="main"
      id="main"
      borderTop={pageContentBorder}
      borderLeft={pageContentBorder}
      borderRight={pageContentBorder}
      {...wrapperProps}
    >
      <Flex
        display={isFullScreen ? 'none' : 'flex'}
        zIndex={4}
        w="full"
        h={`${PAGE_HEADER_HEIGHT}px`}
        px={6}
        gap={4}
        backgroundColor={useThemeStyleValue('primary.800', 'secondary.1000')}
        borderBottom={pageContentBorder}
        align="center"
        justify="space-between"
        {...(isStudent && { boxShadow: '0px 7px 10px 0px #2b364659' })}
      >
        <Flex gap={4} align="center" overflow="hidden">
          {!withoutSidebar && (
            <Box minW="32px">
              {!isLargerThan1281 && (
                <Tooltip
                  variant="dark"
                  label={
                    isOpen ? t('common.closeSidebar') : t('common.openSidebar')
                  }
                  aria-hidden={true}
                >
                  <IconButton
                    size="sm"
                    variant="solid"
                    borderRadius="full"
                    backgroundColor={sidebarBtnBg}
                    aria-label={
                      isOpen
                        ? t('common.closeSidebar')
                        : t('common.openSidebar')
                    }
                    fontSize="2xl"
                    icon={<Icon name="swapOutlined" />}
                    onClick={() => dispatch(sidebarSlice.toggleOpen())}
                  />
                </Tooltip>
              )}
            </Box>
          )}
          {loadingPageTitle && (
            <Text
              variant="h4"
              color="white"
              as="h1"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
            >
              {loadingPageTitle}
            </Text>
          )}
          {typeof pageTitle === 'string' ? (
            <Text
              variant="h4"
              color="white"
              as="h1"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
            >
              {pageTitle}
            </Text>
          ) : (
            <>{pageTitle}</>
          )}
        </Flex>
        <Box>{headerElements}</Box>
      </Flex>
      <Box zIndex={3}>
        {toolbar && (
          <Box
            h={`${toolbarHeight}px`}
            borderBottomWidth={4}
            borderBottomColor="blue.75"
          >
            {toolbar}
          </Box>
        )}
        <Box id="outer-elements-portal" boxShadow="pane" />
      </Box>
      <Flex
        ref={pageContentRef}
        position="relative"
        height={{
          base: `calc(${fullHeight} - ${
            FINAL_SITE_HEADER_HEIGHT +
            (withWizard ? WIZARD_HEIGHT : 0) +
            PAGE_HEADER_HEIGHT +
            (portalContentHeight || 0) +
            (toolbar ? toolbarHeight : 0)
          }px)`,
          md: `calc(${fullHeight} - ${
            FINAL_SITE_HEADER_HEIGHT +
            PAGE_HEADER_HEIGHT +
            (withWizard ? WIZARD_HEIGHT : 0) +
            (isAdmin ? 0 : FINAL_SITE_FOOTER_HEIGHT) +
            (portalContentHeight || 0) +
            (toolbar ? toolbarHeight : 0)
          }px)`,
          lg: `calc(${fullHeight} - ${
            FINAL_SITE_HEADER_HEIGHT +
            (!isFullScreen ? PAGE_HEADER_HEIGHT : 0) +
            (withWizard ? WIZARD_HEIGHT : 0) +
            (isAdmin ? 16 : FINAL_SITE_FOOTER_HEIGHT) +
            (portalContentHeight || 0) +
            (toolbar ? toolbarHeight : 0)
          }px)`,
          b1281: `calc(${fullHeight} - ${
            FINAL_SITE_HEADER_HEIGHT +
            (!isFullScreen ? PAGE_HEADER_HEIGHT : 0) +
            (withWizard ? WIZARD_HEIGHT : 0) +
            (isAdmin ? 0 : FINAL_SITE_FOOTER_HEIGHT) +
            (isStudentBelowK3
              ? DESKTOP_CHILD_CONTENT_PADDING
              : DESKTOP_CONTENT_PADDING) +
            (portalContentHeight || 0) +
            (toolbar ? toolbarHeight : 0)
          }px)`,
        }}
        className="page-content"
        overflowY={reserveScrollbarWidth ? 'scroll' : 'auto'}
        backgroundColor={useThemeStyleValue('none', 'secondary.950')}
        {...pageContentProps}
        sx={{
          ...(!isMobileDevice && {
            ...scrollbar,
            ...(!hasScrollBar && reserveScrollbarWidth
              ? {
                  '&::-webkit-scrollbar': {
                    width: '0',
                  },
                }
              : { scrollbarGutter: { md: 'stable' } }),
          }),
          '&:has(.react-grid-layout)': {
            overflowX: 'hidden',
          },
        }}
      >
        <PageContentContext.Provider value={{ pageContentRef }}>
          <Flex
            p={{ base: 0, md: 6 }}
            pr={{
              base: 0,
              md:
                isMobileDevice || (!hasScrollBar && reserveScrollbarWidth)
                  ? 6
                  : 'calc(var(--chakra-space-1) * 3.5)',
            }}
            pb={{ base: 0, b1281: 6 }}
            grow={1}
            gap={4}
            w="full"
            direction="column"
            justify="space-between"
            position="relative"
            {...bodyProps}
          >
            <>
              {typeof children === 'function'
                ? children({ hasScrollBar })
                : children}
            </>
            {!isTablet && !isAdmin && !checkContentPlayer() && <Footer />}
          </Flex>
        </PageContentContext.Provider>
      </Flex>
    </Flex>
  );
};

export default PageContent;
