// for wcag accessibility
export const changeIdForMenus = (menuWrapper: Element) => {
  const menuButtons = menuWrapper?.querySelectorAll(`[id*="menu-button-:"]`);
  const menuListsAndItems =
    menuWrapper?.querySelectorAll(`[id*="menu-list-:"]`);

  menuButtons?.forEach((button) => {
    button.id = button.id.replace(/:/g, '');

    if (button.hasAttribute('aria-controls')) {
      const ariaControls = button
        ?.getAttribute('aria-controls')
        ?.replace(/:/g, '');
      button.setAttribute('aria-controls', ariaControls || '');
    }
  });

  menuListsAndItems?.forEach((list) => {
    list.id = list.id.replace(/:/g, '');
  });
};
