import { landingPage } from '../../duck';
import { ContentPlayerBreadcrumbsWrapper } from '../content-player-breadcrumbs-wrapper';
import { SuitBreadcrumbsWrapper } from '../suit-breadcrumbs-wrapper';
import { Card, Text } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { FullScreenContext, ScopeContext } from '@lon/shared/contexts';
import { Element, ScopeType } from '@lon/shared/types';
import { checkContentPlayer } from '@lon/shared/utils';

interface BreadcrumbProps {
  element: Element;
  projectType?: string;
}

const BaseBreadcrumb: React.FC<BreadcrumbProps> = ({
  element,
  projectType,
}) => {
  const { isFullScreen } = useContext(FullScreenContext);
  const isLandingPage = element.metadata?.sectionType === landingPage;
  const isContentPlayer = checkContentPlayer();
  const isHowToScope = projectType === ScopeType.HowTo;
  const { metadata, pageTitle } = useContext(ScopeContext);

  if (isHowToScope && isLandingPage) {
    return (
      <Card justifyContent="center" mb={4} py={4} px={8}>
        <Text variant="s2">
          {metadata?.curriculumArea}, {pageTitle}
        </Text>
      </Card>
    );
  }

  if (isLandingPage) {
    return null;
  }

  if (!isFullScreen && !isLandingPage && !isContentPlayer) {
    return <SuitBreadcrumbsWrapper />;
  }

  return <ContentPlayerBreadcrumbsWrapper />;
};

export default BaseBreadcrumb;
