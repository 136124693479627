import { ContentStructure, Item, Item2 } from '../../duck';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Flex,
  Tag,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Icon } from '@lon/shared/components';
import { routes } from '@lon/shared/configs';

const NestedAccordion = ({
  items,
  selectedItem,
  level = 0,
}: {
  items: ContentStructure[] | Item[] | Item2[];
  selectedItem: ContentStructure;
  level: number;
}) => {
  const { articleId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const renderAccordionItems = ({
    items2,
    level,
  }: {
    items2: ContentStructure[] | Item[] | Item2[];
    level: number;
  }) => {
    return items2?.map((item, index) => {
      return (
        <AccordionItem key={index} p={0}>
          {({ isExpanded }) => (
            <>
              <AccordionButton
                p={4}
                justifyContent="space-between"
                pl={`${level * 2}rem`}
                sx={{
                  '&:hover': {
                    backgroundColor: 'primary.30',
                  },
                  color: 'blue.500',
                  borderBottom:
                    isExpanded &&
                    '1px var(--chakra-colors-chakra-border-color) solid',
                }}
                onClick={() => {
                  if (item.identifier === articleId) {
                    return navigate(
                      generatePath(routes.additionalResources.help.root, {
                        articleId: selectedItem.identifier,
                      })
                    );
                  }
                  navigate(
                    generatePath(routes.additionalResources.help.root, {
                      articleId: item.identifier,
                    })
                  );
                }}
              >
                <Text as="span" variant="h5">
                  {item.title}
                  {item?.items?.filter((i) => i.href).length ? (
                    <Tag
                      ml="12px"
                      color="primary.700"
                      p="0px 12px"
                      variant="counter"
                      border="none"
                    >
                      {item.items.filter((i) => i.href).length}{' '}
                      {t(
                        item.items.filter((i) => i.href).length > 1
                          ? 'helpPage.articles'
                          : 'helpPage.article'
                      )}
                    </Tag>
                  ) : null}
                  {!item?.items?.filter((i) => i.href).length &&
                  item?.items?.length ? (
                    <Tag
                      ml="12px"
                      color="primary.700"
                      p="0px 12px"
                      variant="counter"
                      border="none"
                    >
                      {item.items.length}{' '}
                      {t(
                        item.items.length > 1
                          ? 'helpPage.folders'
                          : 'helpPage.folder'
                      )}
                    </Tag>
                  ) : null}
                </Text>
                <Icon name={isExpanded ? 'chevron-down' : 'chevron-right'} />
              </AccordionButton>
              <AccordionPanel
                p={0}
                gap={item?.items?.some((i) => i.href) ? 4 : 0}
                display="flex"
                flexDirection="column"
              >
                <NestedAccordion
                  items={item?.items || []}
                  selectedItem={selectedItem}
                  level={level + 1}
                />
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      );
    });
  };

  if (items?.some((i) => i.href)) {
    return items.map((item, index) => (
      <Flex
        alignItems="center"
        cursor="pointer"
        onClick={() => navigate(`${item.identifier}`)}
        pl={`${level * 2}rem`}
        color="primary.800"
        pt={index === 0 ? 4 : undefined}
        pb={index === items.length - 1 ? 4 : undefined}
        gap="8px"
      >
        <Icon name="readOutlined" size="md" />
        <Text textDecoration="underline" variant="n5">
          {item.title}
        </Text>
      </Flex>
    ));
  }

  return (
    <Accordion
      p={0}
      allowToggle
      index={selectedItem.items?.findIndex(
        (i) =>
          i.items?.find((j) => j.identifier === articleId)?.identifier ||
          i.identifier === articleId
      )}
      id={
        selectedItem.items?.findIndex(
          (i) =>
            i.items?.find((j) => j.identifier === articleId)?.identifier ||
            i.identifier === articleId
        ) !== -1
          ? selectedItem.items?.find(
              (i) =>
                i.items?.find((j) => j.identifier === articleId)?.identifier ||
                i.identifier === articleId
            )?.identifier
          : ''
      }
    >
      {renderAccordionItems({ items2: items || [], level })}
    </Accordion>
  );
};

export default NestedAccordion;
