import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { ScopeContext } from '@lon/shared/contexts';
import { Scope, ScopeType } from '@lon/shared/types';
import { FactFluencyScopeMenu } from './fact-fluency-scope-menu';
import { LessonScopeMenu } from './lesson-scope-menu';
import { NumeracyScopeMenu } from './numeracy-scope-menu';
import { ScopeMenu } from './scope-menu';

export const ElementList: React.FC<{
  scope?: Scope;
}> = ({ scope }) => {
  const { sectionId, scopeId, projectId } = useParams() as {
    sectionId: string;
    scopeId: string;
    projectId: string;
    elementId: string;
  };
  const { projectType, sections } = useContext(ScopeContext);

  switch (projectType) {
    case ScopeType.DailyNumeracy:
      return <NumeracyScopeMenu sections={sections} />;

    case ScopeType.LessonScope:
      return (
        <LessonScopeMenu
          scope={scope}
          sectionId={sectionId}
          metadata={scope?.metadata}
          scopeId={scopeId}
          projectId={projectId}
        />
      );

    case ScopeType.FactFluency:
      return (
        <FactFluencyScopeMenu
          sidebarElements={scope?.home?.sideBar}
          sections={sections}
        />
      );

    default:
      return (
        <ScopeMenu
          scope={scope}
          sidebarElements={scope?.home?.sideBar}
          metadata={scope?.metadata}
          scopeId={scopeId}
        />
      );
  }
};
