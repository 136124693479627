import { Icon } from '../icon';
import { Image } from '../image';
import { Link } from '../link';
import { Text, VisuallyHidden } from '@chakra-ui/react';
import clsx from 'clsx';
import React from 'react';
import { ThemeEnum } from '@lon/shared/enums';
import { useGetTheme } from '@lon/shared/hooks';
import { types } from './duck';
import styles from './ListItem.module.css';

const ListItem: React.FC<types.ListItemProps> = ({
  image,
  title,
  link,
  content,
  alt = '',
}) => {
  const theme = useGetTheme();
  const isHighContrast = theme === ThemeEnum.HIGH_CONTRAST;

  return (
    <li
      className={clsx(styles.wrapper, {
        [styles.highContrastWrapper]: isHighContrast,
      })}
      data-testid="list-item"
    >
      <div
        className={clsx(styles.imageWrapper, {
          [styles.highContrastImageWrapper]: isHighContrast,
        })}
      >
        {image ? (
          <Image
            className={styles.image}
            src={image}
            plugImageSrc="suit/assets/horizontal-plug.svg"
            alt={alt}
            data-testid="list-item-image"
          />
        ) : (
          <div className={styles.brokenImage}>
            <VisuallyHidden>{title}</VisuallyHidden>
          </div>
        )}
      </div>
      <div
        className={clsx(styles.main, {
          [styles.highContrastMain]: isHighContrast,
        })}
      >
        <div className={styles.text}>
          <Text variant="h6">
            <Link
              withoutStyles
              to={{
                pathname: link,
              }}
              state={{ scopesListSearch: window.location.search }}
              className={clsx(styles.titleLink, {
                [styles.highContrastTitleLink]: isHighContrast,
              })}
            >
              {title}
            </Link>
          </Text>
          {Array.isArray(content)
            ? content.length !== 0 &&
              content.map((item) => (
                <Text
                  key={item}
                  variant="s3"
                  className={clsx(styles.contentItem, {
                    [styles.highContrastContentItem]: isHighContrast,
                  })}
                >
                  {item}
                </Text>
              ))
            : content && (
                <Text
                  variant="s3"
                  className={clsx(styles.contentItem, {
                    [styles.highContrastContentItem]: isHighContrast,
                  })}
                >
                  {content}
                </Text>
              )}
        </div>
        <Icon name="arrow-right-filled" size="small" />
      </div>
    </li>
  );
};

export default ListItem;
