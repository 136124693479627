import { useEffect, useRef } from 'react';
import { useQueryParams } from '@lon/shared/hooks';

export const useFetchContent = ({
  attempts,
  setAttempts,
  refetch,
  contentUrl,
  shouldHidePage,
}: {
  refetch: () => void;
  attempts: number;
  setAttempts: React.Dispatch<React.SetStateAction<number>>;
  contentUrl: string;
  shouldHidePage: boolean;
}) => {
  const iframeRef = useRef<HTMLIFrameElement>();
  const [params] = useQueryParams();
  const answers = params.get('answers');
  const tts = params.get('tts');
  const voiceSettings = localStorage.getItem('voicePreferences');

  useEffect(() => {
    if (!contentUrl || shouldHidePage) {
      return;
    }

    const controller = new AbortController();

    const fetchIframe = async (controller: AbortController) => {
      try {
        // for local iframe update fetch url to "/public/test.html"
        const response = await fetch(contentUrl, {
          credentials: 'include',
          signal: controller.signal,
        });

        if (response.status === 401) {
          if (attempts < 3) {
            setAttempts(attempts + 1);
            refetch();
          }
        } else {
          if (iframeRef?.current) {
            iframeRef.current.src = contentUrl;
          }
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetchIframe(controller);

    return () => {
      controller.abort();
    };
  }, [attempts, contentUrl, shouldHidePage, answers, tts, voiceSettings]);

  return iframeRef;
};
