import { Icon } from '../../../../../../../icon';
import { Message } from '../../../../../../../message';
import { Section, countNestedItems } from '../../duck';
import { Items } from '../items';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Tag,
  Text,
} from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

const Activities = ({
  items,
  level = 0,
  sectionId,
}: {
  items?: Section[];
  level: number;
  sectionId?: string;
}) => {
  const { t } = useTranslation();
  const countedArrayOfNestedItems = React.useMemo(
    () => items?.map((item) => countNestedItems(item)),
    [items]
  );

  return (
    <Accordion p={0} allowToggle>
      {items?.map((item, index) => {
        const { elementsCount, sectionsCount } =
          countedArrayOfNestedItems?.[index] || {};

        return (
          <AccordionItem
            p={0}
            key={item.identifier}
            _last={{
              borderBottomWidth: 0,
            }}
          >
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  p={4}
                  justifyContent="space-between"
                  pl={`${level * 2}rem`}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'primary.30',
                    },
                    color: 'blue.500',
                  }}
                  border={0}
                >
                  <Flex gap="4" alignItems="center">
                    <Text as="span" variant="h5" textAlign="left">
                      {item?.metadata?.sectionTitle}
                    </Text>
                    <Flex gap="1">
                      {!!sectionsCount && (
                        <Tag
                          fontSize="14px"
                          variant="counter"
                          border="none"
                          color="primary.700"
                          px="3"
                          whiteSpace="nowrap"
                        >
                          {t('factFluency.foldersCount', {
                            count: sectionsCount,
                          })}
                        </Tag>
                      )}
                      {!!elementsCount && (
                        <Tag
                          fontSize="14px"
                          variant="counter"
                          border="none"
                          color="primary.700"
                          px="3"
                          whiteSpace="nowrap"
                        >
                          {t('factFluency.activitiesCount', {
                            count: elementsCount,
                          })}
                        </Tag>
                      )}
                      {!item?.sections?.length && !item?.elements?.length && (
                        <Tag
                          fontSize="14px"
                          variant="yellowOutline"
                          border="none"
                          px="3"
                          whiteSpace="nowrap"
                        >
                          {t('factFluency.activitiesCount', {
                            count: 0,
                          })}
                        </Tag>
                      )}
                    </Flex>
                  </Flex>
                  <Icon name={isExpanded ? 'chevron-down' : 'chevron-right'} />
                </AccordionButton>
                <AccordionPanel
                  p={0}
                  display="flex"
                  flexDirection="column"
                  border={0}
                >
                  {!!item?.elements?.length && (
                    <Items
                      level={level + 1}
                      items={item?.elements}
                      sectionId={sectionId}
                    />
                  )}
                  {!!item?.sections?.length && (
                    <Activities
                      items={item?.sections}
                      level={level + 1}
                      sectionId={sectionId}
                    />
                  )}
                  {!item?.sections?.length && !item?.elements?.length && (
                    <Box
                      py="4"
                      pl={`calc(${level * 2}rem + var(--chakra-space-6))`}
                      pr="8"
                      w="full"
                      borderTopWidth="1px"
                      borderColor="secondary.200"
                    >
                      <Message
                        variant="formWarning"
                        p="8"
                        alertDescriptionProps={{
                          display: 'block',
                          padding: 0,
                        }}
                        message={
                          <Flex
                            direction="column"
                            gap="4"
                            alignItems="flex-start"
                          >
                            <Icon name="info-circle" size="2" />
                            <Flex direction="column">
                              <Text variant="n1">
                                {t('factFluency.noActivitiesTitle')}
                              </Text>
                              <Text variant="n3">
                                {t('factFluency.noActivitiesDescription')}
                              </Text>
                            </Flex>
                          </Flex>
                        }
                      />
                    </Box>
                  )}
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};

export default Activities;
