import React from 'react';
import { WorkingLocation } from '@lon/shared/contexts';
import {
  SearchableScope,
  useGetHowToScopesQuery,
  useGetScopesQuery,
} from '@lon/shared/requests';

type UseGetStudentScopes = {
  scopes: SearchableScope[];
  loading: boolean;
};

const useGetStudentScopes = (
  scopeIds?: string[],
  withHowTo = false
): UseGetStudentScopes => {
  const { currentSchoolId = '' } = React.useContext(WorkingLocation);

  const { data, loading } = useGetScopesQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      schoolIds: [currentSchoolId],
      filter: {
        id: scopeIds || [],
      },
    },
    skip: !scopeIds,
  });

  const { data: howToData, loading: howToLoading } = useGetHowToScopesQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      schoolIds: [currentSchoolId],
      filter: {
        id: scopeIds || [],
      },
    },
    skip: !scopeIds || !withHowTo,
  });

  return {
    loading: loading || howToLoading,
    scopes: [
      ...((data?.scopes?.collection as SearchableScope[]) || []),
      ...((howToData?.howToScopes?.collection as SearchableScope[]) || []),
    ],
  };
};

export default useGetStudentScopes;
