import { Icon } from '../../../../../../../icon';
import { Image } from '../../../../../../../image';
import { StandardsModal } from '../../../../../../../standards-modal';
import { AspectRatio, Box, Button, Flex, Link, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import { getQuery } from '@lon/api';
import { routes } from '@lon/shared/configs';
import {
  checkContentPlayer,
  getSrcBasedOnLanguage,
  resizeImage,
} from '@lon/shared/utils';
import { styles, types } from './duck';

const Description: React.FC<types.DescriptionProps> = ({
  scope,
  standards = [],
  sectionId,
  elementId,
  isDailyNumeracy,
  isHowTo,
}) => {
  const { t } = useTranslation();
  const isContentPlayer = checkContentPlayer();

  const imageSrc = scope?.sections
    ?.find((section) => section.identifier === sectionId)
    ?.elements.find((element) => element.identifier === elementId)?.coverImage;
  const elementImg = !isContentPlayer
    ? getSrcBasedOnLanguage(imageSrc)
    : imageSrc;

  return (
    <Flex
      direction="column"
      color="white"
      gap={1}
      pt={{ xl: isDailyNumeracy || isHowTo || isContentPlayer ? 8 : 14 }}
    >
      {standards.length !== 0 && !isDailyNumeracy && (
        <StandardsModal
          standards={standards}
          trigger={
            <Button
              justifyContent="flex-start"
              height="fit-content"
              minHeight="fit-content"
              maxWidth="fit-content"
              variant="text"
              textDecor="none"
              rightIcon={
                <Icon name="safety-certificate" size="medium" color="white" />
              }
            >
              <Text variant="h5" color="white" as="span">
                {t('header.standards')}
              </Text>
            </Button>
          }
        />
      )}
      {!isDailyNumeracy && !isHowTo && (
        <Box>
          <Text as="span" variant="n3" mr="2">
            {t('scope.grade')}:
          </Text>
          <Link
            as={RouterLink}
            to={`${generatePath(routes.scopes.root)}?${getQuery({
              filters: {
                gradeLevel: [scope?.metadata?.gradeLevel],
              },
            })}`}
            variant="navy"
            aria-label={t('scope.gradeLabel', {
              grade: scope?.metadata?.gradeLevel,
            })}
          >
            {scope?.metadata?.gradeLevel}
          </Link>
        </Box>
      )}
      {scope?.metadata?.topic && !isDailyNumeracy && !isHowTo && (
        <Box>
          <Text as="span" variant="n3" mr="2">
            {t('scope.topic')}:
          </Text>
          {scope.metadata.topic.map((topic, index) => (
            <React.Fragment key={topic}>
              {index ? ', ' : ''}
              <Link
                as={RouterLink}
                to={`${generatePath(routes.scopes.root)}?${getQuery({
                  filters: {
                    topic: [topic],
                  },
                })}`}
                variant="navy"
                aria-label={t('scope.topicLabel', {
                  topic,
                })}
              >
                {topic}
              </Link>
            </React.Fragment>
          ))}
        </Box>
      )}
      <Box
        pos="relative"
        mt={isDailyNumeracy || isHowTo ? 0 : 6}
        overflow="hidden"
      >
        <AspectRatio ratio={16 / 9}>
          <Image
            sx={styles.image}
            src={
              !isContentPlayer
                ? resizeImage({
                    src: (isDailyNumeracy ? elementImg : scope?.image) || '',
                    height: 600,
                    width: 800,
                    useLangReplace: true,
                  })
                : scope?.image
            }
            plugImageSrc="suit/assets/horizontal-plug.svg"
            alt=""
            data-testid="card-image"
          />
        </AspectRatio>
      </Box>
    </Flex>
  );
};

export default Description;
