import { useFetchContent } from '../../duck';
import { FullScreenButton } from '../full-screen-button';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  SimpleGrid,
  Tag,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { RefObject, useContext } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { Icon } from '../../../../../../../icon';
import { FullScreenContext } from '@lon/shared/contexts';
import { useAuth } from '@lon/shared/hooks';
import { getSrcBasedOnLanguage, resizeImage } from '@lon/shared/utils';
import { ElementContent } from '../../../../../../../element-content';
import { LinkCard } from '../../../../../../../link-card';
import { routes } from '@lon/shared/configs';
import { ScopeContext } from '@lon/shared/contexts';
import { useCurrentElement } from '@lon/shared/hooks';
import { Element } from '@lon/shared/types';
import { Banner } from './components';

const NumeracyLanding: React.FC = () => {
  const [{ user }] = useAuth();
  const { projectId, scopeId } = useParams();
  const currentElement = useCurrentElement() as Element;
  const { sections, attempts, refetch, setAttempts } = useContext(ScopeContext);
  const { isFullScreen } = useContext(FullScreenContext);
  const [isBigMobile] = useMediaQuery(
    '(min-width: 376px) and (max-width: 767px)'
  );
  const [isLargerThan1680] = useMediaQuery('(min-width: 1680px)');
  const currentView = currentElement.teacherView;
  const shouldHidePage = currentView?.metadata?.hide === 'Yes';
  const imageTag = process.env?.['NX_IMAGE_TAG'] || 1;
  const href = currentView?.href;
  const contentUrl = `${href}${href?.includes('?') ? '&' : '?'}userType=${
    user.type
  }&userId=${user.userId}&v=${imageTag}`;
  const iframeRef = useFetchContent({
    attempts,
    setAttempts,
    refetch,
    contentUrl,
    shouldHidePage,
  });

  return (
    <Flex
      direction="column"
      position="relative"
      pt="6"
      pb={{
        b768: 6,
      }}
    >
      {!isFullScreen && <Banner />}
      <Flex
        position="relative"
        flexDirection="column"
        backgroundColor="white"
        height="full"
        mb="4"
        borderRadius="6px"
        overflow="hidden"
      >
        <FullScreenButton />
        <Box px={{ base: 4, md: 6, lg: 8 }}>
          <ElementContent
            element={currentElement}
            iframeRef={iframeRef as RefObject<HTMLIFrameElement>}
            src={contentUrl}
          />
        </Box>
      </Flex>
      {!isFullScreen && (
        <Accordion
          display="flex"
          flexDir="column"
          gap={6}
          rowGap={4}
          allowMultiple
        >
          {sections?.map((section) => {
            const sectionTitle =
              section?.metadata?.sectionTitle || section?.title;
            const elements = section.elements || [];

            return (
              <AccordionItem
                key={section.identifier}
                bg="white"
                borderRadius="6px"
                border="none"
              >
                {({ isExpanded }) => (
                  <>
                    <Box as="h2">
                      <Box
                        boxShadow="0px 5px 20px -2px rgba(43, 54, 70, 0.07)"
                        pos="relative"
                        zIndex={1}
                      >
                        <AccordionButton
                          py="4"
                          px="8"
                          _hover={{
                            bgColor: 'primary.30',
                            borderBottomRadius: isExpanded ? '0' : '6px',
                          }}
                          _disabled={{ bgColor: 'unset', cursor: 'unset' }}
                          borderRadius="6px"
                          transitionProperty="background-color, border-radius"
                        >
                          <Flex flex="1" gap={3} alignItems="center">
                            <Text as="span" variant="h5" color="primary.800">
                              {sectionTitle}
                            </Text>
                            <Tag
                              variant="primary"
                              fontWeight={600}
                              minH="20px"
                              backgroundColor="primary.30"
                              border="unset"
                            >
                              <Text
                                as="span"
                                variant="s2"
                                lineHeight="0.875rem"
                                color="blue.800"
                              >
                                {section.elements.length || 0}
                              </Text>
                            </Tag>
                          </Flex>

                          <Flex
                            transform={isExpanded ? 'rotate(90deg)' : 'none'}
                            justifyContent="center"
                            alignItems="center"
                            color="primary.800"
                          >
                            <Icon name="arrow-right" />
                          </Flex>
                        </AccordionButton>
                      </Box>
                    </Box>
                    <AccordionPanel py="6" px="6" bg="white">
                      <SimpleGrid
                        spacing={6}
                        columns={
                          isBigMobile
                            ? 2
                            : isLargerThan1680
                            ? 5
                            : { base: 1, md: 3, lg: 4 }
                        }
                        as="ul"
                      >
                        {elements?.map((element) => (
                          <LinkCard
                            key={element.identifier}
                            to={{
                              pathname: generatePath(routes.scopes.element, {
                                projectId,
                                scopeId,
                                sectionId: section.identifier,
                                elementId: element.identifier,
                              }),
                            }}
                            linkContent={
                              <Text
                                variant="h6"
                                py={2}
                                px={4}
                                color="primary.800"
                              >
                                {`${element?.metadata?.elementName || ''}${
                                  element?.teacherView?.metadata?.activityTitle
                                    ? `: ${element?.teacherView?.metadata?.activityTitle}`
                                    : ''
                                }`}
                              </Text>
                            }
                            imageUrl={resizeImage({
                              src:
                                getSrcBasedOnLanguage(element?.coverImage) ||
                                '',
                              height: 600,
                              width: 800,
                            })}
                            as="li"
                          />
                        ))}
                      </SimpleGrid>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            );
          })}
        </Accordion>
      )}
    </Flex>
  );
};

export default NumeracyLanding;
