import { AppliedFilters } from '../applied-filters';
import { PageContent } from '../page-content';
import { ViewSwitcher } from '../view-switcher';
import { Flex, Text, VisuallyHidden } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setListType } from '@lon/shared/configs';
import { WorkingLocation } from '@lon/shared/contexts';
import { ApplicationEnum } from '@lon/shared/enums';
import { ViewTypes } from '@lon/shared/types';
import { checkContentPlayer } from '@lon/shared/utils';
import { scopesSelectors } from './duck';
import {
  ScopeListFrostWrapper,
  ScopeListSuitWrapper,
  Sidebar,
} from './components';

export const AllScopes: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { application } = useContext(WorkingLocation);
  const listType = useSelector(scopesSelectors.selectListType);
  const isParentSuite = application === ApplicationEnum.PARENT_SUIT;
  const isContentPlayer = checkContentPlayer();

  const toggleListView = (type: ViewTypes) => {
    dispatch(setListType(type));
  };

  return (
    <Flex w="full">
      <VisuallyHidden>
        <Text as="h2">{t('scopes.allScopes')}</Text>
      </VisuallyHidden>
      {!isContentPlayer && !isParentSuite && <Sidebar />}
      <PageContent
        withoutSidebar={isParentSuite || isContentPlayer}
        pageTitle={t('scopes.title')}
        headerElements={
          <ViewSwitcher
            onChange={toggleListView}
            initial={listType as ViewTypes}
          />
        }
      >
        {!isContentPlayer && !isParentSuite && (
          <AppliedFilters filter="scopes" />
        )}
        {isContentPlayer ? (
          <ScopeListFrostWrapper type={listType} />
        ) : (
          <ScopeListSuitWrapper type={listType} />
        )}
      </PageContent>
    </Flex>
  );
};

export default AllScopes;
