import { ContentLoader } from '../../content-loader';
import React from 'react';

const Skeleton: React.FC = () => (
  <div data-testid="card-skeleton">
    <ContentLoader viewBox="0 0 250 208">
      <rect x="0" y="0" width="250" height="142" />
      <rect x="0" y="152" width="250" height="28" />
      <rect x="0" y="182" width="250" height="18" />
    </ContentLoader>
  </div>
);

export default Skeleton;
