import { Box, Button, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { MenuGroupItem } from '../../../../../../../sidebar';
import { Icon } from '../../../../../../../icon';
import { routes } from '@lon/shared/configs';
import { FactFluencyScopeMenuProps } from './duck';

const FactFluencyScopeMenu: React.FC<FactFluencyScopeMenuProps> = ({
  sidebarElements,
  sections,
}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = React.useState<number>();
  const { scopeId, projectId, elementId } = useParams() as {
    scopeId: string;
    projectId: string;
    elementId: string;
  };
  const location = useLocation();
  const navigate = useNavigate();

  const handleTabOpen = (i: number, path?: string) => {
    activeTab === i ? setActiveTab(undefined) : setActiveTab(i);
    path && navigate(path);
  };

  const sectionsSubItems =
    sections?.map((section) => ({
      id: section.identifier,
      title: section.metadata?.sectionTitle || '',
      path: generatePath(routes.scopes.section, {
        scopeId,
        projectId,
        sectionId: section.identifier,
      }),
    })) || [];

  return (
    <>
      {sidebarElements?.map(({ identifier, metadata }) => (
        <Box as="li" key={identifier}>
          <Button
            variant="sidebarButton"
            key={identifier}
            as={Link}
            justifyContent="flex-start"
            whiteSpace="pre-wrap"
            to={`home/${identifier}`}
            leftIcon={<Icon name="read-outlined" />}
            w="full"
            title={metadata?.elementName}
            role="tab"
            id={identifier}
            aria-controls="scope-element"
            aria-selected={elementId === identifier}
            {...(elementId === identifier && {
              isActive: true,
              className: 'active',
              css: {
                backgroundColor: 'var(--chakra-colors-primary-50) !important',
                color: 'var(--chakra-colors-primary-800) !important',
              },
            })}
          >
            <Text as="span" noOfLines={5}>
              {metadata?.elementName}
            </Text>
          </Button>
        </Box>
      ))}
      <MenuGroupItem
        index={0}
        activeTab={activeTab}
        handleTabOpen={handleTabOpen}
        selectedPath={location.pathname}
        mode="light"
        title={t('factFluency.topics')}
        icon="book-outlined"
        subItems={sectionsSubItems}
      />
    </>
  );
};

export default FactFluencyScopeMenu;
