import { ContentLoader } from '../../content-loader';
import React from 'react';

const Skeleton: React.FC = () => (
  <div data-testid="list-skeleton">
    <ContentLoader viewBox="0 0 1068 57">
      <rect x="0" y="0" width="1068" height="57" />
    </ContentLoader>
  </div>
);

export default Skeleton;
