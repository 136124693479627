import { useContext } from 'react';
import { WorkingLocation } from '@lon/shared/contexts';
import { useGetSchoolIdsForPlanner as useGetSchoolIds } from '@lon/shared/hooks';
import {
  useGetCurriculumAreasQuery,
  useGetGradesQuery,
  useGetTopicsQuery,
} from '@lon/shared/requests';
import FilterTabs from './FilterTabs';

const FilterTabsSuitWrapper: React.FC = () => {
  const { currentSchoolId } = useContext(WorkingLocation);
  const accessibleSchoolIds = useGetSchoolIds();

  const { data: grades, loading: isGradesLoading } = useGetGradesQuery({
    variables: { schoolIds: accessibleSchoolIds || [] },
  });
  const { data: topics, loading: isTopicsLoading } = useGetTopicsQuery({
    variables: { schoolIds: accessibleSchoolIds },
  });
  const { data: curriculumAreas, loading: isCurriculumAreasLoading } =
    useGetCurriculumAreasQuery({
      variables: { schoolId: currentSchoolId as string },
    });

  return (
    <FilterTabs
      grades={grades}
      isGradesLoading={isGradesLoading}
      topics={topics}
      isTopicsLoading={isTopicsLoading}
      curriculumAreas={curriculumAreas}
      isCurriculumAreasLoading={isCurriculumAreasLoading}
    />
  );
};

export default FilterTabsSuitWrapper;
