export const resizeImage = ({
  src,
  height = 600,
  width = 800,
  useLangReplace = false,
}: {
  src: string;
  height: number;
  width: number;
  useLangReplace?: boolean;
}): string => {
  let finalSrc = src;

  if (useLangReplace) {
    const language = localStorage.getItem('googletrans') || 'en';
    if (language === 'es') {
      finalSrc = src.replace('EN_', 'ES_');
    }
  }

  if (finalSrc.includes('.com/images/')) {
    if (finalSrc.includes('_optimized.')) {
      return `${finalSrc.replace('_optimized.', `_${width}.`)}`;
    } else {
      const pos = finalSrc.lastIndexOf('.');
      return `${finalSrc.substring(0, pos)}_${width}${finalSrc.substring(pos)}`;
    }
  } else {
    return `${finalSrc}/resize?width=${width}&height=${height}`;
  }
};
