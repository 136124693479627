import { AssignmentFormContext } from '../../duck/context';
import { TabProps } from '../../duck/types';
import { Box, Flex, TabPanel } from '@chakra-ui/react';
import { uniq } from 'lodash-es';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivitiesTable, Loader } from './components';

const MyPlan: React.FC<TabProps> = ({
  ownerAssignments,
  loadingOwnerAssignments,
}) => {
  const { t } = useTranslation();
  const { elementsData } = useContext(AssignmentFormContext);

  const data = useMemo(
    () =>
      Array.isArray(elementsData)
        ? elementsData.map((element, i) => {
            const assignedElementIds = uniq(
              ownerAssignments?.ownerAssignments?.assignments.map(
                (assignment) => assignment.assignmentSource?.element?.viewId
              )
            );
            return {
              value: element?.identifier ?? '',
              label: element?.elementName ?? '',
              previouslyAssigned: assignedElementIds?.includes(
                element.identifier
              ),
              activityTime: element?.activityTime ?? '',
              referenceId: element?.referenceId ?? '',
              activityTitle: element?.elementName ?? '',
            };
          })
        : [],
    [elementsData, ownerAssignments]
  );

  return (
    <TabPanel p="0" h="full">
      <Flex justify="center" pt="6" py="8" h="full">
        <Box
          maxW="700px"
          w="full"
          h="full"
          color="primary.800"
          textAlign="left"
        >
          {loadingOwnerAssignments ? (
            <Loader
              isLoading={loadingOwnerAssignments}
              message={t(
                'createAssignmentDrawer.plannedActivitiesLoadingMessage'
              )}
            />
          ) : (
            <ActivitiesTable data={data} />
          )}
        </Box>
      </Flex>
    </TabPanel>
  );
};

export default MyPlan;
