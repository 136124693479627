import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Radio,
  Spinner,
  Tag,
  Text,
  Tooltip,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Icon } from 'libs/shared/components/src/icon';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { sxDarkScrollBar } from '@lon/shared/constants';
import { FullScreenContext, useFullScreen } from '@lon/shared/contexts';
import { ThemeEnum } from '@lon/shared/enums';
import {
  useGetParentStudents,
  useGetTheme,
  useMediaQuery,
} from '@lon/shared/hooks';

const StudentSwitcher = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const theme = useGetTheme();
  const [isTooltipVisible, setTooltipVisible] = React.useState(false);
  const isHighContrast = theme === ThemeEnum.HIGH_CONTRAST;
  const {
    students,
    currentStudent,
    changeStudent,
    loading: studentsLoading,
  } = useGetParentStudents();
  const { setIsFullScreen } = useFullScreen();
  const { setIsFullScreen: setTeacherIsFullScreen } =
    useContext(FullScreenContext);

  const [isTablet] = useMediaQuery('(min-width: 768px)');

  return (
    <Box mr={8} color="primary.800">
      <Menu autoSelect={false} isOpen={isOpen} onClose={onClose}>
        <>
          <Tooltip
            variant="dark"
            label={
              isOpen
                ? t('studentSwitcher.closeMenu')
                : t('studentSwitcher.openMenu')
            }
            aria-hidden={true}
            isOpen={isTooltipVisible}
          >
            <MenuButton
              aria-label={
                isOpen
                  ? t('studentSwitcher.closeMenu')
                  : t('studentSwitcher.openMenu')
              }
              data-testid="menu-button"
              h={{ base: 38, md: 76 }}
              w={{ base: 38, md: 252 }}
              borderWidth={0}
              borderRadius={{ base: 'full', md: 0 }}
              backgroundColor={isHighContrast ? 'teal.500' : 'secondary.50'}
              _hover={{
                backgroundColor: isHighContrast ? 'teal.600' : 'secondary.100',
              }}
              _focus={{
                boxShadow: '0px 0px 0px 3px #72E9FC',
                borderWidth: isHighContrast ? '1px' : 0,
                borderColor: isHighContrast ? 'secondary.1000' : 'unset',
              }}
              position="relative"
              onMouseEnter={() => setTooltipVisible(true)}
              onMouseLeave={() => setTooltipVisible(false)}
              onFocus={() => setTooltipVisible(false)}
              onBlur={() => setTooltipVisible(false)}
              onClick={() => {
                onOpen();
                setTeacherIsFullScreen && setTeacherIsFullScreen(false);
                setIsFullScreen(false);
              }}
            >
              <Flex
                direction="row"
                justify={{ base: 'center', md: 'space-between' }}
                align="center"
                color={isHighContrast ? 'primary.1000' : 'primary.800'}
                gap={{ base: 0, md: 4 }}
                w="full"
                px={{ base: 0, md: 6 }}
              >
                <Flex
                  gap={{ base: 0, md: 4 }}
                  align="center"
                  justify={{ base: 'center', md: 'flex-start' }}
                  flexGrow={0}
                  maxW={{ base: 'unset', md: 'calc(100% - 28px)' }}
                >
                  <Flex>
                    {studentsLoading ? (
                      <Spinner />
                    ) : (
                      isTablet && (
                        <Box
                          display={{ base: 'none', md: 'block' }}
                          lineHeight={0}
                        >
                          <Icon
                            name="school-outlined"
                            width="23px"
                            height="20px"
                          />
                        </Box>
                      )
                    )}
                  </Flex>

                  <Flex
                    display={{ base: 'flex', md: 'none' }}
                    justify="center"
                    align="center"
                    position="absolute"
                    top={0}
                    right={0}
                    backgroundColor={isHighContrast ? 'white' : 'primary.800'}
                    borderRadius="full"
                    w="14px"
                    h="14px"
                    color={isHighContrast ? 'primary.1000' : 'white'}
                  >
                    <Icon name="school-outlined" width="14px" height="8px" />
                  </Flex>

                  {!studentsLoading && (
                    <Flex
                      flexGrow={0}
                      direction="column"
                      gap={1}
                      alignItems="flex-start"
                      overflow="hidden"
                      mt={{
                        md: 3,
                      }}
                    >
                      <Text
                        maxW="100%"
                        variant="n3"
                        lineHeight={1}
                        textAlign="left"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        whiteSpace="nowrap"
                      >
                        {isTablet
                          ? `${currentStudent?.student?.firstName || ''} ${
                              currentStudent?.student?.lastName || ''
                            }`
                          : `${currentStudent?.student?.firstName?.[0] || ''}${
                              currentStudent?.student?.lastName?.[0] || ''
                            }`}
                      </Text>
                      {isTablet && (
                        <Text
                          variant="n3"
                          fontSize="0.5rem"
                          lineHeight={1}
                          color={
                            isHighContrast ? 'primary.1000' : 'primary.200'
                          }
                          textTransform="uppercase"
                        >
                          {!studentsLoading &&
                            t('studentSwitcher.studentInfo', {
                              grade: t(
                                `gradeLevels.${currentStudent?.student?.gradeLevel}`
                              ),
                            })}
                        </Text>
                      )}
                    </Flex>
                  )}
                </Flex>
                <Box
                  justifySelf="flex-end"
                  display={{ base: 'none', md: 'block' }}
                >
                  <Icon name={isOpen ? 'arrow-up' : 'arrow-down'} />
                </Box>
              </Flex>
            </MenuButton>
          </Tooltip>
          <MenuList
            maxH="600px"
            minW={300}
            w={{ base: 'full', b480: 'min-content' }}
            overflowY="auto"
            sx={sxDarkScrollBar}
            margin={{ base: '11px 0', md: '-8px 0' }}
            backgroundColor={isHighContrast ? 'secondary.1000' : 'white'}
          >
            <Text
              variant="n1"
              textAlign="left"
              px={6}
              py={4}
              color={isHighContrast ? 'white' : 'primary.800'}
            >
              {t('studentSwitcher.students')}
            </Text>
            {students
              ?.filter(
                (parentStudent) =>
                  !!parentStudent.student?.classes?.collection?.length
              )
              ?.map((parentStudent) => (
                <VStack
                  key={`${parentStudent?.student?._id}`}
                  px={6}
                  paddingTop={2}
                  paddingBottom="0.75rem"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  bg={
                    parentStudent?.student?._id === currentStudent?.student?._id
                      ? isHighContrast
                        ? 'teal.500'
                        : 'gray.25'
                      : 'transparent'
                  }
                  flex={1}
                  onClick={() => {
                    changeStudent(`${parentStudent?.student?._id}`);
                    onClose();
                  }}
                  cursor="pointer"
                  _hover={{
                    backgroundColor: isHighContrast
                      ? 'secondary.400'
                      : 'secondary.100',
                  }}
                >
                  <Radio
                    isChecked={
                      parentStudent?.student?._id ===
                      currentStudent?.student?._id
                    }
                    spacing={4}
                    alignItems="flex-start"
                    pt={1.5}
                  >
                    <Flex
                      mt={-1.5}
                      direction="column"
                      gap={2}
                      color={
                        isHighContrast
                          ? parentStudent?.student?._id ===
                            currentStudent?.student?._id
                            ? 'secondary.1000'
                            : 'white'
                          : 'primary.800'
                      }
                    >
                      <Text variant="s2">
                        {parentStudent?.student?.firstName}{' '}
                        {parentStudent?.student?.lastName}
                      </Text>
                      <Text variant="es2" lineHeight="1.125rem">
                        {parentStudent?.student?.classes?.collection
                          ?.reduce<string[]>((result, item) => {
                            if (
                              result?.includes(
                                item?.school?.localName as string
                              )
                            ) {
                              return result;
                            } else {
                              result.push(item?.school?.localName as string);
                              return result;
                            }
                          }, [])
                          ?.map((schoolName) => schoolName)
                          .join(', ')}
                      </Text>

                      <Flex gap={2}>
                        <Tag
                          variant="violet"
                          size="xs"
                          borderRadius="4px"
                          textTransform="uppercase"
                          style={
                            isHighContrast
                              ? {
                                  backgroundColor: 'white',
                                  border: 'none',
                                }
                              : {}
                          }
                          {...(isHighContrast && {
                            color: 'secondary.1000',
                          })}
                        >
                          {t('studentSwitcher.student')}
                        </Tag>
                        <Tag
                          variant="successOutline"
                          size="xs"
                          borderRadius="4px"
                          textTransform="uppercase"
                          style={
                            isHighContrast
                              ? {
                                  backgroundColor: 'white',
                                  border: 'none',
                                }
                              : {}
                          }
                          {...(isHighContrast && {
                            color: 'secondary.1000',
                          })}
                        >
                          {t(
                            `gradeLevels.${parentStudent?.student?.gradeLevel}`
                          )}
                        </Tag>
                      </Flex>
                    </Flex>
                  </Radio>
                </VStack>
              ))}
          </MenuList>
        </>
      </Menu>
    </Box>
  );
};

export default StudentSwitcher;
