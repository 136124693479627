import { uniq } from 'lodash-es';
import { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { WorkingLocation } from '@lon/shared/contexts';
import { ApplicationEnum } from '@lon/shared/enums';
import {
  useDebounceValue,
  useGetParentStudents,
  useGetSchoolIdsForPlanner as useGetSchoolIds,
  useQueryParams,
  useQueryWithPagination,
} from '@lon/shared/hooks';
import { useGetScopesQuery } from '@lon/shared/requests';
import { ScopesListTypes } from '@lon/shared/types';
import ScopesList from './ScopesList';
import { listConstants } from './duck';

const ScopeListSuitWrapper: React.FC<{
  type: ScopesListTypes;
  gridGap?: number;
  backgroundColor?: string;
  itemPath?: string;
}> = ({ type, gridGap, ...props }) => {
  const [params] = useQueryParams();
  const { projectId } = useParams();
  const { application } = useContext(WorkingLocation);

  const isParentSuite = application === ApplicationEnum.PARENT_SUIT;

  const { currentStudent, loading: studentsLoading } = useGetParentStudents();

  const studentGrades = useMemo(() => {
    const gradeLevels = currentStudent?.classes?.collection?.map(
      (item) => item.gradeOrCourse
    );

    return uniq(gradeLevels);
  }, [currentStudent?.classes]);

  const accessibleSchoolIds = useGetSchoolIds();
  const debouncedSearch = useDebounceValue(params.search || '', 500);

  const { data, loading, refetch } = useQueryWithPagination({
    queryHook: useGetScopesQuery,
    queryOptions: {
      variables: {
        schoolIds: accessibleSchoolIds,
        search: debouncedSearch,
        filter: {
          ...params.filters,
          projectId,
          ...(isParentSuite && { gradeLevel: studentGrades || [] }),
        },
      },
      skip:
        !accessibleSchoolIds?.length ||
        (isParentSuite && !studentGrades?.length),
    },
    defaultPaginationSize: listConstants.DEFAULT_ITEMS_COUNT,
  });

  return (
    <ScopesList
      type={type}
      gridGap={gridGap}
      data={data}
      loading={loading}
      refetch={refetch}
      studentsLoading={studentsLoading}
      {...props}
    />
  );
};

export default ScopeListSuitWrapper;
