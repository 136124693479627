import { Menu, MenuItem } from '../../../../../../../menu';
import { Flex } from '@chakra-ui/react';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { HideElement } from '@lon/shared/types';
import { changeIdForMenus } from '@lon/shared/utils';
import { navigationTypes } from './duck';
import { SectionItem, ViewAllButton } from './components';
import styles from './Navigation.module.css';

const Navigation: React.FC<navigationTypes.NavigationProps> = ({
  className,
  sections = [],
  sectionsLoading,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const sectionMenuBarRef = React.useRef<HTMLDivElement>(null);
  const [hasScrollBar, setHasScrollBar] = React.useState(false);

  const { scopesListSearch } =
    (location.state as {
      scopesListSearch: string;
    }) || {};

  React.useEffect(() => {
    const updateScroll = () => {
      const el = sectionMenuBarRef.current;
      el && setHasScrollBar(el.scrollWidth > el.clientWidth);
    };

    updateScroll();
    window.addEventListener('resize', updateScroll);
    return () => window.removeEventListener('resize', updateScroll);
  }, []);

  React.useEffect(() => {
    const menuBar = sectionMenuBarRef.current;

    if (menuBar) {
      changeIdForMenus(menuBar);
    }
  }, []);

  if (!sections.length && !sectionsLoading) {
    return null;
  }

  return (
    <>
      <nav
        data-testid="menubar"
        aria-label={t('scope.scopeNavigation')}
        className={clsx(styles.wrapper, className)}
      >
        <Menu
          display="flex"
          gap={2}
          maxWidth="calc(100% - 8rem)"
          overflowX="auto"
          sx={{
            '&::-webkit-scrollbar, &::-webkit-scrollbar-thumb': {
              display: 'none',
            },
          }}
          innerRef={sectionMenuBarRef}
        >
          {sections
            .filter((section) => !(section?.metadata?.hide === HideElement.Yes))
            .map((section, index) => (
              <MenuItem key={section.identifier}>
                <SectionItem
                  id={section.identifier}
                  index={index}
                  name={section.metadata?.sectionTitle}
                  elements={section.elements}
                  scopesListSearch={scopesListSearch}
                />
              </MenuItem>
            ))}
        </Menu>
        <Flex
          px={6}
          py={2}
          background="primary.900"
          position="absolute"
          right={0}
          {...(hasScrollBar ? { boxShadow: '-4px 0px 20px 0px #0D1D3A' } : {})}
        >
          <ViewAllButton />
        </Flex>
      </nav>
    </>
  );
};

export default Navigation;
