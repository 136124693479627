import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { WorkingLocation } from '@lon/shared/contexts';
import { AclApplication } from '@lon/shared/gql/types';
import { useAcl } from '@lon/shared/hooks';
import { NavigationTypes, getNavigationLinks } from '@lon/suit/utils';

const useNavigationLinks = ({
  links,
}: {
  links: NavigationTypes.Links;
}): NavigationTypes.MenuItem[] => {
  const { application } = useContext(WorkingLocation);

  const acl = useAcl<AclApplication[]>({
    application: application,
  });
  const { t } = useTranslation();

  return useMemo(
    () =>
      getNavigationLinks({
        links,
        t,
        application: application!,
        hasPermission: !!acl,
      }),
    [links, application, acl]
  );
};

export default useNavigationLinks;
