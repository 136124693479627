import { Icon } from '../../icon';
import { LimitedAccessPopover } from '../components';
import { PermissionButtonProps } from '../duck/types';
import { useBoolean } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { WorkingLocation } from '@lon/shared/contexts';
import { ApplicationEnum } from '@lon/shared/enums';
import { BehaviourEnum } from '@lon/shared/gql/types';
import { usePermission } from '@lon/shared/hooks';
import { BehaviourExtraEnum } from '@lon/shared/types';

export const PermissionButton: React.FC<PermissionButtonProps> = ({
  children,
  module,
  operation,
  popoverPosition = 'bottom',
  offset = [0, 50],
  districtPermission,
  skip,
  ...rest
}) => {
  const { application: currentApplication, currentSchoolId } =
    useContext(WorkingLocation);

  const [districtAccessBehaviour] = usePermission<
    BehaviourEnum & BehaviourExtraEnum
  >([
    {
      skip: !districtPermission,
      permission: districtPermission,
      module: module,
      operation: operation,
      field: 'behaviour',
    },
  ]);

  const [policyBehaviour] = usePermission<BehaviourEnum>([
    {
      skip: !currentApplication,
      schoolId: [
        ApplicationEnum.ADMIN,
        ApplicationEnum.PLATFORM_ADMIN,
      ]?.includes(currentApplication!)
        ? undefined
        : currentSchoolId,
      application: currentApplication,
      module: module,
      operation: operation,
      field: 'behaviour',
    },
  ]);

  const [popover, popoverControl] = useBoolean(false);

  if (skip) {
    return children;
  }

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    if (policyBehaviour === BehaviourEnum.Restricted) {
      event.stopPropagation();
      event.preventDefault();

      return popoverControl.on();
    }

    if (children?.props?.onClick) {
      children?.props?.onClick(event);
    }
  };

  if (
    districtAccessBehaviour !== BehaviourExtraEnum.Skip &&
    districtAccessBehaviour === BehaviourEnum.Hidden
  ) {
    return null;
  }

  if (policyBehaviour === BehaviourEnum.Hidden) {
    return null;
  }

  if (policyBehaviour === BehaviourEnum.Display) {
    return children;
  }

  const rightIcon =
    policyBehaviour === BehaviourEnum.Restricted ? (
      <Icon name="locked" />
    ) : undefined;

  return (
    <LimitedAccessPopover
      isOpen={popover}
      control={popoverControl}
      popoverPosition={popoverPosition}
      offset={offset}
      trigger={React.cloneElement(children, {
        onClick: handleClick,
        rightIcon: rightIcon,
      })}
      module={module}
      operation={operation}
      {...rest}
    />
  );
};

export default PermissionButton;
