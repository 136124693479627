import { AssignmentFormContext } from '../../../../duck/context';
import { utils as generalSettingsUtils } from '../../duck';
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Icon } from '../../../../../icon';
import { useMediaQuery } from '@lon/shared/hooks';
import { AssignmentTypeConfirmationProps } from './duck';

const AssignmentTypeConfirmation: React.FC<AssignmentTypeConfirmationProps> = ({
  isOpen,
  onClose,
  nextTabIndex,
  setAssignmentTypeTabIndex,
}) => {
  const { t } = useTranslation();
  const formContext = useFormContext();
  const { defaultValues } = useContext(AssignmentFormContext);
  const [isMobile] = useMediaQuery('(max-width: 767px)');
  const [isSmallMobile] = useMediaQuery('(max-width: 479px)');
  const tabs = generalSettingsUtils.getTabs();

  const handleConfirm = () => {
    formContext.reset(
      {
        ...formContext.getValues(),
        classes: defaultValues?.classes || [],
        subgroups: defaultValues?.subgroups || [],
        individualStudents: defaultValues?.individualStudents || [],
      },
      {
        keepDefaultValues: true,
      }
    );
    setAssignmentTypeTabIndex(nextTabIndex);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      {...(isMobile && { size: 'full' })}
    >
      <ModalOverlay />
      <ModalContent
        data-focus-visible-disabled
        maxW={isMobile ? 'initial' : '500px'}
      >
        <ModalBody
          p={isMobile ? '0' : '6'}
          {...(isMobile && { bgColor: 'info.100' })}
        >
          <Flex
            gap={2}
            bgColor="info.100"
            borderRadius="md"
            direction="column"
            alignItems="flex-start"
            p="6"
          >
            <Text as="h5" variant="h5" whiteSpace="pre-wrap">
              {t('createAssignmentDrawer.switchTypeModal.title')}
            </Text>
            <Flex direction="column" gap={2}>
              <Trans
                i18nKey="createAssignmentDrawer.switchTypeModal.body"
                values={{
                  newType: tabs[nextTabIndex].tab,
                }}
              >
                <Text variant="n3">0</Text>
                <Text variant="n3">1</Text>
              </Trans>
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter
          borderTopWidth="0"
          justifyContent="space-between"
          px="6"
          pt={isMobile ? '6' : '0'}
        >
          <Button variant="outline" onClick={onClose}>
            {t('createAssignmentDrawer.switchTypeModal.cancelButton')}
          </Button>
          <Button
            variant="solid"
            onClick={handleConfirm}
            leftIcon={<Icon name="check-simple-outlined" size="lg" />}
          >
            {isSmallMobile
              ? t('createAssignmentDrawer.switchTypeModal.confirmButtonMobile')
              : tabs[nextTabIndex].confirmButtonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AssignmentTypeConfirmation;
