import { useFetchContent } from '../../duck';
import { FullScreenButton } from '../full-screen-button';
import { Box, Flex } from '@chakra-ui/react';
import { useContext } from 'react';
import * as React from 'react';
import { FullScreenContext } from '@lon/shared/contexts';
import { useAuth } from '@lon/shared/hooks';
import { ElementContent } from '../../../../../../../element-content';
import { ScopeContext } from '@lon/shared/contexts';
import { useCurrentElement } from '@lon/shared/hooks';
import { Element } from '@lon/shared/types';
import { Banner } from './components';

const FactFluencyLanding = () => {
  const [{ user }] = useAuth();
  const { attempts, refetch, setAttempts } = useContext(ScopeContext);
  const { isFullScreen } = useContext(FullScreenContext);
  const currentElement = useCurrentElement() as Element;
  const currentView = currentElement.teacherView;
  const shouldHidePage = currentView?.metadata?.hide === 'Yes';
  const imageTag = process.env?.['NX_IMAGE_TAG'] || 1;
  const href = currentView?.href;
  const contentUrl = `${href}${href?.includes('?') ? '&' : '?'}userType=${
    user.type
  }&userId=${user.userId}&v=${imageTag}`;
  const iframeRef = useFetchContent({
    attempts,
    setAttempts,
    refetch,
    contentUrl,
    shouldHidePage,
  });

  return (
    <Flex w="full" direction="column" gap={12} grow={1} py={6}>
      {!isFullScreen && <Banner />}
      <Flex
        position="relative"
        flexDirection="column"
        backgroundColor="white"
        height="full"
        mb="4"
        borderRadius="6px"
        overflow="hidden"
      >
        <FullScreenButton />
        <Box px={{ base: 4, md: 6, lg: 8 }}>
          <ElementContent
            element={currentElement}
            iframeRef={iframeRef as React.RefObject<HTMLIFrameElement>}
            src={contentUrl}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default FactFluencyLanding;
