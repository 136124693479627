import { ScopesListTypes } from '@lon/shared/types';
import { useGetFrostDataParsed } from '@lon/shared/utils';
import ScopesList from './ScopesList';

const ScopeListFrostWrapper: React.FC<{ type: ScopesListTypes }> = ({
  type,
}) => {
  const { data, loading, refetch } = useGetFrostDataParsed();

  return (
    <ScopesList
      type={type}
      data={data}
      loading={loading}
      refetch={refetch as any}
      studentsLoading={false}
    />
  );
};

export default ScopeListFrostWrapper;
