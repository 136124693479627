import ViewAllSectionsDrawer from '../view-all-sections-drawer';
import { Button, Text, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ViewAllButtonProps } from './types';

const ViewAllButton: React.FC<ViewAllButtonProps> = ({
  backgroundColor,
  color,
}) => {
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();

  const {
    isOpen: isViewAllOpen,
    onOpen: onViewAllOpen,
    onClose: onViewAllClose,
  } = useDisclosure();

  return (
    <>
      <Button
        backgroundColor={backgroundColor}
        color={color}
        ref={buttonRef}
        aria-label={t('scope.viewSections')}
        aria-expanded={isViewAllOpen}
        variant="solidDark"
        size="sm"
        onClick={onViewAllOpen}
      >
        <Text as="span" variant="bs" isTruncated>
          {t('scope.all')}
        </Text>
      </Button>

      <ViewAllSectionsDrawer isOpen={isViewAllOpen} onClose={onViewAllClose} />
    </>
  );
};

export default ViewAllButton;
