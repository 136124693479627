import { App } from '@suit/app';
import { CampusLeaderPages, SharedPages, TeacherPages } from '@suit/pages';
import React from 'react';
import { Route, createRoutesFromElements } from 'react-router-dom';
import { NotFound, ProtectedRoute } from '@lon/shared/components';
import { routes } from '@lon/shared/configs';
import { isFloridaReviewEnv, isReviewSite } from '@lon/shared/constants';
import { ApplicationEnum, DistrictPermission } from '@lon/shared/enums';
import { ErrorBoundary, TestError } from '@lon/shared/pages';
import { ModuleEnum, OperationEnum } from '@lon/shared/types';

const CampusLeaderRouter = createRoutesFromElements(
  <Route
    path={routes.home}
    element={<App />}
    errorElement={
      <ErrorBoundary application={ApplicationEnum.CAMPUS_LEADER_SUIT} />
    }
  >
    <Route index element={<TeacherPages.Dashboard />} />
    <Route path={routes.scopes.root}>
      <Route index element={<TeacherPages.Scopes />} />
      <Route
        path={routes.scopes.projectScopes}
        element={<TeacherPages.Scopes />}
      />
      <Route path={routes.scopes.show} element={<TeacherPages.Scope />}>
        <Route index element={<TeacherPages.Element />} />
        <Route
          path={routes.scopes.viewAll}
          element={<TeacherPages.ScopeSection />}
        />
        <Route
          path={routes.scopes.section}
          element={<TeacherPages.ScopeSection />}
        />
        <Route
          path={routes.scopes.element}
          element={<TeacherPages.Element />}
        />
      </Route>
    </Route>
    {!isReviewSite() && (
      <Route path={routes.navigation} element={<SharedPages.Navigation />} />
    )}
    <Route
      path={routes.teacherResources.root}
      element={<TeacherPages.Resources />}
    >
      <Route index element={<TeacherPages.ResourcesPage />} />
      <Route
        path={routes.teacherResources.resourceSections}
        element={<TeacherPages.ResourceSectionsList />}
      >
        <Route index element={<TeacherPages.ResourceSectionElementList />} />
        <Route
          path={routes.teacherResources.showResourceContent}
          element={<TeacherPages.ResourceContent />}
        />
      </Route>
    </Route>
    <Route
      path={routes.coding.root}
      element={
        <ProtectedRoute
          districtPermission={DistrictPermission.TEACHER_SUIT}
          module={ModuleEnum.coding}
          operation={OperationEnum.view}
          skipPolicy
        >
          <SharedPages.Coding />
        </ProtectedRoute>
      }
    />
    {!isReviewSite() && (
      <Route path={routes.planner.root} element={<TeacherPages.Planner />}>
        <Route index element={<TeacherPages.Calendar />} />
        <Route
          path={routes.planner.pacingGuide}
          element={<TeacherPages.PacingGuide />}
        >
          <Route
            path={routes.planner.selectedPacingGuide}
            index
            element={<TeacherPages.PacingGuide />}
          />
        </Route>
        <Route path={routes.planner.plans} element={<TeacherPages.Plans />}>
          <Route
            path={routes.planner.selectedPlan}
            index
            element={<TeacherPages.Plans />}
          />
        </Route>
      </Route>
    )}
    {!isReviewSite() && (
      <Route
        path={routes.teachers.root}
        element={<CampusLeaderPages.Teachers />}
      >
        <Route index element={<CampusLeaderPages.TeachersList />} />
        <Route
          path={routes.teachers.editTeacher}
          element={<CampusLeaderPages.EditTeacher />}
        />
        <Route
          path={routes.teachers.studentRoster}
          element={<CampusLeaderPages.StudentRoster />}
        />
        <Route
          path={routes.teachers.editStudent}
          element={<CampusLeaderPages.EditStudent />}
        />
        <Route
          path={routes.teachers.reports.root}
          element={<CampusLeaderPages.UserReport />}
        />
        <Route
          path={routes.teachers.reports.districtAssessment}
          element={<CampusLeaderPages.DistrictAssessmentsReports />}
        />
        <Route
          path={routes.teachers.reports.districtAssessmentDetails}
          element={<CampusLeaderPages.DistrictAssessmentDetails />}
        />
        <Route
          path={routes.teachers.districtAssessmentResult}
          element={<CampusLeaderPages.DistrictAssessmentResults />}
        />
      </Route>
    )}
    <Route
      path={routes.assessments.root}
      element={<TeacherPages.Assessments />}
    >
      <Route index element={<TeacherPages.AssessmentsList />} />
      <Route
        path={routes.assessments.show}
        element={<TeacherPages.Assessment />}
      />
    </Route>
    <Route
      path={routes.additionalResources.root}
      element={<TeacherPages.AdditionalResources />}
    />
    {isFloridaReviewEnv() && (
      <Route
        path={routes.previewStudentPlatform}
        element={<SharedPages.PreviewStudentPlatform />}
      />
    )}
    <Route path={routes.files.root}>
      <Route index element={<TeacherPages.Files />} />
      <Route path={routes.files.show} element={<TeacherPages.PdfPreview />} />
    </Route>
    <Route path={routes.testError} element={<TestError />} />
    <Route
      path={routes.googleClassRoom.root}
      element={<SharedPages.GoogleClassroom />}
    />
    <Route path={routes.additionalResources.help.root}>
      <Route index element={<SharedPages.HelpPage />} />
      <Route
        path={routes.additionalResources.help.details}
        element={<SharedPages.HelpPageDetails />}
      />
    </Route>
    <Route path="*" element={<NotFound withoutSidebar />} />
  </Route>
);

export default CampusLeaderRouter;
