import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { WorkingLocation } from '@lon/shared/contexts';
import { useGetSchoolIdsForPlanner } from '@lon/shared/hooks';
import { useScopePlansLazyQuery } from '@lon/shared/requests';
import { setPlansList, setSelectedPlan } from '@lon/shared/configs';
import * as plannerSelectors from '@lon/suit/selectors';
import { useUpdateSelectedPlan } from './useUpdateSelectedPlan';

export function useUpdatePlansList(redirect = true) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessibleSchoolIds = useGetSchoolIdsForPlanner();
  const { application } = useContext(WorkingLocation);
  const userRoleCode = application?.replace('Suit', '') || '';
  const { teamIds: userTeamIds } = useSelector(plannerSelectors.teams);

  const [executeGetScopePlans] = useScopePlansLazyQuery({
    variables: {
      schoolIds: accessibleSchoolIds,
      userRoleCode,
      userTeamIds,
    },
  });
  const [updateSelectedPlan] = useUpdateSelectedPlan();

  const updatePlansList = async (planId?: string | null, isDelete = false) => {
    await executeGetScopePlans({
      fetchPolicy: 'no-cache',
      onCompleted(data) {
        if (data?.scopePlans?.collection) {
          const plansList = {
            list: data?.scopePlans?.collection || [],
            fetchingPlansList: false,
          };
          const recentSelectedPlan = data?.scopePlans?.collection.find(
            (plan) => plan?.isSelected
          );
          const selectedPlan =
            planId ||
            recentSelectedPlan?.id ||
            data?.scopePlans?.collection?.[0]?.id;
          if (planId || isDelete) {
            const splittedPlanIdArray = planId
              ? planId?.split('/')
              : selectedPlan?.split('/') ?? [];
            updateSelectedPlan(
              splittedPlanIdArray[splittedPlanIdArray.length - 1]
            );
            const selectedPlanIndex = plansList.list.findIndex((plan) => {
              return plan?.id === selectedPlan;
            });
            const currPlan = plansList.list[selectedPlanIndex];
            if (currPlan) {
              plansList.list[selectedPlanIndex] = {
                ...currPlan,
                isSelected: true,
              };
            }
          }
          dispatch(setSelectedPlan(selectedPlan));
          if (selectedPlan) {
            if (redirect) {
              navigate(
                '/planner/plans/' +
                  selectedPlan.slice(selectedPlan.lastIndexOf('/') + 1)
              );
            }
          } else {
            if (redirect) {
              navigate('/planner/plans/');
            }
          }
          dispatch(setPlansList(plansList));
        }
      },
    });
  };
  return [updatePlansList];
}
