import { ViewAllButton } from '../../../menubar/components/navigation/components';
import { handleExitFullScreen, handleOpenFullScreen } from '../../duck';
import { Button, Flex, IconButton, Text } from '@chakra-ui/react';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../../../../../../icon';
import { FullScreenContext } from '@lon/shared/contexts';

const FullScreenButton: React.FC<{ showViewAllButton?: boolean }> = ({
  showViewAllButton = false,
}) => {
  const { t } = useTranslation();
  const { isFullScreen, setIsFullScreen } = useContext(FullScreenContext);

  const handleFullScreenChange = () => {
    const isCurrentlyFullScreen = !!document.fullscreenElement;
    setIsFullScreen(isCurrentlyFullScreen);
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullScreenChange);
    const handleKeydown = (event: any) => {
      if (event.key === 'Escape' && isFullScreen) {
        handleExitFullScreen(setIsFullScreen);
      }
    };

    window.addEventListener('keydown', handleKeydown);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
      window.removeEventListener('keydown', handleKeydown);
    };
  }, [isFullScreen]);

  return isFullScreen ? (
    <Flex alignSelf="flex-end" alignItems="center" mt={4} mb={4}>
      {showViewAllButton && (
        <ViewAllButton
          backgroundColor="var(--chakra-colors-primary-900)"
          color="white"
        />
      )}
      <IconButton
        aria-label="exit-full-screen"
        icon={<Icon name="closeOutlined" />}
        alignSelf="flex-end"
        mr={2}
        border={0}
        sx={{
          _hover: {
            bg: 'transparent',
            color: 'inherit',
          },
          _focus: {
            boxShadow: 'none',
          },
          _active: {
            bg: 'transparent',
          },
        }}
        onClick={() => handleExitFullScreen(setIsFullScreen)}
      />
    </Flex>
  ) : (
    <Button
      variant="outline"
      size="sm"
      alignSelf="flex-end"
      mr={{ base: 4, md: 6, lg: 8 }}
      mt={8}
      mb={4}
      aria-label="full-screen"
      leftIcon={<Icon name="fullScreen" />}
      onClick={() => handleOpenFullScreen(setIsFullScreen)}
    >
      <Text as="span">{t('common.expandBtn')}</Text>
    </Button>
  );
};

export default FullScreenButton;
