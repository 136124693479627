import { Box, Text } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { t } from 'i18next';
import { Link, generatePath } from 'react-router-dom';
import { GradeLevelEnum } from '@lon/shared/requests';
import { routes } from '@lon/shared/configs';
import { Columns, FullName } from './types';

export const getColumns = () => {
  const columnHelper = createColumnHelper<Columns>();

  return [
    columnHelper.accessor('name', {
      cell: (info) => {
        if (typeof info.getValue() === 'string') {
          return info.getValue();
        }

        const name = info.getValue() as FullName;

        return (
          <Box className="notranslate">
            <Link
              tabIndex={-1}
              to={generatePath(routes.classes.studentAssignments, {
                studentId: `${info.row.original.id}`,
              })}
            >
              <Text as="span" variant="su4" _hover={{ textDecoration: 'none' }}>
                {name.lastName}, {name.firstName}
              </Text>
            </Link>
            <Text variant="s2">{name.userName}</Text>
          </Box>
        );
      },
      header: t('subgroups.student') as string,
      sortingFn: (a, b) => {
        const aName = a.original.name as FullName;
        const bName = b.original.name as FullName;

        // TODO: update selection the sorting value when the student name display setting is implemented
        const first = (aName.lastName || '') + aName.firstName;
        const second = (bName.lastName || '') + bName.firstName;
        // TODO: update hardcoded 'en' to Spanish when change to Spanish is implemented
        return first.localeCompare(second, 'en', { numeric: true });
      },
    }),
    columnHelper.accessor('grade', {
      cell: (info) => (
        <Text variant="s2" color="blue.800">
          {t(`gradeLevels.${info.getValue()}`) as string}
        </Text>
      ),
      header: t('subgroups.grade') as string,
      sortingFn: (a, b) => {
        if (a.subRows.length || b.subRows.length) {
          return 0;
        }

        const first = a.original.grade;
        const second = b.original.grade;
        const grades = Object.values(GradeLevelEnum);

        if (!first) {
          return -1;
        }

        if (!second) {
          return 1;
        }

        return grades.indexOf(first) - grades.indexOf(second);
      },
    }),
  ];
};
