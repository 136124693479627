export const getGradeProgressVariant = (grade: number) => {
  switch (true) {
    case grade >= 90:
      return 'green';
    case grade >= 80:
      return 'yellow';
    case grade >= 70:
      return 'orange';
    default:
      return 'red';
  }
};
