import { Icon } from '../../../../../icon';
import { Message } from '../../../../../message';
import { Box, Flex, Tag, Text } from '@chakra-ui/react';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { sxDarkScrollBar } from '@lon/shared/constants';
import { ScopeContext } from '@lon/shared/contexts';
import { Section, countNestedItems } from './duck';
import { Activities, Breadcrumbs, Items } from './components';

const FactFluencyElementList: React.FC = () => {
  const { t } = useTranslation();
  const { sectionId } = useParams() as {
    sectionId: string;
  };
  const { sections } = useContext(ScopeContext);
  const currentTopic =
    (sections?.find(
      (section) => section.identifier === sectionId
    ) as Section) || null;

  const { elementsCount } = useMemo(
    () => countNestedItems(currentTopic),
    [currentTopic]
  );
  const isEmptyTopic = elementsCount === 0;

  return (
    <Box role="tabpanel" id="scope-elements" aria-labelledby="all-elements">
      <Breadcrumbs />
      <Flex
        flexDirection="column"
        backgroundColor="white"
        borderRadius="6px 6px"
        width="full"
        overflow="hidden"
      >
        <Flex width="full" borderRadius="6px 6px 0px 0px" css={sxDarkScrollBar}>
          <Flex width="full" height="100%" alignItems="center" px={8} h="64px">
            <Text variant="h5" color="blue.500">
              {t('factFluency.activities')}
            </Text>
            <Tag
              fontSize="14px"
              variant={isEmptyTopic ? 'yellowOutline' : 'counter'}
              border="none"
              ml="12px"
              px="3"
              {...(isEmptyTopic ? {} : { color: 'primary.700' })}
            >
              {elementsCount}
            </Tag>
          </Flex>
        </Flex>

        <Flex
          height="100%"
          flexDirection="column"
          gap={2}
          css={sxDarkScrollBar}
        >
          <Flex
            height="100%"
            flexDirection="column"
            width="full"
            backgroundColor="white"
          >
            <Box w="full">
              {!!currentTopic?.elements?.length && (
                <Items
                  level={1}
                  items={currentTopic?.elements}
                  sectionId={currentTopic?.identifier}
                />
              )}
              {!!currentTopic?.sections?.length && (
                <Activities
                  items={currentTopic?.sections}
                  level={1}
                  sectionId={currentTopic?.identifier}
                />
              )}
              {!currentTopic?.elements?.length &&
                !currentTopic?.sections?.length && (
                  <Box
                    py="4"
                    px="8"
                    w="full"
                    borderTopWidth="1px"
                    borderColor="secondary.200"
                  >
                    <Message
                      variant="formWarning"
                      p="8"
                      alertDescriptionProps={{
                        display: 'block',
                        padding: 0,
                      }}
                      message={
                        <Flex
                          direction="column"
                          gap="4"
                          alignItems="flex-start"
                        >
                          <Icon name="info-circle" size="2" />
                          <Flex direction="column">
                            <Text variant="n1">
                              {t('factFluency.noActivitiesTitle')}
                            </Text>
                            <Text variant="n3">
                              {t('factFluency.noActivitiesDescription')}
                            </Text>
                          </Flex>
                        </Flex>
                      }
                    />
                  </Box>
                )}
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default FactFluencyElementList;
