import {
  useGetFrostCurriculumAreasDataParsed,
  useGetFrostGradeDataParsed,
  useGetFrostTopicsDataParsed,
} from '@lon/shared/utils';
import FilterTabs from './FilterTabs';

const FilterTabsFrostWrapper: React.FC = () => {
  const { data: grades, loading: isGradesLoading } =
    useGetFrostGradeDataParsed();

  const { data: topics, loading: isTopicsLoading } =
    useGetFrostTopicsDataParsed();

  const { data: curriculumAreas, loading: isCurriculumAreasLoading } =
    useGetFrostCurriculumAreasDataParsed();

  return (
    <FilterTabs
      grades={grades}
      isGradesLoading={isGradesLoading}
      topics={topics}
      isTopicsLoading={isTopicsLoading}
      curriculumAreas={curriculumAreas}
      isCurriculumAreasLoading={isCurriculumAreasLoading}
    />
  );
};

export default FilterTabsFrostWrapper;
