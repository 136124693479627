import {
  Flex,
  HStack,
  IconButton,
  NumberInput,
  NumberInputField,
  Select,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@lon/shared/components';
import { DEFAULT_PAGINATION_SIZE } from '@lon/shared/constants';
import { onNumberInputKeyDown, onNumberInputPaste } from '@lon/shared/utils';
import { paginationConstants, paginationTypes, paginationUtils } from './duck';
import { Drawer, Popover } from './components';

const Pagination = <Data extends object>({
  initialPageSize,
  size = 'sm',
  table,
  innerRef,
  scrollRef,
  tableRef,
  paginationContainerProps = {},
  loading,
  resetPagination,
  onPaginationChange,
  syncExternalPagination,
  pageCount,
}: paginationTypes.PaginationProps<Data>) => {
  const [paginationSize, setPaginationSize] = useState<
    'mobile' | 'desktop' | 'tablet'
  >('desktop');

  const [itemsPerPage, setItemsPerPage] = React.useState<number>(
    initialPageSize || DEFAULT_PAGINATION_SIZE
  );
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const total = Object.keys(table.getRowModel().rowsById).length;
  const pageIndex = table.getState().pagination.pageIndex;
  const isDisabledNavigation = !pageCount || pageCount < 2;

  const pageSize = (e: React.ChangeEvent<HTMLSelectElement>) => {
    table.setPageSize(Number(e.target?.value || DEFAULT_PAGINATION_SIZE));
    setItemsPerPage(Number(e.target?.value || DEFAULT_PAGINATION_SIZE));
  };

  useEffect(() => {
    table.setPageSize(itemsPerPage);
  }, []);

  useEffect(() => {
    if (scrollRef?.current?.scrollTo) {
      scrollRef.current.scrollTo({ top: 0 });
    }
  }, [pageIndex]);

  useEffect(() => {
    if (!syncExternalPagination) return;

    if (syncExternalPagination.pageIndex !== undefined) {
      table.setPageIndex(syncExternalPagination.pageIndex);
    }

    if (syncExternalPagination.pageSize !== undefined) {
      table.setPageSize(syncExternalPagination.pageSize);
    }
  }, [syncExternalPagination]);

  useEffect(() => {
    if (!onPaginationChange) {
      return;
    }
    onPaginationChange(pageIndex, itemsPerPage);
  }, [pageIndex, itemsPerPage]);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const width = entry.borderBoxSize?.[0].inlineSize;
        const size =
          width <= 440 ? 'mobile' : width <= 976 ? 'tablet' : 'desktop';
        setPaginationSize(size);
      }
    });

    if (tableRef?.current?.parentElement) {
      observer.observe(tableRef?.current?.parentElement, {
        box: 'border-box',
      });
    }

    return () => observer?.disconnect();
  }, []);

  useLayoutEffect(() => {
    if (!loading && pageIndex !== 0 && !table.getRowModel().rows.length) {
      table.setPageIndex(table.getPageCount() - 1);
    }
  }, [loading]);

  useEffect(() => {
    if (resetPagination?.status) {
      table.resetPageIndex();
      resetPagination.onReset();
    }
  }, [resetPagination?.status]);

  if (!total) {
    return null;
  }

  return (
    <>
      <Flex
        ref={innerRef}
        data-testid="pagination"
        bgColor="white"
        color="primary.800"
        borderBottomRadius="md"
        justifyContent="space-between"
        alignItems="center"
        gap="3"
        py="3"
        px="8"
        zIndex={2}
        marginTop="-1px"
        borderTopWidth="1px"
        borderTopStyle="solid"
        borderTopColor="secondary.200"
        {...paginationContainerProps}
      >
        <HStack display={paginationSize === 'desktop' ? 'flex' : 'none'}>
          <Text as="span" variant="s2" flexShrink={0} mr="2">
            {t('pagination.showRecords')}
          </Text>
          <Select
            size={size}
            data-testid="show-records"
            color="primary.800"
            iconColor="primary.200"
            borderColor="primary.200"
            borderRadius="4px"
            w="90px"
            value={table.getState().pagination.pageSize}
            onChange={(e) => pageSize(e)}
            autoComplete="on"
          >
            {paginationConstants.ROW_OPTIONS.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        </HStack>
        <HStack
          spacing="2"
          justify="space-between"
          w={paginationSize === 'desktop' ? 'fit-content' : 'full'}
        >
          <HStack spacing="2">
            <IconButton
              size={size}
              variant="empty"
              aria-label={t('pagination.firstPage')}
              isDisabled={!table.getCanPreviousPage()}
              onClick={() => table.setPageIndex(0)}
              icon={<Icon name="arr-double-left-outlined" />}
            />
            <IconButton
              size={size}
              variant="empty"
              aria-label={t('pagination.previousPage')}
              onClick={() => table.previousPage()}
              isDisabled={!table.getCanPreviousPage()}
              icon={<Icon name="arr-left-outlined" />}
            />
          </HStack>
          {paginationSize === 'tablet' ? (
            <Popover
              size={size}
              table={table}
              currentPage={table.getState().pagination.pageIndex + 1}
              pageSize={pageSize}
              isDisabledNavigation={isDisabledNavigation}
            />
          ) : (
            <HStack
              spacing={paginationSize === 'mobile' ? '0' : '2.5'}
              align="center"
              alignSelf="normal"
              px={paginationSize === 'mobile' ? '2' : '4'}
              borderX="1px solid"
              borderColor="secondary.50"
              key={table.getPageCount()}
            >
              {paginationSize === 'desktop' && (
                <Flex
                  display="flex"
                  whiteSpace="pre-wrap"
                  justifyContent="center"
                  alignItems="center"
                  gap={1}
                >
                  <Text variant="s2">{t('pagination.page')}</Text>
                  <Text
                    variant="s1"
                    whiteSpace="nowrap"
                    data-testid="current-page"
                  >
                    <Text as="span" className="notranslate">
                      {table.getState().pagination.pageIndex + 1}
                    </Text>
                    &nbsp;
                    <Text as="span">{t('pagination.of')}</Text>&nbsp;
                    <Text as="span" className="notranslate">
                      {table.getPageCount()}
                    </Text>
                  </Text>
                </Flex>
              )}
              {paginationSize === 'mobile' && (
                <IconButton
                  size={size}
                  display="flex"
                  variant="solidDark"
                  aria-label={t('pagination.openPaginationSettings')}
                  onClick={onOpen}
                  icon={<Icon name="ellipsis-outlined" />}
                />
              )}
            </HStack>
          )}
          <HStack spacing="2">
            <IconButton
              size={size}
              variant="empty"
              transform="scaleX(-1)"
              aria-label={t('pagination.nextPage')}
              onClick={() => table.nextPage()}
              isDisabled={!table.getCanNextPage()}
              icon={<Icon name="arr-left-outlined" />}
            />
            <IconButton
              size={size}
              variant="empty"
              transform="scaleX(-1)"
              aria-label={t('pagination.lastPage')}
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              isDisabled={!table.getCanNextPage()}
              icon={<Icon name="arr-double-left-outlined" />}
            />
          </HStack>
        </HStack>
        <HStack
          display={paginationSize === 'desktop' ? 'flex' : 'none'}
          opacity={isDisabledNavigation ? '0.6' : '1'}
        >
          <Text variant="s2" whiteSpace="nowrap" mr="2">
            {t('pagination.goToPage')}
          </Text>
          <NumberInput
            size={size}
            data-testid="go-to-page"
            maxW="90px"
            min={1}
            isDisabled={isDisabledNavigation}
            max={table.getPageCount()}
            inputMode="numeric"
            onKeyDown={(e) => {
              onNumberInputKeyDown(e);
              if (e.key === 'Enter') {
                const value = (e.target as HTMLInputElement).value;
                paginationUtils.validatePageNumber({
                  pageNumber: value,
                  max: table.getPageCount(),
                  successCallback: (page) => {
                    table.setPageIndex(page - 1);
                    (e.target as HTMLInputElement).blur();
                  },
                });
              }
            }}
            onPaste={onNumberInputPaste}
            onBlur={(e) => {
              const value = e.target.value;
              paginationUtils.validatePageNumber({
                pageNumber: value,
                max: table.getPageCount(),
                successCallback: (page) => {
                  table.setPageIndex(page - 1);
                },
              });
            }}
          >
            <NumberInputField />
          </NumberInput>
        </HStack>
      </Flex>
      {/*<Drawer*/}
      {/*  isOpen={isOpen}*/}
      {/*  onClose={onClose}*/}
      {/*  table={table}*/}
      {/*  currentPage={table.getState().pagination.pageIndex + 1}*/}
      {/*  pageSize={pageSize}*/}
      {/*  isDisabledNavigation={isDisabledNavigation}*/}
      {/*/>*/}
    </>
  );
};

export default Pagination;
