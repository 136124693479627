import { Button, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setFilters } from '@lon/shared/configs';
import { useQueryParams, useThemeStyleValue } from '@lon/shared/hooks';

const ResetButton: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [params, setParams] = useQueryParams();
  const page = Number(params.get('pagination.page'));
  const size = Number(params.get('pagination.itemsPerPage'));

  const handleReset = () => {
    const initialPagination = page && { itemsPerPage: size, page: 1 };

    setParams({
      ...params,
      filters: undefined,
      ...(initialPagination ? { pagination: initialPagination } : {}),
    });
    dispatch(setFilters({ scopes: { filters: {} } }));
  };

  return (
    <Button
      w="100%"
      variant={useThemeStyleValue('outlineDark', 'outline')}
      onClick={handleReset}
      title={t('filters.reset')}
      fontWeight={400}
    >
      <Text as="span" isTruncated>
        {t('filters.reset')}
      </Text>
    </Button>
  );
};

export default ResetButton;
