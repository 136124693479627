/* eslint-disable array-callback-return */
import {
  Badge,
  Box,
  Button,
  DrawerFooter,
  Flex,
  HStack,
  TabPanel,
  Tag,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { AiOutlinePlus } from '@react-icons/all-files/ai/AiOutlinePlus';
import { isEmpty, isEqual } from 'lodash-es';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  ConfirmModal,
  Icon,
  IconButton,
  RequiredNotification,
  TextInput,
  useToast,
} from '@lon/shared/components';
import { sxLightScrollBar } from '@lon/shared/constants';
import {
  TeamContextEnum,
  TeamPageConnection,
  UserTypeEnum,
  useCreateTeamMutation,
  useTeamsQuery,
  useUpdateTeamMutation,
} from '@lon/shared/requests';
import { clearQueryCache, handleError } from '@lon/shared/utils';
import { setTeamsData } from '@lon/shared/configs';
import { RootState } from '@lon/shared/configs';
import { Props, getInitialData, getValidation, teamsType } from './duck';
import {
  ArchieveModal,
  AsyncSelectComponent,
  Skeleton,
  StatusLabel,
} from './components';

const Teams = React.forwardRef<
  {
    closeSettings: () => void;
    changeTab: (index: number) => void;
  },
  Props
>(
  (
    {
      setIsPreferencesOpen,
      setIsDirtyForm,
      isFormDirty,
      setCurrentTab,
      initialTabIndex,
    },
    externalRef
  ) => {
    const { user } = useSelector((state: RootState) => state.auth);
    const { t } = useTranslation();
    const { toast } = useToast();
    const [changeTab, setChangeTab] = useState<number | null>(null);
    const [archivedIndex, setArchivedIndex] = useState<number | null>(null);
    const [restoredItem, setRestoredItem] = useState<any>(null);
    const isParent = user?.type === UserTypeEnum.Parent;
    const {
      isOpen: isConfirmOpen,
      onOpen: onConfirmOpen,
      onClose: onConfirmClose,
    } = useDisclosure();
    const archiveModal = useDisclosure();
    const restoreModal = useDisclosure();
    const [updateTeam, { loading: updateTeamLoading }] =
      useUpdateTeamMutation();
    const [createTeam, { loading: createTeamLoading }] =
      useCreateTeamMutation();
    const dispatch = useDispatch();

    const { data: teams, loading: teamsLoading } = useTeamsQuery({
      variables: {
        itemsPerPage: 100,
        page: 1,
        filter: {
          membersActive: true,
        },
      },
      skip: isParent,
    });

    React.useEffect(() => {
      dispatch(
        setTeamsData(
          teams?.teams?.collection?.filter((v) => !v?.archived) || []
        )
      );
    }, [teams]);

    const mainTeacher = {
      id: user?.userId,
      firstName: user?.firstName,
      lastName: user?.lastName,
    };

    const getTeachers = (users: any) =>
      users?.users?.collection?.length
        ? users?.users?.collection?.map((teacher: any) => ({
            value: `${teacher?._id}`,
            label: `${teacher?.firstName} ${teacher?.lastName}`,
            isFixed: teacher?._id === mainTeacher?.id,
          }))
        : [];

    const { validationSchema, defaultValues } = getValidation(
      t,
      teams?.teams as TeamPageConnection
    );

    const formProviderProps = useForm({
      resolver: yupResolver(validationSchema),
      defaultValues,
      mode: 'onSubmit',
    });

    const values = formProviderProps.getValues().teams;

    const { fields, append, remove } = useFieldArray({
      control: formProviderProps.control,
      name: 'teams',
    });

    useEffect(() => {
      resetToDefault();
    }, [teams]);

    const resetToDefault = () => {
      formProviderProps.reset(getInitialData(teams?.teams as any, mainTeacher));
    };

    const addTeam = () => {
      append({
        name: '',
        members: [
          {
            value: mainTeacher?.id,
            label: `${mainTeacher?.firstName} ${mainTeacher?.lastName}`,
            isFixed: true,
          },
        ],
        archived: false,
        identifier: undefined,
      });
    };

    const archiveTeam = async () => {
      const archivedTeam = values?.find(
        (_: any, i: number) => i === archivedIndex
      );

      await updateTeam({
        variables: {
          input: {
            id: archivedTeam?.identifier,
            name: archivedTeam?.name,
            archived: true,
            members: archivedTeam?.members?.map((member: any) => member.value),
          },
        },
        onCompleted: () => {
          archiveModal.onClose();
          toast({
            title: t('userSettings.teams.updatedSuccessfully', {
              entity: 'User Settings',
            }),
            description: t(
              'userSettings.teams.archivedSuccessfullyDescription',
              {
                entity: archivedTeam?.name,
              }
            ),
            variant: 'success-light',
            isClosable: true,
          });
          clearQueryCache('teams');
        },
        onError: () => {
          archiveModal.onClose();
          toast({
            title: t('userSettings.teams.errorMessage'),
            variant: 'error-light',
            isClosable: true,
            duration: null,
          });
        },
      });
    };

    const removeTeam = (index: number, isTeamCreated: boolean) => {
      if (isTeamCreated) {
        archiveModal.onOpen();
        setArchivedIndex(index);
        return;
      }

      remove(index);
    };

    useEffect(() => {
      setIsDirtyForm(formProviderProps.formState.isDirty);
    }, [formProviderProps.formState.isDirty]);

    const handleCloseSettings = () => {
      formProviderProps.trigger();
      setChangeTab(null);

      if (isFormDirty) {
        onConfirmOpen();
      } else {
        setIsPreferencesOpen(false);
      }
    };

    const handleChangeTab = (index: number) => {
      formProviderProps.trigger();
      setChangeTab(index);

      if (isFormDirty) {
        onConfirmOpen();
      } else {
        setCurrentTab(index);
      }
    };

    useImperativeHandle(
      externalRef,
      () => ({
        closeSettings: handleCloseSettings,
        changeTab: handleChangeTab,
      }),
      [handleCloseSettings, handleChangeTab]
    );

    const restoreTeam = async () => {
      const archivedTeam = values?.find(
        (v: any) => v?.name === restoredItem?.name && restoredItem?.identifier
      );

      await updateTeam({
        variables: {
          input: {
            id: archivedTeam?.identifier,
            name: archivedTeam?.name,
            archived: false,
            members: archivedTeam?.members?.map((member: any) => member.value),
          },
        },
        onCompleted: () => {
          restoreModal.onClose();
          toast({
            title: t('userSettings.teams.updatedSuccessfully', {
              entity: 'User Settings',
            }),
            description: t(
              'userSettings.teams.restoredSuccessfullyDescription',
              {
                entity: archivedTeam?.name,
              }
            ),
            variant: 'success-light',
            isClosable: true,
          });
          clearQueryCache('teams');
        },
        onError: () => {
          restoreModal.onClose();
          toast({
            title: t('userSettings.teams.errorMessage'),
            variant: 'error-light',
            isClosable: true,
            duration: null,
          });
        },
      });
    };

    const handleRestoreTeam = (item: any) => {
      setRestoredItem(item);
      restoreModal.onOpen();
    };

    const resetDeletion = (index: number) => {
      const teamsValue = values;

      if (typeof index === 'number') {
        teamsValue[index] = {
          ...values[index],
          members:
            [
              ...values[index].members,
              {
                value: mainTeacher?.id,
                label: `${mainTeacher?.firstName} ${mainTeacher?.lastName}`,
                isFixed: true,
              },
            ] || [],
        };
      }

      formProviderProps.reset({ teams: teamsValue });
    };

    const findDuplicateIndices = (teams: any) => {
      const nameCount = {} as any;
      const duplicateIndexes = [] as number[];

      teams.forEach((team: any, index: number) => {
        const { name } = team;
        if (nameCount?.[name] === undefined) {
          nameCount[name] = index;
        } else {
          if (!duplicateIndexes?.includes(nameCount?.[name])) {
            duplicateIndexes?.push(nameCount?.[name]);
          }
          duplicateIndexes.push(index);
        }
      });

      return duplicateIndexes;
    };

    const onSubmit = formProviderProps.handleSubmit(async (values) => {
      const newFields = values?.teams?.filter(
        (v: any) => !(v as any)?.identifier
      ) as teamsType[];

      const duplicateIndices = findDuplicateIndices(values?.teams);
      if (duplicateIndices?.length) {
        duplicateIndices.forEach((index: number, idx) => {
          if (idx === 0 && !values.teams[index].archived) {
            formProviderProps.setFocus(`teams.${index}.name`);
          }

          formProviderProps.setError(`teams.${index}.name`, {
            type: 'validation',
            message: t('userSettings.teams.errorMessageNotUnique'),
          });
        });
        return;
      }

      try {
        if (newFields?.length) {
          await Promise.all(
            newFields?.map(async (value) => {
              await createTeam({
                variables: {
                  input: {
                    name: value.name,
                    members: value.members.map((v) => v?.value),
                    context: TeamContextEnum.Calendar,
                  },
                },
                onCompleted: () => {
                  toast({
                    title: t('userSettings.teams.createdSuccessfully', {
                      entity: 'User Settings',
                    }),
                    description: t(
                      'userSettings.teams.createdSuccessfullyDescription',
                      {
                        entity: value.name,
                      }
                    ),
                    variant: 'success-light',
                    isClosable: true,
                  });
                },
                onError: (error: any) => {
                  if (
                    error?.message ===
                    'name: VALIDATION_CONSTRAINT_TEAM_NAME_UNIQUE'
                  ) {
                    const currentIndex = values?.teams?.findIndex(
                      (v: any) => v?.name === value.name && !v?.identifier
                    );
                    if (!values.teams[currentIndex].archived) {
                      formProviderProps.setFocus(`teams.${currentIndex}.name`);
                    }

                    formProviderProps.setError(`teams.${currentIndex}.name`, {
                      type: 'validation',
                      message: t('userSettings.teams.errorMessageNotUnique'),
                    });
                    toast({
                      title: t('userSettings.teams.errorMessage', {
                        entity: value?.name,
                      }),
                      description: (
                        <Trans
                          i18nKey={t(
                            'userSettings.teams.uniqueErrorDescription'
                          )}
                        />
                      ),
                      variant: 'error-light',
                      isClosable: true,
                    });
                  } else {
                    toast({
                      title: t('userSettings.teams.errorMessage'),
                      variant: 'error-light',
                      isClosable: true,
                      duration: null,
                    });
                  }
                },
              });
            })
          );
        }
      } catch (error: any) {
        handleError({ error, toast });
      }

      const createdFields = values?.teams?.filter(
        (v: any) => (v as any)?.identifier
      ) as teamsType[];

      try {
        if (createdFields?.length) {
          await Promise.all(
            createdFields?.map(async (v) => {
              const matchedValue = teams?.teams?.collection?.find(
                (value) => value?.id === v?.identifier
              );
              const editedValue = !isEqual(
                {
                  name: v?.name,
                  members: v?.members
                    ?.map((val) => ({
                      label: val?.label,
                      value: val?.value,
                    }))
                    .sort((a: any, b: any) => (a?.label > b?.label ? 1 : -1)),
                },
                {
                  name: matchedValue?.name,
                  members: matchedValue?.members
                    ?.map((v) => ({
                      value: `${v?.id}`,
                      label: `${v?.firstName} ${v?.lastName}`,
                    }))
                    .sort((a: any, b: any) => (a?.label > b?.label ? 1 : -1)),
                }
              );

              if (editedValue) {
                await updateTeam({
                  variables: {
                    input: {
                      id: v?.identifier,
                      name: v?.name,
                      members: v?.members?.map((member) => member.value),
                    },
                  },
                  onCompleted: () => {
                    toast({
                      title: t('userSettings.teams.updatedSuccessfully', {
                        entity: 'User Settings',
                      }),
                      description: t(
                        'userSettings.teams.updatedSuccessfullyDescription',
                        {
                          entity: v?.name,
                        }
                      ),
                      variant: 'success-light',
                      isClosable: true,
                    });
                  },
                  onError: (err) => {
                    if (
                      err?.message ===
                      'name: VALIDATION_CONSTRAINT_TEAM_NAME_UNIQUE'
                    ) {
                      const currentIndex = values?.teams?.findIndex(
                        (val: any) => val?.name === v?.name && val?.identifier
                      );

                      formProviderProps.setError(`teams.${currentIndex}.name`, {
                        type: 'validation',
                        message: t('userSettings.teams.errorMessageNotUnique'),
                      });
                      toast({
                        title: t('userSettings.teams.errorMessage', {
                          entity: v?.name,
                        }),
                        description: (
                          <Trans
                            i18nKey={t(
                              'userSettings.teams.uniqueErrorDescription'
                            )}
                          />
                        ),
                        variant: 'error-light',
                        isClosable: true,
                      });
                    } else {
                      toast({
                        title: t('userSettings.teams.errorMessage'),
                        variant: 'error-light',
                        isClosable: true,
                        duration: null,
                      });
                    }
                  },
                });
              } else {
                // eslint-disable-next-line array-callback-return
                return;
              }
            })
          );
        }
      } catch (error: any) {
        handleError({ error, toast });
      }
      setIsDirtyForm(false);
      clearQueryCache('teams');
    });

    const onClose = (shouldCloseForm?: boolean) => {
      if (shouldCloseForm) {
        setIsPreferencesOpen(false);
      } else if (typeof changeTab === 'number') {
        setCurrentTab(changeTab);
      } else {
        setIsPreferencesOpen(false);
      }

      setCurrentTab(initialTabIndex || 0);
      formProviderProps.reset(
        getInitialData(teams?.teams as TeamPageConnection, mainTeacher)
      );
      onConfirmClose();
    };

    const closeForm = () => {
      if (
        formProviderProps.formState.isDirty ||
        !isEmpty(formProviderProps.formState.dirtyFields) ||
        isFormDirty
      ) {
        onConfirmOpen();
      } else {
        onClose(true);
      }
    };

    const getCurrentIndex = (fields: any, id: string, isArchived?: boolean) => {
      const filteredValues = fields?.filter((v: any) => v?.archived);
      const unarchivedValues = fields?.filter((v: any) => !v?.archived);
      if (isArchived) {
        return (
          (filteredValues?.findIndex((v: any) => v?.id === id) || 0) +
          unarchivedValues?.length
        );
      } else {
        return unarchivedValues?.findIndex((v: any) => v?.id === id) || 0;
      }
    };

    if (teamsLoading) {
      return <Skeleton />;
    }

    return (
      <TabPanel as="form" p={0} noValidate onSubmit={onSubmit} w="full">
        <FormProvider {...formProviderProps}>
          <Flex
            display="flex"
            direction="column"
            color="primary.800"
            h="calc(100vh - 9.25rem)"
          >
            <Box
              overflow="auto"
              h="calc(100vh - 9.25rem)"
              sx={sxLightScrollBar}
            >
              <Box
                pl={{ base: 5, md: 7, lg: '150px' }}
                pr={{ base: 5, md: 7, lg: '125px' }}
                py={6}
                borderBottom="1px solid"
                borderColor="secondary.200"
              >
                <RequiredNotification />
              </Box>
              <Flex justify="space-between">
                <Box pt={4} w="full">
                  <Text
                    variant="s3"
                    pl={{ base: 5, md: 7, lg: '150px' }}
                    pr={{ base: 5, md: 7, lg: '125px' }}
                  >
                    <Trans i18nKey={'userSettings.teams.description'} />
                  </Text>
                  {fields?.map((item: any, index: number) => {
                    if (!item.archived) {
                      return (
                        <Box
                          pb={6}
                          pl={{ base: 5, md: 7, lg: '150px' }}
                          pr={{ base: 5, md: 7, lg: '125px' }}
                          borderBottom="1px solid"
                          borderColor="secondary.200"
                        >
                          <Flex
                            py={4}
                            w="100%"
                            position="relative"
                            alignItems="center"
                            justify="space-between"
                          >
                            <Box w="100%" position="relative">
                              <Flex position="absolute" right={0} top={0}>
                                <StatusLabel
                                  memberId={(item as any)?.identifier}
                                  teams={teams}
                                />
                                <Box display={{ base: 'block', lg: 'none' }}>
                                  <Button
                                    ml={2}
                                    zIndex={1}
                                    onClick={() =>
                                      removeTeam(
                                        index,
                                        (item as any)?.identifier
                                      )
                                    }
                                    isDisabled={
                                      (formProviderProps.formState.isDirty ||
                                        isFormDirty) &&
                                      (item as any)?.identifier
                                    }
                                    rightIcon={
                                      <Icon
                                        name={
                                          !(item as any)?.identifier
                                            ? 'delete-outlined'
                                            : (item as any)?.archived
                                            ? 'unarchived'
                                            : 'archived'
                                        }
                                        size="lg"
                                      />
                                    }
                                    size="md"
                                    variant="link"
                                    textDecoration="underline"
                                  >
                                    {!(item as any)?.identifier
                                      ? t('userSettings.teams.delete')
                                      : (item as any)?.archived
                                      ? t('userSettings.teams.unarchive')
                                      : t('userSettings.teams.archive')}
                                  </Button>
                                </Box>
                              </Flex>
                              <TextInput
                                label={t('userSettings.teams.teamName', {
                                  entity: `${
                                    getCurrentIndex(fields, item?.id) + 1
                                  }`,
                                })}
                                maxLength={30}
                                name={`teams.${index}.name`}
                                isRequired
                                placeholder={t(
                                  'userSettings.teams.teamNamePlaceholder',
                                  {
                                    entity: `${
                                      getCurrentIndex(fields, item?.id) + 1
                                    }`,
                                  }
                                )}
                              />
                            </Box>
                            <IconButton
                              aria-label={
                                (item as any)?.archived
                                  ? t('userSettings.teams.unarchive')
                                  : t('userSettings.teams.archive')
                              }
                              variant="ghost"
                              position="absolute"
                              right={'-4rem'}
                              display={{ base: 'none', lg: 'block' }}
                              top="50px"
                              isDisabled={
                                (formProviderProps.formState.isDirty ||
                                  isFormDirty) &&
                                (item as any)?.identifier
                              }
                              icon={
                                <Icon
                                  name={
                                    !(item as any)?.identifier
                                      ? 'delete-outlined'
                                      : (item as any)?.archived
                                      ? 'unarchived'
                                      : 'archived'
                                  }
                                  size="lg"
                                />
                              }
                              onClick={() =>
                                removeTeam(index, (item as any)?.identifier)
                              }
                            />
                          </Flex>
                          <Box w="100%">
                            <AsyncSelectComponent
                              getTeachers={getTeachers}
                              index={index}
                              selectedIndex={getCurrentIndex(fields, item?.id)}
                              backspaceRemovesValue={false}
                              tooltipEntity={formProviderProps.getValues(
                                `teams.${index}.name`
                              )}
                            />
                          </Box>
                          {!values[index]?.members?.find(
                            (v: any) => v?.value === mainTeacher?.id
                          ) && (
                            <Flex
                              w="100%"
                              alignItems="center"
                              flexDir="column"
                              py="35px"
                              px={8}
                              mt={6}
                              color="yellow.800"
                              borderRadius="6px"
                              backgroundColor="warning.extraLight"
                              borderWidth="1px"
                              borderColor="warning.light"
                            >
                              <Box mb={2}>
                                <Text variant="h6">
                                  {t('userSettings.teams.warningTitle')}
                                </Text>
                              </Box>
                              <Box mb={2} textAlign="center">
                                <Text variant="s2">
                                  {
                                    <Trans
                                      i18nKey={t(
                                        'userSettings.teams.warningDescription'
                                      )}
                                    />
                                  }
                                </Text>
                              </Box>
                              <Button
                                textDecoration="underline"
                                variant="link"
                                color="yellow.800"
                                leftIcon={
                                  <Icon
                                    color="yellow.800"
                                    name="redoOutlined"
                                  />
                                }
                                _active={{
                                  color: 'yellow.800',
                                }}
                                onClick={() => resetDeletion(index)}
                              >
                                {t('userSettings.teams.warningLink')}
                              </Button>
                            </Flex>
                          )}
                        </Box>
                      );
                    } else {
                      return;
                    }
                  })}
                </Box>
              </Flex>
              <Flex
                mb={4}
                w="full"
                pl={{ base: 5, md: 7, lg: '150px' }}
                pr={{ base: 5, md: 7, lg: '125px' }}
                mt="1.375rem"
              >
                <Flex w="100%" justifyContent="flex-end">
                  <Button
                    onClick={addTeam}
                    variant="outline"
                    size="sm"
                    leftIcon={<AiOutlinePlus />}
                  >
                    {t('userSettings.teams.addNewTeam')}
                  </Button>
                </Flex>
              </Flex>
              {fields?.map((item: any, index: number) => {
                if (item.archived) {
                  return (
                    <Flex
                      w="full"
                      pl={{ base: 5, md: 7, lg: '150px' }}
                      pr={{ base: 5, md: 7, lg: '125px' }}
                      mt="1.375rem"
                    >
                      <Flex
                        w="100%"
                        borderRadius="6px"
                        boxShadow="0px 5px 20px -2px rgba(43, 54, 70, 0.07)"
                      >
                        <Flex
                          w="100%"
                          direction="column"
                          p={6}
                          color="primary.800"
                        >
                          <Flex
                            mb="6px"
                            flexDir={{ base: 'column', md: 'row' }}
                            justifyContent={{
                              base: 'flex-start',
                              md: 'space-between',
                            }}
                            align={{ base: 'flex-start', md: 'center' }}
                          >
                            <Text variant="n1">
                              {t('userSettings.teams.teamName', {
                                entity: `${
                                  getCurrentIndex(fields, item?.id, true) + 1
                                }`,
                              })}
                            </Text>
                            <Box>
                              <Flex
                                flexDir="row"
                                justifyContent="space-between"
                                align="center"
                              >
                                <Badge
                                  variant="solid"
                                  bg="primary.800"
                                  mr={6}
                                  py={1}
                                  px="10px"
                                >
                                  <Text variant="tag">
                                    {t('userSettings.teams.archived')}
                                  </Text>
                                </Badge>
                                <Button
                                  zIndex={1}
                                  onClick={() => handleRestoreTeam(item)}
                                  isDisabled={
                                    formProviderProps.formState.isDirty ||
                                    isFormDirty
                                  }
                                  size="md"
                                  variant="link"
                                  textDecoration="underline"
                                  rightIcon={
                                    <Icon name="unarchived" size="lg" />
                                  }
                                >
                                  {t('userSettings.teams.restore')}
                                </Button>
                              </Flex>
                            </Box>
                          </Flex>
                          <Text mb={6} variant="n3">
                            {item?.name}
                          </Text>
                          <Text mb={2} variant="n1">
                            {t('userSettings.teams.teamMembers', {
                              entity: `${
                                getCurrentIndex(fields, item?.id, true) + 1
                              }`,
                            })}
                          </Text>
                          <Box pt="10px" pb={1}>
                            {item?.members?.map(
                              ({ label }: { label: string }) => {
                                return (
                                  <Tag
                                    mr="6px"
                                    mb="6px"
                                    variant="subtle"
                                    borderRadius="12px"
                                  >
                                    <Text variant="tag" color="primary.700">
                                      {label}
                                    </Text>
                                  </Tag>
                                );
                              }
                            )}
                          </Box>
                        </Flex>
                      </Flex>
                    </Flex>
                  );
                } else {
                  return;
                }
              })}
            </Box>
            <DrawerFooter
              h="5.75rem"
              borderTopWidth="1px"
              justifyContent="flex-start"
              py={0}
              flex="none"
            >
              <HStack justify="space-between" width="100%">
                <Button
                  leftIcon={<Icon name="arr-left-outlined" />}
                  onClick={closeForm}
                >
                  {t('userSettings.teams.cancel')}
                </Button>
                <Button
                  leftIcon={<Icon name="check-outlined" />}
                  variant="solid"
                  type="submit"
                  isDisabled={
                    updateTeamLoading ||
                    createTeamLoading ||
                    !(formProviderProps.formState.isDirty || isFormDirty)
                  }
                >
                  {t('userSettings.teams.save')}
                </Button>
              </HStack>
            </DrawerFooter>
          </Flex>
        </FormProvider>
        <ConfirmModal
          primaryHeading={t('userSettings.modal.title')}
          subHeading={t('userSettings.modal.description', {
            entity: 'Teams',
          })}
          dontSaveBtnText={t('userSettings.modal.cancel')}
          submitButtonText={t('userSettings.modal.save')}
          hasDontSaveBtn
          hasSaveBtn={formProviderProps.formState.isValid}
          isModalOpen={isConfirmOpen}
          handleSave={() => {
            onSubmit();
            onConfirmClose();
            onClose();
          }}
          handleDontSave={onClose}
          handleCancel={onConfirmClose}
          isLoading={updateTeamLoading || createTeamLoading}
          dontSaveBtnProps={{
            variant: 'solidDark',
            leftIcon: <Icon name="delete-outlined" size="lg" />,
            px: 4,
          }}
          submitBtnProps={{
            leftIcon: <Icon name="check-outlined" size="md" />,
            px: 4,
          }}
          modalSize={{ base: 'full', sm: '500px' }}
        />
        {archiveModal.isOpen && (
          <ArchieveModal
            isOpen
            loading={updateTeamLoading}
            onClose={() => archiveModal.onClose()}
            onSubmit={archiveTeam}
            teamName={
              values?.find((_: any, i: number) => i === archivedIndex)?.name
            }
          />
        )}
        <ConfirmModal
          primaryHeading={t('userSettings.teams.modal.restoreTitle')}
          subHeading={t('userSettings.teams.modal.restoreDescription', {
            entity: restoredItem?.name,
          })}
          submitButtonText={t('userSettings.teams.modal.restore')}
          hasSaveBtn
          isModalOpen={restoreModal.isOpen}
          handleSave={restoreTeam}
          handleCancel={restoreModal.onClose}
          isLoading={updateTeamLoading}
          submitBtnProps={{
            leftIcon: <Icon name="check-outlined" size="md" />,
            px: 4,
          }}
          modalSize={{ base: 'full', sm: '500px' }}
        />
      </TabPanel>
    );
  }
);

export default Teams;
