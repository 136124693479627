import Caption from '../caption';
import { Text, VStack, useToast } from '@chakra-ui/react';
import { Table } from 'libs/shared/modules/table/src';
import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ActivitiesTableProps, Columns, name, useGetColumns } from './duck';

const ActivitiesTable: React.FC<ActivitiesTableProps> = ({ data }) => {
  const { t } = useTranslation();
  const formContext = useFormContext();
  const toast = useToast();

  const columns = useGetColumns(data, t);

  useEffect(() => {
    data?.forEach((element) => {
      formContext.setValue(
        `${name}.${element.value}.isGradable`,
        element.referenceId ? 'true' : 'false'
      );
    });
  }, [data]);

  const handleSelectedRows = useCallback((rows: string[]) => {
    const activities = formContext.getValues(name);

    if (
      rows.length ===
      Object.keys(activities).filter(
        (key) => activities[key].isChecked === 'true'
      )?.length
    ) {
      return;
    }

    if (rows.length === 0) {
      data.forEach((element) => {
        formContext.setValue(`${name}.${element.value}.isChecked`, 'false');
      });
    } else {
      data.forEach((element) => {
        const isChecked = rows.includes(element.value) ? 'true' : 'false';
        formContext.setValue(`${name}.${element.value}.isChecked`, isChecked, {
          shouldDirty: true,
        });
      });
    }
  }, []);

  const handleMarkAllAsGradable = () => {
    if (data?.every((element) => !element.referenceId)) {
      toast({
        title: t('createAssignmentDrawer.noGradableActivitiesTitle'),
        status: 'error',
        variant: 'error-light',
        isClosable: true,
        duration: null,
      });
      return;
    }

    data.forEach((element) => {
      if (!element.referenceId) {
        return;
      }
      formContext.setValue(`${name}.${element.value}.isGradable`, 'true');
    });
  };

  const handleMarkAllAsResource = () => {
    data.forEach((element) => {
      formContext.setValue(`${name}.${element.value}.isGradable`, 'false');
    });
  };

  return (
    <VStack gap="6" h="full" w="full">
      <Text variant="s3">{t('createAssignmentDrawer.activitiesNote')}</Text>
      <Table
        data={data as Columns[]}
        columns={columns}
        enableRowSelection
        onRowSelectStateChange={handleSelectedRows}
        getRowId={(row) => row.value}
        enableVerticalBorders
        showGroupActionsIfEmpty
        renderGroupActions={(table) => (
          <Caption
            table={table}
            handleMarkAllAsGradable={handleMarkAllAsGradable}
            handleMarkAllAsResource={handleMarkAllAsResource}
          />
        )}
        containerProps={{ w: 'full' }}
      />
    </VStack>
  );
};

export default ActivitiesTable;
