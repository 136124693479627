import {
  scopeConstants,
  scopeHooks,
} from '@lon/shared/components';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AsyncOutlet } from '@lon/shared/components';
import { StudentGradeContext, WorkingLocation } from '@lon/shared/contexts';
import { ProfileTypeEnum } from '@lon/shared/enums';
import {
  useAuth,
  useGetSchoolIdsForPlanner as useGetSchoolIds,
  useGradeLevel,
} from '@lon/shared/hooks';
import { useGetScope2Query } from '@lon/shared/requests';
import { JsonScopeStructure, ScopeContext } from '@lon/shared/contexts';
import { usePrevious, useSchoolContentGroups } from '@lon/shared/hooks';
import { ContentFilterer } from '@lon/suit/utils';
import { transformElements } from '@lon/shared/utils';

const LtiScopeContainer: React.FC = () => {
  const [auth] = useAuth();
  const { currentSchoolId } = useContext(WorkingLocation);
  const accessibleSchoolIds = useGetSchoolIds();
  const params = useParams<{
    scopeId: string;
  }>();
  const { scopeId } = params;
  const { isStudentBelowK3 } = useGradeLevel();
  const [attempts, setAttempts] = useState<number>(0);
  const prevAttempts = usePrevious(attempts);

  const { data: standardsJson } = scopeHooks.useFetchJsonData({
    url: scopeConstants.standardsUrl,
    options: {},
    skip: auth.profileType === ProfileTypeEnum.STUDENT,
  });

  const state = auth?.user?.state;
  const {
    data: scopeUrlData,
    loading: scopeUrlLoading,
    refetch,
    called,
  } = useGetScope2Query({
    variables: {
      schoolId: currentSchoolId,
      id: scopeId!,
    },
    fetchPolicy: 'network-only',
    skip: !currentSchoolId || !scopeId,
  });

  const scopeUrl = scopeUrlData?.baseScope?.jsonUrl;

  const {
    data,
    loading: scopeLoading,
    called: scopeCalled,
  } = scopeHooks.useFetchJsonData<JsonScopeStructure>({
    url: scopeUrl as string,
    options: { credentials: 'include' },
  });

  const { contentGroups, loading: contentGroupsLoading } =
    useSchoolContentGroups(accessibleSchoolIds || undefined);

  const scope = data
    ? new ContentFilterer(
        { ...data, id: scopeId },
        contentGroups
      ).getFilteredContent()
    : {};

  const { elementsBySection } = React.useMemo(
    () => transformElements(scope as any, standardsJson, state),
    [scope, standardsJson, state]
  ) as any;

  const loading =
    scopeUrlLoading ||
    scopeLoading ||
    (!scopeCalled && !!scopeUrl) ||
    contentGroupsLoading;

  return (
    <ScopeContext.Provider
      value={{
        original: data,
        loading,
        elementsBySection,
        refetch,
        attempts: prevAttempts,
        setAttempts,
        projectType: scope?.projectType,
        called,
        metadata: scope?.metadata,
      }}
    >
      <StudentGradeContext.Provider value={{ isStudentBelowK3 }}>
        <AsyncOutlet />
      </StudentGradeContext.Provider>
    </ScopeContext.Provider>
  );
};

export default LtiScopeContainer;
