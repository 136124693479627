import useGetScope from '../use-get-scope';
import { useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { WorkingLocation } from '@lon/shared/contexts';
import { StudentAssignmentStatusTransformed } from '@lon/shared/enums';
import { useAuth } from '@lon/shared/hooks';
import { UserTypeEnum, useGetAssignmentsQuery } from '@lon/shared/requests';
import { getStudentAssignmentStatus } from '@lon/shared/utils';

interface Props {
  isLti?: boolean;
  parentStudentId?: string;
  parentStudentSchoolId?: string;
}
export const usePrepareStudentAssignment = ({
  isLti,
  parentStudentId,
  parentStudentSchoolId,
}: Props) => {
  const [{ user }] = useAuth();
  const isParent = user?.type === UserTypeEnum.Parent;
  const [isRefetching, setIsRefetching] = useState(false);

  const { assignmentId, notebookId } = useParams<{
    notebookId: string;
    assignmentId: string;
  }>();

  const { currentSchoolId: schoolId } = useContext(WorkingLocation);

  const currentSchoolId = isParent ? parentStudentSchoolId : schoolId;
  const studentId = isParent ? parentStudentId : user.userId;

  const skip =
    !currentSchoolId ||
    !assignmentId ||
    (user?.type === UserTypeEnum.Parent && !studentId);

  const { data: assignmentsData, loading: assignmentsLoading } =
    useGetAssignmentsQuery({
      variables: {
        schoolId: isLti ? undefined : currentSchoolId,
        filter: {
          classId: notebookId,
          ...(isParent && { studentId }),
        },
      },
      skip,
      fetchPolicy: 'cache-and-network',
      onCompleted: () => {
        if (isRefetching) {
          setIsRefetching(false);
        }
      },
    });

  const assignment = assignmentsData?.studentStudentAssignments?.collection
    ?.filter((item) => !item.archived)
    ?.find((assignment) => assignment.assignmentId === assignmentId);

  const sourceScopeId: string =
    assignment?.assignmentSource?.element?.scopeId || '';

  const { scope } = useGetScope({
    scopeId: sourceScopeId,
    currentSchoolId,
  });

  const contentUrl = useMemo(() => {
    const transformedStatus = getStudentAssignmentStatus(
      assignment?.status as any
    );
    const currentSection = scope?.sections?.find(
      (section: any) =>
        section.identifier === assignment?.assignmentSource?.element?.sectionId
    );

    const currentElement = currentSection?.elements?.find(
      (element: any) =>
        element.identifier === assignment?.assignmentSource?.element?.elementId
    );

    const viewId = assignment?.assignmentSource?.element?.viewId;
    const href =
      currentElement?.studentView?.find(
        (view: any) => view.identifier === viewId
      )?.href || currentElement?.studentView[0]?.href;

    const shareResults = assignment?.shareResults;

    const ltiToBeGradedReport =
      isLti &&
      transformedStatus === StudentAssignmentStatusTransformed.ToBeGraded;

    const isReport =
      (shareResults !== 'DISABLED' &&
        transformedStatus === StudentAssignmentStatusTransformed.Graded) ||
      ltiToBeGradedReport;

    const sessionId = assignment?.sessionId || assignment?.id;
    const imageTag = process.env?.['NX_IMAGE_TAG'] || 1;
    const params = new URLSearchParams({
      v: imageTag,
      schoolId: currentSchoolId,
      scopeId: sourceScopeId,
      userType: 'student',
      userId: studentId,
      ...(isReport && { sessionId, shareResults }),
    } as Record<string, string>);

    return href ? `${href}?${params.toString()}` : href;
  }, [scope, assignment, sourceScopeId, currentSchoolId]);

  return {
    contentUrl,
    learnosityReferenceId: assignment?.learnosityReferenceId,
    assignment,
    assignmentsLoading,
    isRefetching,
    setIsRefetching,
  };
};
