import { Badge, Flex, Radio, Text } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { Controller, useFormContext } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import { ActivitiesTableProps, Columns } from './types';
import { name } from './constants';

export const useGetColumns = (
  _data: ActivitiesTableProps['data'],
  t: TFunction
) => {
  const columnHelper = createColumnHelper<Columns>();
  const { control } = useFormContext();

  return [
    columnHelper.accessor('activityTitle', {
      cell: (info) => (
        <Text as="span" variant="s2">
          {info.getValue()}
        </Text>
      ),
      header: () => (
        <Flex h="full" w="full" align="center">
          {t('createAssignmentDrawer.activityTitle') as string}
        </Flex>
      ),
    }),
    columnHelper.accessor('previouslyAssigned', {
      cell: (info) =>
        info.getValue() ? (
          <Flex align="center" justify="center">
            <Badge
              variant="greenBadge"
              background="olive.50"
              color="olive.100"
              borderColor="olive.75"
              borderRadius="12"
              py="0"
              px="2.5"
              h="1.25rem"
              textTransform="none"
            >
              {t('createAssignmentDrawer.assigned')}
            </Badge>
          </Flex>
        ) : (
          <Text
            variant="s2"
            color="secondary.500"
            minW={{ sm: '5rem' }}
            textAlign="center"
            whiteSpace="nowrap"
          >
            ---
          </Text>
        ),
      header: () => (
        <Flex h="full" w="full" align="center">
          {t('createAssignmentDrawer.previouslyAssigned') as string}
        </Flex>
      ),
    }),
    columnHelper.accessor('assignAsGradable', {
      cell: (info) => (
        <Flex justifyContent="center" alignItems="center">
          <Controller
            name={`${name}.${info.row.original.value}.isGradable`}
            control={control}
            render={({ field }) => (
              <Radio
                {...field}
                isDisabled={!info.row.original.referenceId}
                value="true"
                isChecked={field.value === 'true'}
              />
            )}
          />
        </Flex>
      ),
      header: () => (
        <Flex flexDirection="column" alignItems="center" gap={2} py={2}>
          <Text
            as="span"
            css={{
              writingMode: 'vertical-rl',
              direction: 'rtl',
            }}
            transform="rotate(180deg)"
            whiteSpace="pre-wrap"
          >
            {t('createAssignmentDrawer.assignAsGradable') as string}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('assignAsResource', {
      cell: (info) => (
        <Flex justifyContent="center" alignItems="center">
          <Controller
            name={`${name}.${info.row.original.value}.isGradable`}
            control={control}
            render={({ field }) => (
              <Radio
                {...field}
                isDisabled={!info.row.original.referenceId}
                value="false"
                isChecked={field.value === 'false'}
              />
            )}
          />
        </Flex>
      ),
      header: () => (
        <Flex flexDirection="column" alignItems="center" gap={2} py={2}>
          <Text
            as="span"
            css={{
              writingMode: 'vertical-rl',
              direction: 'rtl',
            }}
            transform="rotate(180deg)"
            whiteSpace="pre-wrap"
          >
            {t('createAssignmentDrawer.assignAsResource') as string}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('activityTime', {
      cell: (info) => (
        <Text as="span" variant="s2">
          {info.getValue().replace('minutes', '')}
        </Text>
      ),
      header: () => (
        <Flex flexDirection="column" alignItems="center" gap={2} py={2}>
          <Text
            as="span"
            css={{
              writingMode: 'vertical-rl',
              direction: 'rtl',
            }}
            transform="rotate(180deg)"
            whiteSpace="pre-wrap"
          >
            {t('createAssignmentDrawer.activityTime') as string}
          </Text>
        </Flex>
      ),
    }),
  ];
};
