import { t } from 'i18next';
import { studentNoteMaxLength } from '@lon/shared/constants';
import { yup } from '@lon/shared/utils';

export const getValidationSchema = () =>
  yup.object().shape({
    includeCorrectAnswers: yup.boolean().default(true),
    notes: yup
      .string()
      .default('')
      .max(
        studentNoteMaxLength,
        t('validationMessages.maxLength', {
          fieldName: t('assignments.studentNoteTitle'),
          length: studentNoteMaxLength,
        })
      ),
  });

export const getValidation = () => ({
  validationSchema: getValidationSchema(),
  defaultValues: getValidationSchema().cast({}),
});
