import { get } from 'lodash-es';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { ScopeContext } from '@lon/shared/contexts';

export const useCurrentElement = () => {
  const { sectionId = 'home', elementId } = useParams<{
    sectionId: string;
    elementId: string;
  }>();
  const { elementsBySection } = useContext(ScopeContext);

  const currentElement = elementId
    ? get(elementsBySection, `[${sectionId}][${elementId}]`)
    : Object.values(elementsBySection?.[sectionId] || {})?.[0];

  return currentElement || null;
};

export default useCurrentElement;