import AppRouter from '../routers/AppRouter';
import { ApolloProvider } from '@apollo/client';
import { CacheProvider } from '@emotion/react';
import React, { StrictMode } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { emotionCache } from '@lon/shared/constants';
import { BootstrapSuiteApp } from '@lon/shared/pages';
import { apolloClient } from '@lon/shared/utils';
import { persistor, store } from '@lon/shared/configs';

export const AppProvider = () => {
  return (
    <StrictMode>
      <HelmetProvider>
        <CacheProvider value={emotionCache}>
          <ApolloProvider client={apolloClient}>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <BootstrapSuiteApp>
                  <AppRouter />
                </BootstrapSuiteApp>
              </PersistGate>
            </Provider>
          </ApolloProvider>
        </CacheProvider>
      </HelmetProvider>
    </StrictMode>
  );
};

export default AppProvider;
