import { NotFound } from '../errors';
import React, { FC, useContext } from 'react';
import { CurrentSchoolContext, WorkingLocation } from '@lon/shared/contexts';
import { ApplicationEnum, DistrictPermission } from '@lon/shared/enums';
import { BehaviourEnum } from '@lon/shared/gql/types';
import { usePermission } from '@lon/shared/hooks';
import {
  BehaviourExtraEnum,
  ModuleEnum,
  OperationEnum,
} from '@lon/shared/types';

interface Props {
  children: React.ReactElement;
  application?: ApplicationEnum;
  districtPermission?: DistrictPermission;
  module?: ModuleEnum;
  operation?: OperationEnum;
  skipPolicy?: boolean;
}

export const ProtectedRoute: FC<Props> = ({
  children,
  module,
  operation,
  districtPermission = DistrictPermission.TEACHER_SUIT,
  skipPolicy = false,
}) => {
  const { currentSchoolId } = useContext(CurrentSchoolContext);
  const { application: currentApplication } = useContext(WorkingLocation);

  const [hasPolicyAccess] = usePermission([
    {
      schoolId: currentSchoolId,
      application: currentApplication,
      module,
      operation,
    },
  ]);

  const [districtAccessBehaviour] = usePermission<
    BehaviourEnum & BehaviourExtraEnum
  >([
    {
      skip: !districtPermission,
      permission: districtPermission,
      module: module,
      operation: operation,
      field: 'behaviour',
    },
  ]);

  if (
    districtAccessBehaviour !== BehaviourExtraEnum.Skip &&
    districtAccessBehaviour === BehaviourEnum.Hidden
  ) {
    return <NotFound />;
  }

  if (!hasPolicyAccess && !skipPolicy) {
    return <NotFound />;
  }

  return children;
};
