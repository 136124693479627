import { Icon } from '../../icon';
import { MessageContent } from '../components';
import { PermissionMenuItemProps } from '../duck/types';
import {
  Badge,
  Flex,
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useBoolean,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { WorkingLocation } from '@lon/shared/contexts';
import { BehaviourEnum } from '@lon/shared/gql/types';
import { usePermission } from '@lon/shared/hooks';
import { BehaviourExtraEnum } from '@lon/shared/types';

export const PermissionMenuItem: React.FC<PermissionMenuItemProps> = ({
  module,
  operation,
  popoverPosition = 'bottom',
  offset = [0, 50],
  districtPermission,
  skip,
  isDisabledTooltip,
  title,
  ...props
}) => {
  const { application: currentApplication, currentSchoolId } =
    useContext(WorkingLocation);

  const [districtAccessBehaviour] = usePermission<
    BehaviourEnum & BehaviourExtraEnum
  >([
    {
      skip: !districtPermission,
      permission: districtPermission,
      module: module,
      operation: operation,
      field: 'behaviour',
    },
  ]);

  const [policyBehaviour] = usePermission<BehaviourEnum>([
    {
      schoolId: currentSchoolId,
      application: currentApplication,
      module: module,
      operation: operation,
      field: 'behaviour',
    },
  ]);

  const [popover, popoverControl] = useBoolean(false);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    console.log({ policyBehaviour });
    if (policyBehaviour === BehaviourEnum.Restricted) {
      event.stopPropagation();
      event.preventDefault();

      return popoverControl.on();
    }

    if (props?.onClick) {
      props?.onClick(event);
    }
  };

  if (
    districtAccessBehaviour !== BehaviourExtraEnum.Skip &&
    districtAccessBehaviour === BehaviourEnum.Hidden
  ) {
    return null;
  }

  if (policyBehaviour === BehaviourEnum.Hidden) {
    return null;
  }

  const icon =
    policyBehaviour === BehaviourEnum.Restricted ? (
      <Icon name="locked" />
    ) : undefined;

  console.log({ popover });

  return (
    <>
      <Tooltip
        variant="dark"
        label={`${props.tooltip}${
          policyBehaviour === BehaviourEnum.Restricted ? ', Access Limited' : ''
        }`}
        isDisabled={props.isDisabled}
      >
        <MenuItem
          aria-label={props.tooltip}
          py="2"
          icon={icon}
          px="6"
          {...props}
          onClick={handleClick}
        >
          <Flex alignItems="center" justifyContent="space-between" gap={8}>
            <Text as="span" variant="s2">
              {title}
            </Text>
            {icon}
          </Flex>
        </MenuItem>
      </Tooltip>
      <Modal
        variant="rounded"
        isOpen={popover}
        onClose={popoverControl.off}
        isCentered
      >
        <ModalOverlay />
        <ModalContent data-focus-visible-disabled maxW="500px">
          <ModalHeader>
            <Text variant="h5" fontSize="1.25rem" lineHeight="1.875rem">
              No Access
            </Text>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <Badge variant="redBadge" mb={2}>
              Access Limited
            </Badge>
            <MessageContent feature={props.feature} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PermissionMenuItem;
