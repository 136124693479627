import * as LTI from '../pages/lti';
import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { Route, createRoutesFromElements } from 'react-router-dom';
import { NotFound } from '@lon/shared/components';
import { ApplicationEnum } from '@lon/shared/enums';
import { ErrorBoundary } from '@lon/shared/pages';
import { routes } from '@lon/shared/configs';

const LtiRouter = createRoutesFromElements(
  <Route
    path={routes.lti.root}
    element={<LTI.Container />}
    errorElement={<ErrorBoundary application={ApplicationEnum.TEACHER_SUIT} />}
  >
    <Route
      path={routes.lti.determineUserType}
      element={<LTI.DetermineUserType />}
    />
    <Route
      path={routes.lti.studentAssignment}
      element={<LTI.StudentAssignment />}
    />{' '}
    <Route
      path={routes.lti.gradeAssignment}
      element={<LTI.GradeAssignment />}
    />
    <Route path={routes.lti.scopes.root} element={<LTI.Scopes />} />
    <Route path={routes.lti.scopes.show} element={<LTI.ScopeContainer />}>
      <Route index element={<LTI.Scope />} />
      <Route path={routes.lti.scopes.activity} element={<LTI.Activity />} />
    </Route>
    <Route
      path={routes.lti.previewAssignment}
      element={<LTI.PreviewAssignment />}
    />
    <Route path={routes.lti.showAssignment} element={<LTI.ShowAssignment />} />
    <Route
      path={routes.lti.progressGrading}
      element={
        <Box p={4}>
          <Text>
            Please wait, the grades are being calculated. This may take up to a
            minute. You can refresh the page until the grades appear.
          </Text>
        </Box>
      }
    />
    <Route path="*" element={<NotFound withoutSidebar />} />
  </Route>
);

export default LtiRouter;
