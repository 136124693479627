import { ImpersonatedUserLS } from '@lon/shared/types';
import { getSignature, parseJSON } from '@lon/shared/utils';
import { CancelImpersonation } from './types';

export const cancelImpersonation: CancelImpersonation = async ({
  cancelImpersonationMutation,
}) => {
  const impersonate = JSON.parse(
    localStorage.getItem('impersonate') || '{}'
  ) as ImpersonatedUserLS;

  try {
    await cancelImpersonationMutation({
      variables: { input: {} },
    });

    localStorage.removeItem('impersonate');

    const signature = getSignature();

    // remove the lastApplicationBySignature and lastSchoolIdsBySignature
    // items from local storage when admin impersonates a user to ensure
    // that the default school and application are used.

    if (signature) {
      ['lastApplicationBySignature', 'lastSchoolIdsBySignature'].forEach(
        (item) => {
          const value = localStorage.getItem(item);
          if (value) {
            const parsedValue = parseJSON<Record<string, string>>(value, '{}');
            delete parsedValue[signature];
            localStorage.setItem(item, JSON.stringify(parsedValue));
          }
        }
      );
    }

    if (!impersonate?.redirectTo) {
      window.location.reload();
    } else {
      window.location.href = impersonate?.redirectTo;
    }
  } catch (error) {
    console.log(error);
  }
};
