import { ConditionalWrapper } from '../conditional-wrapper';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionPanelProps,
  AccordionProps,
  Box,
  Tooltip,
} from '@chakra-ui/react';
import React from 'react';

export interface InformativeAccordionProps extends AccordionProps {
  titleText: string | React.ReactElement;
  accordionPanelProps?: AccordionPanelProps;
  accordionButtonTooltipText?: (isExpanded: boolean) => string;
}

export const InformativeAccordion: React.FC<InformativeAccordionProps> = ({
  titleText,
  accordionPanelProps,
  children,
  accordionButtonTooltipText,
  ...props
}) => {
  return (
    <Accordion allowToggle variant="informative" {...props}>
      <AccordionItem>
        {({ isExpanded }) => (
          <>
            <ConditionalWrapper
              condition={typeof accordionButtonTooltipText === 'function'}
              wrapper={(children) => (
                <Tooltip
                  variant="dark"
                  label={accordionButtonTooltipText?.(isExpanded)}
                >
                  {children}
                </Tooltip>
              )}
            >
              <AccordionButton pt="1" pb="3px">
                <Box flex="1" textAlign="left">
                  {titleText}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </ConditionalWrapper>
            <AccordionPanel pb={4} {...accordionPanelProps}>
              {children}
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};
