import { GradeLevelEnum } from '@lon/shared/requests';

export const planIdPrefix = 'api/scope_plans/';
export const CHILD_PAGE_HEADER_HEIGHT = 65;
export const showAnswers = 'ON';
export const hideAnswers = 'OFF';
export const showTTS = 'ON';
export const hideTTS = 'OFF';
export const notebookCoverImageSrc = {
  Science: {
    Biology: 'suit/assets/EN_Cover_Biology_Dark.svg',
    Chemistry: 'suit/assets/EN_Cover_Chemistry_Dark.svg',
    IPC: 'suit/assets/EN_Cover_IPC_Dark.svg',
    K: 'suit/assets/EN_Cover_Science_Kindergarten.svg',
    '1': 'suit/assets/EN_Cover_Science_1.svg',
    '2': 'suit/assets/EN_Cover_Science_2.svg',
  },
  Math: {
    Algebra: 'suit/assets/EN_Cover_Algebra_Dark.svg',
    Geometry: 'suit/assets/EN_Cover_Geometry_Dark.svg',
    K: 'suit/assets/EN_Cover_Math_Kindergarten.svg',
    '1': 'suit/assets/EN_Cover_Math_1.svg',
    '2': 'suit/assets/EN_Cover_Math_2.svg',
  },
  OtherAssignments: {
    'k-2': 'suit/assets/EN_Cover_Assignments_Dark_K-2.svg',
    '3-12': 'suit/assets/EN_Cover_Assignments_Dark.svg',
  },
};
export const studentNoteMaxLength = 255;
export const SKELETONS_COUNT = 9;
export const maxScore = 1;
export const COURSERS_BY_GRADE_MAPPING: Record<GradeLevelEnum, string[]> = {
  [GradeLevelEnum.GlPreK]: [],
  [GradeLevelEnum.GlK]: ['Kindergarten', 'K'],
  [GradeLevelEnum.Gl_1]: ['First Grade', '1', 'Grade 1'],
  [GradeLevelEnum.Gl_2]: ['Second Grade', '2', 'Grade 2'],
  [GradeLevelEnum.Gl_3]: ['Third Grade', '3', 'Grade 3'],
  [GradeLevelEnum.Gl_4]: ['Fourth Grade', '4', 'Grade 4'],
  [GradeLevelEnum.Gl_5]: ['Fifth Grade ', '5', 'Grade 5'],
  [GradeLevelEnum.Gl_6]: [
    'Sixth Grade',
    '6',
    'Grade 6',
    'MS ESS',
    'MS LS',
    'MS PS',
    'Middle School Earth and Space Science',
    'Middle School Life Science',
    'Middle Physical Science',
    'MS ESS Advanced',
    'MS LS Advanced',
    'MS PS Advanced',
    'Comp Sci 1',
    'Comp Sci 1 Advanced',
    'Comp Sci 1 ACC',
  ],
  [GradeLevelEnum.Gl_7]: [
    'Seventh Grade',
    '7',
    'Grade 7',
    'MS ESS',
    'MS LS',
    'MS PS',
    'Middle School Earth and Space Science',
    'Middle School Life Science',
    'Middle Physical Science',
    'MS ESS Advanced',
    'MS LS Advanced',
    'MS PS Advanced',
    'Comp Sci 2',
    'Comp Sci 2 Advanced',
    'Comp Sci 1 ACC',
    'Comp Sci 2 ACC',
  ],
  [GradeLevelEnum.Gl_8]: [
    'Eighth Grade',
    '8',
    'Grade 8',
    'MS ESS',
    'MS LS',
    'MS PS',
    'Middle School Earth and Space Science',
    'Middle School Life Science',
    'Middle Physical Science',
    'MS ESS Advanced',
    'MS LS Advanced',
    'MS PS Advanced',
    'Comp Sci 3',
    'Comp Sci 3 Advanced',
    'Comp Sci 2 ACC',
  ],
  [GradeLevelEnum.Gl_9]: [
    'Algebra 1',
    'Algebra 2',
    'Geometry',
    'Biology',
    'Biology Honors',
    'Chemistry',
    'IPC',
    'Integrated Physics and Chemistry',
    'Physics',
    'Physics Honors',
    'HSESS',
    'High School Earth and Space Science',
    'HSLS',
    'High School Life Science',
    'HSPS',
    'High School Physical Science',
    'BIOESS',
    'Biology with ESS Integrated',
    'CHEMESS',
    'Chemistry with ESS Integrated',
    'PHYESS',
    'Physics with ESS Integrated',
  ],
  [GradeLevelEnum.Gl_10]: [
    'Algebra 1',
    'Algebra 2',
    'Geometry',
    'Biology',
    'Biology Honors',
    'Chemistry',
    'IPC',
    'Integrated Physics and Chemistry',
    'Physics',
    'Physics Honors',
    'HSESS',
    'High School Earth and Space Science',
    'HSLS',
    'High School Life Science',
    'HSPS',
    'High School Physical Science',
    'BIOESS',
    'Biology with ESS Integrated',
    'CHEMESS',
    'Chemistry with ESS Integrated',
    'PHYESS',
    'Physics with ESS Integrated',
  ],
  [GradeLevelEnum.Gl_11]: [
    'Algebra 1',
    'Algebra 2',
    'Geometry',
    'Biology',
    'Biology Honors',
    'Chemistry',
    'IPC',
    'Integrated Physics and Chemistry',
    'Physics',
    'Physics Honors',
    'HSESS',
    'High School Earth and Space Science',
    'HSLS',
    'High School Life Science',
    'HSPS',
    'High School Physical Science',
    'BIOESS',
    'Biology with ESS Integrated',
    'CHEMESS',
    'Chemistry with ESS Integrated',
    'PHYESS',
    'Physics with ESS Integrated',
  ],
  [GradeLevelEnum.Gl_12]: [
    'Algebra 1',
    'Algebra 2',
    'Geometry',
    'Biology',
    'Biology Honors',
    'Chemistry',
    'IPC',
    'Integrated Physics and Chemistry',
    'Physics',
    'Physics Honors',
    'HSESS',
    'High School Earth and Space Science',
    'HSLS',
    'High School Life Science',
    'HSPS',
    'High School Physical Science',
    'BIOESS',
    'Biology with ESS Integrated',
    'CHEMESS',
    'Chemistry with ESS Integrated',
    'PHYESS',
    'Physics with ESS Integrated',
  ],
};
