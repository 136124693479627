import { Icon } from '../../icon';
import { MenuItem } from '../../menu';
import actionsStyles from '../ToolbarActions.module.css';
import { utils } from '../duck';
import {
  Box,
  MenuItem as ChakraMenuItem,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuGroup,
  MenuList,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  CONTENT_ACTIONS_TOOLBAR_HEIGHT,
  DESKTOP_CONTENT_PADDING,
  PAGE_HEADER_HEIGHT,
  SITE_FOOTER_HEIGHT,
  SITE_HEADER_HEIGHT,
  TOOLBAR_HEIGHT,
  TOOLBAR_MENU_CONTENT_PADDING,
  sxLightScrollBar,
} from '@lon/shared/constants';
import { useOutsideClick } from '@lon/shared/hooks';
import { ReceivedMessage } from '@lon/shared/types';

const ContentMenu: React.FC<any> = ({ iframeRef, ...rest }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [content, setContent] = useState<{ title: string; position: number }[]>(
    []
  );

  const menuButtonRef = useRef<HTMLElement>(null);
  const menuRef = useOutsideClick<HTMLDivElement>((event) => {
    if (
      isOpen &&
      (event.target === window ||
        !menuButtonRef.current?.contains(event.target as Node))
    ) {
      onClose();
    }
  });

  const displayableContentCount = content.filter(
    ({ title }) => title?.length
  )?.length;

  useEffect(() => {
    const handleMessage = (message: any) => {
      if (message?.data?.type === ReceivedMessage.Resize) {
        if (message?.data?.payload?.content?.length) {
          setContent(message?.data?.payload?.content);
        } else {
          setContent([]);
        }
      }
    };

    window.addEventListener('message', handleMessage);

    iframeRef?.current?.contentWindow?.postMessage(
      {
        type: 'getData',
      },
      '*'
    );

    return () => {
      window.removeEventListener('message', handleMessage, false);
    };
  }, []);

  useEffect(() => {
    return () => {
      setContent([]);
    };
  }, [location.pathname]);

  if (!content.length) {
    return null;
  }

  return (
    <MenuItem {...rest}>
      {(menuItemProps: any) => (
        <Menu
          key="menu"
          placement="bottom"
          gutter={4}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
        >
          <Tooltip
            variant="dark"
            label={
              isOpen
                ? t('toolbar.hideContentMenuTooltip')
                : t('toolbar.showContentMenuTooltip')
            }
            aria-hidden={true}
          >
            <MenuButton
              ref={menuButtonRef}
              as={IconButton}
              icon={<Icon name="unordered-list-outlined" size="lg" />}
              aria-label={t('toolbar.contentMenu')}
              variant="ghost"
              size="md"
              className={actionsStyles.action}
              _active={{
                backgroundColor: 'primary.800',
                color: 'white',
              }}
              {...menuItemProps}
            />
          </Tooltip>
          <MenuList
            wordBreak="break-word"
            data-focus-visible-disabled
            w={{ base: '100vw', sm: '368px' }}
            backgroundColor="secondary.50"
            py={0}
            borderBottomRadius="md"
            onKeyDown={utils.closeMenuByPressXArrow}
            ref={menuRef}
          >
            <Box
              px="4"
              pb="4"
              borderRadius="md"
              sx={{
                '.chakra-menu__group__title': {
                  color: 'primary.500',
                  textTransform: 'uppercase',
                  fontSize: '0.75rem',
                  fontWeight: '400',
                  m: '6',
                },
              }}
            >
              <MenuGroup title={t('toolbar.contentMenu')}>
                <Box
                  maxH={`calc(100vh - (${
                    SITE_HEADER_HEIGHT +
                    PAGE_HEADER_HEIGHT +
                    TOOLBAR_HEIGHT +
                    CONTENT_ACTIONS_TOOLBAR_HEIGHT +
                    SITE_FOOTER_HEIGHT +
                    DESKTOP_CONTENT_PADDING +
                    TOOLBAR_MENU_CONTENT_PADDING
                  }px))`}
                  overflowY="auto"
                  css={sxLightScrollBar}
                >
                  {content
                    .filter(({ title }) => title?.length)
                    .map(({ title, position }, index) => (
                      <ChakraMenuItem
                        key={title}
                        onClick={() => {
                          iframeRef?.current
                            ?.closest('.page-content')
                            ?.scrollTo(0, position + 26);
                        }}
                        p={0}
                        {...(index !== displayableContentCount - 1 && {
                          borderBottomWidth: '1px',
                          borderBottomStyle: 'solid',
                          borderBottomColor: 'secondary.100',
                        })}
                        {...(index === 0 && {
                          borderTopRadius: 'md',
                        })}
                        {...(index === displayableContentCount - 1 && {
                          borderBottomRadius: 'md',
                        })}
                      >
                        <Flex flex="1" px="6" py="10px">
                          <Text
                            variant="s2"
                            wordBreak="break-word"
                            maxW="290px"
                          >
                            {title}
                          </Text>
                        </Flex>
                      </ChakraMenuItem>
                    ))}
                </Box>
              </MenuGroup>
            </Box>
          </MenuList>
        </Menu>
      )}
    </MenuItem>
  );
};

export default ContentMenu;
