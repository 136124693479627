import { MockedProvider } from '@apollo/client/testing';
import { RenderOptions, render } from '@testing-library/react';
import { MemoryHistory, createMemoryHistory } from 'history';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Router } from 'react-router-dom';

export interface WrapperProps {
  children?: React.ReactNode;
  history: MemoryHistory;
  mocks?: any[];
}

export const Wrapper: React.FC<WrapperProps> = ({
  children,
  mocks,
  history,
}) => {
  return (
    <Router location={history.location} navigator={history}>
      <MockedProvider mocks={mocks} addTypename={false}>
        <HelmetProvider>{children}</HelmetProvider>
      </MockedProvider>
    </Router>
  );
};

export const renderWithProvider = (
  ui: JSX.Element,
  options?: RenderOptions & { mocks?: any[] }
) => {
  const history = createMemoryHistory();

  return {
    history,
    ...render(ui, {
      wrapper: (props) => (
        <Wrapper {...props} history={history} mocks={options?.mocks} />
      ),
      ...options,
    }),
  };
};

export * from '@testing-library/react';

export { renderWithProvider as render };
