import React from 'react';

export const useOutsideClick = <T extends HTMLElement>(
  callback: (event: Event, isIframe: boolean) => void,
  options?: {
    eventNames?: string[];
    notOutsideElements?: React.MutableRefObject<HTMLElement[]>;
  }
): React.RefObject<T> => {
  const { eventNames = ['mousedown', 'touchstart'] } = options || {};

  const ref = React.useRef<T>(null);

  React.useEffect(() => {
    const handleEvent = (event: Event): void => {
      const isIframe =
        (event?.target as any).document?.activeElement.tagName === 'IFRAME';
      const isOutsideClick =
        isIframe ||
        (ref.current &&
          event.target instanceof Node &&
          !ref.current.contains(event.target) &&
          !options?.notOutsideElements?.current.some((el) =>
            el?.contains(event.target as Node)
          ));

      if (isOutsideClick) {
        callback(event, isIframe);
      }
    };

    window.addEventListener('blur', handleEvent);
    eventNames.forEach((name) => document.addEventListener(name, handleEvent));

    return () => {
      window.removeEventListener('blur', handleEvent);
      eventNames.forEach((name) =>
        document.removeEventListener(name, handleEvent)
      );
    };
  }, [callback, eventNames]);

  return ref;
};

export default useOutsideClick;
