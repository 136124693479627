import { Dispatch, SetStateAction } from 'react';

// handling for on full screen here...
export const handleOpenFullScreen = (
  setIsFullScreen: Dispatch<SetStateAction<boolean>>
) => {
  setIsFullScreen(true);

  const elem = document.documentElement as any;

  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    // Firefox
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    // Chrome, Safari and Opera
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    // IE/Edge
    elem.msRequestFullscreen();
  }
};

export const handleExitFullScreen = (
  setIsFullScreen: Dispatch<SetStateAction<boolean>>
) => {
  setIsFullScreen(false);

  const documentVar = document as any;

  if (!documentVar?.fullscreenElement) {
    return;
  }
  if (documentVar.exitFullscreen) {
    documentVar.exitFullscreen();
  } else if (documentVar.mozCancelFullScreen) {
    // Firefox
    documentVar.mozCancelFullScreen();
  } else if (documentVar.webkitExitFullscreen) {
    // Chrome, Safari, and Opera
    documentVar.webkitExitFullscreen();
  } else if (documentVar.msExitFullscreen) {
    // IE/Edge
    documentVar.msExitFullscreen();
  }
};
