import { Link as RouterLink } from '../../../../../../../link';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Icon as ChakraIcon,
  Flex,
  Text,
} from '@chakra-ui/react';
import { useContext } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { arrowRight } from '@lon/shared/assets';
import { routes } from '@lon/shared/configs';
import { ScopeContext } from '@lon/shared/contexts';

const Breadcrumbs = () => {
  const {
    projectId = '',
    scopeId = '',
    sectionId = '',
  } = useParams<{
    [key: string]: string;
  }>();
  const { metadata, sections } = useContext(ScopeContext);
  const currentSection = sections?.find(
    (section) => section.identifier === sectionId
  );

  const scopePath = generatePath(routes.scopes.show, {
    scopeId,
    projectId,
  });

  return (
    <Flex
      w="full"
      py="4"
      px="8"
      mb="4"
      gap={{ base: '4', md: '8' }}
      bg="white"
      borderRadius="6"
      justifyContent="space-between"
      alignItems="center"
      flexWrap={{ base: 'wrap', md: 'unset' }}
      boxShadow="pane"
    >
      <Breadcrumb
        spacing="2"
        separator={
          <ChakraIcon
            as={arrowRight}
            boxSize="1.25rem"
            verticalAlign="middle"
          />
        }
        css={{
          list: {
            flexWrap: 'wrap',
          },
        }}
      >
        <BreadcrumbItem my="1">
          <BreadcrumbLink
            withoutStyles
            w="fit-content"
            color="primary.800"
            textDecoration="underline"
            _hover={{ textDecoration: 'none' }}
            as={RouterLink}
            to={scopePath}
            display="flex"
            alignItems="center"
          >
            <Text variant="s2">{metadata?.displayName}</Text>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem my="1">
          <BreadcrumbLink
            withoutStyles
            w="fit-content"
            color="primary.800"
            textDecoration="none"
            disabled={true}
            _hover={{ textDecoration: 'none' }}
            as={RouterLink}
            to={generatePath(routes.scopes.section, {
              scopeId,
              projectId,
              sectionId,
            })}
            display="flex"
            alignItems="center"
          >
            <Text variant="s2">{currentSection?.metadata?.sectionTitle}</Text>
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
    </Flex>
  );
};

export default Breadcrumbs;
