import { Link as RouterLink } from '../../../../../../../../../link';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Icon as ChakraIcon,
  Text,
} from '@chakra-ui/react';
import { sanitize } from 'dompurify';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';
import { arrowRight } from '@lon/shared/assets';
import { routes } from '@lon/shared/configs';
import { teacherView } from '@lon/shared/constants';
import { ScopeContext } from '@lon/shared/contexts';
import { useCurrentElement } from '@lon/shared/hooks';
import { useQueryParams } from '@lon/shared/hooks';
import { ScopeType, TransformedElement } from '@lon/shared/types';
import { checkContentPlayer } from '@lon/shared/utils';

const Breadcrumbs = ({ isDisabled = false }: { isDisabled?: boolean }) => {
  const { t } = useTranslation();
  const {
    elementId = 0,
    projectId = 0,
    scopeId = 0,
    sectionId = 0,
    unitId = 0,
  } = useParams<{
    [key: string]: string;
  }>();
  const [queryParams] = useQueryParams();

  const { metadata, projectType } = useContext(ScopeContext);
  const isHowTo = projectType === ScopeType.HowTo;
  const isDailyNumeracy = projectType === ScopeType.DailyNumeracy;
  const currentElement = useCurrentElement() as TransformedElement;
  const viewType = currentElement?.identifier
    ? queryParams.type || teacherView
    : null;

  const scopePath = generatePath(routes.scopes.show, {
    scopeId,
    unitId,
    projectId,
  });

  const sectionPath = generatePath(routes.scopes.section, {
    scopeId,
    unitId,
    sectionId,
    projectId,
  });

  const elementPath = generatePath(routes.scopes.element, {
    scopeId,
    unitId,
    sectionId,
    elementId,
    projectId,
  });

  const isCurrentPath = (linkPath: string) => {
    const appName = !checkContentPlayer() ? '/suite' : '/content-player';
    const currentPath = window.location.pathname.replace(appName, '');
    return currentPath === linkPath;
  };

  const elementName = sanitize(
    isDailyNumeracy
      ? t('scope.breadcrumbs.numeracyElementName', {
          elementName: currentElement?.metadata?.elementName,
          separator: currentElement?.metadata?.elementName ? '|' : '',
          sectionName: currentElement?.metadata?.sectionTitle,
          interpolation: {
            escapeValue: false,
          },
        })
      : t('scope.breadcrumbs.elementName', {
          elementName: currentElement?.metadata?.elementName || '',
          separator: viewType ? '|' : '',
          viewType:
            viewType === teacherView
              ? t('scope.breadcrumbs.teacherPage')
              : t('scope.breadcrumbs.studentPage'),
          interpolation: {
            escapeValue: false,
          },
        })
  );

  useEffect(() => {
    if (typeof (window as any).runGoogleTranslate === 'function') {
      (window as any).runGoogleTranslate(
        localStorage.getItem('googletrans') || 'en'
      );
    }
  }, [currentElement]);

  return (
    <Breadcrumb
      spacing="2"
      separator={
        <ChakraIcon as={arrowRight} boxSize="1.25rem" verticalAlign="middle" />
      }
      css={{
        list: {
          flexWrap: 'wrap',
        },
      }}
    >
      <BreadcrumbItem my="1">
        <BreadcrumbLink
          withoutStyles
          w="fit-content"
          color="primary.800"
          textDecoration="underline"
          _hover={{ textDecoration: 'none' }}
          as={RouterLink}
          to={scopePath}
          display="flex"
          alignItems="center"
          disabled={isDisabled}
        >
          <Text variant="s2">
            {isHowTo
              ? `${metadata?.curriculumArea}, ${metadata?.projectType}: ${metadata?.internalName}`
              : metadata?.displayName}
          </Text>
        </BreadcrumbLink>
      </BreadcrumbItem>
      {(currentElement?.metadata?.sectionTitle ||
        currentElement?.metadata?.sectionType !== 'Side Bar') &&
        !isDailyNumeracy && (
          <BreadcrumbItem my="1">
            <BreadcrumbLink
              withoutStyles
              w="fit-content"
              color="primary.800"
              textDecoration="underline"
              _hover={{ textDecoration: 'none' }}
              as={RouterLink}
              to={sectionPath}
              display="flex"
              alignItems="center"
              disabled={isDisabled}
            >
              <Text variant="s2">{currentElement?.metadata?.sectionTitle}</Text>
            </BreadcrumbLink>
          </BreadcrumbItem>
        )}
      <BreadcrumbItem my="1">
        <BreadcrumbLink
          withoutStyles
          w="fit-content"
          color="primary.800"
          textDecoration={isCurrentPath(elementPath) ? 'none' : 'underline'}
          disabled={isCurrentPath(elementPath) || isDisabled}
          _hover={{ textDecoration: 'none' }}
          as={RouterLink}
          to={elementPath}
          display="flex"
          alignItems="center"
        >
          <Text variant="s2">{elementName}</Text>
        </BreadcrumbLink>
      </BreadcrumbItem>
    </Breadcrumb>
  );
};

export default Breadcrumbs;
