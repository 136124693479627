import { ContentLoader } from '../../../../../../../content-loader';
import React from 'react';

const Skeleton: React.FC = () => (
  <div data-testid="lesson-scope-elements-skeleton">
    <ContentLoader viewBox="0 0 1135 385">
      <rect x="25" y="25" width="250" height="140" />
      <rect x="303" y="25" width="250" height="140" />
      <rect x="581" y="25" width="250" height="140" />
      <rect x="859" y="25" width="250" height="140" />
    </ContentLoader>
  </div>
);

export default Skeleton;
