import { AssignmentFormDrawer } from '../../../../../../../assignment-form-drawer';
import { Icon } from '../../../../../../../icon';
import { ConfirmModal } from '../../../../../../../modals';
import PermissionButton from '../../../../../../../permissions/permission-button/PermissionButton';
import { PlansDrawer } from '../../../../../../../plans-drawer';
import { scopesSelectors } from '../../../../../../duck';
import {
  Box,
  Button,
  Flex,
  IconButton,
  List,
  Tab,
  TabList,
  Tabs,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { isEmpty } from 'lodash-es';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setPlanData } from '@lon/shared/configs';
import { ScopeContext } from '@lon/shared/contexts';
import { WorkingLocation } from '@lon/shared/contexts';
import {
  useGetIsLeaderSuitFlag,
  useGetSchoolIdsForPlanner,
} from '@lon/shared/hooks';
import {
  ScopePlanScopeElementActionTypeEnum,
  ScopePlanTypeEnum,
  useCreateResourceMutation,
  useGetElementsFromPlanQuery,
  useGetPlanFromFieldsQuery,
  usePutAssignmentMutation,
  useUpdateScopePlanScopeMutation,
} from '@lon/shared/requests';
import { ModuleEnum, OperationEnum, PlanType } from '@lon/shared/types';
import { CreateAssignmentRespTypeEnum } from '@lon/shared/utils';
import { sanitizeWithSpecialChars } from '@lon/shared/utils';
import {
  clearQueryCache,
  errorHandler,
  handleSelectToGoogleClassroom,
} from '@lon/shared/utils';
import PlanElementSkeleton from './components/plan-element-skeleton';
import { types, utils } from './duck';

const PlanElementList: React.FC<types.PlanElementListType> = ({
  scope,
  metadata,
  scopeId,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const dispatch = useDispatch();
  const { currentSchoolId: schoolId, application } =
    useContext(WorkingLocation);
  const { elementsBySection } = useContext(ScopeContext);
  const accessibleSchoolIds = useGetSchoolIdsForPlanner();
  const isLeaderSuit = useGetIsLeaderSuitFlag();
  const userRoleCode = application?.replace('Suit', '') || '';

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: openAssignStudents,
    onOpen: onOpenAssignStudents,
    onClose: onCloseAssignStudents,
  } = useDisclosure();

  const {
    isOpen: isOpenPlansDrawer,
    onOpen: onOpenPlansDrawer,
    onClose: onClosePlansDrawer,
  } = useDisclosure();

  const [planType, setPlanType] = useState<ScopePlanTypeEnum>(
    ScopePlanTypeEnum.Individual
  );

  const planData = useSelector(scopesSelectors.planData);
  const userTeamIds = useSelector(scopesSelectors.userTeamIds);
  const [updateScopePlanScope, { loading: removeElementLoading }] =
    useUpdateScopePlanScopeMutation();
  const [createAssignment, { loading: assignmentLoading }] =
    usePutAssignmentMutation();
  const [createResource, { loading: resourceLoading }] =
    useCreateResourceMutation();

  const { data: scopePlansRaw, loading: plansLoading } =
    useGetPlanFromFieldsQuery({
      skip: !metadata || !accessibleSchoolIds || !userRoleCode || !userTeamIds,
      fetchPolicy: 'no-cache',
      variables: {
        curriculumArea: metadata!.curriculumArea?.toString(),
        grade: metadata!.gradeLevel?.toString(),
        schoolIds: accessibleSchoolIds,
        userRoleCode,
        userTeamIds,
      },
    });

  const currentScopePlan =
    scopePlansRaw?.scopePlans?.collection?.find(
      (plan) => plan?.scopePlanType === planType
    ) || null;
  const isIndividualAvailable = scopePlansRaw?.scopePlans?.collection?.some(
    (plan) => plan?.scopePlanType === ScopePlanTypeEnum.Individual
  );
  const isTeamPlanAvailable = scopePlansRaw?.scopePlans?.collection?.some(
    (plan) => plan?.scopePlanType === ScopePlanTypeEnum.Team
  );

  const { data: scopeElements, loading: elementsLoading } =
    useGetElementsFromPlanQuery({
      skip: !currentScopePlan?.scopePlanId || !accessibleSchoolIds,
      fetchPolicy: 'network-only',
      variables: {
        scopeId: scopeId,
        scopePlanId: currentScopePlan?.scopePlanId as string,
        schoolIds: accessibleSchoolIds,
        userRoleCode,
        userTeamIds,
      },
    });

  const currentScope = scopeElements?.scopePlanScopes?.collection?.[0];
  const [selectedElementToDelete, setSelectedElementToDelete] = useState<
    string | null
  >();

  const assignStudentContentElements = useMemo(() => {
    return planData?.elements?.reduce<types.PlanStudentViewElementType[]>(
      (acc, element) => {
        const elementData = {
          ...element,
          curriculumArea: metadata?.curriculumArea,
          gradeLevel: metadata?.gradeLevel,
        };
        const studentViewElements =
          utils.getStudentViewElements(
            elementData,
            elementsBySection,
            element?.sectionId
          ) || [];
        return [...acc, ...studentViewElements];
      },
      []
    );
  }, [planData]);

  const handleRemoveElements = async () => {
    const elements = planData?.elements?.reduce<types.PlanElement[]>(
      (acc, element) => {
        if (selectedElementToDelete === element.identifier) {
          acc.push(element);
        }
        return acc;
      },
      []
    );
    try {
      if (planData?.scopePlanId) {
        await updateScopePlanScope({
          variables: {
            input: {
              scopePlanId: planData.scopePlanId,
              scopeId: scopeId,
              action: ScopePlanScopeElementActionTypeEnum.Remove,
              elements: elements,
              schoolIds: accessibleSchoolIds,
              userRoleCode,
              userTeamIds,
              version: planData.version,
            },
          },
          onCompleted() {
            toast({
              position: 'bottom',
              title: t('scope.removeElementsSuccess'),
              description: sanitizeWithSpecialChars(
                t('scope.removeElementsSuccessDesc', {
                  name: elements?.[0].elementName,
                  interpolation: {
                    escapeValue: false,
                  },
                })
              ),
              status: 'success',
              duration: 3000,
              isClosable: true,
              variant: 'success-light',
            });
            setSelectedElementToDelete(null);
          },
          refetchQueries: ['getElementsFromPlan'],
        });
      }
    } catch (error) {
      toast({
        position: 'bottom',
        title: t('scope.removeElementsFailed'),
        status: 'error',
        duration: 3000,
        isClosable: true,
        variant: 'error-light',
      });
    }
    onClose();
  };

  useEffect(() => {
    if (
      !scopePlansRaw?.scopePlans?.collection?.filter(
        (plan) => plan?.scopePlanType === planType
      ).length
    ) {
      setPlanType(
        ScopePlanTypeEnum.Individual === planType
          ? ScopePlanTypeEnum.Team
          : ScopePlanTypeEnum.Individual
      );
    }
  }, [scopePlansRaw]);

  useEffect(() => {
    const scopePlanId = currentScopePlan?.scopePlanId;
    const payload: PlanType = {
      scopeId: scopeId,
      scopePlanId: scopePlanId,
      curriculumArea: metadata!.curriculumArea?.toString(),
      grade: metadata!.gradeLevel?.toString(),
      elements: currentScope?.elements ?? [],
      scopeName: scope?.displayName ?? '',
      version: scopeElements?.scopePlanScopes?.collection?.[0]?.version ?? 1,
    };

    dispatch(setPlanData(payload));
  }, [scopeElements, currentScopePlan]);

  return (
    <>
      <Box
        bgColor="white"
        py={4}
        px={3}
        borderRadius={4}
        minHeight={200}
        color="primary.800"
        display="inline-flex"
        fontWeight={700}
        flexDirection="column"
        justifyContent="flex-start"
        width="full"
      >
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <Text fontWeight="bold">
            {currentScopePlan
              ? currentScopePlan?.name
              : t('scope.planElementSidebar.myPlan')}
          </Text>
          <IconButton
            variant="ghost"
            aria-label={t('calendarList.addCalendar')}
            icon={<Icon name="plus" size="md" />}
            borderWidth="1px"
            color="primary.800"
            borderColor="primary.800"
            borderRadius="4px"
            size="sm"
            isDisabled={isIndividualAvailable && isTeamPlanAvailable}
            onClick={onOpenPlansDrawer}
          />
          <PlansDrawer
            drawerOpen={isOpenPlansDrawer}
            handleDrawerClose={onClosePlansDrawer}
            isEdit={false}
            viewOnly={false}
            isCreatePlanFromScopes={true}
            dataAutoFill={true}
            defaultDisabledTab={utils.getDefaultDisabledTab(
              t,
              isIndividualAvailable,
              isTeamPlanAvailable
            )}
            addElementProps={{
              grade: metadata?.gradeLevel?.toString() || '',
              curriculumArea: metadata?.curriculumArea?.toString() || '',
            }}
          />
        </Flex>

        {!currentScopePlan && !plansLoading && !elementsLoading ? (
          <Text textAlign="center" variant="s2" py={2}>
            {t('scope.planElementSidebar.noPlanCreated')}
          </Text>
        ) : plansLoading || elementsLoading ? (
          <PlanElementSkeleton />
        ) : (
          <Flex flexDirection="column" flexGrow={1}>
            <Flex flexDirection="column">
              <Flex
                backgroundColor="secondary.200"
                px={2}
                py={2}
                gap={1}
                justifyContent="space-around"
                borderRadius="md"
              >
                <Flex
                  direction="column"
                  justifyContent="space-between"
                  alignItems="center"
                  flex={1}
                >
                  <Text variant="es2" pb={1}>
                    {t('scope.planElementSidebar.startDate')}
                  </Text>
                  <Text
                    variant="lmu"
                    backgroundColor="white"
                    px={2}
                    py={1}
                    whiteSpace="nowrap"
                    borderRadius="sm"
                    w="full"
                  >{`${
                    currentScope?.startDate
                      ? format(
                          parseISO(`${currentScope?.startDate}`),
                          'MM-dd-yyyy'
                        )
                      : 'MM-DD-YYYY'
                  }`}</Text>
                </Flex>
                <Text variant="es2" alignSelf="flex-end">
                  -
                </Text>
                <Flex
                  direction="column"
                  justifyContent="space-between"
                  alignItems="center"
                  flex={1}
                >
                  <Text variant="es2" pb={1}>
                    {t('scope.planElementSidebar.dueDate')}
                  </Text>
                  <Text
                    variant="lmu"
                    backgroundColor="white"
                    px={2}
                    py={1}
                    whiteSpace="nowrap"
                    borderRadius="sm"
                    w="full"
                  >{`${
                    currentScope?.endDate
                      ? format(
                          parseISO(`${currentScope?.endDate}`),
                          'MM-dd-yyyy'
                        )
                      : 'MM-DD-YYYY'
                  }`}</Text>
                </Flex>
              </Flex>

              {isIndividualAvailable && isTeamPlanAvailable && (
                <Tabs
                  isFitted
                  variant="clear"
                  onChange={(index) => {
                    !index
                      ? setPlanType(ScopePlanTypeEnum.Individual)
                      : setPlanType(ScopePlanTypeEnum.Team);
                  }}
                  index={planType === ScopePlanTypeEnum.Individual ? 0 : 1}
                >
                  <TabList boxShadow="none">
                    <Tab>
                      <Icon name="calendarOutlined" />
                      <Text ml={2}>{t('planner.individual')}</Text>
                    </Tab>

                    <Tab>
                      <Icon name="usergroupEdit" />
                      <Text ml={2}>{t('planner.team')}</Text>
                    </Tab>
                  </TabList>
                </Tabs>
              )}

              <List ml={1} mr={2} textAlign="left" spacing={2}>
                {!isEmpty(planData.elements) &&
                  currentScopePlan &&
                  planData.elements?.map(
                    (ele: {
                      identifier: React.Key | null | undefined;
                      elementName:
                        | string
                        | number
                        | boolean
                        | React.ReactElement<
                            any,
                            string | React.JSXElementConstructor<any>
                          >
                        | React.ReactFragment
                        | React.ReactPortal
                        | null
                        | undefined;
                    }) => (
                      <Flex key={ele.identifier} alignItems="center">
                        <IconButton
                          border="none"
                          aria-label={'delete-' + ele.identifier}
                          icon={<Icon name="deleteOutlined" />}
                          onClick={() => {
                            if (ele.identifier) {
                              setSelectedElementToDelete(
                                ele.identifier as string
                              );
                              onOpen();
                            }
                          }}
                        />

                        <Text variant="s2" ml={2} pr={2} fontSize={14}>
                          {ele.elementName}
                        </Text>
                      </Flex>
                    )
                  )}
              </List>
            </Flex>
          </Flex>
        )}

        {!isLeaderSuit && (
          <PermissionButton
            feature={t('plans.assignToStudents')}
            tooltip={t('plans.assignToStudents')}
            module={ModuleEnum.learningMaterials}
            operation={OperationEnum.assign}
          >
            <Button
              isDisabled={
                Boolean(!assignStudentContentElements?.length) ||
                !currentScopePlan ||
                isLeaderSuit
              }
              mt={2}
              mb={{ base: 16, md: 0 }}
              onClick={onOpenAssignStudents}
              variant="solid"
            >
              {t('scope.planElementSidebar.assign')}
            </Button>
          </PermissionButton>
        )}
      </Box>
      <AssignmentFormDrawer
        isPlanElement
        isOpen={openAssignStudents}
        assignmentLoading={assignmentLoading}
        resourceLoading={resourceLoading}
        elementsData={assignStudentContentElements}
        onClose={onCloseAssignStudents}
        backBtnText={t('createAssignmentDrawer.back')}
        createAssignment={({ generalInputData, values, isGoogle }) => {
          let openIndex = 1;

          return Promise.all(
            Object.keys(values.activity).map((studentViewId) => {
              const elementDetails = assignStudentContentElements?.find(
                (ele) => ele.identifier === studentViewId
              );
              return createAssignment({
                variables: {
                  assignmentParams: {
                    stemscopesPutAssignmentInput: {
                      ...generalInputData,
                      learnosityReferenceId: elementDetails?.referenceId || '',
                      assignmentSource: {
                        element: {
                          elementId: elementDetails?.elementId ?? '',
                          elementName: elementDetails?.elementName ?? '',
                          viewId: elementDetails?.viewId ?? '',
                          projectId: elementDetails?.projectId ?? '',
                          scopeId: elementDetails?.scopeId ?? '',
                          scopeName: elementDetails?.scopeName ?? '',
                          sectionId: elementDetails?.sectionId ?? '',
                          sectionName: elementDetails?.sectionName ?? '',
                        },
                      },
                      name: `${elementDetails?.scopeName ?? ''} - ${
                        elementDetails?.elementName ?? ''
                      }`,
                      schoolId,
                    },
                  },
                },
                onCompleted: (res) => {
                  if (isGoogle) {
                    const dividedClassId =
                      values.classes?.[0]?.classId.split('/');

                    handleSelectToGoogleClassroom({
                      assignmentId: (res?.putAssignment?.[0] as any)
                        ?.assignmentId,
                      classId:
                        dividedClassId?.[dividedClassId.length - 1] || '',
                      title: `${elementDetails?.scopeName} - ${elementDetails?.sectionName} - ${elementDetails?.elementName}`,
                      queryParams: `scopeId=${elementDetails?.scopeId}`,
                      index: openIndex++,
                    });
                  }
                  clearQueryCache([
                    'studentAssignments',
                    ...generalInputData.classes.map(
                      (cl) => `ownerAssignments({"classId":"${cl.id}"})`
                    ),
                  ]);
                },
              })
                .then(() => ({
                  status: CreateAssignmentRespTypeEnum.SUCCESS,
                  elementId: elementDetails?.elementId ?? '',
                  elementName: elementDetails?.elementName ?? '',
                }))
                .catch(() => ({
                  status: CreateAssignmentRespTypeEnum.FAILED,
                  elementId: elementDetails?.elementId ?? '',
                  elementName: elementDetails?.elementName ?? '',
                }));
            })
          ).then((createAssignsResp) => {
            const isAnyAssignmentFailed = createAssignsResp.some(
              (resp) => resp.status === CreateAssignmentRespTypeEnum.FAILED
            );
            const isAnyAssignmentSuccess = createAssignsResp.some(
              (resp) => resp.status === CreateAssignmentRespTypeEnum.SUCCESS
            );
            if (isAnyAssignmentFailed) {
              // TODO: commenting out temporarily
              // const failedDesc = getCreateAssignmentToastDesc(
              //   createAssignsResp,
              //   CreateAssignmentRespTypeEnum.FAILED
              // );
              // toast({
              //   title: t('createAssignmentDrawer.failedToast'),
              //   description: failedDesc,
              //   variant: 'error-light',
              //   duration: 5000,
              //   isClosable: true,
              // });
            }
            if (isAnyAssignmentSuccess) {
              // TODO: commenting out temporarily
              // const successDesc = getCreateAssignmentToastDesc(
              //   createAssignsResp,
              //   CreateAssignmentRespTypeEnum.SUCCESS
              // );
              // toast({
              //   title: t('createAssignmentDrawer.successToast'),
              //   description: successDesc,
              //   variant: 'info-light',
              //   duration: 5000,
              //   isClosable: true,
              // });
            }
          });
        }}
        createResource={({ generalInputData, values }) =>
          Promise.all(
            Object.keys(values.activity).map((studentViewId) => {
              const elementDetails = assignStudentContentElements?.find(
                (ele) => ele.identifier === studentViewId
              );
              return createResource({
                variables: {
                  params: {
                    ...generalInputData,
                    learnosityReferenceId: elementDetails?.referenceId ?? '',
                    name: `${elementDetails?.scopeName} - ${elementDetails?.elementName}`,
                    gradeLevel: metadata?.gradeLevel ?? '',
                    resourceSource: {
                      element: {
                        elementId: elementDetails?.elementId || '',
                        elementName: elementDetails?.elementName || '',
                        viewId: elementDetails?.viewId || '',
                        projectId: elementDetails?.projectId || '',
                        scopeId: elementDetails?.scopeId || '',
                        scopeName: elementDetails?.scopeName || '',
                        sectionId: elementDetails?.sectionId || '',
                        sectionName: elementDetails?.sectionName || '',
                      },
                    },
                  },
                  schoolId,
                },
                onCompleted: () => {
                  // TODO: commenting out temporarily
                  // toast({
                  //   title: t('createAssignmentDrawer.successToast'),
                  //   description: elementDetails?.elementName ?? '',
                  //   variant: 'info-light',
                  //   duration: 5000,
                  //   isClosable: true,
                  // });
                },
                onError: errorHandler(() => {
                  // TODO: commenting out temporarily
                  // toast({
                  //   title: t('createAssignmentDrawer.failedToast'),
                  //   description: elementDetails?.elementName ?? '',
                  //   variant: 'error-light',
                  //   duration: 3000,
                  //   isClosable: true,
                  // })
                }),
              });
            })
          )
        }
      />
      <ConfirmModal
        primaryHeading={t('scope.removeElementConfirmation')}
        isModalOpen={isOpen}
        onModalClose={onClose}
        isLoading={removeElementLoading}
        handleSave={handleRemoveElements}
        handleCancel={onClose}
        hasDontSaveBtn={false}
        submitButtonText={t('buttons.remove')}
      />
    </>
  );
};

export default PlanElementList;
