import { MODALS, NETWORK_ERROR_CODES } from '@lon/shared/constants';
import { OPENED_MODAL, REFETCH_APP_SETTINGS, logout } from '@lon/shared/utils';
import * as Types from './types';

export const prepareRequest: Types.PrepareRequest = (req) => {
  if (
    localStorage.getItem('globalId') &&
    req.method !== 'post' &&
    req.method !== 'patch' &&
    req.method !== 'put'
  ) {
    req.url = `${req.url}${
      req.url.indexOf('?') > 0 ? '&' : '?'
    }gid=${localStorage.getItem('globalId')}`;
  }

  return req;
};

export const prepareSuccess: Types.PrepareSuccess = (response) => {
  const { headers } = response;

  if (typeof headers === 'object') {
    if ('Application-Settings-Outdated' in headers) {
      REFETCH_APP_SETTINGS(true);
    }
  }

  switch (response.status) {
    case 201:
    case 200:
    case 204:
    case 304:
      return Promise.resolve(response);
    default:
      return Promise.reject(response);
  }
};

export const prepareFailure: Types.PrepareFailure = ({ error }) => {
  const { response } = error;
  const { code } = response?.data || {};
  switch (response?.status) {
    case 401:
      if (code === NETWORK_ERROR_CODES.passwordExpired) {
        OPENED_MODAL(MODALS.PasswordUpdate);
      } else if (code === NETWORK_ERROR_CODES.sessionExpired) {
        console.error('sessionExpired prepareFailure ', error);
        OPENED_MODAL(MODALS.SessionExpired);
      } else {
        logout({ keepPreviousUrl: true });
      }
      return Promise.reject(response);
    default:
      return Promise.reject(response);
  }
};
