import { Icon } from '../../../../../../../../../icon';
import { navigationTypes } from '../../duck';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  Text,
} from '@chakra-ui/react';
import { omit, toArray } from 'lodash-es';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sxLightScrollBar } from '@lon/shared/constants';
import { ScopeContext, useFullScreen } from '@lon/shared/contexts';
import { useIpadSafari } from '@lon/shared/hooks';
import { checkContentPlayer } from '@lon/shared/utils';
import {
  ContentPlayerSectionCardWrapper,
  SuitSectionCardWrapper,
} from './components';

const ViewAllSectionsDrawer: React.FC<
  navigationTypes.ViewAllSectionsDrawerProps
> = ({ isOpen, onClose, sections }) => {
  const { t } = useTranslation();
  const isContentPlayer = checkContentPlayer();
  
  const RenderSectionCard = !isContentPlayer
    ? SuitSectionCardWrapper
    : ContentPlayerSectionCardWrapper;

  
    const { metadata, elementsBySection } = useContext(ScopeContext);
    const { isFullScreen } = useFullScreen();
    const scrollableSectionRef = useRef<HTMLDivElement>(null);
    const [scrollInterval, setScrollInterval] = useState<NodeJS.Timeout | null>(
      null
    );
    const [canScrollUp, setCanScrollUp] = useState(false);
    const [canScrollDown, setCanScrollDown] = useState(false);
    const isIpadSafari = useIpadSafari();
  
    useEffect(() => {
      const handleScroll = () => {
        const drawerBody = scrollableSectionRef.current;
        if (drawerBody) {
          setCanScrollUp(drawerBody.scrollTop > 0);
          setCanScrollDown(
            drawerBody.scrollTop + drawerBody.clientHeight <
              drawerBody.scrollHeight
          );
        }
      };
  
      const observer = new MutationObserver(() => {
        const drawerBody = scrollableSectionRef.current;
        if (drawerBody) {
          handleScroll();
          drawerBody.addEventListener('scroll', handleScroll);
          observer.disconnect();
        }
      });
  
      observer.observe(document.body, { childList: true, subtree: true });
  
      return () => {
        observer.disconnect();
        const drawerBody = scrollableSectionRef.current;
        if (drawerBody) {
          drawerBody.removeEventListener('scroll', handleScroll);
        }
      };
    }, []);

  const topBarElements = useMemo(() => {
    const sidebarSectionId = Object.keys(elementsBySection).find(
      (sectionId) => {
        const elements = toArray(elementsBySection[sectionId]);
        return Boolean(
          elements.find(
            (element) => element.metadata?.sectionType === 'Side Bar'
          )
        );
      }
    );

    return sidebarSectionId
      ? omit(elementsBySection, sidebarSectionId)
      : elementsBySection;
  }, [elementsBySection]);

  
  const handleScrollUp = () => {
    const drawerBody = scrollableSectionRef.current;
    if (drawerBody) {
      drawerBody.scrollTo({
        top: drawerBody.scrollTop - 50,
        behavior: 'smooth',
      });
    }
  };

  const handleScrollDown = () => {
    const drawerBody = scrollableSectionRef.current;
    if (drawerBody) {
      drawerBody.scrollTo({
        top: drawerBody.scrollTop + 50,
        behavior: 'smooth',
      });
    }
  };

  const handleScrollUpContinuous = () => {
    const drawerBody = scrollableSectionRef.current;
    if (scrollInterval) clearInterval(scrollInterval);

    if (drawerBody && drawerBody.scrollTop > 0) {
      const interval = setInterval(() => {
        if (drawerBody.scrollTop > 0) {
          drawerBody.scrollBy({ top: -100, behavior: 'smooth' });
        } else {
          clearInterval(interval);
        }
      }, 100);
      setScrollInterval(interval);
    }
  };

  const handleScrollDownContinuous = () => {
    const drawerBody = scrollableSectionRef.current;
    if (scrollInterval) clearInterval(scrollInterval);

    if (
      drawerBody &&
      drawerBody.scrollTop + drawerBody.clientHeight < drawerBody.scrollHeight
    ) {
      const interval = setInterval(() => {
        if (
          drawerBody.scrollTop + drawerBody.clientHeight <
          drawerBody.scrollHeight
        ) {
          drawerBody.scrollBy({ top: 100, behavior: 'smooth' });
        } else {
          clearInterval(interval);
        }
      }, 100);
      setScrollInterval(interval);
    }
  };

  const handleMouseDownUp = () => {
    handleScrollUpContinuous();
  };

  const handleMouseDownDown = () => {
    handleScrollDownContinuous();
  };

  const handleMouseUp = () => {
    if (scrollInterval) clearInterval(scrollInterval);
    setScrollInterval(null);
  };

  return (
    <Drawer
      isOpen={isOpen}
      variant="formDrawer"
      onClose={onClose}
      onEsc={onClose}
      onOverlayClick={onClose}
    >
      <DrawerOverlay />
      <DrawerContent boxShadow="none" maxW="1000px">
        <DrawerCloseButton top={{ base: '4', md: '7' }} />
        <DrawerHeader py={{ base: '4', md: '6' }}>
          <Text variant="formHeader">{t('scope.sectionsDrawerTitle')}</Text>
        </DrawerHeader>
        <DrawerBody>
          {isFullScreen && isIpadSafari && (
            <>
              <IconButton
                position="fixed"
                bottom="54%"
                right={10}
                zIndex={1}
                isDisabled={!canScrollUp}
                aria-label="scroll-up"
                icon={<Icon name="arrow-up" />}
                onClick={handleScrollUp}
                onMouseDown={handleMouseDownUp}
                onMouseUp={handleMouseUp}
                onTouchStart={handleMouseDownUp}
                onTouchEnd={handleMouseUp}
                sx={{
                  _hover: {
                    bg: 'transparent',
                    color: 'inherit',
                  },
                  _focus: {
                    boxShadow: 'none',
                  },
                  _active: {
                    bg: 'transparent',
                  },
                }}
              />
              <IconButton
                position="fixed"
                bottom="42%"
                right={10}
                zIndex={1}
                isDisabled={!canScrollDown}
                aria-label="scroll-down"
                icon={<Icon name="arrow-down" />}
                onClick={handleScrollDown}
                onMouseDown={handleMouseDownDown}
                onMouseUp={handleMouseUp}
                onTouchStart={handleMouseDownDown}
                onTouchEnd={handleMouseUp}
                sx={{
                  _hover: {
                    bg: 'transparent',
                    color: 'inherit',
                  },
                  _focus: {
                    boxShadow: 'none',
                  },
                  _active: {
                    bg: 'transparent',
                  },
                }}
              />
            </>
          )}
          <Flex
            py="4"
            justify="center"
            boxShadow="0px 2px 7px 0px rgba(43, 54, 70, 0.10)"
            px={{ base: '8', md: '0' }}
          >
            <Text
              variant="h5"
              lineHeight="1.625rem"
              textAlign="left"
              color="primary.800"
              w="full"
              maxW="700px"
            >
              {metadata?.displayName}
            </Text>
          </Flex>
          <Flex
            justify="center"
            py="4"
            px={{ base: '4', md: '0' }}
            overflowY="auto"
            maxHeight="calc( 100% - 58px )"
            sx={{ scrollbarGutter: 'stable', ...sxLightScrollBar }}
            ref={scrollableSectionRef}
          >
            <Flex maxW="700px" w="full" h="full" direction="column" gap="6">
              {Object.keys(topBarElements).map((sectionId) => {
                const sectionElements = toArray(elementsBySection[sectionId]);
                const sectionTitle =
                  sectionElements.find((element) => element?.sectionTitle)
                    ?.sectionTitle || '';
                const sectionIcon = sectionElements.find(
                  (element) => element?.metadata?.elementIconType
                )?.metadata?.elementIconType;

                return (
                  <RenderSectionCard
                    key={sectionId}
                    sectionTitle={sectionTitle}
                    sectionElements={sectionElements}
                    sectionIconType={sectionIcon}
                    handleCloseDrawer={onClose}
                  />
                );
              })}
            </Flex>
          </Flex>
        </DrawerBody>
        <DrawerFooter
          justifyContent="start"
          px={{ base: '4', md: '8' }}
          py={{ base: '4', md: '6' }}
        >
          <Button
            leftIcon={<Icon name="arr-left-outlined" />}
            onClick={onClose}
          >
            {t('scope.backToScope')}
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default ViewAllSectionsDrawer;
