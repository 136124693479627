import { CollapsibleSidebar } from '../../../../../collapsible-sidebar';
import { Icon } from '../../../../../icon';
import { scopeTypes } from '../../duck';
import { Box, Button, Collapse, Flex } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { sxSidebarDarkScrollBar } from '@lon/shared/constants';
import { ScopeContext, WorkingLocation } from '@lon/shared/contexts';
import { ApplicationEnum } from '@lon/shared/enums';
import { useScrollExisting } from '@lon/shared/hooks';
import { ScopeType } from '@lon/shared/types';
import { checkContentPlayer } from '@lon/shared/utils';
import PlanElementList from './components/plan-element-list/PlanElementList';
import {
  ContentGroupsMenu,
  Description,
  ElementList,
  Skeleton,
} from './components';

const Sidebar: React.FC<scopeTypes.SidebarProps> = ({
  scope,
  standards = [],
  ...rest
}) => {
  const {
    contentRef,
    contentScrolls: { vertical: hasContentScroll },
  } = useScrollExisting({
    horizontal: false,
    dependencies: [scope],
  });
  const { projectType } = useContext(ScopeContext);
  const { application } = useContext(WorkingLocation);
  const { sectionId, scopeId, projectId, elementId } = useParams() as {
    sectionId: string;
    scopeId: string;
    projectId: string;
    elementId: string;
  };
  const { t } = useTranslation();
  const [show, setShow] = React.useState(true);

  const isContentPlayer = checkContentPlayer();
  const isParentSuite = application === ApplicationEnum.PARENT_SUIT;
  const isDailyNumeracy = projectType === ScopeType.DailyNumeracy;
  const isFactFluency = projectType === ScopeType.FactFluency;
  const isHowTo = projectType === ScopeType.HowTo;

  const handleToggle = () => setShow(!show);

  return (
    <CollapsibleSidebar
      {...rest}
      skeleton={<Skeleton />}
      contentWrapperProps={{
        sx: { scrollbarGutter: 'unset' },
        pt: isFactFluency ? '3.25rem' : 0,
      }}
      {...(isContentPlayer
        ? { parentWrapperProps: { height: '100vh !important' } }
        : {})}
    >
      <>
        {!isFactFluency && (
          <Box bgColor="blue.500" p={6}>
            {isContentPlayer && <ContentGroupsMenu />}
            <Description
              scope={scope}
              standards={standards}
              sectionId={sectionId}
              elementId={elementId}
              isDailyNumeracy={isDailyNumeracy}
              isHowTo={isHowTo}
            />
          </Box>
        )}
        <Box
          ref={contentRef}
          as="nav"
          py={1}
          overflowY="auto"
          overflowX="hidden"
          pl={6}
          pr={hasContentScroll ? 2.5 : 6}
          mb={6}
          flexGrow={1}
          sx={{ ...sxSidebarDarkScrollBar }}
          mr={hasContentScroll ? 6 : 0}
        >
          <Flex
            as="ul"
            direction="column"
            gap={4}
            listStyleType="none"
            role="tablist"
            aria-orientation="vertical"
            aria-label={t('scope.scopeSidebarNavigation')}
          >
            <Box as="li" sx={{ svg: { w: '20px', h: '20px' } }}>
              <Button
                justifyContent="flex-start"
                variant="sidebarButton"
                aria-label="Home"
                leftIcon={<Icon name="home-outlined" />}
                as={Link}
                to={`/scopes/${projectId}/${scopeId}`}
                w="full"
                role="tab"
                id={scope?.home?.landingPage?.identifier}
                aria-controls="scope-element"
                aria-selected={
                  !sectionId && !window.location.pathname.includes('view-all')
                }
                {...(!sectionId && {
                  isActive: true,
                  className: 'active',
                  css: {
                    backgroundColor:
                      'var(--chakra-colors-primary-50) !important',
                    color: 'var(--chakra-colors-primary-800) !important',
                  },
                })}
              >
                {t('scope.homeButton')}
              </Button>
            </Box>
            {!isParentSuite && <ElementList scope={scope} />}
          </Flex>
          {!isContentPlayer &&
            !isParentSuite &&
            !isDailyNumeracy &&
            !isFactFluency &&
            !isHowTo && (
              <Flex flexDirection="column" gap={4} mt={4}>
                <Button
                  w="full"
                  role="menuitem"
                  onClick={handleToggle}
                  variant="sidebarButton"
                  position="relative"
                  justifyContent="flex-start"
                  fontWeight={400}
                  leftIcon={<Icon name={'user-group'} size="small" />}
                  iconSpacing="3"
                  sx={
                    show
                      ? {
                          backgroundColor: 'primary.50',

                          _before: {
                            position: 'absolute',
                            my: '7px',
                            top: '0',
                            left: '0',
                            zIndex: '1',
                            borderRadius: '0 4px 4px 0',
                            width: '4px',
                            height: 'calc(100% - 14px)',
                            backgroundColor: 'primary.400',

                            content: `''`,
                          },

                          _hover: {
                            backgroundColor: 'primary.30',
                          },

                          _active: {
                            textDecoration: 'none',
                            backgroundColor: 'primary.700',
                            color: 'white',
                          },
                        }
                      : {}
                  }
                >
                  {'My Plans'}
                </Button>

                <Collapse in={show}>
                  <PlanElementList
                    metadata={scope?.metadata}
                    scopeId={scopeId}
                    scope={scope}
                  />
                </Collapse>
              </Flex>
            )}
        </Box>
      </>
    </CollapsibleSidebar>
  );
};

export default Sidebar;
