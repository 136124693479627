import { LimitedAccessPopoverProps } from '../duck/types';
import {
  Badge,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Tooltip,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { WorkingLocation } from '@lon/shared/contexts';
import { BehaviourEnum } from '@lon/shared/gql/types';
import { usePermission } from '@lon/shared/hooks';
import { MessageContent } from './MessageContent';

export const LimitedAccessPopover: React.FC<LimitedAccessPopoverProps> = ({
  isOpen,
  popoverPosition,
  offset,
  control,
  feature,
  trigger,
  tooltip,
  tooltipPosition,
  module,
  operation,
}) => {
  const { application: currentApplication, currentSchoolId } =
    useContext(WorkingLocation);

  const [policyBehaviour] = usePermission<BehaviourEnum>([
    {
      schoolId: currentSchoolId,
      application: currentApplication,
      module: module,
      operation: operation,
      field: 'behaviour',
    },
  ]);
  return (
    <Popover
      variant="admin"
      placement={popoverPosition}
      isOpen={isOpen}
      onClose={control.off}
      computePositionOnMount
      offset={offset}
    >
      <PopoverTrigger>
        <Tooltip
          variant="dark"
          label={`${tooltip}${
            policyBehaviour === BehaviourEnum.Restricted
              ? ', Access Limited'
              : ''
          }`}
          aria-hidden={true}
          hidden={!tooltip}
          placement={
            tooltipPosition ||
            (policyBehaviour === BehaviourEnum.Restricted ? 'top' : 'bottom')
          }
        >
          {trigger}
        </Tooltip>
      </PopoverTrigger>
      <Portal>
        <PopoverContent w={{ base: '250px', md: '400px' }}>
          <PopoverCloseButton
            top={6}
            right={6}
            w="9px"
            h="9px"
            onClick={control.off}
          />
          <PopoverHeader border={0} mr={6} textAlign="start">
            <Badge variant="fullRed">Access Limited</Badge>
          </PopoverHeader>
          <PopoverBody>
            <MessageContent feature={feature} />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
