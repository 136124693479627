import { CollapsibleSidebar } from '../../../collapsible-sidebar';
import { Banner, Filters } from './components';

const Sidebar = () => {
  return (
    <CollapsibleSidebar>
      <>
        <Filters />
        <Banner />
      </>
    </CollapsibleSidebar>
  );
};

export default Sidebar;
