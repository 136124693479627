import { t } from 'i18next';

export const MENU_TOGGLE_BUTTON_AND_LOGO_GAP = 6;
export const LOGO_WRAPPER_WIDTH = 160;
export const SKIP_TO_CONTENT_LINK_ID = 'skip-to-content-link';
export const SKIP_TO_FOOTER_LINK_ID = 'skip-to-footer-link';
export const HAMBURGER_BUTTON_ID = 'toggle-main-menu-button';

export const skipLinks = [
  {
    id: SKIP_TO_CONTENT_LINK_ID,
    title: t('header.skipToContent'),
    href: `${window.location.href.replace('#main', '')}#main`,
  },
  {
    id: SKIP_TO_FOOTER_LINK_ID,
    title: t('header.skipToFooter'),
    href: `${window.location.href.replace('#footer', '')}#footer`,
  },
];
