import styles from '../../AssignmentGrades.module.css';
// import { ReopenAssignment } from '../reopen-assignment';
// import { ResetAssignment } from '../reset-assignment';
// import ReturnAssignment from '../return-assignment';
// import RevokeAssignmentResults from '../revoke-assignment-results';
import ShareAssignmentResults from '../share-assignment-results';
import {
  Box,
  Button, // Divider,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Switch,
  Tag,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath, useParams } from 'react-router-dom';
import { Icon } from '@lon/shared/components';
import { StudentAssignmentStatusTransformed } from '@lon/shared/enums';
import { useThemeStyleValue } from '@lon/shared/hooks';
import { routes } from '@lon/shared/configs';
import { types } from './duck';

const GroupActions: React.FC<types.GroupActionsProps> = ({
  table,
  showAnswersColumn,
  setShowAnswersColumn,
  // isArchived,
}) => {
  const { t } = useTranslation();
  const { assignmentId } = useParams();
  const selectedStudentAssignments = table.getSelectedRowModel().flatRows.length
    ? table.getSelectedRowModel().flatRows.map((row) => row.original)
    : table.getRowModel().flatRows.map((row) => row.original);

  const labelColor = useThemeStyleValue('primary.800', 'white');

  const rows = table.getRowModel().flatRows;

  const gradeByStudentPath = React.useMemo(() => {
    const filteredStudents = rows.filter((row) => {
      return (
        row.original.transformedStatus &&
        [
          StudentAssignmentStatusTransformed.ToBeGraded,
          StudentAssignmentStatusTransformed.Graded,
          StudentAssignmentStatusTransformed.Returned,
        ].includes(row.original.transformedStatus)
      );
    });

    const firstStudentWithoutGrade = filteredStudents.find((row) => {
      if (row.original.transformedStatus) {
        return !row.original.grade;
      }

      return false;
    });

    if (firstStudentWithoutGrade) {
      return generatePath(routes.classes.districtAssessmentGrading, {
        assignmentId: assignmentId,
        studentId: firstStudentWithoutGrade.original.studentId,
        sessionId: firstStudentWithoutGrade.original.id,
      });
    }

    const isAllHasGrade =
      !!filteredStudents.length &&
      filteredStudents.every((row) => row.original.grade);

    if (isAllHasGrade) {
      return generatePath(routes.classes.districtAssessmentGrading, {
        assignmentId: assignmentId,
        studentId: filteredStudents[0].original.studentId,
        sessionId: filteredStudents[0].original.id,
      });
    }

    return null;
  }, [rows]);

  return (
    <Flex px={8} py={5} justify="space-between" align="center">
      <Box display="flex" gap={2} alignItems="center">
        <Text variant="h5" color={labelColor}>
          {t('assignments.tableTitle')}
        </Text>
        <Tag variant="primary" fontWeight={600}>
          <Text
            variant="s2"
            color={useThemeStyleValue('primary.800', 'secondary.1000')}
            lineHeight="0.875rem"
          >
            {Object.keys(table.getRowModel().rowsById).length}
          </Text>
        </Tag>
      </Box>
      <Flex gap={4} align="center">
        <Flex align="center">
          <Text color={labelColor}>{t('assignments.showAnswers')}</Text>
          <Switch
            size="sm"
            ml={3}
            isChecked={showAnswersColumn}
            onChange={() => {
              setShowAnswersColumn((prevState) => !prevState);
            }}
          />
        </Flex>
        {/*TODO enable when DA actions have been developed*/}
        {/*{!isArchived && (*/}
        <Menu
          variant="primary"
          eventListeners={{ scroll: false }}
          placement="bottom-end"
        >
          {({ isOpen }) => (
            <>
              <MenuButton
                as={Button}
                rightIcon={
                  <Box
                    as={Icon}
                    name="chevron-down"
                    size="xl"
                    transform={`rotate(${isOpen ? -180 : 0}deg)`}
                  />
                }
                size="sm"
              >
                <Text variant="bs">{t('assignments.actions')}</Text>
              </MenuButton>
              <MenuList data-focus-visible-disabled>
                <MenuItem
                  p={0}
                  to={generatePath(
                    routes.classes.reportDashboard
                      .districtAssessmentGradeByQuestion,
                    {
                      assignmentId,
                    }
                  )}
                  as={Link}
                >
                  <Tooltip
                    variant="dark"
                    label={t('assignments.gradesByQuestion')}
                  >
                    <Flex flex="1" gap={3} py="2" px="6">
                      <Icon name="eyeDdlOutlined" className={styles.icon} />
                      <Text variant="s2">
                        {t('assignments.gradesByQuestion')}
                      </Text>
                    </Flex>
                  </Tooltip>
                </MenuItem>

                <Tooltip
                  variant="dark"
                  label={t('assignments.gradesByStudentTooltip')}
                >
                  <MenuItem
                    p={0}
                    {...(gradeByStudentPath
                      ? { to: gradeByStudentPath, as: Link }
                      : { isDisabled: true })}
                  >
                    <Flex flex="1" gap={3} py="2" px="6">
                      <Icon name="eyeDdlOutlined" className={styles.icon} />
                      <Text variant="s2">
                        {t('assignments.gradesByStudent')}
                      </Text>
                    </Flex>
                  </MenuItem>
                </Tooltip>
                <ShareAssignmentResults
                  isGroupActions
                  selectedStudentAssignments={selectedStudentAssignments}
                />
                {/*          <MenuItem*/}
                {/*            px={0}*/}
                {/*            cursor="initial"*/}
                {/*            _hover={{*/}
                {/*              backgroundColor: 'initial',*/}
                {/*            }}*/}
                {/*            _focusVisible={{*/}
                {/*              outline: 'none',*/}
                {/*            }}*/}
                {/*            _focus={{*/}
                {/*              backgroundColor: 'initial',*/}
                {/*            }}*/}
                {/*            closeOnSelect={false}*/}
                {/*          >*/}
                {/*            <Divider my={1.5} />*/}
                {/*          </MenuItem>*/}
                {/*          <ReturnAssignment*/}
                {/*            isGroupActions*/}
                {/*            studentsData={selectedStudentAssignments}*/}
                {/*          />*/}
                {/*          <ResetAssignment*/}
                {/*            isGroupActions*/}
                {/*            selectedStudentAssignments={selectedStudentAssignments}*/}
                {/*          />*/}
                {/*          <ReopenAssignment*/}
                {/*            isGroupActions*/}
                {/*            selectedStudentAssignments={selectedStudentAssignments}*/}
                {/*          />*/}
                {/*          <RevokeAssignmentResults*/}
                {/*            isGroupActions*/}
                {/*            selectedStudentAssignments={selectedStudentAssignments}*/}
                {/*          />*/}
              </MenuList>
            </>
          )}
        </Menu>
      </Flex>
    </Flex>
  );
};

export default GroupActions;
