import { AssignmentFormDrawer } from '../../assignment-form-drawer';
import { Icon } from '../../icon';
import { MenuItem } from '../../menu';
import { PermissionButton } from '../../permissions/permission-button';
import actionsStyles from '../ToolbarActions.module.css';
import {
  Button,
  Text,
  useDisclosure,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import { sanitize } from 'dompurify';
import { pick } from 'lodash-es';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { teacherView } from '@lon/shared/constants';
import { WorkingLocation } from '@lon/shared/contexts';
import { ScopeContext } from '@lon/shared/contexts';
import { useQueryParams } from '@lon/shared/hooks';
import { useCurrentElement } from '@lon/shared/hooks';
import {
  useCreateResourceMutation,
  usePutAssignmentMutation,
} from '@lon/shared/requests';
import {
  ModuleEnum,
  OperationEnum,
  TransformedElement,
} from '@lon/shared/types';
import { clearQueryCache } from '@lon/shared/utils';
import { types } from './duck';

const AssignToStudents: React.FC<types.AssignToStudentsProps> = ({
  viewType,
  ...rest
}) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [isMobile] = useMediaQuery('(max-width: 767px)');
  const [createAssignment, { loading: assignmentLoading }] =
    usePutAssignmentMutation();
  const [createResource, { loading: resourceLoading }] =
    useCreateResourceMutation();
  const params = useParams<{
    scopeId: string;
    elementId: string;
    sectionId: string;
  }>();
  const { scopeId, elementId, sectionId, projectId } = params as {
    elementId: string;
    sectionId: string;
    projectId: string;
    scopeId: string;
  };
  const { currentSchoolId } = useContext(WorkingLocation);
  const { metadata } = useContext(ScopeContext);
  const currentElement = useCurrentElement() as TransformedElement;
  const [queryParams] = useQueryParams();

  const studentElementId = queryParams.get('studentElementId');
  const studentElement = studentElementId
    ? currentElement?.studentView.find(
        (view) => view.identifier === studentElementId
      )
    : { lorResources: [], href: '', identifier: '' };

  const { elementData, element } = {
    element: studentElement,
    elementData: {
      scopeName: currentElement?.metadata?.scopeDisplayName || '',
      sectionName: currentElement?.metadata?.sectionTitle || '',
      elementName: currentElement?.metadata?.elementName || '',
      curriculumArea: metadata?.curriculumArea,
      gradeLevel: metadata?.gradeLevel,
    },
  };

  const learnosityReferenceId =
    element?.lorResources?.find(
      (loadResource) => loadResource?.type === 'activities'
    )?.referenceId || '';

  if (viewType === teacherView) {
    return null;
  }

  return (
    <>
      <MenuItem {...rest}>
        <PermissionButton
          module={ModuleEnum.learningMaterials}
          operation={OperationEnum.assign}
          tooltip="Assign Activity"
          feature="Assign Activity"
        >
          <Button
            variant="ghost"
            className={actionsStyles.action}
            aria-label={t('toolbar.assignToStudents')}
            onClick={onOpen}
            leftIcon={<Icon name="subscription-outlined" size="lg" />}
            _active={{
              backgroundColor: 'primary.800',
              color: 'white',
            }}
            {...(isMobile && {
              px: '12px',
              sx: {
                '.chakra-button__icon': {
                  mr: 0,
                },
              },
            })}
          >
            {!isMobile && (
              <Text as="span" isTruncated variant="s2">
                {t('toolbar.assignActivity')}
              </Text>
            )}
          </Button>
        </PermissionButton>
      </MenuItem>
      <AssignmentFormDrawer
        isOpen={isOpen}
        assignmentLoading={assignmentLoading}
        resourceLoading={resourceLoading}
        elementsData={elementData}
        onClose={onClose}
        isAssignmentWithoutLearnosity={!learnosityReferenceId}
        createAssignment={({ generalInputData }) =>
          createAssignment({
            variables: {
              assignmentParams: {
                stemscopesPutAssignmentInput: {
                  ...generalInputData,
                  learnosityReferenceId,
                  assignmentSource: {
                    element: {
                      projectId,
                      scopeId,
                      elementId,
                      sectionId,
                      viewId: studentElement?.identifier ?? '',
                      ...pick(elementData, [
                        'elementName',
                        'scopeName',
                        'sectionName',
                      ]),
                    },
                  },
                  name: `${elementData.scopeName} - ${elementData.elementName}`,
                  schoolId: currentSchoolId,
                },
              },
            },
            onCompleted: () => {
              clearQueryCache([
                'ownerAssignments',
                'studentAssignments',
                ...generalInputData.classes.map(
                  (cl) => `ownerAssignments({"classId":"${cl.id}"})`
                ),
              ]);
              toast({
                title: t('createAssignmentDrawer.successToast'),
                description: sanitize(
                  t('createAssignmentDrawer.successDescription', {
                    assignment: elementData.elementName,
                    interpolation: {
                      escapeValue: false,
                    },
                  })
                ),
                status: 'success',
                variant: 'success-light',
                isClosable: true,
              });
            },
            onError: (err) => {
              toast({
                title: err.message,
                variant: 'error-light',
                duration: null,
                isClosable: true,
              });
            },
          })
        }
        createResource={({ generalInputData }) =>
          createResource({
            variables: {
              params: {
                ...generalInputData,
                learnosityReferenceId:
                  element?.lorResources?.find(
                    (loadResource) => loadResource?.type === 'activities'
                  )?.referenceId || '',
                name: `${elementData.scopeName} - ${elementData.elementName}`,
                gradeLevel: elementData.gradeLevel ?? '',
                resourceSource: {
                  element: {
                    elementId,
                    projectId,
                    scopeId,
                    sectionId,
                    viewId: studentElement?.identifier ?? '',
                    ...pick(elementData, [
                      'elementName',
                      'scopeName',
                      'sectionName',
                    ]),
                  },
                },
              },
              schoolId: currentSchoolId,
            },
            onCompleted: () => {
              toast({
                title: t('createAssignmentDrawer.successToast'),
                description: sanitize(
                  t('createAssignmentDrawer.successDescription', {
                    assignment: elementData.elementName,
                    interpolation: {
                      escapeValue: false,
                    },
                  })
                ),
                status: 'success',
                variant: 'success-light',
                isClosable: true,
              });
            },
            onError: (err) => {
              toast({
                title: err.message,
                variant: 'error-light',
                duration: null,
                isClosable: true,
              });
            },
          })
        }
      />
    </>
  );
};

export default AssignToStudents;
