import { Box, Button, Flex, VStack } from '@chakra-ui/react';
import { HiPlus } from '@react-icons/all-files/hi/HiPlus';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Icon, PermissionButton } from '@lon/shared/components';
import { MenuGroupItem } from '@lon/shared/components';
import {
  CollapsibleSidebar,
  collapsibleSidebarPaddingRight,
} from '@lon/shared/components';
import { routes } from '@lon/shared/configs';
import { DistrictPermission } from '@lon/shared/enums';
import { useGetTheme } from '@lon/shared/hooks';
import { ModuleEnum, OperationEnum } from '@lon/shared/types';
import { getThemeStyleValue } from '@lon/shared/utils';
import { sidebarLinks, sidebarTypes, sidebarUtils } from './duck';

const Sidebar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentTheme = useGetTheme();
  const [activeTab, setActiveTab] = useState<number>();

  const handleTabOpen = (i: number, path?: string) => {
    activeTab === i ? setActiveTab(undefined) : setActiveTab(i);
    path && navigate(path);
  };

  const renderLink = ({
    title,
    path,
    id,
    icon,
    pathsToHighlight,
  }: sidebarTypes.SidebarLink) => {
    const isActive = sidebarUtils.isActive(pathname, pathsToHighlight);
    return (
      <Box as="li" key={id} role="none">
        <Button
          w="full"
          as={Link}
          to={path}
          variant="sidebarButton"
          position="relative"
          justifyContent="flex-start"
          fontWeight={400}
          leftIcon={<Icon name={icon || 'user-group'} size="small" />}
          iconSpacing="3"
          role="tab"
          id={`${title.toLowerCase()}-tab`}
          aria-controls={`${title.toLowerCase()}-list`}
          aria-selected={isActive && !pathname.includes('create')}
          isActive={isActive}
          className={isActive ? 'active' : ''}
          css={
            isActive
              ? {
                  backgroundColor: getThemeStyleValue(
                    'var(--chakra-colors-primary-50) !important',
                    'var(--chakra-colors-teal-500) !important'
                  )(currentTheme),
                  color: getThemeStyleValue(
                    'var(--chakra-colors-primary-800) !important',
                    'var(--chakra-colors-secondary-1000) !important'
                  )(currentTheme),
                  '&:hover': {
                    backgroundColor: getThemeStyleValue(
                      'var(--chakra-colors-primary-50) !important',
                      'var(--chakra-colors-teal-600) !important'
                    )(currentTheme),
                  },
                }
              : {}
          }
        >
          {title}
        </Button>
      </Box>
    );
  };

  return (
    <CollapsibleSidebar>
      <Flex
        direction="column"
        gap={10}
        p={6}
        pr={collapsibleSidebarPaddingRight}
      >
        <Box as="nav">
          <Flex
            gap={4}
            mb={4}
            direction="column"
            as="ul"
            listStyleType="none"
            role="tablist"
            aria-orientation="vertical"
            aria-label={t('classes.classesNavigation')}
          >
            {sidebarLinks.firstSection.map(renderLink)}
          </Flex>
          <VStack as="ul" role="menubar" spacing="1rem" listStyleType="none">
            {sidebarLinks.reportSection.map((item, index) => (
              <MenuGroupItem
                key={item.title}
                index={index}
                activeTab={activeTab}
                handleTabOpen={handleTabOpen}
                selectedPath={pathname}
                mode="light"
                subMenuItemProps={{
                  py: 2,
                }}
                {...item}
              />
            ))}
          </VStack>
        </Box>

        <Flex
          gap={4}
          direction="column"
          role="tablist"
          aria-orientation="vertical"
          aria-label={t('classes.classesActions')}
        >
          <PermissionButton
            module={ModuleEnum.classes}
            operation={OperationEnum.create}
            tooltip={t('classes.createClassBtn')}
            feature="Create Class"
            districtPermission={DistrictPermission.TEACHER_SUIT}
          >
            <Button
              w="full"
              as={NavLink}
              to={routes.classes.create}
              variant={getThemeStyleValue(
                'outlineDark',
                'outline'
              )(currentTheme)}
              justifyContent="flex-start"
              fontWeight={400}
              leftIcon={<HiPlus />}
              iconSpacing="3"
              draggable="false"
              role="tab"
              id={`${'Create Class'.toLowerCase().replace(' ', '-')}-tab`}
              aria-controls={'Create Class'.toLowerCase().replace(' ', '-')}
              aria-selected={routes.classes.create === pathname}
            >
              Create Class
            </Button>
          </PermissionButton>
          <PermissionButton
            module={ModuleEnum.students}
            operation={OperationEnum.create}
            tooltip="Create Student"
            feature="Create Student"
            districtPermission={DistrictPermission.TEACHER_SUIT}
          >
            <Button
              w="full"
              as={NavLink}
              to={routes.classes.createStudent}
              variant={getThemeStyleValue(
                'outlineDark',
                'outline'
              )(currentTheme)}
              justifyContent="flex-start"
              fontWeight={400}
              leftIcon={<HiPlus />}
              iconSpacing="3"
              draggable="false"
              role="tab"
              id={`${'Create Student'.toLowerCase().replace(' ', '-')}-tab`}
              aria-controls={'Create Student'.toLowerCase().replace(' ', '-')}
              aria-selected={routes.classes.createStudent === pathname}
            >
              Create Student
            </Button>
          </PermissionButton>
        </Flex>
      </Flex>
    </CollapsibleSidebar>
  );
};

export default Sidebar;
