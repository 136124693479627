import { Icon } from '../../../../../../../icon';
import { HowToScope } from '../../duck';
import { Button, HStack, Text, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';
import { routes } from '@lon/shared/configs';

const ScopeButton: React.FC<{ scope: HowToScope }> = ({ scope }) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      variant="dark"
      label={t('howToScope.buttonTooltip', {
        internalName: scope?.metadata?.displayName,
        gradeLevel: scope?.metadata?.gradeLevel,
      })}
    >
      <Button
        as={Link}
        to={generatePath(routes.scopes.show, {
          scopeId: scope.id,
          projectId: scope.projectId,
        })}
        variant="howToButton"
      >
        <HStack w="full" spacing={2} justify="space-between">
          <Text as="span" isTruncated variant="bm">
            {scope?.metadata?.displayName}
            {', '}
            {scope?.metadata?.gradeLevel}
          </Text>
          <Icon name="arrow-right-outlined" width="18px" height="18px" />
        </HStack>
      </Button>
    </Tooltip>
  );
};

export default ScopeButton;
