import { AccessDenied } from '../../../../../errors';
import { Icon } from '../../../../../icon';
import { Button, Link as ChakraLink, Flex, Text } from '@chakra-ui/react';
import { UserSettings } from 'libs/shared/modules/user-nav/src';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { routes } from '@lon/shared/configs';

export const ScopeAccessDenied = () => {
  const { t } = useTranslation();

  const [isUserSettingsOpen, setIsUserSettingsOpen] = useState<boolean>(false);

  return (
    <>
      <AccessDenied
        withoutSidebar
        title={t('scope.accessDeniedTitle')}
        description={
          <Flex direction="column" gap="4">
            <Trans i18nKey="scope.accessDeniedMessage">
              <Text whiteSpace="pre-wrap" variant="n3" as="h2">
                0
                <ChakraLink
                  rel="noreferrer nooppener"
                  target="_blank"
                  textDecor="underline"
                  href="https://acceleratelearning.com/stempreview/"
                >
                  1
                </ChakraLink>
              </Text>
            </Trans>
            <Trans i18nKey="scope.accessDeniedMessage2">
              <Text whiteSpace="pre-wrap" variant="n3" as="h2">
                0
                <ChakraLink
                  textDecor="underline"
                  onClick={() => setIsUserSettingsOpen(true)}
                >
                  1
                </ChakraLink>
              </Text>
            </Trans>
          </Flex>
        }
        headerTitle={t('scope.accessDeniedHeader')}
        actions={
          <Flex
            justify="space-between"
            w="full"
            maxW="450px"
            gap={4}
            direction={{ base: 'column', md: 'row' }}
          >
            <Button
              as={Link}
              to={routes.home}
              title={t('errorMessage.goToHome')}
              variant="outline"
              leftIcon={<Icon name="arr-double-left-outlined" />}
            >
              <Text as="span" isTruncated>
                {t('errorMessage.goToHome')}
              </Text>
            </Button>
            <Button
              as={Link}
              to={routes.scopes.root}
              title={t('scope.viewScopeListButton')}
              variant="solid"
              rightIcon={<Icon name="arr-double-right-outlined" />}
            >
              <Text as="span" isTruncated>
                {t('scope.viewScopeListButton')}
              </Text>
            </Button>
          </Flex>
        }
      />
      <UserSettings
        isPreferencesOpen={isUserSettingsOpen}
        setIsPreferencesOpen={setIsUserSettingsOpen}
        initialTabIndex={3}
      />
    </>
  );
};

export default ScopeAccessDenied;
