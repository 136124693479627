import { ErrorMessage } from '../../../../../errors';
import { Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';
import { routes } from '@lon/shared/configs';
import { ScopeContext } from '@lon/shared/contexts';
import { useCurrentElement } from '@lon/shared/hooks';
import { ScopeType } from '@lon/shared/types';
import {
  FactFluencyLanding,
  Main,
  NumeracyLanding,
  Skeleton,
} from './components';

const Element: React.FC<{
  hasToolbar?: boolean;
  isDisabledBreadcrumbs?: boolean;
}> = ({ hasToolbar = true, isDisabledBreadcrumbs = false }) => {
  const isLandingPage = !!useMatch(routes.scopes.show);
  const { t } = useTranslation();
  const currentElement = useCurrentElement();
  const { loading, refetch, attempts, setAttempts, projectType } =
    React.useContext(ScopeContext);
  const isNumeracyLanding =
    projectType === ScopeType.DailyNumeracy && isLandingPage;
  const isFactFluencyLanding =
    projectType === ScopeType.FactFluency && isLandingPage;

  if (loading) {
    return <Skeleton />;
  }
  if (!currentElement) {
    return (
      <ErrorMessage
        body={
          <Text as="p" variant="n3" pb="12">
            {t('errorMessage.404Body')}
          </Text>
        }
      />
    );
  }

  return isNumeracyLanding ? (
    <NumeracyLanding />
  ) : isFactFluencyLanding ? (
    <FactFluencyLanding />
  ) : (
    <Main
      element={currentElement as any}
      refetch={refetch}
      setAttempts={setAttempts}
      attempts={attempts}
      projectType={projectType}
      hasToolbar={hasToolbar}
      isDisabledBreadcrumbs={isDisabledBreadcrumbs}
    />
  );
};

export default Element;
