import { ListItem as ListItemUI } from '../../../../../../../list-item';
import { useFetchImage } from '../../../../duck/hooks';
import { memo } from 'react';
import { generatePath } from 'react-router-dom';
import { routes } from '@lon/shared/configs';
import { SearchableScope } from '@lon/shared/types';
import { checkContentPlayer, resizeImage } from '@lon/shared/utils';

const ListItem: React.FC<{ scope: SearchableScope }> = ({ scope }) => {
  const imageUrl = useFetchImage(
    resizeImage({ src: scope.image, height: 600, width: 800 }),
    { mode: 'no-cors' }
  );

  return (
    <ListItemUI
      title={scope.displayName}
      link={generatePath(routes.scopes.show, {
        scopeId: scope.id,
        projectId: scope.projectId,
      })}
      image={imageUrl}
      content={
        checkContentPlayer()
          ? [
              scope.metadata?.gradeLevel || '',
              scope.metadata?.editionName || '',
            ]
          : scope.metadata?.gradeLevel
      }
    />
  );
};

export default memo(ListItem);
