import { Icon } from '../../../icon';
import { Box, Button, Text, VStack, chakra } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetTheme } from '@lon/shared/hooks';
import { getThemeStyleValue } from '@lon/shared/utils';
import { menuGroupItemTypes } from './duck';

const MenuGroupItem: React.FC<menuGroupItemTypes.MenuGroupItemProps> = ({
  title,
  icon,
  index,
  path,
  subItems,
  activeTab,
  handleTabOpen,
  selectedPath,
  mode,
  subMenuItemProps,
}) => {
  const navigate = useNavigate();
  const currentTheme = useGetTheme();
  const selectedMenuItem =
    subItems?.find((v) => selectedPath.startsWith(v.path)) ||
    selectedPath === path;

  const handleRedirect = (path: string) => {
    path && navigate(path);
  };

  const activeStateForMenuItem =
    (selectedMenuItem && activeTab !== index) ||
    (activeTab === index &&
      !subItems.length &&
      selectedPath.includes(path as string));

  const getMenuItemButtonType = (mode: 'dark' | 'light'): string => {
    const activeStyle =
      (selectedMenuItem && activeTab !== index) ||
      (activeTab === index &&
        !subItems.length &&
        selectedPath.includes(path as string));
    const styles = {
      dark:
        activeTab === index && subItems.length
          ? 'sidebarDarkButton'
          : activeStyle
          ? 'solidDark'
          : activeTab === index && selectedPath.includes(path as string)
          ? 'sidebarDarkButton'
          : 'solid',
      light: activeStyle ? 'sidebarLightButton' : 'solidDark',
    };
    return getThemeStyleValue(styles[mode], 'sidebarButton')(currentTheme);
  };

  const getSubItemButtonType = (
    value: string,
    mode: 'dark' | 'light'
  ): string => {
    const styles = {
      dark: selectedPath.startsWith(value)
        ? 'solidDark'
        : 'sidebarDarkSubMenuButton',
      light: selectedPath.startsWith(value)
        ? 'sidebarLightButton'
        : 'solidDark',
    };
    return getThemeStyleValue(styles[mode], 'sidebarButton')(currentTheme);
  };

  return (
    <Box as="li" w="full" position="relative" cursor="pointer">
      {activeStateForMenuItem && (
        <Box
          position="absolute"
          top="50%"
          transform="translateY(-50%)"
          left={0}
          width="4px"
          height="31px"
          display={getThemeStyleValue('block', 'none')(currentTheme)}
          bg="primary.400"
          zIndex={10}
          borderTopRightRadius="6px"
          borderBottomRightRadius="6px"
        />
      )}
      <Button
        onClick={() => handleTabOpen(index, path, subItems.length)}
        role="menuitem"
        variant={getMenuItemButtonType(mode)}
        w="full"
        h="100%"
        position="relative"
        justifyContent="flex-start"
        className={activeStateForMenuItem ? 'active' : ''}
        fontWeight={400}
        _hover={{
          color: getThemeStyleValue(
            mode === 'dark' && !activeStateForMenuItem
              ? 'white'
              : 'primary.800',
            'secondary.1000'
          )(currentTheme),
          backgroundColor: getThemeStyleValue(
            mode === 'dark' && activeStateForMenuItem
              ? 'white'
              : mode === 'dark'
              ? 'primary.500'
              : 'primary.50',
            'secondary.400'
          )(currentTheme),
        }}
        borderBottomRadius={
          activeTab === index && !!subItems.length ? 0 : '4px'
        }
        leftIcon={<Icon name={icon} size="lg" />}
        rightIcon={
          <chakra.span
            position="absolute"
            right="17px"
            top="50%"
            transform="translateY(-50%)"
          >
            {!!subItems.length && (
              <Icon
                name={activeTab === index ? 'caret-down' : 'caret-right'}
                size="xs"
              />
            )}
          </chakra.span>
        }
        iconSpacing="3"
      >
        <Text isTruncated textAlign="left" whiteSpace="normal" mr={1}>
          {title}
        </Text>
      </Button>
      {activeTab === index && !!subItems.length && (
        <VStack
          as="ul"
          role="menubar"
          spacing={0}
          py={2}
          listStyleType="none"
          bg={mode === 'dark' ? 'primary.700' : 'white'}
          borderTopWidth="1px"
          borderTopStyle="solid"
          borderTopColor={mode === 'dark' ? 'primary.500' : 'primary.30'}
          borderBottomRadius="4px"
        >
          {subItems.length &&
            subItems.map((v) => (
              <Box
                key={`${v.title}-${v?.id ?? ''}`}
                as="li"
                w="full"
                position="relative"
              >
                {selectedMenuItem && selectedPath.includes(v.path) && (
                  <Box
                    position="absolute"
                    top="50%"
                    transform="translateY(-50%)"
                    left={0}
                    width="4px"
                    height="25px"
                    display={getThemeStyleValue('block', 'none')(currentTheme)}
                    bg="primary.400"
                    zIndex={10}
                    borderTopRightRadius="6px"
                    borderBottomRightRadius="6px"
                  />
                )}
                <Button
                  isDisabled={v.isDisabled}
                  className={
                    selectedMenuItem && selectedPath.includes(v.path)
                      ? 'active'
                      : ''
                  }
                  role="menuitem"
                  variant={getSubItemButtonType(v.path, mode)}
                  onClick={() => handleRedirect(v.path)}
                  w="full"
                  pl="42px"
                  pr="16px"
                  py={1}
                  fontWeight={400}
                  bg="primary.700"
                  size="sidebarSize"
                  justifyContent="flex-start"
                  borderRadius={0}
                  _hover={{
                    color: getThemeStyleValue(
                      mode === 'dark' && !selectedPath.includes(v.path)
                        ? 'white'
                        : 'primary.800',
                      'secondary.1000'
                    )(currentTheme),
                    backgroundColor: getThemeStyleValue(
                      mode === 'dark' && selectedPath.includes(v.path)
                        ? 'white'
                        : mode === 'dark'
                        ? 'primary.500'
                        : 'primary.50',
                      'secondary.400'
                    )(currentTheme),
                  }}
                  whiteSpace="normal"
                  textAlign="left"
                  {...subMenuItemProps}
                >
                  {v?.badge && v.badge}
                  {v.title}
                </Button>
              </Box>
            ))}
        </VStack>
      )}
    </Box>
  );
};

export default MenuGroupItem;
