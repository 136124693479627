import styles from '../../AssignmentGrades.module.css';
// import { ReopenAssignment } from '../reopen-assignment';
// import { ResetAssignment } from '../reset-assignment';
// import ReturnAssignment from '../return-assignment';
// import RevokeAssignmentResults from '../revoke-assignment-results';
import ShareAssignmentResults from '../share-assignment-results';
import {
  Divider,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath, useParams } from 'react-router-dom';
import { Icon } from '@lon/shared/components';
import { StudentAssignmentStatusTransformed } from '@lon/shared/enums';
import { useThemeStyleValue } from '@lon/shared/hooks';
import { routes } from '@lon/shared/configs';
import { PreparedDistrictAssignmentData } from '@lon/shared/types';

const RowActions: React.FC<{
  rowData: PreparedDistrictAssignmentData;
}> = ({ rowData }) => {
  const { t } = useTranslation();
  const { daId } = useParams();

  return (
    <Menu variant="primary">
      <Tooltip variant="dark" label={t('assignments.rowActionsTooltip')}>
        <MenuButton
          as={IconButton}
          size="md"
          icon={<Icon name="pointsOutlined" />}
          variant={useThemeStyleValue('empty', 'ghost')}
          boxShadow="none"
          aria-label={t('assignments.viewActions')}
          _active={{ backgroundColor: 'unset' }}
        >
          <Text variant="bs">{t('assignments.actions')}</Text>
        </MenuButton>
      </Tooltip>
      <MenuList data-focus-visible-disabled>
        {[
          StudentAssignmentStatusTransformed.Graded,
          StudentAssignmentStatusTransformed.Returned,
          StudentAssignmentStatusTransformed.Assigned,
          StudentAssignmentStatusTransformed.ToBeGraded,
          StudentAssignmentStatusTransformed.Started,
        ].includes(
          rowData.transformedStatus as StudentAssignmentStatusTransformed
        ) && (
          <>
            <Tooltip
              variant="dark"
              label={t('assignments.viewAssignment')}
              isDisabled={[
                StudentAssignmentStatusTransformed.Assigned,
                StudentAssignmentStatusTransformed.Started,
              ].includes(
                rowData.transformedStatus as StudentAssignmentStatusTransformed
              )}
            >
              <MenuItem
                p={0}
                isDisabled={[
                  StudentAssignmentStatusTransformed.Assigned,
                  StudentAssignmentStatusTransformed.Started,
                ].includes(
                  rowData.transformedStatus as StudentAssignmentStatusTransformed
                )}
                as={Link}
                to={{
                  pathname: generatePath(
                    routes.teachers.districtAssessmentResult,
                    {
                      daId,
                      studentId: rowData.studentId,
                      sessionId: rowData.id,
                    }
                  ),
                  search: `schoolId=${rowData.schoolId || ''}`,
                }}
              >
                <Flex flex="1" gap={3} py="2" px="6">
                  <Icon name="eyeDdlOutlined" className={styles.icon} />
                  <Text variant="s2">{t('assignments.viewAssignment')}</Text>
                </Flex>
              </MenuItem>
            </Tooltip>
            <Divider my={2} />
          </>
        )}
        {/* {rowData.transformedStatus ===
          StudentAssignmentStatusTransformed.ToBeGraded && (
          <>
            <Tooltip variant="dark" label={t('assignments.gradeAssignment')}>
              <MenuItem
                //TODO enable when DA actions have been developed
                isDisabled={true}
                p={0}
              >
                <Flex flex="1" gap={3} py="2" px="6">
                  <Icon name="eyeDdlOutlined" className={styles.icon} />
                  <Text variant="s2">{t('assignments.gradeAssignment')}</Text>
                </Flex>
              </MenuItem>
            </Tooltip>
            <Divider my={2} />
          </>
        )} */}
        {/* <ReturnAssignment studentsData={[rowData]} />
        <ResetAssignment selectedStudentAssignments={[rowData]} />
        <ReopenAssignment selectedStudentAssignments={[rowData]} />
        {rowData.shareResults === ShareResults.Disabled ? (
          <ShareAssignmentResults selectedStudentAssignments={[rowData]} />
        ) : (
          <RevokeAssignmentResults selectedStudentAssignments={[rowData]} />
        )} */}
        <ShareAssignmentResults selectedStudentAssignments={[rowData]} />
      </MenuList>
    </Menu>
  );
};

export default RowActions;
