import { Box, Flex, Link, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { stemDomain } from '@lon/shared/constants';
import { useMediaQuery } from '@lon/shared/hooks';
import { useAuth } from '@lon/shared/hooks';

const StudentFooter = () => {
  const [{ user }] = useAuth();
  const [isTablet] = useMediaQuery('(min-width: 768px)');
  const { t } = useTranslation();

  return !isTablet && user.impersonated ? (
    <Box
      as="footer"
      boxShadow={{ base: 'none', sm: '2xl' }}
      zIndex={3}
      background="blue.75"
    >
      <Flex justify="center" align="center" h="52px" m="0 auto" py="15px">
        <Flex
          gap={8}
          direction={{ base: 'column', sm: 'row' }}
          justify="center"
          align="center"
        >
          <Box>
            <Text as="span" color="primary.800" fontSize="sm" fontWeight={500}>
              &copy; {new Date().getFullYear()}
            </Text>
            <Link
              href={`${stemDomain}/home.php`}
              isExternal
              color="primary.800"
              textDecoration="underline"
              fontSize="sm"
              fontWeight={500}
            >
              {t('footer.company')}
            </Link>
          </Box>
        </Flex>
      </Flex>
    </Box>
  ) : null;
};

export default StudentFooter;
