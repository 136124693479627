import { useQueryParams } from '../use-query-params';
import update from 'immutability-helper';
import { get, isEmpty } from 'lodash-es';
import { useDispatch } from 'react-redux';
import { setFilters } from '@lon/shared/configs';

type UseFilterToggle = (
  filterGroup: string,
  filterName: string,
  filter: 'scopes' | 'streaming',
  limit?: number
) => [{ on: boolean; limitExceeded: boolean }, () => void];

export const useFilterToggle: UseFilterToggle = (
  filterGroup,
  filterName,
  filter,
  limit
) => {
  const dispatch = useDispatch();

  const [{ pagination, ...params }, setParams] = useQueryParams();

  const initialPagination = pagination && {
    itemsPerPage: pagination.itemsPerPage,
    page: 1,
  };

  const limitExceeded =
    typeof limit === 'number'
      ? params?.['filters'][filterGroup]?.length === limit
      : false;

  const on = !!params?.['filters']?.[filterGroup]?.includes(filterName);

  const toggle = () => {
    if (!on) {
      if (limitExceeded) {
        return;
      }

      const alreadyHasThisValue = params?.['filters']?.[filterGroup]?.some(
        (filter: string) => filter === filterName
      );

      const compValue = alreadyHasThisValue
        ? params?.['filters']?.[filterGroup]
        : [...get(params, `filters[${filterGroup}]`, []), filterName];

      const newFilters = update(params, {
        filters: {
          [isEmpty(params['filters']) ? '$set' : '$merge']: {
            [filterGroup]: compValue,
          },
        },
        pagination: {
          ...(initialPagination ? { $set: initialPagination } : {}),
        },
      });

      setParams(newFilters);
      dispatch(setFilters({ [filter]: { filters: newFilters['filters'] } }));
    } else {
      const newFilters = update(params, {
        filters: {
          $merge: {
            [filterGroup]: params?.['filters']?.[filterGroup].filter(
              (filter: string) => filter !== filterName
            ),
          },
        },
        pagination: {
          ...(initialPagination ? { $set: initialPagination } : {}),
        },
      });

      setParams(newFilters);
      dispatch(setFilters({ [filter]: { filters: newFilters['filters'] } }));
    }
  };

  return [{ on, limitExceeded }, toggle];
};
