import { DistrictAssignmentContext } from '../../duck/context';
import {
  Box,
  Collapse,
  Flex,
  IconButton,
  TabList,
  Tabs,
  Tag,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomTab, Icon } from '@lon/shared/components';
import { useGetTheme } from '@lon/shared/hooks';
import { getGradeProgressVariant, getThemeStyleValue } from '@lon/shared/utils';
import { getAverageLineHighContrastVariant } from '@lon/suit/utils';
import { DescriptionProps } from './duck';
import {
  DescriptionSkeleton,
  ProgressLine,
  StartAssessment,
} from './components';

const Description: React.FC<DescriptionProps> = ({ progress }) => {
  const { t } = useTranslation();
  const currentTheme = useGetTheme();
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
  const { isLoading, districtAssignment, resetAreas, updateAreas } = useContext(
    DistrictAssignmentContext
  );
  const isAssessmentStarted = !!districtAssignment?.availableDate;
  const textColor = getThemeStyleValue('primary.800', 'white')(currentTheme);

  useEffect(() => {
    updateAreas('assignmentGrades', true);
  }, []);

  if (isLoading) {
    return <DescriptionSkeleton />;
  }

  return (
    <Box
      boxShadow="pane"
      borderRadius="md"
      border={getThemeStyleValue(
        'unset',
        '1px solid var(--chakra-colors-white)'
      )(currentTheme)}
      overflow="hidden"
    >
      <Flex
        px={8}
        py={6}
        backgroundColor={getThemeStyleValue(
          'white',
          'secondary.1000'
        )(currentTheme)}
        justifyContent="space-between"
      >
        <Flex alignItems="center" gap={3}>
          <Text variant="h5" color={textColor}>
            {t('assignments.generalAssignmentTitle')}
          </Text>
          <IconButton
            variant="ghost"
            size="sm"
            sx={{ svg: { width: '10px', height: '10px' } }}
            aria-label={t('assignments.generalAssignmentTitle')}
            icon={
              <Box
                as={Icon}
                name="arrow-down"
                transform={`rotate(${isOpen ? 0 : -90}deg)`}
              />
            }
            onClick={onToggle}
          />
        </Flex>

        <Flex alignItems="center" gap={4}>
          <Text variant="h5" color={textColor}>
            {t('assignments.showAssignmentContent')}
          </Text>
          <Tabs
            variant="drawerInternal"
            textAlign="left"
            isFitted
            onChange={(value) => {
              resetAreas();
              if (value === 0) {
                updateAreas('assignmentGrades', true);
              } else if (value === 1) {
                updateAreas('responseAnalysisReport', true);
              } else {
                updateAreas('standardsReport', true);
              }
            }}
          >
            <TabList height="32px" display="inline-flex">
              <CustomTab
                size="sm"
                w="50%"
                paddingInline={3}
                minW="fit-content"
                iconProps={{
                  width: '16px',
                  height: '16px',
                }}
              >
                {t('assignments.assignmentGrades')}
              </CustomTab>
              <CustomTab
                size="sm"
                w="50%"
                paddingInline={3}
                minW="fit-content"
                iconProps={{
                  width: '16px',
                  height: '16px',
                }}
              >
                {t('assignments.responseAnalysisReport')}
              </CustomTab>
              <CustomTab
                size="sm"
                w="50%"
                paddingInline={3}
                minW="fit-content"
                iconProps={{
                  width: '16px',
                  height: '16px',
                }}
              >
                {t('assignments.standardsReport')}
              </CustomTab>
            </TabList>
          </Tabs>
        </Flex>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <Flex
          position="relative"
          backgroundColor={getThemeStyleValue(
            'white',
            'secondary.1000'
          )(currentTheme)}
          justifyContent="space-between"
          direction={{ base: 'column', md: 'row' }}
          borderTop={getThemeStyleValue(
            'unset',
            '1px solid var(--chakra-colors-white)'
          )(currentTheme)}
        >
          <Box
            aria-hidden="true"
            position="absolute"
            top="-2px"
            left={0}
            right={0}
            height="2px"
            boxShadow="0px 2px 3px rgba(43, 54, 70, 0.15)"
          />
          <Flex
            direction="column"
            w={{ base: 'full', md: '50%' }}
            px={8}
            pt="2.625rem"
            pb={7}
            color={textColor}
            borderRightWidth={{
              base: 0,
              md: isAssessmentStarted ? '1px' : 0,
            }}
            borderBottomWidth={{ base: '1px', md: 0 }}
            borderColor="secondary.200"
          >
            <Text variant="n1" mb={4}>
              {t('assignments.assignedAssessmentTitle')}
            </Text>
            <Box mb={4}>
              <Text as="span" variant="n5" color={textColor}>
                {districtAssignment?.name}
              </Text>
            </Box>

            <Flex align="center" gap={6} mb={4}>
              <Box
                height="45px"
                sx={{ svg: { width: '45px', height: '45px' } }}
              >
                <Icon name="clock-detailed" />
              </Box>
              <Flex align="flex-end" gap={1}>
                <Flex direction="column" gap={1}>
                  <Text variant="s2">{t('assessmentDetails.startDate')}</Text>
                  <Tag variant="info" fontSize="0.875rem" lineHeight="1.25rem">
                    {districtAssignment?.startDate &&
                      format(
                        parseISO(districtAssignment?.startDate),
                        'MM/dd/yyyy'
                      )}
                  </Tag>
                </Flex>
                <Box>-</Box>
                <Flex direction="column" gap={1}>
                  <Text variant="s2">{t('assessmentDetails.dueDate')}</Text>
                  <Tag variant="info" fontSize="0.875rem" lineHeight="1.25rem">
                    {districtAssignment?.endDate &&
                      format(
                        parseISO(districtAssignment?.endDate),
                        'MM/dd/yyyy'
                      )}
                  </Tag>
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          {isAssessmentStarted ? (
            <Flex
              direction="column"
              w={{ base: 'full', md: '50%' }}
              p={8}
              gap={6}
            >
              <ProgressLine
                title={t('assignments.percentCompleted')}
                value={progress?.percentCompleted}
                variant={
                  getThemeStyleValue('blue', 'teal')(currentTheme) as any
                }
              />
              <ProgressLine
                title={t('assignments.percentGraded')}
                value={progress?.percentGraded}
                variant={
                  getThemeStyleValue('green', 'teal')(currentTheme) as any
                }
              />
              <ProgressLine
                title={t('assignments.percentAverage')}
                value={progress?.percentAverage}
                variant={
                  getThemeStyleValue(
                    getGradeProgressVariant(progress?.percentAverage),
                    getAverageLineHighContrastVariant(progress?.percentAverage)
                  )(currentTheme) as any
                }
              />
            </Flex>
          ) : (
            <StartAssessment />
          )}
        </Flex>
      </Collapse>
    </Box>
  );
};

export default Description;
