export const getIconPathForContentPlayer = (activityTime?: string) => {
  const iconName = `${activityTime?.replace(' ', '_')}.png`;
  return activityTime
    ? {
        filePath: `/icons/en/${iconName}`,
        languageFilePath: [
          {
            languageCode: 'en',
            filePath: `/icons/en/${iconName}`,
          },
          {
            languageCode: 'es',
            filePath: `/icons/es/${iconName}`,
          },
        ],
      }
    : {};
};
