import { tableContentConstants, tableContentUtils } from '../../duck';
import { Box, IconButton, Th, Thead, Tr } from '@chakra-ui/react';
import { flexRender } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { Icon } from '@lon/shared/components';
import { useThemeStyleValue } from '@lon/shared/hooks';
import type { THeadProps } from './duck/types';
import styles from './THead.module.css';

const THead = <Data extends object>({
  table,
  enableClientSideSorting,
  enableStickyColumns,
  serverSideSorting,
  bgColor = 'white',
  color = 'primary.800',
  whiteSpace = 'nowrap',
  minW = 'auto',
  enableVerticalBorders,
  useDefaultSortingIcon,
  enableRowSelection,
  fontWeight = 400,
  innerTheadRef,
  py = 2,
  px = 4,
  boxShadow = '0px 2px 3px rgba(43, 54, 70, 0.15)',
  showNoDataHeader,
  ...rest
}: THeadProps<Data>) => {
  const { t } = useTranslation();
  const themeColor = useThemeStyleValue(color, 'white');
  const themeBorderColor = useThemeStyleValue(
    'var(--chakra-colors-secondary-200)',
    'white'
  );
  const themeBgColor = useThemeStyleValue(bgColor, 'secondary.1000');
  const borderBottomWidth = useThemeStyleValue(0, 1);

  return (
    <Thead
      bgColor={themeBgColor}
      ref={innerTheadRef}
      tabIndex={0}
      overflow="hidden"
      {...rest}
    >
      {table.getHeaderGroups().map((headerGroup) => (
        <Tr key={headerGroup.id}>
          {headerGroup.headers.map((header, index) => {
            // TODO: to type this correctly
            const meta: any = header.column.columnDef.meta;
            const rightBorderWidth =
              tableContentUtils.getRightBorderWidth<object>({
                isFirst:
                  headerGroup.headers.length < 2 || enableRowSelection
                    ? index === 0
                    : false,
                enableVerticalBorders,
                isLast: index + 1 === headerGroup.headers.length,
                enableRowSelection,
              });

            return (
              <Th
                width={
                  index === 0 && enableRowSelection
                    ? `${tableContentConstants.CHECKBOX_TH_WIDTH}px`
                    : 'unset'
                }
                paddingInlineStart={4}
                paddingInlineEnd={
                  index + 1 === headerGroup.headers.length ? 8 : 4
                }
                {...(index === 0 && {
                  padding: 0,
                  paddingLeft: enableRowSelection ? 4 : 8,
                  paddingRight: enableRowSelection ? 0.5 : 4,
                })}
                key={header.id}
                onClick={header.column.getToggleSortingHandler()}
                onKeyDown={(event) =>
                  tableContentUtils.handleHeadArrowsNavigation({
                    event,
                    header,
                    index,
                  })
                }
                isNumeric={meta?.isNumeric}
                color={themeColor}
                fontWeight={fontWeight}
                fontSize="0.875rem"
                lineHeight="1.25rem"
                textTransform="unset"
                whiteSpace={whiteSpace}
                h="full"
                minW={minW}
                _focusVisible={{
                  border: '8px solid transparent',
                  outline: '4px solid var(--chakra-colors-blue-600)',
                  zIndex: 10,
                }}
                tabIndex={header.column.getCanSort() ? 0 : -1}
                py={py}
                px={px}
                id={header.id}
                bgColor={themeBgColor}
                border="none"
                boxShadow={`inset -${rightBorderWidth}px -${borderBottomWidth}px ${themeBorderColor}`}
                textAlign={meta?.actionsEnabled ? 'center' : 'left'}
                position="relative"
                letterSpacing="normal"
                scope={index === 0 && enableRowSelection ? undefined : 'col'}
                {...(tableContentUtils.shouldShowVerticalBorders(
                  index,
                  enableVerticalBorders,
                  enableRowSelection,
                  enableStickyColumns
                ) && {
                  _after: {
                    content: `''`,
                    position: 'absolute',
                    right: '-1px',
                    top: 0,
                    width: '1px',
                    height: '100%',
                    backgroundColor: 'secondary.200',
                    zIndex: 1,
                  },
                })}
                {...tableContentUtils.getStickyStyles(
                  header.index,
                  headerGroup.headers.length,
                  enableStickyColumns,
                  enableRowSelection,
                  true
                )}
              >
                <Box
                  as="span"
                  display="block"
                  textAlign={
                    header.id === 'actions'
                      ? 'center'
                      : meta?.headerAlign || 'left'
                  }
                  {...(header.column.id === 'select' && { mb: 1 })}
                  {...(showNoDataHeader && {
                    sx: {
                      svg: {
                        fill: 'secondary.300',
                      },
                    },
                  })}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                  {useDefaultSortingIcon ? (
                    (typeof serverSideSorting !== 'undefined' &&
                      header.column.getCanSort()) ||
                    (enableClientSideSorting && header.column.getCanSort()) ? (
                      header.column.getIsSorted() && !showNoDataHeader ? (
                        header.column.getIsSorted() === 'desc' ? (
                          <IconButton
                            aria-label={t('table.sortDesc')}
                            minW="20px"
                            minH="20px"
                            h="unset"
                            ml={1}
                            variant="ghost"
                            icon={
                              <Icon
                                name="sorting-desc"
                                className={styles.icon}
                              />
                            }
                          />
                        ) : (
                          <IconButton
                            aria-label={t('table.sortAsc')}
                            minW="20px"
                            minH="20px"
                            h="unset"
                            ml={1}
                            variant="ghost"
                            icon={
                              <Icon
                                name="sorting-asc"
                                className={styles.icon}
                              />
                            }
                          />
                        )
                      ) : (
                        <IconButton
                          aria-label={t('table.sortDefault')}
                          minW="20px"
                          minH="20px"
                          h="unset"
                          ml={1}
                          variant="ghost"
                          icon={
                            <Icon
                              name="sorting-default"
                              className={styles.icon}
                            />
                          }
                        />
                      )
                    ) : null
                  ) : null}
                  <Box
                    aria-hidden="true"
                    position="absolute"
                    bottom="-1px"
                    left={0}
                    right={0}
                    height="2px"
                    zIndex={1}
                    boxShadow={boxShadow}
                  />
                </Box>
              </Th>
            );
          })}
        </Tr>
      ))}
    </Thead>
  );
};

export default THead;
