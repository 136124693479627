import {
  AspectRatio,
  Flex,
  Image,
  LinkBox,
  LinkOverlay,
  Tooltip,
} from '@chakra-ui/react';
import clsx from 'clsx';
import { memo, useState } from 'react';
import { Link } from 'react-router-dom';
import { ThemeEnum } from '@lon/shared/enums';
import { useGetTheme } from '@lon/shared/hooks';
import { getThemeStyleValue } from '@lon/shared/utils';
import { LinkCardProps } from './duck';
import styles from './LinkCard.module.css';

const LinkCard: React.FC<LinkCardProps> = ({
  imageUrl,
  plugImageUrl,
  alt = '',
  children,
  to,
  linkContent,
  isStudentBelowK3,
  hint,
  linkRef,
  ...props
}) => {
  const [hasError, setHasError] = useState(false);
  const theme = useGetTheme();

  return (
    <LinkBox
      boxShadow="0px 5px 20px -2px rgba(43, 54, 70, 0.07)"
      display="flex"
      flexDir="column"
      className={clsx(styles.container, {
        [styles.studentK2Container]: isStudentBelowK3,
        [styles.highContrast]: theme === ThemeEnum.HIGH_CONTRAST,
      })}
      {...props}
    >
      <AspectRatio ratio={16 / 9}>
        <Image
          src={
            hasError || !imageUrl
              ? plugImageUrl || 'suit/assets/missing-image.png'
              : imageUrl
          }
          objectFit="cover"
          onError={(err) => {
            if (!hasError) {
              setHasError(true);
            }
          }}
          alt={alt}
        />
      </AspectRatio>
      <Flex className="link-overlay-wrapper" flexDir="column" flex="1">
        <Tooltip variant="dark" label={hint}>
          <LinkOverlay
            _focusVisible={{
              outline: 'none',
              _before: {
                boxShadow: getThemeStyleValue(
                  'focus.blue',
                  'focus.teal'
                )(theme),
              },
            }}
            as={Link}
            aria-label={hint}
            to={to}
            ref={linkRef}
          >
            {linkContent}
          </LinkOverlay>
        </Tooltip>

        {children}
      </Flex>
    </LinkBox>
  );
};

export default memo(LinkCard);
