export const navigatePrev = <
  T extends { disabled: boolean } = { disabled: boolean }
>(
  list: T[],
  startIndex: number
): { item: T; index: number } => {
  for (let i = startIndex - 1; i >= 0; i -= 1) {
    if (list[i] && !list[i].disabled) return { item: list[i], index: i };
  }
  for (let i = list.length - 1; i >= startIndex; i -= 1) {
    if (list[i] && !list[i].disabled) return { item: list[i], index: i };
  }
  return { item: list[startIndex], index: startIndex };
};

export default navigatePrev;
