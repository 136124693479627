import { Icon } from '../icon';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import clsx from 'clsx';
import React from 'react';
import { types } from './duck';
import styles from './NoContent.module.css';

export const NoContent: React.FC<types.NoContentProps> = ({
  containerProps,
  image,
  title,
  description,
  descriptionProps,
  headingProps,
  imageClassName,
  controls,
}) => {
  return (
    <Flex
      direction="column"
      alignItems="center"
      data-testid="no-content"
      {...containerProps}
    >
      <Flex h="auto" maxW="12.5rem" justifyContent="center" alignItems="center">
        <Icon
          name={image}
          className={clsx(styles.image, imageClassName)}
          size="large"
        />
      </Flex>
      {(title || description) && (
        <Box maxW="22.5rem" textAlign="center">
          {title && (
            <Heading mt={6} fontWeight={700} fontSize="xl" {...headingProps}>
              {title}
            </Heading>
          )}
          {description && (
            <Text
              mt={2}
              color="secondary.700"
              fontSize="xs"
              {...descriptionProps}
            >
              {description}
            </Text>
          )}
        </Box>
      )}
      {controls && <Box mt={8}>{controls}</Box>}
    </Flex>
  );
};

export default NoContent;
