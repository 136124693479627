import { MockedProvider } from '@apollo/client/testing';
import { ChakraProvider } from '@chakra-ui/react';
import { RenderOptions, render } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import { createMemoryHistory } from 'history';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { WorkingLocation } from '@lon/shared/contexts';
import { ApplicationEnum } from '@lon/shared/enums';
import { initTranslationService } from '@lon/suit/configs';
import { persistor, store } from '@lon/shared/configs';
import { types } from './duck';

initTranslationService();

export const Wrapper: React.FC<types.WrapperProps> = ({
  children,
  mocks,
  history,
  application = ApplicationEnum.TEACHER_SUIT,
}) => {
  return (
    <Router location={history.location} navigator={history}>
      <MockedProvider mocks={mocks} addTypename={false}>
        <HelmetProvider>
          <ChakraProvider>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <WorkingLocation.Provider
                  value={{
                    currentSchoolId: '1',
                    setCurrentSchoolId: () => {},
                    permissions: [],
                    schoolIds: [],
                    application,
                  }}
                >
                  {children}
                </WorkingLocation.Provider>
              </PersistGate>
            </Provider>
          </ChakraProvider>
        </HelmetProvider>
      </MockedProvider>
    </Router>
  );
};

export const renderWithProvider = (
  ui: JSX.Element,
  options?: RenderOptions & {
    mocks?: any[];
    application?: types.WrapperProps['application'];
  }
) => {
  const history = createMemoryHistory();
  const { application, ...renderOptions } = options || {};

  return {
    user: userEvent.setup(),
    history,
    ...render(ui, {
      wrapper: (props) => (
        <Wrapper
          {...props}
          history={history}
          mocks={renderOptions?.mocks}
          application={application}
        />
      ),
      ...renderOptions,
    }),
  };
};

export * from '@testing-library/react';

export { renderWithProvider as render };
