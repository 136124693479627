import { useContext } from 'react';
import { AclContext } from '@lon/shared/contexts';
import { BehaviourEnum } from '@lon/shared/gql/types';
import { BehaviourExtraEnum } from '@lon/shared/types';
import { Option } from './duck/types';

export const usePermission = <Result = boolean>(opts?: Option[]): Result[] => {
  const { value: aclApplications } = useContext(AclContext);

  return (
    opts?.reduce<Result[]>((acc, option) => {
      const {
        application,
        permission,
        module,
        operation,
        schoolId,
        field = 'exist',
        skip = false,
        defaultPermission = false,
      } = option || {};

      if (skip) {
        return [...acc, BehaviourExtraEnum.Skip as Result];
      }
      if (defaultPermission) {
        acc.push(true as Result);
        return acc;
      }

      if (!module && operation) {
        throw new Error('Operation cannot be passed without the module');
      }
      if (!application && !permission && module) {
        throw new Error(
          'Module cannot be passed without the application or permission'
        );
      }
      if (!application && schoolId) {
        throw new Error('SchoolId cannot be passed without the application');
      }
      // Check if application exists
      if (application && !module && !operation) {
        const applicationExist = aclApplications.some(
          (aclApplication) => aclApplication.name === application
        );
        acc.push(!!applicationExist as Result);

        return acc;
      }

      // Check if module without school exists
      if (application && module && !schoolId && !operation) {
        const selectedApplications = aclApplications.filter(
          (aclApplication) => aclApplication.name === application
        );

        const moduleExist = selectedApplications.some((aclApp) =>
          aclApp.modules.some((aclModule) => aclModule.name === module)
        );

        acc.push(!!moduleExist as Result);

        return acc;
      }

      // Check if module with school exists
      if (application && module && schoolId && !operation) {
        const selectedApplication = aclApplications.find(
          (aclApplication) => aclApplication.name === application
        );
        const selectedSchool = selectedApplication?.schools.find((aclSchool) =>
          aclSchool.schoolIds.includes(schoolId)
        );

        const moduleExist = selectedSchool?.modules.some(
          (aclModule) => aclModule.name === module
        );

        acc.push(!!moduleExist as Result);

        return acc;
      }

      // Check if operation without school exists
      if (application && module && !schoolId && operation) {
        const selectedApplication = aclApplications.find(
          (aclApplication) => aclApplication.name === application
        );
        const selectedModule = selectedApplication?.modules.find(
          (aclModule) => aclModule.name === module
        );

        if (field === 'exist') {
          const behaviour = selectedModule?.operations.find(
            (aclOperation) => aclOperation.name === operation
          )?.behaviour;

          acc.push((behaviour === BehaviourEnum.Display || false) as Result);
        }

        if (field === 'behaviour') {
          const behaviour = selectedModule?.operations.find(
            (aclOperation) => aclOperation.name === operation
          )?.behaviour;

          acc.push((behaviour || BehaviourEnum.Hidden) as Result);
        }

        return acc;
      }

      // Check if operation without school exists
      if (application && module && schoolId && operation) {
        const selectedApplication = aclApplications.find(
          (aclApplication) => aclApplication.name === application
        );
        const selectedSchool = selectedApplication?.schools.find((aclSchool) =>
          aclSchool.schoolIds.includes(schoolId)
        );
        const selectedModule = selectedSchool?.modules.find(
          (aclModule) => aclModule.name === module
        );

        if (field === 'exist') {
          const behaviour = selectedModule?.operations.find(
            (aclOperation) => aclOperation.name === operation
          )?.behaviour;

          acc.push((behaviour === BehaviourEnum.Display || false) as Result);
        }

        if (field === 'behaviour') {
          const behaviour = selectedModule?.operations.find(
            (aclOperation) => aclOperation.name === operation
          )?.behaviour;

          acc.push((behaviour || BehaviourEnum.Hidden) as Result);
        }

        return acc;
      }

      // Check if permission exists
      if (permission && module && operation) {
        const selectedPermissions = aclApplications.find(
          (aclApplication) => aclApplication.permissionName === permission
        );

        const selectedModule = selectedPermissions?.modules.find(
          (aclModule) => aclModule.name === module
        );

        if (field === 'exist') {
          const op = selectedModule?.operations.find(
            (aclOperation) => aclOperation.name === operation
          );

          acc.push(
            (op?.behaviour === BehaviourEnum.Display || false) as Result
          );
        }

        if (field === 'behaviour') {
          const op = selectedModule?.operations.find(
            (aclOperation) => aclOperation.name === operation
          );

          if (!op) {
            acc.push(BehaviourExtraEnum.Skip as Result);
          } else {
            acc.push(
              (op.behaviour === BehaviourEnum.Display
                ? BehaviourEnum.Display
                : BehaviourEnum.Hidden) as Result
            );
          }
        }

        return acc;
      }

      acc.push(false as Result);

      return acc;
    }, []) || []
  );
};
