import React, { useContext, useEffect } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { getQuery } from '@lon/api';
import { Loader } from '@lon/shared/components';
import { WorkingLocation } from '@lon/shared/contexts';
import { ProfileTypeEnum } from '@lon/shared/enums';
import { useAuth, useQueryParams } from '@lon/shared/hooks';
import {
  StemscopesAssignment,
  useOpenLtiAssignmentLinkMutation,
} from '@lon/shared/requests';
import { routes } from '@lon/shared/configs';
import { studentView } from '@lon/shared/constants';
import { ScopeContext } from '@lon/shared/contexts';
import { useCurrentElement } from '@lon/shared/hooks';

const DetermineUserType = () => {
  const [auth] = useAuth();
  const [queryParams] = useQueryParams();
  // @ts-ignore
  const currentElement = useCurrentElement() as JsonElementStructure;
  const assignmentId = (queryParams.get('sa_pk') || '') as string;
  const ownerId = (queryParams.get('ownerId') || '') as string;
  const scope = useContext(ScopeContext);
  const [openAssignment, { called }] = useOpenLtiAssignmentLinkMutation({
    onCompleted: (data) => {
      const response = data?.openLtiAssignmentLink as StemscopesAssignment;
      navigate(
        generatePath(routes.lti.studentAssignment, {
          assignmentId: response?.assignmentId,
          classId: response?.classId,
        })
      );
    },
  });
  const { currentSchoolId } = useContext(WorkingLocation);

  const params = useParams<{
    scopeId: string;
    sectionId: string;
    projectId: string;
    elementId: string;
  }>();
  const navigate = useNavigate();

  const currentStudentView = currentElement?.studentView?.find(
    // @ts-ignore
    (view) => view.identifier === queryParams.get('studentElementId')
  );

  const getReference = (): string =>
    currentStudentView?.lorResources?.find(
      // @ts-ignore
      (loadResource) => loadResource?.type === 'activities'
    )?.referenceId || '';

  useEffect(() => {
    if (
      auth &&
      [
        ProfileTypeEnum.TEACHER,
        ProfileTypeEnum.DISTRICT_CURRICULUM_LEADER,
        ProfileTypeEnum.CAMPUS_CURRICULUM_LEADER,
        ProfileTypeEnum.CAMPUS_LEADER,
      ].includes(auth.profileType!)
    ) {
      navigate(
        `${generatePath(routes.lti.previewAssignment, {
          elementId: params.elementId || 0,
          projectId: params.projectId || 0,
          scopeId: params.scopeId || 0,
          sectionId: params.sectionId || 0,
        })}?${getQuery({
          ...queryParams,
          type: studentView,
        })}`
      );
    } else if (
      auth.profileType === ProfileTypeEnum.STUDENT &&
      currentElement &&
      !called
    ) {
      openAssignment({
        variables: {
          curriculumArea: scope.original?.metadata?.curriculumArea || '',
          gradeLevel: scope.original?.metadata?.gradeLevel || '',
          assignmentParams: {
            ltiPutAssignmentInput: {
              learnosityReferenceId: getReference(),
              schoolId: currentSchoolId,
              name: `${currentElement?.metadata?.scopeDisplayName} - ${currentElement?.metadata?.elementName}`,
              assignmentSource: {
                element: {
                  elementId: params.elementId!,
                  projectId: params.projectId!,
                  scopeId: params.scopeId!,
                  sectionId: params.sectionId!,
                  scopeName: currentElement?.metadata?.scopeDisplayName ?? '',
                  sectionName: currentElement?.metadata?.sectionTitle ?? '',
                  elementName: currentElement?.metadata?.elementName ?? '',
                  viewId: currentStudentView?.identifier ?? '',
                },
              },
            },
          },
          assignmentId,
          ownerId,
        },
      }).catch(console.log);
    }
  }, [currentElement, called]);

  return <Loader />;
};

export default DetermineUserType;
