import { NotFound } from '../../../errors';
import { Button, Flex, Text } from '@chakra-ui/react';
import { isEmpty } from 'lodash-es';
import React, { useContext, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { routes } from '@lon/shared/configs';
import { WorkingLocation } from '@lon/shared/contexts';
import {
  useAuth,
  useGetSchoolIdsForPlanner as useGetSchoolIds,
  usePrevious,
  useSchoolContentGroups,
} from '@lon/shared/hooks';
import { useGetScope2Query } from '@lon/shared/requests';
import {
  ContentFilterer,
  filterStandards,
  transformElements,
} from '@lon/shared/utils';
import { scopeConstants, scopeHooks } from './duck';
import { ScopeView, ScopeAccessDenied } from './components';
import { ScopeType } from '@lon/shared/types';
import { Icon } from '../../../icon';

export const Scope: React.FC = () => {
  const { t } = useTranslation();
  const [auth] = useAuth();
  const [attempts, setAttempts] = useState<number>(0);
  const prevAttempts = usePrevious(attempts);
  const { data: standardsJson } = scopeHooks.useFetchJsonData({
    url: scopeConstants.standardsUrl,
    options: {},
  });
  const state = auth?.user?.state;
  const accessibleSchoolIds = useGetSchoolIds();
  const { currentSchoolId } = useContext(WorkingLocation);
  const params = useParams();
  const { scopeId, projectId } = params as {
    scopeId: string;
    elementId: string;
    sectionId: string;
    projectId: string;
  };

  const {
    loading: scopeUrlLoading,
    data: scopeUrlData,
    error,
    refetch,
    called,
  } = useGetScope2Query({
    variables: {
      schoolId: currentSchoolId as string,
      id: scopeId,
    },
    fetchPolicy: 'network-only',
    skip: !currentSchoolId || !scopeId,
  });

  const scopeUrl = scopeUrlData?.baseScope?.jsonUrl;
  const { contentGroups, loading: contentGroupsLoading } =
    useSchoolContentGroups(accessibleSchoolIds || undefined);

  const {
    data,
    loading: scopeLoading,
    called: scopeCalled,
  } = scopeHooks.useFetchJsonData({
    url: scopeUrl as string,
    options: { credentials: 'include' },
  });

  const loading =
    scopeUrlLoading ||
    scopeLoading ||
    (!scopeCalled && !!scopeUrl) ||
    contentGroupsLoading;

  const scope = useMemo(() => {
    return data
      ? new ContentFilterer(
          { ...data, id: scopeId },
          contentGroups
        ).getFilteredContent()
      : {};
  }, [data, contentGroups]);

  const isFactFluency = scope?.projectType === ScopeType.FactFluency;

  const { sections, elementsBySection } = React.useMemo(
    () => transformElements(scope as any, standardsJson, state, isFactFluency),
    [scope, standardsJson, state]
  );

  const scopeStandards = React.useMemo(() => {
    return filterStandards(scope?.taxonomy, standardsJson, state);
  }, [scope, standardsJson, state]);

  if (
    !loading &&
    (!scope ||
      isEmpty(scope) ||
      scopeUrlData?.baseScope?.projectId !== projectId)
  ) {
    const isAccessDenied = error?.graphQLErrors?.some(
      (errorJson) => errorJson?.message === 'Access Denied.'
    );

    if (isAccessDenied) {
      return <ScopeAccessDenied />;
    }

    try {
      const scope404 = JSON.parse(
        window.sessionStorage.getItem('scope-404') || '[]'
      );
      const alreadyTriedToReload = scope404?.includes(scopeId);

      if (!alreadyTriedToReload) {
        window.sessionStorage.setItem(
          'scope-404',
          JSON.stringify([...scope404, scopeId])
        );
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }

    return (
      <NotFound
        withoutSidebar
        title={t('scope.notFoundTitle')}
        description={t('scope.notFoundMessage')}
        headerTitle={t('scope.notFoundHeader')}
        actions={
          <Flex
            justify="space-between"
            direction={{ base: 'column', md: 'row' }}
            w="full"
            gap={4}
            maxW="450px"
          >
            <Button
              as={Link}
              to={routes.home}
              title={t('errorMessage.goToHome')}
              variant="outline"
              leftIcon={<Icon name="arr-double-left-outlined" />}
            >
              <Text as="span" isTruncated>
                {t('errorMessage.goToHome')}
              </Text>
            </Button>
            <Button
              as={Link}
              to={routes.scopes.root}
              title={t('scope.viewScopeListButton')}
              variant="solid"
              rightIcon={<Icon name="arr-double-right-outlined" />}
            >
              <Text as="span" isTruncated>
                {t('scope.viewScopeListButton')}
              </Text>
            </Button>
          </Flex>
        }
      />
    );
  }

  return (
    <>
      <ScopeView
        loading={loading}
        elementsBySection={elementsBySection}
        refetch={refetch}
        attempts={prevAttempts}
        setAttempts={setAttempts}
        projectType={scope?.projectType}
        called={called}
        metadata={scope?.metadata}
        scope={scope}
        scopeStandards={scopeStandards}
        sections={sections}
      />
    </>
  );
};

export default Scope;
