import { ElementContent } from '../../../../../../../element-content';
import { ErrorMessage } from '../../../../../../../errors';
import { Icon } from '../../../../../../../icon';
import { Toolbar } from '../../../../../../../toolbar';
import { FullScreenButton } from '../full-screen-button';
import { Box, Flex, IconButton, Text } from '@chakra-ui/react';
import { isEmpty } from 'lodash-es';
import React, {
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { isDevMode, showAnswers, teacherView } from '@lon/shared/constants';
import { FullScreenContext, ScopeContext } from '@lon/shared/contexts';
import { useAuth, useIpadSafari, useQueryParams } from '@lon/shared/hooks';
import { ScopeType, ViewType } from '@lon/shared/types';
import { changeIdForMenus, checkContentPlayer } from '@lon/shared/utils';
import BaseBreadcrumb from './components/breadcrumbs/BaseBreadcrumb';
import { landingPage, types } from './duck';

const Main: React.FC<types.MainProps> = ({
  element,
  className,
  refetch,
  setAttempts,
  attempts,
  projectType,
  hasToolbar,
}) => {
  const [{ user }] = useAuth();
  const { t } = useTranslation();
  const [params] = useQueryParams();
  const isContentPlayer = checkContentPlayer();
  const { scopeId, projectId } = useParams() as {
    scopeId: string;
    projectId: string;
  };
  const iframeRef = useRef<HTMLIFrameElement>();
  const { metadata } = useContext(ScopeContext);
  const { isFullScreen } = useContext(FullScreenContext);
  const [canScrollUp, setCanScrollUp] = useState(false);
  const [canScrollDown, setCanScrollDown] = useState(false);
  const [scrollInterval, setScrollInterval] = useState<NodeJS.Timeout | null>(
    null
  );
  const isIpadSafari = useIpadSafari();
  const [isKahootElement, setIsKahootElement] = useState(false);

  const viewType: ViewType = params.get('type', teacherView);
  const answers = params.get('answers');
  const tts = params.get('tts');
  const studentElementId = params.get('studentElementId');
  const studentElement = studentElementId
    ? element.studentView.find((view) => view.identifier === studentElementId)
    : element.studentView?.[0] || {
        taxonomy: [],
        href: '',
        metadata: { activityTitle: '', hide: '' },
      };

  const currentView =
    viewType === teacherView ? element?.[viewType] : studentElement;
  const voiceSettings = localStorage.getItem('voicePreferences');
  const elementStandards = currentView?.taxonomy;
  const activityTitle = currentView?.metadata?.activityTitle;
  const isFactFluency = projectType === ScopeType.FactFluency;
  const isDailyNumeracy = projectType === ScopeType.DailyNumeracy;
  const isLandingPage = element.metadata?.sectionType === landingPage;
  const toolbarTitle = `${element?.metadata?.elementName || ''}${
    activityTitle ? ': ' + activityTitle : ''
  }`;

  const imageTag = process.env?.['NX_IMAGE_TAG'] || 1;
  const href = currentView?.href;
  const contentUrl = isContentPlayer
    ? href?.split('?')[0]
    : `${href}${href?.includes('?') ? '&' : '?'}userType=${user?.type}&userId=${
        user?.userId
      }&v=${imageTag}`;
  const shouldHidePage = currentView?.metadata?.hide === 'Yes';
  const shouldDisplayToolbar = !isFullScreen && !isLandingPage && hasToolbar;

  const printFile = () => {
    const elementIcon = element?.metadata?.elementIconType || '';
    const elementName = element?.metadata?.elementName || '';
    const pageOrientation = currentView?.metadata?.page_orientation || '';
    const nameDateLine = currentView?.metadata?.nameDateLine || 'Yes';
    const editable = !studentElementId; // download button hidden on student page

    const printParams = `&mode=print&printType=manual&editable=${editable}&pageOrientation=${pageOrientation}&nameDateLine-${nameDateLine}&projectId=${projectId}&scopeId=${scopeId}&scopeName=${
      metadata?.displayName
    }&elementName=${elementName}&elementIconType=${elementIcon}&answers=${
      answers || showAnswers
    }&type=local_practice&hasPrint=true`;

    const encodedContentURL = encodeURIComponent(
      `${contentUrl}?isContentPlayer=true${printParams}`
    );
    const contentPlayerBasePath = isDevMode() ? '/public' : '/content-player';

    const urlToOpen = !isContentPlayer
      ? `${contentUrl}${printParams}`
      : `${contentPlayerBasePath}/epub-content-player.html?url=${encodedContentURL}`;
    window.open(urlToOpen, '_blank');
  };

  useEffect(() => {
    const handleScroll = () => {
      const parent = document.querySelector('.page-content');
      if (parent) {
        setCanScrollUp(parent.scrollTop > 0);
        setCanScrollDown(
          parent.scrollTop + parent.clientHeight < parent.scrollHeight
        );
      }
    };

    handleScroll();

    const parent = document.querySelector('.page-content');
    if (parent) {
      parent.addEventListener('scroll', handleScroll);
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (parent) {
        parent.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isFullScreen, contentUrl, shouldHidePage]);

  const handleScrollUp = () => {
    const parent = document.querySelector('.page-content');
    if (parent) {
      parent.scrollTo({
        top: parent.scrollTop - 50,
        behavior: 'smooth',
      });
    }
  };

  const handleScrollDown = () => {
    const parent = document.querySelector('.page-content');
    if (parent) {
      parent.scrollTo({
        top: parent.scrollTop + 50,
        behavior: 'smooth',
      });
    }
  };

  const handleScrollUpContinuous = () => {
    const parent = document.querySelector('.page-content');
    if (scrollInterval) clearInterval(scrollInterval);

    if (parent && parent.scrollTop > 0) {
      const interval = setInterval(() => {
        if (parent.scrollTop > 0) {
          parent.scrollBy({ top: -100, behavior: 'smooth' });
        } else {
          clearInterval(interval);
        }
      }, 100);
      setScrollInterval(interval);
    }
  };

  const handleScrollDownContinuous = () => {
    const parent = document.querySelector('.page-content');
    if (scrollInterval) clearInterval(scrollInterval);

    if (
      parent &&
      parent.scrollTop + parent.clientHeight < parent.scrollHeight
    ) {
      const interval = setInterval(() => {
        if (parent.scrollTop + parent.clientHeight < parent.scrollHeight) {
          parent.scrollBy({ top: 100, behavior: 'smooth' });
        } else {
          clearInterval(interval);
        }
      }, 100);
      setScrollInterval(interval);
    }
  };

  const handleMouseDownUp = () => {
    handleScrollUpContinuous();
  };

  const handleMouseDownDown = () => {
    handleScrollDownContinuous();
  };

  const handleMouseUp = () => {
    if (scrollInterval) clearInterval(scrollInterval);
    setScrollInterval(null);
  };

  useEffect(() => {
    if (!contentUrl || shouldHidePage) {
      return;
    }
    const imageTag = process.env?.['NX_IMAGE_TAG'] || 1;

    const elementUrl = isContentPlayer
      ? contentUrl
      : `${contentUrl}&scopeId=${scopeId}&type=local_practice&v=${imageTag}`;

    const controller = new AbortController();

    const fetchIframe = async (controller: AbortController) => {
      try {
        // for local iframe update fetch url to "/public/test.html"
        const response = await fetch(elementUrl, {
          credentials: 'include',
          signal: controller.signal,
        });

        if (response.status === 401) {
          if (attempts < 3) {
            setAttempts(attempts + 1);
            refetch();
          }
        } else {
          if (iframeRef?.current) {
            iframeRef.current.src = elementUrl;
          }
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetchIframe(controller);

    return () => {
      controller.abort();
    };
  }, [attempts, contentUrl, shouldHidePage, answers, tts, voiceSettings]);

  const ToolbarPortal = () => {
    const mount = document.getElementById('outer-elements-portal');

    useEffect(() => {
      if (iframeRef?.current && !isKahootElement) {
        const kahootElement =
          iframeRef.current.contentDocument?.querySelector(`[src*="kahoot"]`);

        if (kahootElement) {
          setIsKahootElement(true);
        }
      }

      if (mount) {
        changeIdForMenus(mount);
      }
    }, []);

    return ReactDOM.createPortal(
      <Toolbar
        title={projectType === ScopeType.Scope ? toolbarTitle : ''}
        standards={elementStandards}
        printMethod={printFile}
        onViewTypeChangeComplete={() => {
          const content = document.querySelector('.page-content');
          content?.scrollTo(0, 0);
        }}
        hasStudentView={!isEmpty(element.studentView)}
        iframeRef={iframeRef as RefObject<HTMLIFrameElement>}
        isKahootElement={isKahootElement}
      />,
      mount as Element
    ) as unknown as React.ReactElement;
  };

  return (
    <Box
      className={className}
      flex="1"
      role="tabpanel"
      id="scope-element"
      aria-labelledby={element?.identifier}
    >
      {shouldHidePage ? (
        <ErrorMessage
          body={
            <Text as="p" variant="n3" pb="12">
              {t('errorMessage.404Body')}
            </Text>
          }
        />
      ) : (
        <Box pb={6} height="full">
          {/* @ts-ignore */}
          {shouldDisplayToolbar && <ToolbarPortal />}
          <BaseBreadcrumb element={element} projectType={projectType} />
          <Flex flexDirection="column" backgroundColor="white" height="full">
            <Flex
              position="sticky"
              top={0}
              zIndex={1}
              backgroundColor="white"
              justifyContent="flex-end"
            >
              <FullScreenButton
                showViewAllButton={!isFactFluency && !isDailyNumeracy}
              />
            </Flex>
            {isFullScreen && isIpadSafari && (
              <>
                <IconButton
                  position="fixed"
                  bottom="54%"
                  right={10}
                  zIndex={1}
                  isDisabled={!canScrollUp}
                  aria-label="scroll-up"
                  icon={<Icon name="arrow-up" />}
                  onClick={handleScrollUp}
                  onMouseDown={handleMouseDownUp}
                  onMouseUp={handleMouseUp}
                  onTouchStart={handleMouseDownUp}
                  onTouchEnd={handleMouseUp}
                  sx={{
                    _hover: {
                      bg: 'transparent',
                      color: 'inherit',
                    },
                    _focus: {
                      boxShadow: 'none',
                    },
                    _active: {
                      bg: 'transparent',
                    },
                  }}
                />
                <IconButton
                  position="fixed"
                  bottom="42%"
                  right={10}
                  zIndex={1}
                  isDisabled={!canScrollDown}
                  aria-label="scroll-down"
                  icon={<Icon name="arrow-down" />}
                  onClick={handleScrollDown}
                  onMouseDown={handleMouseDownDown}
                  onMouseUp={handleMouseUp}
                  onTouchStart={handleMouseDownDown}
                  onTouchEnd={handleMouseUp}
                  sx={{
                    _hover: {
                      bg: 'transparent',
                      color: 'inherit',
                    },
                    _focus: {
                      boxShadow: 'none',
                    },
                    _active: {
                      bg: 'transparent',
                    },
                  }}
                />
              </>
            )}
            <Box px={{ base: 4, md: 6, lg: 8 }}>
              <ElementContent
                element={element}
                iframeRef={iframeRef as RefObject<HTMLIFrameElement>}
                src={contentUrl}
              />
            </Box>
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export default Main;
