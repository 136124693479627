import { Flex, HStack, Text, VStack } from '@chakra-ui/react';
import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { WorkingLocation } from '@lon/shared/contexts';
import { ApplicationEnum } from '@lon/shared/enums';
import { useAuth } from '@lon/shared/hooks';

const UserInfo: React.FC<{
  isStudentBelowK3?: boolean;
}> = ({ isStudentBelowK3 }) => {
  const { application } = useContext(WorkingLocation);
  const isNotStudentSuite = application !== ApplicationEnum.STUDENT_SUIT;
  const [{ user }] = useAuth();
  const { t } = useTranslation();

  return (
    <Flex justify="space-between" px={{ base: 6, lg: '150px' }}>
      <VStack align="left">
        <Text
          variant="h6"
          fontWeight={isStudentBelowK3 ? 600 : 400}
          lineHeight={isStudentBelowK3 ? '1.5rem' : '1.625rem'}
        >
          {t('userSettings.credentials.aboutYou')?.toUpperCase()}
        </Text>
        <Flex
          gap={{ base: 2, md: 16 }}
          direction={{ base: 'column', md: 'row' }}
        >
          <HStack gap={3}>
            <VStack align="left">
              <Text
                variant="n1"
                fontWeight={
                  isNotStudentSuite ? 700 : isStudentBelowK3 ? 600 : 400
                }
                lineHeight={isStudentBelowK3 ? '1.5rem' : '1.625rem'}
              >
                {t('userSettings.credentials.firstName')}
              </Text>
              <Text
                variant="n1"
                fontWeight={
                  isNotStudentSuite ? 700 : isStudentBelowK3 ? 600 : 400
                }
                lineHeight={isStudentBelowK3 ? '1.5rem' : '1.625rem'}
              >
                {t('userSettings.credentials.lastName')}
              </Text>
            </VStack>
            <VStack align="left">
              <Text
                variant="n3"
                fontWeight={isStudentBelowK3 ? 600 : 400}
                lineHeight={isStudentBelowK3 ? '1.5rem' : '1.625rem'}
                className="notranslate"
              >
                {user.firstName}
              </Text>
              <Text
                variant="n3"
                fontWeight={isStudentBelowK3 ? 600 : 400}
                lineHeight={isStudentBelowK3 ? '1.5rem' : '1.625rem'}
                className="notranslate"
              >
                {user.lastName}
              </Text>
            </VStack>
          </HStack>
          <HStack gap={3}>
            <VStack align="left">
              <Text
                variant="n1"
                fontWeight={
                  isNotStudentSuite ? 700 : isStudentBelowK3 ? 600 : 400
                }
                lineHeight={isStudentBelowK3 ? '1.5rem' : '1.625rem'}
              >
                {t('userSettings.credentials.username')}
              </Text>
              <Text
                variant="n1"
                fontWeight={
                  isNotStudentSuite ? 700 : isStudentBelowK3 ? 600 : 400
                }
                lineHeight={isStudentBelowK3 ? '1.5rem' : '1.625rem'}
              >
                {t('userSettings.credentials.email')}
              </Text>
            </VStack>
            <VStack align="left">
              <Text
                variant="n3"
                fontWeight={isStudentBelowK3 ? 600 : 400}
                lineHeight={isStudentBelowK3 ? '1.5rem' : '1.625rem'}
                className="notranslate"
              >
                {user.username ?? '-'}
              </Text>
              <Text
                variant="n3"
                fontWeight={isStudentBelowK3 ? 600 : 400}
                lineHeight={isStudentBelowK3 ? '1.5rem' : '1.625rem'}
                color={user.email ? 'primary.800' : 'primary.400'}
                className="notranslate"
              >
                {user.email || t('userSettings.credentials.emptyEmail')}
              </Text>
            </VStack>
          </HStack>
        </Flex>
      </VStack>
    </Flex>
  );
};

export default UserInfo;
