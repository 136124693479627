import {
  Box,
  Button,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { uniq } from 'lodash-es';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { BackButton, Icon, Link, PageContent } from '@lon/shared/components';
import { routes } from '@lon/shared/configs';
import { Table } from '@lon/shared/modules/table';
import {
  DaAssignType,
  useGetBaseDistrictAssignmentsQuery,
} from '@lon/shared/requests';
import { DistrictReport, DistrictReportAssessment } from './duck';
import { Filter, MoreDetails, NoReport } from './components';

const DistrictAssessmentsReports = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenMoreDetails,
    onOpen: onOpenMoreDetails,
    onClose: onCloseMoreDetails,
  } = useDisclosure();
  const [assignmentDetails, setAssignmentDetails] = useState<DistrictReport>();

  const { data, loading } = useGetBaseDistrictAssignmentsQuery();

  const assigneesFilter = (assessment: DistrictReportAssessment) => {
    let assigneeData: Array<string> = [];
    if (
      assessment.assignType &&
      assessment.assignType === DaAssignType.GradeLevEl
    ) {
      assessment.schoolGradeLevels?.forEach(
        (school) =>
          (assigneeData = [...assigneeData, ...(school.gradeLevels ?? [])])
      );
      return uniq(assigneeData);
    } else if (
      assessment.assignType &&
      assessment.assignType === DaAssignType.School
    ) {
      assessment.schoolGradeLevels?.forEach((school) =>
        assigneeData.push(school.schoolId ?? '')
      );
      return uniq(assigneeData);
    } else {
      return [];
    }
  };

  const tableData: DistrictReport[] = useMemo(() => {
    if (data?.baseDistrictAssignments?.collection?.length) {
      return data.baseDistrictAssignments.collection.map((assessment) => ({
        assessment: assessment.name ?? '',
        start: assessment.startDate ?? '',
        end: assessment.endDate ?? '',
        actions: {
          id: assessment.daId ?? '',
        },
        type: assessment.assignType
          ? assessment.assignType === DaAssignType.GradeLevEl
            ? 'Grade Levels'
            : 'Schools'
          : '',
        assignees: assigneesFilter(assessment),
      }));
    }
    return [];
  }, [data]);

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<DistrictReport>();

    return [
      columnHelper.accessor('assessment', {
        cell: (info) => {
          return (
            <Link
              tabIndex={-1}
              to={generatePath(
                routes.teachers.reports.districtAssessmentDetails,
                {
                  daId: info.row.original.actions.id,
                }
              )}
            >
              <Text
                as="span"
                variant="s2"
                color="primary.800"
                textDecoration="underline"
                _hover={{ color: '#00709D' }}
              >
                {info.row.original.assessment}
              </Text>
            </Link>
          );
        },
        header: t(
          'reportsDashboard.districtAssessmentsReports.assessments'
        ) as string,
        sortingFn: (a, b) => {
          return a.original.assessment.localeCompare(
            b.original.assessment,
            'en',
            { numeric: true }
          );
        },
      }),
      columnHelper.accessor('start', {
        cell: (info) => (
          <Text as="span" variant="s2">
            {info.getValue()}
          </Text>
        ),
        header: t(
          'reportsDashboard.districtAssessmentsReports.startDate'
        ) as string,
        sortDescFirst: true,
      }),
      columnHelper.accessor('end', {
        cell: (info) => (
          <Text as="span" variant="s2">
            {info.getValue()}
          </Text>
        ),
        header: t(
          'reportsDashboard.districtAssessmentsReports.endDate'
        ) as string,
        sortDescFirst: true,
      }),
      columnHelper.accessor('type', {
        cell: (info) => (
          <Text as="span" variant="s2">
            {info.getValue()}
          </Text>
        ),
        header: t(
          'reportsDashboard.districtAssessmentsReports.assigneeType'
        ) as string,
        enableSorting: false,
      }),
      columnHelper.accessor('assignees', {
        cell: (info) =>
          info.getValue() && (
            <Flex gap={2} wrap="wrap">
              {info
                .getValue()
                ?.slice(0, 3)
                .map((assignee) => (
                  <Tag
                    variant="secondary"
                    bg="primary.30"
                    px={3}
                    borderRadius="xl"
                    key={assignee}
                    w="auto"
                    color="primary.700"
                  >
                    {assignee}
                  </Tag>
                ))}
              {Number(info?.getValue()?.length) - 3 > 0 && (
                <Tag
                  px={3}
                  colorScheme={undefined}
                  fontWeight="normal"
                  w="auto"
                  onClick={() => {
                    onOpenMoreDetails();
                    setAssignmentDetails(info.row.original);
                  }}
                  cursor="pointer"
                  textColor="primary.700"
                >
                  <Icon name="eyeOutlined" size="sm" />
                  <Text
                    ml={2}
                    mt="2px"
                    variant="s2"
                    color="primary.700"
                    fontSize="14px"
                  >
                    +{Number(info?.getValue()?.length) - 3} more
                  </Text>
                </Tag>
              )}
            </Flex>
          ),
        enableSorting: false,
        header: t(
          'reportsDashboard.districtAssessmentsReports.assignee'
        ) as string,
      }),
      columnHelper.accessor('actions', {
        cell: (info) => (
          <Flex gap={2} wrap="wrap">
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Reports"
                color="gray.300"
                fontWeight="semibold"
                w="85px"
                p={0}
                _active={{
                  color: 'white',
                  bgColor: 'transparent',
                }}
                _hover={{
                  bgColor: 'transparent',
                }}
                textDecoration="none"
                fontSize={{ md: 'sm', lg: 'md' }}
                variant="ghost"
                icon={<Icon name="more-outlined" />}
              />
              <MenuList data-focus-visible-disabled>
                <MenuItem
                  p="0"
                  display="none" // TODO remove when settings functionality is developed
                >
                  <Tooltip
                    variant="dark"
                    label={t(
                      'reportsDashboard.districtAssessmentsReports.settingsTooltip'
                    )}
                  >
                    <Flex flex="1" gap={3.5} py="2" px="6">
                      <Icon name="settingsOutlined" size="xl" />
                      <Text variant="s2">
                        {t(
                          'reportsDashboard.districtAssessmentsReports.settings'
                        )}
                      </Text>
                    </Flex>
                  </Tooltip>
                </MenuItem>
                <MenuItem p="0">
                  <Tooltip
                    variant="dark"
                    label={t(
                      'reportsDashboard.districtAssessmentsReports.viewResultsTooltip'
                    )}
                  >
                    <Flex
                      flex="1"
                      gap={3.5}
                      py="2"
                      px="6"
                      as={Link}
                      to={generatePath(
                        routes.teachers.reports.districtAssessmentDetails,
                        {
                          daId: info.row.original.actions.id,
                        }
                      )}
                    >
                      <Icon name="gradebook" size="xl" />
                      <Text variant="s2" color="primary.700">
                        {t(
                          'reportsDashboard.districtAssessmentsReports.viewResults'
                        )}
                      </Text>
                    </Flex>
                  </Tooltip>
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        ),
        enableSorting: false,
        header: t(
          'reportsDashboard.districtAssessmentsReports.actions'
        ) as string,
      }),
    ];
  }, [data]);

  return (
    <PageContent
      pageTitle={t('reportsDashboard.districtAssessmentsReports.assessments')}
      reserveScrollbarWidth
      headerElements={
        <BackButton
          label={t('reportsDashboard.districtAssessmentsReports.dashboard')}
          tooltipLabel={t(
            'reportsDashboard.districtAssessmentsReports.dashboardToolTip'
          )}
          to={'/'}
        />
      }
    >
      <Table
        data={tableData as DistrictReport[]}
        columns={columns}
        enableClientSideSorting
        loading={loading}
        enableYScroll={false}
        emptyMessage={<NoReport />}
        headerProps={{
          py: '2',
          px: '4',
        }}
        bodyProps={{
          py: '2',
          px: '4',
        }}
        renderGroupActions={(table) => (
          <Box
            display="flex"
            w="full"
            px={8}
            py={4}
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
          >
            <Box display="flex" gap={2} alignItems="center">
              <Text variant="h5" color="primary.800">
                {t('reportsDashboard.districtAssessmentsReports.assessments')}
              </Text>
              {!loading && (
                <Tag
                  variant="secondary"
                  bg="primary.30"
                  px={3}
                  fontWeight={600}
                  borderRadius={16}
                >
                  <Text variant="s2" color="primary.700">
                    {tableData.length}
                  </Text>
                </Tag>
              )}
            </Box>
            <Button
              display="none" // TODO remove display="none" when we will implement the filter action
              onClick={() => onOpen()}
              leftIcon={<Icon name="filter" />}
            >
              {t('reportsDashboard.districtAssessmentsReports.filters')}
            </Button>
          </Box>
        )}
      />

      <Filter isOpen={isOpen} onClose={onClose} />
      <MoreDetails
        isOpen={isOpenMoreDetails}
        onClose={onCloseMoreDetails}
        assignmentDetails={assignmentDetails}
      />
    </PageContent>
  );
};

export default DistrictAssessmentsReports;
